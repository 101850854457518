import React, { useEffect, useState, useRef } from "react";
import { Form, Input, List, Modal, Switch, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import "./club-house-admin.scss"
// import { editUserByAdminInitiate } from "../../../redux/actions/adminUsersActions";
import {
  change_menu_status,
  clubhouse_rearrange,
  create_clubhouse,
  create_menu,
  delete_clubhouse,
  delete_menu,
  get_clubhouse,
  get_home_page_team_section,
  get_menu,
  menu_rearrange,
} from "../../../api/Api-list";
import axios from "axios";
import { Button } from "../../admin-components/Buttons/Button";
import { updateHomeTeamSectionInitiate } from "../../../redux/actions/cmsSettingAction";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import audioIcon from "../../images/audio-icon.gif";
import dragIcon from "../../images/drag-icon.png";
import editIcon from "../../images/edit.png";
import deleteIcon from "../../images/delete.png";
import { Editor } from "@tinymce/tinymce-react";


import { get } from "http";
import { Link } from "react-router-dom";
const ClubhouseList = () => {
  const Admin_Token = localStorage.getItem("tiger");
  const [form] = Form.useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { addToast } = useToasts();

  const InputFileImg = useRef(null);
  const InputFileAudio = useRef(null);

  const [menus, setMenus] = useState([]);
  const [clubhouses, setClubhouses] = useState([]);

  const [check, setCheck] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [imgFile, setImgFile] = useState();
  const [imgFilePreview, setImgFilePreview] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [infoData, setInfoData] = useState(null);

  const inputclass =
    "form-control bg-transparent !py-[0.7rem] !rounded-[0.3rem] text-black";

  const config = {
    headers: {
      Authorization: `Bearer ${Admin_Token}`,
    },
  };

  const handleImgChange = (e) => {
    if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      addToast("select valid image", {
        appearance: "error",
        autoDismiss: true,
      });
      setImgFile("");
      return;
    }
    setImgFile(e.target.files[0]);
    setImgFilePreview(URL.createObjectURL(e.target.files[0]));
  };

  const submitUpdate = async () => {
    if(!imgFile ){
        addToast("Image Field is Required", {
            appearance: "error",
            autoDismiss: true,
          });
          return
    }
    if(!infoData){
      addToast("Description Field is Required", {
          appearance: "error",
          autoDismiss: true,
        });
        return
  }

    try {
      let formData = new FormData();
      formData.append("image", imgFile);
      if(audioFile){

        formData.append("audio", audioFile);
      }
      formData.append("info", infoData);

      const response = await axios.post(create_clubhouse, formData, config);
      if (response.status < 400) {
        addToast("Add menu successfully", {
          appearance: "success",
          autoDismiss: true,
          position: "top-center",
        });
        getClubhouse();
        setImgFile("");
        setAudioFile("");
        setImgFilePreview(null)
        setInfoData("");
        setToggle(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getClubhouse = async () => {
    try {
      const response = await axios.get(get_clubhouse(), config);
      if (response.status < 400) {
        console.log(response?.data, "daaa");
        setClubhouses(response?.data?.clubhouses);
      }
    } catch (error) {
      console.log(error);
    }
  };

 

  useEffect(() => {
    getClubhouse();
  }, []);

  const deleteMenu = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Successfully!", "Address Deleted Successfully", "success");

        axios
          .delete(delete_clubhouse(id), config)
          .then((res) => {
            if (res.status === 200) {
              getClubhouse();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

//   const changeMenuStatus = async (id) => {
//     try {
//       const response = await axios.patch(change_menu_status(id), id, config);
//       if (response.status < 400) {
//         addToast("Menu Status Change successfully", {
//           appearance: "success",
//           autoDismiss: true,
//           position: "top-center",
//         });
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(clubhouses);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setClubhouses(tempData);

    let data = tempData.map((temp) => temp._id);

    axios
      .patch(
        clubhouse_rearrange(),
        {
          headerFooterIds: data,
        },
        config
      )
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          getClubhouse();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="create-menu-body">
        <div className="flex items-center justify-between w-full">
          <h2>Add Clubhouse</h2>
          {!toggle ? (
            <button
              onClick={() => setToggle(!toggle)}
              className="text-[20px] font-bold text-center w-full mt-2 bg-[#245ef3] transition-all duration-75 hover:bg-orange-600 text-white py-[10px] rounded-3xl create-menu-btn"
            >
              Add Clubhouse
            </button>
          ) : (
            ""
          )}
        </div>
        {toggle ? (
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            className="edit-club-house-form"
            onFinish={submitUpdate}
          >
            <ul>
              <li className="img-row">
                <h3>Image</h3>
                {imgFilePreview && <img src={imgFilePreview} />}
                <div className="upload-btn" onClick={()=>{
                  InputFileImg.current.click()
                }}>
                  +<br/>
                  Upload
                </div>
                <input
                  type="file"
                  onChange={(e) => handleImgChange(e)}
                  className={inputclass}
                  accept=".jpg,.jpeg, .png"
                  ref={InputFileImg}
                  style={{display: 'none'}}
                />
              </li>

              <li className="audio-row">
                <h3>Audio</h3>
                {/* <img src={audioIcon} alt="Audio Icon" /> */}
                <div className="upload-btn" onClick={()=>{
                  InputFileAudio.current.click()
                }}>
                  +<br/>
                  Upload
                </div>
                <input
                  type="file"
                  onChange={(e) => {
                    if (!e.target.files[0].name.match(/\.(mp3)$/)) {
                      addToast("Select Valid File", {
                        appearance: "error",
                        autoDismiss: true,
                      });
                      setImgFile("");
                      return;
                    }
                    setAudioFile(e.target.files[0]);
                  }}
                  className={inputclass}
                  accept=".mp3,audio/*"
                  ref={InputFileAudio}
                  style={{display: 'none'}}
                />
                <span className="audioSec">{audioFile?.name} 🎵</span>
              </li>
              <li>
                <Editor
                  // onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={(e) => {
                    setInfoData(e);
                  }}
                  init={{
                    height: 300,
                    // automatic_uploads: true,
                    // file_picker_types: "file image media",
                    // paste_data_images: true,
                    // image_advtab: true,
                    // file_picker_callback: function (callback, value, meta) {
                    //   if (meta.filetype == "image") {
                    //     var input = document.createElement("input");
                    //     input.setAttribute("type", "file");
                    //     input.setAttribute("accept", "image/*");
                    //     input.onchange = function () {
                    //       var file = this.files[0];
                    //       var reader = new FileReader();
                    //       reader.onload = function () {
                    //         callback(reader.result, {
                    //           alt: file.name,
                    //         });
                    //       };
                    //       reader.readAsDataURL(file);
                    //     };
                    //     input.click();
                    //   }
                    // },

                    advlist_bullet_styles: "square",
                    advlist_number_styles:
                      "lower-alpha,lower-roman,upper-alpha,upper-r",

                    /* and here's our custom image picker*/
                    toolbar_mode: "sliding",
                  }}
                  value={infoData || ""}
                  plugins={[
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor",
                    "textpattern",
                    "image",
                    "imageupload",
                    "link",
                    "help",
                    "wordcount",
                    "code",
                    "preview",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "paste",
                    "charmap",
                    "searchreplace",
                    "visualblocks",
                    "emoticons",
                    "image paste",
                  ]}
                  toolbar={[
                    "undo redo | formatselect | bold italic backcolor forecolor   |alignleft aligncenter alignright alignjustify | fonts | bullist numlist outdent indent | removeformat | image| help",
                    "link imageupload | fontselect | fontsizeselect |fullscreen | insertdatetime | media | wordcount | charmap | searchreplace | visualblocks |  table | code | preview |emoticons |",
                  ]}
                />
              </li>

              {/* <Switch defaultChecked={check} onChange={() => setCheck(!check)} /> */}

              <br />
              <br />

              <Form.Item>
                <Button
                  type="submit"
                  className="w-full py-2 rounded-md font-semibold text-[1.1rem] !bg-orange-500  text-center text-white hover:!border-none focus:!border-none"
                >
                  Add
                </Button>
              </Form.Item>
            </ul>
          </Form>
        ) : (
          ""
        )}

        <br />
        <br />

        <DragDropContext onDragEnd={handleDragEnd}>
          <table className="table borderd add-club-house-table" style={{ width: "100%" }}>
            {/* <thead>
              {/* <tr>
                <th />
                <th>Menu Name</th>
                <th>Menu Type</th>
                <th>Action</th>
                <th>Active Menu</th>
              </tr> 
            </thead> */}
            <Droppable droppableId="droppable-1">
              {(provider) => (
                <tbody
                  className="text-capitalize"
                  ref={provider.innerRef}
                  {...provider.droppableProps}
                >
                  {clubhouses?.map((item, index) => (
                    <Draggable
                      key={item?._id}
                      draggableId={item?._id}
                      index={index}
                    >
                      {(provider) => (
                        <tr
                          {...provider.draggableProps}
                          ref={provider.innerRef}
                        >
                          <td
                            {...provider.dragHandleProps}
                            className="drag-icon"
                          >
                            <img src={dragIcon} alt="Edit Icon" />
                          </td>
                          <td className="img-clm">
                            <img src={item?.image?.url} />
                          </td>
                          <td className="club-house-description-clm">
                            <div dangerouslySetInnerHTML={{__html: item?.info}} />
                          </td>
                          <td className="create-menu-action club-house-action-clm">
                            <span>
                              {" "}
                              <Link to={`/admin/clubhouse/${item?._id}`}>
                                <img src={editIcon} alt="Edit Icon" />
                              </Link>
                            </span>
                            <span onClick={() => deleteMenu(item?._id)}>
                              <img src={deleteIcon} alt="Edit Icon" />
                            </span>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provider.placeholder}
                </tbody>
              )}
            </Droppable>
          </table>
        </DragDropContext>
      </div>
    </>
  );
};

export default ClubhouseList;