import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
// import storage from "redux-persist/lib/storage";
import rootReducer from "../reducers/rootReducer";

// const persistConfig = {
//   key: "tiger",
//   storage: storage,
//   // whitelist: ['reducer'] // or blacklist to exclude specific reducers
// };

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
