import React, { useState } from "react";
import useEffect from "react";
import { Button, Modal, Form, Input } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./forgot.scss";
// import { useNavigate } from "react-router-dom";
import { forgotPasswordAction } from "../../../redux/actions/forgotPasswordAction";
import { useToasts } from "react-toast-notifications";

const ForgotModal = ({
  forgotModalVisible,
  hideForgotModal,
  setLoginModalVisible,
}) => {
  const { addToast } = useToasts();

  const [form] = Form.useForm();

  const hideModal = () => {
    hideForgotModal();
  };

  const dispatch = useDispatch();
  var selectError = useSelector(
    (state) => state.userForgotPassword.forgotpasswordFailure
  );

  const [error, setError] = useState(false);
  const submitRegister = (e) => {
    e.preventDefault();
    setError(selectError);
    form
      .validateFields()
      .then((values) => {
        dispatch(forgotPasswordAction(values));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        visible={forgotModalVisible}
        title="Forgot Password"
        okText="Forgot Password"
        cancelText="no"
        onCancel={hideModal}
        className="forgot_modal"
      >
        <h5 className="text-white text-center text-[1rem] my-4">
          Enter your email address for reset password link.
        </h5>
        <Form form={form} layout="vertical" name="forgot_form">
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Enter your Email" },
              {
                type: "email",
                message: "please enter valid input",
              },
            ]}
          >
            <Input
              className="!rounded-lg bg-transparent shadow appearance-none border border-gray-300 w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline login-textbox"
              placeholder="Email Address"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              onClick={(e) => submitRegister(e)}
              className="Register-form-button w-full text-center login_button"
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ForgotModal;
