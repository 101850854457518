import React, { useEffect } from "react";

import { Container, Row } from "react-bootstrap";
import BenefitMeet from "../../components/member benefit/benefit meet/BenefitMeet";
import BenefitCard from "../../components/member benefit/benefit card/BenefitCard";
import "./memberBenefit.scss";

import MemberBenefitSlider from "../../components/memberBenefit slider/MemberBenefitSlider";
import { useDispatch, useSelector } from "react-redux";
import { holdersPageInitiate } from "../../redux/actions/holderPageAction";
const MemberBenefit = () => {
  const dispatch = useDispatch();
  const holderPages =
    useSelector((state) => state?.holdersPage?.holdersContent) || [];
  useEffect(() => {
    dispatch(holdersPageInitiate());
  }, []);
  return (
    <div>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>RTGC | Holders</title>
      </Helmet> */}
      <div className="benefit_member">
        <BenefitMeet />
      </div>

      <BenefitCard />

      <Container>
        <div className="my-2">
          <h2 className="font-bold text-white text-center mt-4 heading-design position-relative">
            Eldrick the Roaring Tiger Across the World{" "}
          </h2>
          <MemberBenefitSlider />
        </div>
      </Container>
    </div>
  );
};

export default MemberBenefit;
