import "./order.scss";
import OrderSummary from "./OrderSummary/OrderSummary";

function Order() {
  return (
    <div className="row  container mx-auto successfull_order  !pt-[9rem]">
      <div className="successfull_order_content  ">
        <h2 className=" font-bold text-white text-center mt-4 profile_title">
          Successfully Ordered
        </h2>
      </div>
      <div className="successfull-container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 grid grid-rows-2 md:grid-flow-col grid-flow-row gap-4 place-items-center text-white">
            <div className="left_top_grid_section bg-[#fdfdfd10]  row-span-2 col-span-1 md:col-span-2 col-design text-center thanks-col">
              <img src="images/thumb.png" alt="LikeImage" />
              <h2 className="text-white">Thanks for your order</h2>
              <h4 className="text-white">Order Number: 454970</h4>
              <p className="text-white">
                You will receive an email confirmation shortly
              </p>
              <button className="print-receipt-btn">Print Receipt</button>
            </div>
            <div className="left_top_grid_section row-span-1 bg-[#fdfdfd10] col-span-1 text-center  md:col-span-2 col-design shipping-col">
              <h2 className="text-white heading-pattern position-relative">
                Shipping Address
              </h2>
              <p className="text-gray-300 text-center">
                Frank Ghosh <br />
                @32 Van Horne Cres NE, <br />
                Calgary, AB, T2E 6H1
              </p>
            </div>

            <div className="right_grid_section row-span-3  text-red-300 ">
              <OrderSummary />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Order;
