import { Button, message } from "antd";
import Meta from "antd/lib/card/Meta";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { fav_product, get_clubhouse, remove_fav_product } from "../../../api/Api-list";
import ethIcon from "../../../../src/admin/images/eth-icon.png";
import heartIcon from "../../../../src/admin/images/heart-icon.png";
import "./favProduct.scss";

export default function FavouriteProducts() {
  const [favProduct, setfevPro] = useState();
  const app_token = localStorage.getItem("tiger-token");
  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  const getFavouriteProducts = async () => {
    try {
      const response = await axios.get(fav_product(), config);
      setfevPro(response?.data?.fevProduct);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFavouriteProducts();
  }, []);

  const removeFav = async (id) => {
    try {
      let res = await axios.delete(remove_fav_product(id), config);
      if (res.status < 400) {
        message.success(`${res.data.message}`);
        getFavouriteProducts()
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
    <div className="fav-container">
      {favProduct?.length ? (
        favProduct?.map((ele) => (
          <>
            <div className="fav-item">
              {ele?.images?.[0]?.image ? (
                <img className="fav-itm-img" src={ele?.images?.[0]?.image} />
              ) : (
                  <p style={{ color: "red" }}>add a default image here </p>
              )}
              <div className="fav-itm-text">
                <h3>{ele?.title}</h3>
                <p className="dscrption">
                <div dangerouslySetInnerHTML={{ __html: ele?.description }} /></p>
                <p className="usd-price">${ele?.minPrice}</p>
                { ele?.minEthPrice && <p className="eth-price"> <img src={ethIcon} alt="ETH Icon" /> <span>{ele?.minEthPrice}</span></p>}
                <p className="fav-love-icon" onClick={() => removeFav(ele?._id)}><img src={heartIcon} alt="Heart Icon" /></p>
              </div>
            </div>
          </>
        ))
      ) : (
        <h3 style={{color: "#ffffff", textAlign: "center", marginTop: "50px",}}>No Product Added</h3>
      )}
    </div>
    </>
  );
}
