import React from "react";
import Card from "../card/Card";
import { Container, Row, Col } from "react-bootstrap";
import "./card_container.scss";
import { useSelector } from "react-redux";
const Card_Container = () => {
  const pages = useSelector((state) => state?.cmsPageSetting?.allPage);
  let data = pages?.filter((page) => page?.section === "ROAD MAP");

  return (
    <div className="main_card_container" id="roadmap">
      <Container>
        <h2 className="main_card_container_heading position-relative mb-lg-5 fontHeadingShloppy">
          {data[0]?.sectionName || "Road Map"}
        </h2>
        <div className=" main_sub_card_container">
          <Card />
        </div>
      </Container>
    </div>
  );
};

export default Card_Container;
