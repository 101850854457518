import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Input, Form } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePageAction,
  getSinglePageAction,
} from "../../../redux/actions/seoAction";

function EditPageSeo() {
  const id = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Admin_Token = localStorage.getItem("tiger");
  const singlePage = useSelector((state) => state?.seoData?.singlepage);
  useEffect(() => {
    dispatch(getSinglePageAction(id.id, Admin_Token));
  }, [singlePage?.data?.name]);

  console.log(singlePage, "singlePage");
  const inputclass = "bg-transparent !py-[0.7rem] !rounded-[0.3rem] text-black";

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      name: singlePage?.data?.name,
      pageTitle: singlePage?.data?.pageTitle,
      pageDesc: singlePage?.data?.pageDesc,
      pageKeyword: singlePage?.data?.pageKeyword,
      pageUrl: singlePage?.data?.pageUrl,
    });
  }, [singlePage?.data?.name]);

  const submiseopage = async (e) => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          pageTitle: values.pageTitle,
          pageDesc: values.pageDesc,
          pageKeyword: values.pageKeyword,
          pageUrl: values.pageUrl,
          id: id.id,
        };
        dispatch(updatePageAction(data, Admin_Token));

        console.log(data, "data");
        setTimeout(() => {
          navigate("/admin/pageseo-management");
        }, 2000);
        // dispatch(ProductUpdate(data, Admin_Token));
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  return (
    <>
      <div className="container-fluid edit_product">
        <div className="row">
          <h2 className="cms-title">Edit Page Seo </h2>

          <div className="col-12 col-sm-12 col-md-12 bg-white shadow-sm p-5 cms-box">
            <Form layout="vertical" form={form}>
              <Form.Item
                name="name"
                type="text"
                disabled
                initialValue={singlePage?.name}
                label=" Page Name"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input
                  placeholder="Page Name"
                  disabled={true}
                  className={inputclass}
                />
              </Form.Item>
              <Form.Item
                name="pageTitle"
                type="text"
                initialValue={singlePage?.name}
                label=" Page Title"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Page title" className={inputclass} />
              </Form.Item>

              <Form.Item
                name="pageDesc"
                type="text"
                // initialValue={pagedata.title}
                label=" Page Description"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="meta description" className={inputclass} />
              </Form.Item>
              <Form.Item
                name="pageKeyword"
                type="text"
                label="Page Keywords"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Page Keywords" className={inputclass} />
              </Form.Item>
              <Form.Item
                name="pageUrl"
                type="text"
                label="Page URL"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Page URL" className={inputclass} />
              </Form.Item>
              <Form.Item>
                <button
                  type="submit"
                  onClick={submiseopage}
                  className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] "
                >
                  Update Page Seo
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditPageSeo;
