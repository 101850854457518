import React, { useEffect } from "react";
import "./benefit.scss";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

function Benefit() {
  const pages = useSelector((state) => state?.cmsPageSetting?.allPage);
  let data = pages?.filter((page) => page?.section === "BENEFITS");
  return (
    <div className="benefit_container">
      <Container>
        <Row>
          <Col className="col-lg-6 col-md-12 col-12">
            <div className="benefit_content">
              <h2 className="my-2 fontHeadingShloppy" style={{ fontSize: 45, color: '#1cffec' }}>
                {data[0]?.title[0].heading}
              </h2>
              <ul className="benefit-member-list">
                {data[0]?.title.map((card) => {
                  return (
                    <li
                      key={card._id}
                      className="!text-[1rem] sm:!text-[1.27rem] md:!text-[1.25rem] lg:!text-[1.375rem] "
                    >
                      <span>
                        <AiOutlineCheckCircle />
                      </span>
                      <div
                        dangerouslySetInnerHTML={{ __html: card.description }}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </Col>
          <Col className="col-lg-6 col-md-12 col-12 benefit_image">

            {
              data[0]?.image.length > 0 ? (
                <img src={data[0]?.image[0]?.item} alt="tiger" />
              ) : (
                ""
              )
            }


          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Benefit;
