import axios from "axios";
import { get_single_page_sections } from "../../api/Api-list";
import { GET_HOLDER_PAGE } from "../types/cmsPageType";

export const holdersPageInitiate = () => {
  return function (dispatch) {
    axios
      .get(`${get_single_page_sections}/HOLDERS`)
      .then((response) => {
        dispatch({
          type: GET_HOLDER_PAGE,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};
