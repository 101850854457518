// import React, { useEffect } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";

// import "swiper/css";
// import "swiper/css/pagination";

// import RecentProductCard from "../product/RecentProductCard";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchActiveProducts, recentProducts } from "../../../redux/actions/productAction";
// import "./recentlyAddedSlider.scss";

// const RecentlyAddedSlider = () => {
//   const products = useSelector((state) => state?.allProducts?.getactiveproducts);
//   const dispatch = useDispatch();
//   const data = products.data;

//   console.log(data, "ddd")

//   useEffect(() => {
//     dispatch(recentProducts());
//   }, [dispatch]);

//   return (
//     <>
//       <Swiper
//         slidesPerView={6}
//         spaceBetween={30}
//         className="mySwiper_recently_added"
//         breakpoints={{
//           // when window width is >= 640px
//           1900: {
//             width: 2000,
//             slidesPerView: 6,
//           },
//           1500: {
//             width: 1500,
//             slidesPerView: 3.7,
//           },
//           1300: {
//             width: 1300,
//             slidesPerView: 3.3,
//           },
//           1100: {
//             width: 1100,
//             slidesPerView: 3.2,
//           },
//           992: {
//             width: 992,
//             slidesPerView: 3.1,
//           },
//           778: {
//             width: 778,
//             slidesPerView: 2.9,
//           },
//           640: {
//             width: 640,
//             slidesPerView: 2,
//           },
//           440: {
//             width: 440,
//             slidesPerView: 2,
//           },
//           210: {
//             width: 210,
//             slidesPerView: 1,
//           },
//         }}
//       >
//         {data &&
//           data.map((product) =>
//             product.isActive === true ? (
//               <SwiperSlide key={product._id}>
//                 <RecentProductCard
//                   key={product._id}
//                   id={product._id}
//                   product={product}
//                 />
//               </SwiperSlide>
//             ) : null
//           )}
//       </Swiper>
//     </>
//   );
// };

// export default RecentlyAddedSlider;

import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

import RecentProductCard from "../product/RecentProductCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchActiveProducts } from "../../../redux/actions/productAction";
import "./recentlyAddedSlider.scss";
import { recentProducts } from "../../../api/Api-list";
import axios from "axios";

const RecentlyAddedSlider = () => {
  // const products = useSelector((state) => state?.allProducts?.getactiveproducts);
  // const dispatch = useDispatch();
  // const data = products.data;
  const [data, setData] = useState([]);
  const [oneEth, setOneEth] = useState(0);

  const getRecentSliderData = () => {
    axios
      .get(recentProducts(1, 12, "desc", "date"))
      .then((response) => {
        setData(response?.data?.allProduct?.data);
        setOneEth(response?.data?.oneETH);
        console.log(response?.data?.allProduct, "dddd");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getRecentSliderData();
  }, []);

  return (
    <>
      <Swiper
        modules={[Navigation]}
        slidesPerView={4}
        spaceBetween={7}
        autoplay={true}
        grabCursor={true}
        navigation
        observer={true}
        observeParents={true}
        className="mySwiper_recently_added"
        breakpoints={{
          // when window width is >= 640px
          1900: {
            width: 2000,
            slidesPerView: 4,
          },
          1500: {
            width: 1500,
            slidesPerView: 4,
          },
          1300: {
            width: 1300,
            slidesPerView: 4,
          },
          1100: {
            width: 1100,
            slidesPerView: 3,
          },
          992: {
            width: 992,
            slidesPerView: 3,
          },
          778: {
            width: 778,
            slidesPerView: 2,
          },
          640: {
            width: 640,
            slidesPerView: 2,
          },
          440: {
            width: 440,
            slidesPerView: 2,
          },
          210: {
            width: 210,
            slidesPerView: 1,
          },
        }}
      >
        {data &&
          data.map((product) =>
            product.isActive === true ? (
              <SwiperSlide key={product._id}>
                <RecentProductCard
                  key={product._id}
                  id={product._id}
                  product={product}
                  oneEth={oneEth}
                />
              </SwiperSlide>
            ) : null
          )}
      </Swiper>
    </>
  );
};

export default RecentlyAddedSlider;
