import React, { useState } from "react";
import { Button, Modal, Form } from "antd";
import "./login.scss";
import { useLocation, useNavigate } from "react-router-dom";
import RegisterModal from "../register/Register";
import ForgotModal from "../forgot/ForgotModal";
import VerifyModal from "../verify/VerifyModal";
import { postRequest } from "../../../api/API";
import { login } from "../../../api/Api-list";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import * as types from "../../../../src/redux/types/usertypes";
import { useCookies } from "react-cookie";

export const LoginModal = ({
  loginModalVisible,
  hideLoginModal,
  setUser,
  setLoginModalVisible,
  setNavbarContainer,
}) => {
  const navigate = useNavigate();
  const [VerifyModalVisible, setVerifyModalVisible] = useState(false);
  const hideVerifyModal = () => {
    setVerifyModalVisible(false);
  };

  const dispatch = useDispatch();

  const [useremail, setUserEmail] = useState("");
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [forgotModalVisible, setForgotModalVisible] = useState(false);

  const [form] = Form.useForm();
  const location = useLocation();

  const hideModal = () => {
    hideLoginModal();
  };
  const hideRegisterModal = () => {
    setRegisterModalVisible(false);
  };
  const hideForgotModal = () => {
    setForgotModalVisible(false);
  };

  const loginUser = () => {
    try {
      form
        .validateFields()
        .then(async (values) => {
          try {
            await postRequest(login, {
              ...values,
            })
              .then((res) => {
                console.log(res);
                form.resetFields();
                hideModal();
                if (location?.hash) {
                  let pid = location?.hash?.replace("#?product=", "");
                  navigate(`/prop-detail/${pid}`);
                } else {
                  navigate("/");
                }
                addToast("login successfully", {
                  appearance: "success",
                  autoDismiss: true,
                  position: "top-center",
                });
                setNavbarContainer(
                  "navbar_container menu_bar text-white border-gradient"
                );
                console.log(res.data.access_token);

                setError("");
                setLoading(false);
                setUserEmail(values.email);
                setUser(true);
                localStorage.setItem("tiger-token", res.data.access_token);
                dispatch({
                  type: types.USER_LOGIN_SUCCESS,
                  payload: res.data,
                });
                console.log(res);
              })
              .catch((err) => {
                console.log("errror", err.response.data.msg);

                if (err.response.data.msg === "User not Verified") {
                  dispatch({
                    type: types.USER_REGISTRATION_SUCCESS,
                    payload: values.email,
                  });
                  hideModal();
                  setVerifyModalVisible(true);
                  addToast("Please verify your account first ", {
                    appearance: "error",
                    autoDismiss: true,
                    position: "top-center",
                  });
                } else {
                  addToast(err.response.data.msg, {
                    appearance: "error",
                    autoDismiss: true,
                    position: "top-center",
                  });
                }
                // setError(err.response.data.msg);
                // setLoading(true);
              });
          } catch (err) {
            console.log("register err", err);
          }
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    } catch (err) {}
  };
  return (
    <>
      <Modal
        visible={loginModalVisible}
        title="Login"
        okText="Login"
        cancelText="no"
        onCancel={hideModal}
        className="login_modal"
      >
        {loading && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
          onFinish={loginUser}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Enter your Email" },
              {
                type: "email",
                message: "please enter valid input",
              },
            ]}
            className="!mb-[20px]"
          >
            {/* <label className="form-label">Email Address</label> */}
            <input
              type="email"
              name="email"
              label="Email Address"
              className=" rounded-lg bg-transparent shadow appearance-none border-[1.4px] border-[#838280] w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline login-textbox"
              placeholder="example@thoughtmedia.com"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                min: 6,
                message: "Enter your password",
              },
            ]}
          >
            {/* <label className="form-label">Password</label> */}
            <input
              type="password"
              name="password"
              className=" rounded-lg bg-transparent  appearance-none border-[1.4px] border-[#838280]  w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline login-textbox"
              placeholder="password"
            />
          </Form.Item>
          <Form.Item className="mt-[-20px]">
            <button
              onClick={() => {
                setForgotModalVisible(true);
                setLoginModalVisible(false);
              }}
              type="button"
              className="text-gray-500 float-right hover:text-orange-300 forgot-password"
            >
              Forgot password
            </button>
          </Form.Item>

          <Form.Item className="!mb-[10px]">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button w-full  text-center login_button"
            >
              Log in
            </Button>
          </Form.Item>
          <Form.Item className="mb-0">
            <div className=" w-full  text-center text-gray-100">
              Don't have a RTGC account?
              <span
                className="text-gray-100 hover:text-orange-300 cursor-pointer pl-2"
                onClick={() => {
                  setRegisterModalVisible(true);
                  hideModal();
                }}
              >
                Sign up
              </span>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <RegisterModal
        registerModalVisible={registerModalVisible}
        hideRegisterModal={hideRegisterModal}
        setLoginModalVisible={setLoginModalVisible}
      />
      <ForgotModal
        forgotModalVisible={forgotModalVisible}
        setLoginModalVisible={setLoginModalVisible}
        hideForgotModal={hideForgotModal}
      />
      <VerifyModal
        verifyModalVisible={VerifyModalVisible}
        hideverifyModal={hideVerifyModal}
        email={useremail}
        otpTime={2}
      />
    </>
  );
};

// export LoginModal;
