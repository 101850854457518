import React, { useState, useEffect } from "react";
import { Row, Col, Input, Modal, Form } from "antd";
import "./profile.scss";
import profilePic from "../../images/user-picture.jpg";
import { useNavigate } from "react-router-dom";
// import { adminGetRequest, adminPostRequest } from "../../../API";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
import "antd/dist/antd.css";

import {
  changeAdminPassword,
  fetchAdminProfile,
  updateAdminProfile,
} from "../../../redux/actions/adminLoginRegisterActions";

// const { Search } = Input;
export default function Profile(props) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.adminProfile.user);
  // const { addToast } = useToasts();
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [profileForm] = Form.useForm();
  const [passwordForm] = Form.useForm();

  const [isProfileModalVisible, setisProfileModalVisible] = useState(false);
  const [ispasswordVisible, setpasswordVisible] = useState(false);
  const Admin_Token = localStorage.getItem("tiger");

  const profileModal = () => {
    setisProfileModalVisible(true); //
    profileForm.setFieldsValue(data);
  };

  const profilehandleCancel = () => {
    setisProfileModalVisible(false);
  };

  const passwordModal = () => {
    setpasswordVisible(true); //
  };

  const passwordhandleCancel = () => {
    setpasswordVisible(false);
  };

  //

  useEffect(() => {
    try {
      dispatch(fetchAdminProfile(Admin_Token));
      setData(selector);
    } catch (err) {
      console.log(err);
    }
  }, [dispatch, selector.firstName]);

  const updateProfile = () => {
    setError("");
    setLoading(false);
    try {
      profileForm
        .validateFields()
        .then(async (values) => {
          try {
            dispatch(
              updateAdminProfile(
                values,
                Admin_Token,
                profilehandleCancel,
                history
              )
            );
            localStorage.setItem("tiger", JSON.stringify(Admin_Token));
          } catch (err) {
            console.log("register err", err);
          }
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const changePassword = () => {
    try {
      passwordForm
        .validateFields()
        .then(async (values) => {
          try {
            dispatch(
              changeAdminPassword(values, Admin_Token, passwordhandleCancel)
            );
          } catch (err) {
            console.log("register err", err);
          }
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const inputclass = "bg-transparent !py-[0.7rem] !rounded-[0.3rem] text-black";
  const changePass =
    "bg-[#B70100] text-white transition-all ease-out duration-200 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] ";
  const updatePass =
    "bg-[#313E49] text-white transition-all ease-out duration-200 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#B70100] ";
  return (
    <div>
      {true && (
        <Row gutter={16}>
          <Col sm={24} xl={12}>
            <h2>Profile</h2>
          </Col>
          <Col sm={24} xl={12} className="text-right">
            <button className={changePass} onClick={passwordModal}>
              Change Password
            </button>
            &nbsp;&nbsp;
            <button className={updatePass} onClick={profileModal}>
              Update Profile
            </button>
          </Col>
          <Col span={24}>
            <Row>
              <Col lg={24} className="profile-container">
                <div className="profile-block">
                  <Row>
                    <Col lg={6} md={6} sm={24} className="position-relative">
                      <img
                        className="img-fluid profile-picture parent_img"
                        src={profilePic}
                        alt="UserProfileImage"
                      />

                      <h2 className="text-center my-3 admin-name">
                        {data?.firstName} {data?.lastName}
                      </h2>
                    </Col>
                    <Col lg={18} md={18} sm={24} className="profile-text ">
                      <Row>
                        <Col span={24} className="form-col">
                          <div className="d-flex">
                            <label className="form-label">
                              <strong>First Name :</strong>
                            </label>
                            <span>{data?.firstName}</span>
                          </div>
                        </Col>
                        <Col span={24} className="form-col">
                          <div className="d-flex">
                            <label className="form-label">
                              <strong>Last Name :</strong>
                            </label>
                            <span>{data?.lastName}</span>
                          </div>
                        </Col>
                        <Col span={24} className="form-col">
                          <div className="d-flex">
                            <label className="form-label">
                              <strong>Email Address :</strong>
                            </label>
                            <span>{data?.email}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={2}></Col>
              <Col lg={8}></Col>
            </Row>
          </Col>
        </Row>
      )}
      <div className="prf">
        <Modal
          title="Update Profile"
          visible={isProfileModalVisible}
          onCancel={profilehandleCancel}
          footer={null}
          className="custom-modal"
          onfinish={updateProfile}
        >
          <Form
            form={profileForm}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            {loading && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: "Please input your first name!" },
              ]}
            >
              <Input className={inputclass} />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: "Please input your last name!" },
              ]}
            >
              <Input className={inputclass} />
            </Form.Item>

            <Form.Item
              label="Email Id"
              name="email"
              rules={[
                { required: true, message: "Please input your user name!" },
                { type: "email", message: "Please enter valid Email!" },
              ]}
            >
              <Input className={inputclass} />
            </Form.Item>

            <Row>
              <Col span={24} className="button-row">
                <button className="cancel_btn" onClick={profilehandleCancel}>
                  Cancel
                </button>
                <button className="update_btn" onClick={updateProfile}>
                  Update
                </button>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title="Change Password"
          visible={ispasswordVisible}
          onCancel={passwordhandleCancel}
          footer={null}
          className="custom-modal"
        >
          <Form
            form={passwordForm}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password placeholder="Old Password" />
            </Form.Item>

            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  min: 6,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                className={inputclass}
                placeholder="New Password"
              />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              dependencies={["newPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  min: 6,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
            <Row>
              <Col span={24} className="button-row">
                <button className="cancel_btn" onClick={passwordhandleCancel}>
                  Cancel
                </button>
                <button className="update_btn" onClick={changePassword}>
                  Update
                </button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </div>
  );
}
