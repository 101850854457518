import { ActionType } from "../types/productType";
import { message } from "antd";
import axios from "axios";
import {
  addProduct,
  addProductCategory,
  delete_Products,
  delete_product_image,
  editProductCategory,
  fetchAllActiveProducts,
  fetch_Single_Product,
  getSingleCategory,
  productCategory,
  update_product,
} from "../../api/Api-list";
import { fetchAllProducts } from "../../api/Api-list";

export const setProduct = (products) => {
  return {
    type: ActionType.SET_PRODUCTS,
    payload: products,
  };
};
export const selectedProduct = (products) => {
  return {
    type: ActionType.SELECTED_PRODUCT,
    payload: products,
  };
};

const getProductCategories = (data) => {
  return {
    type: ActionType.PRODUCT_CATEGORY,
    payload: data,
  };
};

const productAdd = (response) => {
  return {
    type: ActionType.ADD_PRODUCT,
    payload: response.data,
  };
};

export const ProductUpdate = (product, Admin_Token) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Admin_Token}`,
      },
    };

    axios
      .post(update_product, product, config)

      .then((response) => {
        // console.log(response.data, "category");
        if (response?.status < 400) {
          message.success("Product Updated Successfully");
          dispatch({
            type: ActionType.UPDATE_PRODUCT,
            payload: response.data,
          });
        } else {
          message.error(`${response?.data?.msg}`);
        }
      })
      .catch((err) => {
        console.log("error", err);

        message.error(err.response.msg);
      });
  };
};
export const fetchProducts = (page, size) => {
  return (dispatch) => {
    axios
      .get(fetchAllProducts(page, size))
      .then((response) => {
        console.log(response.data?.allProduct?.data, "res");
        dispatch({
          type: ActionType.SELECT_PRODUCT,
          payload: response.data?.allProduct,
        });

        return response.data?.allProduct;
      })
      .catch((error) => {
        console.log(error.response.msg);
        message.error(error.response.msg);
      });
  };
};

export const recentProducts = (orderKey, orderType) => {
  return (dispatch) => {
    const params = {
      orderKey: orderKey || undefined,
      orderType: orderType || undefined,
    };
    axios
      .get(fetchAllActiveProducts(), params)
      .then((response) => {
        dispatch({
          type: ActionType.GET_ACTIVE_PRODUCTS,
          payload: response.data?.allProduct,
        });

        return response.data?.allProduct;
      })
      .catch((error) => {
        console.log(error.response.msg);
        message.error(error.response.msg);
      });
  };
};

export const fetchActiveProducts = (
  page,
  size,
  keywords,
  orderKey,
  orderType,
  filter,
  category,
  color,
  itemSize
) => {
  const app_token = localStorage?.getItem("tiger-token");
  let sendParams = {};
  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };
  return (dispatch) => {
    const params = {
      page: page || undefined,
      size: size || undefined,
      keywords: keywords || undefined,
      orderKey: orderKey || undefined,
      orderType: orderType == "null" ? "asc" : orderType || undefined,
      filter: filter || undefined,
      category: category || undefined,
      color: color || undefined,
      itemSize: itemSize || undefined,
    };
    Object.assign(sendParams, { params });
    if (app_token) {
      Object.assign(sendParams, config);
    }
    console.log(sendParams, "sendparams");
    axios
      .get(fetchAllActiveProducts(), sendParams)
      .then((response) => {
        dispatch({
          type: ActionType.GET_ACTIVE_PRODUCTS,
          payload: {
            allProduct: response.data?.allProduct,
            oneEth: response.data.oneETH,
          },
        });

        return response.data?.allProduct;
      })
      .catch((error) => {
        console.log(error.response.msg);
        message.error(error.response.msg);
      });
  };
};
export const CategoryList = () => {
  return (dispatch) => {
    axios
      .get(productCategory)
      .then((response) => {
        // console.log(response.data, "category");
        dispatch(getProductCategories(response.data));
      })
      .catch((error) => {
        console.log("get_all_content error.message", error.response);
      });
  };
};

export const AddNewCategory = (category, Admin_Token) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Admin_Token}`,
      },
    };
    axios
      .post(addProductCategory, category, config)
      .then((response) => {
        // console.log(response.data, "category");
        if (response.status < 400) {
          message.success("Category Added Successfully");
          dispatch({
            type: ActionType.ADD_CATEGORY,
            payload: response.data,
          });

          // dispatch(CategoryList());
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        // message.error(error.response.data.msg);
      });
  };
};

// select single category
export const selectSingleCategory = (id) => {
  console.log(id, "id");
  return (dispatch) => {
    axios
      .post(getSingleCategory, {
        _id: id.id,
      })
      .then((response) => {
        // console.log(response.data, "category");
        dispatch({
          type: ActionType.SELECT_SINGLE_CATEGORY,
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        console.log("get_all_content error.message", error.response);
      });
  };
};

export const UpdateCategory = (newdata, Admin_Token) => {
  console.log(newdata, "new data");
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Admin_Token}`,
      },
    };

    axios
      .post(editProductCategory, { ...newdata }, config)
      .then((response) => {
        // console.log(response.data, "category");
        message.success("Category Updated Successfully");
        dispatch({
          type: ActionType.UPDATE_CATEGORY,
          payload: response.data,
        });
        // window.location.reload(false);
      })
      .catch((error) => {
        console.log("error", error.response);
        // message.error(error.response.data.msg);
      });
  };
};

export const NewProductAdd = (product, Admin_Token) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Admin_Token}`,
      },
    };

    axios
      .post(
        addProduct,
        product,

        config
      )

      .then((response) => {
        console.log(response.data, "category");

        dispatch(productAdd(response));
        message.success("Product Added Successfully");
        return response.data;
      })
      .catch((error) => {
        // console.log("medf", error.response);
        message.error(error.response.data.msg);
      });
  };
};
const productDelete = (id) => {
  return {
    type: ActionType.DELETE_PRODUCT,
    payload: id,
  };
};
export const deleteProducts = (id, Admin_Token) => {
  return (dispatch) => {
    dispatch(productDelete(id));
    axios
      .delete(delete_Products, {
        data: {
          _id: id,
        },
        headers: {
          Authorization: `Bearer ${Admin_Token}`,
        },
      })

      .then((response) => {
        console.log(response.data, "response.data");
        dispatch({
          type: ActionType.DELETE_PRODUCT,
          payload: response.data,
        });
        message.success("Product Deleted Successfully");
      })
      .catch((error) => {
        console.log(error.response);
        message.error(`${error.response.data.msg}`);
      });
  };
};

const singleProduct = (data) => {
  return {
    type: ActionType.SELECT_SINGLE_PRODUCT,
    payload: data,
  };
};
// get Single Product
export const fetchSingleProduct = (id) => {
  return (dispatch) => {
    axios
      .get(`${fetch_Single_Product}/${id.id}`)
      .then((response) => {
        dispatch(singleProduct(response.data));
        return response.data;
      })
      .catch((error) => {
        message.error(error.response.msg);
      });
  };
};

const deleteproductimage = (data) => {
  return {
    type: ActionType.DELETE_PRODUCT_IMAGE,
    payload: data,
  };
};
export const deleteProductImage = (productId, ImageId, Admin_Token) => {
  return (dispatch) => {
    axios
      .delete(delete_product_image, {
        data: {
          productId: productId,
          imageId: ImageId,
        },
        headers: {
          Authorization: `Bearer ${Admin_Token}`,
        },
      })

      .then((response) => {
        console.log(response.data, "response.data");
        dispatch(deleteproductimage(response.data));
        message.success(`${response.data.message}`);
        fetchSingleProduct(productId)
      })
      .catch((error) => {
        console.log(error.response);
        message.success(`${error.response.data.msg}`);
      });
  };
};
