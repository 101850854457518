import React, { useState, useEffect } from "react";
import ProShopSlider from "../../components/proshop-page/slider/slider-full/ProShopSlider";
import { Row, Col } from "antd";
import { Container } from "react-bootstrap";
import "antd/dist/antd.css";
import Helmet from "react-helmet";
import ProductCardFIlter from "../../components/proshop-page/product/ProductCardFIlter";
import RecentlyAddedSlider from "../../components/proshop-page/recently added/RecentlyAddedSlider";
import PaginationCard from "../../components/proshop-page/paginationCard/PaginationCard";
import { useDispatch, useSelector } from "react-redux";
import { CategoryList } from "../../redux/actions/productAction";
import TopProducts from "../../components/proshop-page/product/TopProducts";

function ProShop() {
  const dispatch = useDispatch();
  const fetchCategories = useSelector(
    (state) => state?.allProducts?.categories
  );
  const [totalItems, setTotalItems] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [orderKey, setOrderKey] = useState("minPrice");
  const [sort, setSort] = useState("asc");

  useEffect(() => {
    dispatch(CategoryList());
  }, []);

  const paginate = (e) => {
    setPageNum(e.selected + 1);
  };

  let topCategory = fetchCategories?.data?.filter(
    (ele) => ele?.isActive == true && ele?.isTop == true
  );

  return (
    <div className="my-3">
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>RTGC | Pro shop</title>
      </Helmet> */}
      {topCategory?.length ? (
        <div className="my-5 flex flex-col items-center justify-center">
          <h2 className="sec-hdn text-white text-center mt-3 font-bold text-[40px]">
            Top Categories
          </h2>
          <img
            src="images/dot_border.png"
            alt="borderimage"
            className="text-center d-inline mb-8 mt-2"
          />
          <Container>
            <ProShopSlider data={topCategory || []} />
          </Container>
        </div>
      ) : (
        ""
      )}
      <div
        className={`my-8  py-8 ${topCategory?.length ? "bg-[#201f1f]" : ""}`}
      >
        <Container>
          <Row>
            <Col span={24} className="text-center mb-3">
              <h2 className="sec-hdn text-white text-center mt-2 font-bold text-[40px]">
                Recently Added Products
              </h2>
              <img
                src="images/dot_border.png"
                alt="borderimage"
                className="text-center d-inline mb-8 mt-2"
              />
            </Col>
          </Row>
          <Row gutter={[24, 8]} justify="center">
            <RecentlyAddedSlider />
          </Row>
        </Container>
      </div>

      <div className="my-8  py-8 ">
        <TopProducts />
      </div>

      <div
        className={`my-5 py-10 ${topCategory?.length ? "bg-[#201f1f]" : ""}`}
      >
        <Container>
          <div className="w-full max-w-[1430px] mx-auto">
            <ProductCardFIlter
              pageNum={pageNum}
              pageSize={pageSize}
              setPageNum={setPageNum}
              selectedCategory={selectedCategory}
              orderKey={orderKey}
              setSelectedCategory={setSelectedCategory}
              setOrderKey={setOrderKey}
              sort={sort}
              setSort={setSort}
            />
          </div>
          <PaginationCard
            paginate={paginate}
            totalItems={totalItems}
            lastPage={lastPage}
            pageNum={pageNum}
            pageSize={pageSize}
            setTotalItems={setTotalItems}
            setLastPage={setLastPage}
            setPageNum={setPageNum}
            setPageSize={setPageSize}
            selectedCategory={selectedCategory}
            orderKey={orderKey}
            sort={sort}
            setSort={setSort}
          />
        </Container>
      </div>
    </div>
  );
}

export default ProShop;
