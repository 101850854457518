import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./propProductDetail.scss";
import { Form, Select } from "antd";
import DetailSlider from "./Product-detail-slider/DetailSlider";
import { add_to_cart, add_to_cart_with_cookie } from "../../api/Api-list";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { getTotalCartItem } from "../../redux/actions/cartSectionAction";
import Seo from "../Seo";
import getSingleProduct from "../../redux/actions/singleProductActions";
import { useCookies } from "react-cookie";
const { Option } = Select;

const PropProductDetail = () => {
  const singleProduct = useSelector((state) => state.singleProduct.data);
  const oneEth = useSelector((state) => state.singleProduct.oneEth);

  const [images, setImages] = useState([]);
  const [color, setColor] = useState(null);
  const [size, setSize] = useState(null);
  const [pricedata, setPriceData] = useState(null);
  const [qut, setQut] = useState(1);
  const [selectedPriceId, setSelectedPriceId] = useState(null);

  const [isOutOfStock, setIsOutOfStock] = useState(false);

  const isAuthenticated = useSelector(
    (state) => state.userProfile.isAuthenticated
  );

  const { id } = useParams();
  const { addToast } = useToasts();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const app_token = localStorage.getItem("tiger-token");
  const rtgcCartId = localStorage.getItem("rtgcCartId");

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  // setProducts(response?.data?.data);
  // setPriceData(response?.data?.data?.minPrice);
  // setColor(response?.data?.data?.color?.[0]);
  // setRelatedProducts(response?.data?.data?.relatedProducts);

  useEffect(() => {
    dispatch(getSingleProduct(id));
  }, [id]);

  useEffect(() => {
    console.log("helllooo");
    let images = singleProduct?.images ? singleProduct.images : [];
    let newimages = [];
    for (let item of images) {
      newimages.push({
        original: item.image,
        thumbnail: item.image,
        originalTitle: item?.text,
        thumbnailTitle: item?.text,
      });
    }
    setImages(newimages);
    setPriceData(singleProduct?.minPrice);
    setColor(singleProduct?.color?.[0]);
  }, [singleProduct]);

  const addToCartHandler = async (product) => {
    if (!color) {
      addToast("Please Select a Color", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (!size) {
      addToast("Please Select Size", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (!qut) {
      addToast("Please Select Quantity", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    if (isAuthenticated) {
      const payload = {
        items: {
          productId: singleProduct._id,
          priceTierId: selectedPriceId,
          quantity: Number(qut),
        },
      };

      try {
        const response = await axios.post(add_to_cart, payload, config);
        if (response.status < 400) {
          dispatch(getTotalCartItem(app_token, isAuthenticated, rtgcCartId));
          addToast(response.data.message || response.data.msg, {
            appearance: "success",
            autoDismiss: true,
          });
        }
      } catch (err) {
        addToast(err.response.data.message || err.response.data.msg, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else if (!isAuthenticated && rtgcCartId !== null) {
      // console.log("object");
      // navigate("#?addToCart");

      const payload = {
        cookieId: rtgcCartId,
        items: {
          productId: singleProduct._id,
          priceTierId: selectedPriceId,
          quantity: Number(qut),
        },
      };

      try {
        const response = await axios.post(add_to_cart_with_cookie, payload);
        if (response.status < 400) {
          dispatch(getTotalCartItem(app_token, isAuthenticated, rtgcCartId));
          addToast(response.data.message || response.data.msg, {
            appearance: "success",
            autoDismiss: true,
          });
        }
      } catch (err) {
        addToast(err.response.data.message || err.response.data.msg, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };

  const priceUpdate = (selectedSize, selectedColor) => {
    console.log(selectedSize, selectedColor, "cl");
    let fa = singleProduct?.priceList?.find(
      (ele) => ele.size == selectedSize && ele.color == selectedColor
    );

    console.log(fa, "cll");

    setPriceData(fa?.price);
    setSelectedPriceId(fa?._id);
  };

  const checkProductAvailability = (size, color) => {
    const outOfStock = singleProduct?.priceList?.filter((item) => {
      return item.color === color && item.size === size && item.quantity === 0;
    });
    if (outOfStock.length > 0) {
      setIsOutOfStock(true);
    } else {
      setIsOutOfStock(false);
    }
  };

  return (
    <>
      <Seo
        title={
          singleProduct?.metaInfo?.pageTitle
            ? singleProduct?.metaInfo?.pageTitle
            : ""
        }
        desc={singleProduct?.metaInfo?.pageDesc}
        keywords={singleProduct?.metaInfo?.keywords}
      />
      <div className="product_card_filter flex" style={{ paddingTop: 200 }}>
        <Container>
          <Row className=" flex">
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <div>
                {images?.length ? (
                  <ImageGallery
                    showPlayButton={false}
                    thumbnailPosition={"left"}
                    showThumbnails={true}
                    items={images}
                  />
                ) : (
                  <img src="../images/noimg.png" />
                )}
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-12 ">
              <div className="content">
                <div className="product-code">
                  <h2 style={{ color: "#fff" }}>
                    {" "}
                    <strong>{singleProduct?.title}</strong>
                  </h2>
                </div>
                {singleProduct?.category?.[0]?.categoryName && (
                  <div className="product-category">
                    Category: {singleProduct?.category?.[0]?.categoryName}
                  </div>
                )}

                {!singleProduct?.isComingSoon && (
                  <div className="flex space-x-2 items-center space-between product-price-row">
                    {pricedata && (
                      <>
                        <span className="product-card-usd">${pricedata}</span>

                        <span className="product-card-ethereum">
                          {(pricedata / oneEth).toFixed(5)}
                          <img src="/images/eth-icon.png" alt="ethicons" />
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>
              {!singleProduct?.isComingSoon && (
                <Form
                  initialValues={{ remember: true }}
                  autoComplete="off"
                  name="horizontal_login"
                  className="mx-auto flex items-center flex-col sm:flex-row gap-4 justify-center md:justify-between my-4  "
                >
                  <div className="flex flex-row items-center !gap-4 flex-wrap content_price_form">
                    <input
                      type="number"
                      min={1}
                      value={qut}
                      onChange={(e) => {
                        setQut(e.target.value);
                      }}
                    />
                    {/* <Form.Item
                    name="Size"
                    className="!w-ful  !bg-[#ffffff08]  !mb-0"
                  > */}
                    <Select
                      style={{ width: "150px" }}
                      placeholder="Size"
                      value={size}
                      onChange={(val) => {
                        setQut(1);
                        setSize(val);
                        priceUpdate(val, color);
                        checkProductAvailability(val, color);
                      }}
                    >
                      {singleProduct?.size
                        ? singleProduct?.size?.map((it, key) => (
                            <Option key={it} value={it}>
                              {it}
                            </Option>
                          ))
                        : null}
                    </Select>
                    {/* </Form.Item> */}
                    <Form.Item className=" !bg-transparent !mb-0">
                      <div className="flex flex-col">
                        <div className="text-sm text-gray-100">Color:</div>
                        <div className="flex mt-1">
                          {singleProduct?.color
                            ? singleProduct?.color?.map((it, key) => (
                                <div
                                  onClick={() => {
                                    setQut(1);

                                    if (!size) {
                                      addToast("Please Select a Size", {
                                        appearance: "error",
                                        autoDismiss: true,
                                      });
                                      return;
                                    } else {
                                      priceUpdate(size, it);
                                      setColor(it);
                                      checkProductAvailability(size, it);
                                    }
                                  }}
                                  key={key}
                                  className="w-4 h-4 ml-2 rounded-full"
                                  style={{
                                    cursor: "pointer",
                                    opacity: it == color ? 1 : "0.5",
                                    background: it,
                                  }}
                                ></div>
                              ))
                            : null}
                        </div>
                      </div>
                    </Form.Item>
                  </div>
                </Form>
              )}
              {isOutOfStock && (
                <p className="text-red-500 text-base">
                  Currently out of stock!
                </p>
              )}

              <div className="add_cart_btn">
                <button
                  onClick={() => {
                    if (singleProduct?.isComingSoon) return;
                    addToCartHandler(singleProduct);
                  }}
                  className="add-to-cart-btn text-[20px] transition-all duration-75 hover:!bg-orange-600"
                  disabled={isOutOfStock || singleProduct?.isComingSoon}
                >
                  {singleProduct?.isComingSoon ? "Coming Soon" : "Add to Cart"}
                </button>
              </div>

              {singleProduct?.description && (
                <div className="flex flex-col mx-auto mt-4 product_card_filter_description">
                  <div>
                    <h2 className="font-bold text-[20px] text-white position-relative mb-0">
                      Description
                    </h2>
                    <img
                      src="/images/dot_border.png"
                      alt="borderimage"
                      className="text-center d-inline mb-8 mt-2 border_image"
                    />
                  </div>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: singleProduct?.description
                        ? singleProduct?.description
                        : "",
                    }}
                    className="text-[17px]  text-gray-200 mb-4 description-text"
                  ></p>
                </div>
              )}
            </div>
            {singleProduct?.relatedProducts?.length > 0 && (
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 related-products-wrapper">
                <div className="flex flex-col  items-center justify-center mx-auto mt-8 ">
                  <h2 className=" text-white text-center mt-3 font-bold text-[40px]">
                    Related Products
                  </h2>
                  <img
                    src="/images/dot_border.png"
                    alt="borderimage"
                    className="text-center d-inline mb-8 mt-2"
                  ></img>
                </div>
                <DetailSlider
                  relatedProducts={singleProduct?.relatedProducts}
                  productId={id}
                  setSize={() => setSize(null)}
                  oneEth={oneEth}
                />
              </div>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PropProductDetail;
