import React, { useEffect, useState } from "react";
import { Form, Input, Switch } from "antd";
import { Button } from "../../../../admin-components/Buttons/Button";
import { useDispatch } from "react-redux";
import "./EditUser.scss";
import { useParams, useNavigate } from "react-router-dom";
import { get_single_user } from "../../../../../api/Api-list";
import { editUserByAdminInitiate } from "../../../../../redux/actions/adminUsersActions";
import axios from "axios";
// --------------------------------------Edit user Details Component --------------------------------------------------
const EditUser = () => {
  const inputclass = "bg-transparent !py-[0.7rem] !rounded-[0.3rem] text-black";
  const Admin_Token = localStorage.getItem("tiger");
  const [form] = Form.useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [data, setData] = useState({});

  const submitUpdate = () => {
    form
      .validateFields()
      .then(async (values) => {
        dispatch(editUserByAdminInitiate(values, Admin_Token, id, history));
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    async function getdata() {
      const config = {
        headers: {
          Authorization: `Bearer ${Admin_Token}`,
        },
      };
      await axios
        .post(
          get_single_user,
          {
            _id: id,
          },

          config
        )
        .then((res) => {
          setData(res.data.data);
          form.setFieldsValue({
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
          });
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
    getdata();
    setTimeout(() => {
      if (checked === undefined) {
        setChecked(data?.isActive);
      }
    }, 1000);
    // dispatch(viewUserByAdminInitiate(id));
  }, [data.firstName]);
  const [checked, setChecked] = useState(data.isActive);
  const handleChangeActive = (checked) => {
    console.log(`switch to ${checked}`);
    setChecked(checked);
  };
  console.log(data.isActive, "checked");
  return (
    <>
      <div className="edit_user_form ">
        <h2 className="text-center ">Update User</h2>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          onFinish={submitUpdate}
        >
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: "Enter your First Name" }]}
            className="text-red-100"
            initialValue={data?.firstName}
          >
            <Input
              type="text"
              value={data?.firstNames}
              className={inputclass}
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            initialValue={data.lastName}
            rules={[{ required: true, message: "Enter your Password" }]}
          >
            <Input className={inputclass} />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email Address"
            initialValue={data.email}
            rules={[
              { required: true, message: "Enter your Email" },
              {
                type: "email",
                message: "please enter valid input",
              },
            ]}
          >
            <Input className={inputclass} />
          </Form.Item>
          <Form.Item
            name="isActive"
            type="switch"
            initialValue={data.isActive}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Switch
              checkedChildren="Active"
              checked={checked}
              unCheckedChildren="Inactive"
              onChange={handleChangeActive}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="submit"
              className="w-full py-2 rounded-md font-semibold text-[1.1rem] !bg-orange-500  text-center text-white hover:!border-none focus:!border-none"
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default EditUser;
