import {
  GET_ABOUT_PAGE,
  GET_PRIVACY_PAGE,
  GET_INNERPAGE_PAGE,
  GET_TERMS_PAGE,
  GET_SITEMAP_PAGE,
} from "../types/cmsPageType";

const initState = {
  sitemap: [],
  innerpage: [],
  about: [],
  terms: [],
  privacy: [],
};
const aboutPageReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case GET_ABOUT_PAGE:
      return {
        ...state,
        about: payload,
      };
    case GET_PRIVACY_PAGE:
      return {
        ...state,
        privacy: payload,
      };
    case GET_INNERPAGE_PAGE:
      return {
        ...state,
        innerpage: payload,
      };
    case GET_TERMS_PAGE:
      return {
        ...state,
        terms: payload,
      };
    case GET_SITEMAP_PAGE:
      return {
        ...state,
        sitemap: payload,
      };

    default:
      return {
        ...state,
        payload: payload,
      };
  }
};
export default aboutPageReducer;
