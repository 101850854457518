import React, { useEffect, useState, useRef } from "react";
import { Form, Input, List, Modal, Switch, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import "./club-house-admin.scss";
// import { editUserByAdminInitiate } from "../../../redux/actions/adminUsersActions";
import {
  change_menu_status,
  clubhouse_rearrange,
  create_clubhouse,
  create_menu,
  delete_clubhouse,
  delete_menu,
  get_clubhouse,
  get_home_page_team_section,
  get_menu,
  get_single_address,
  get_single_clubhouse,
  menu_rearrange,
  update_clubhouse,
} from "../../../api/Api-list";
import axios from "axios";
import { Button } from "../../admin-components/Buttons/Button";
import { updateHomeTeamSectionInitiate } from "../../../redux/actions/cmsSettingAction";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import audioIcon from "../../images/audio-icon.gif";
import dragIcon from "../../images/drag-icon.png";
import editIcon from "../../images/edit.png";
import deleteIcon from "../../images/delete.png";
import { Editor } from "@tinymce/tinymce-react";

import { get } from "http";
import { Link } from "react-router-dom";
const EditClubhouse = () => {
  const Admin_Token = localStorage.getItem("tiger");
  const [form] = Form.useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const EditInputFileImg = useRef(null);
  const EditInputFileAudio = useRef(null);

  const [menus, setMenus] = useState([]);
  const [clubhouses, setClubhouses] = useState([]);

  const [check, setCheck] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [imgFile, setImgFile] = useState({
    url: "",
    fileName: "",
  });
  const [imgFilePreview, setImgFilePreview] = useState(null);
  const [audioFile, setAudioFile] = useState({
    url: "",
    fileName: "",
  });
  const [audioFileName, setAudioFileName] = useState("");
  const [infoData, setInfoData] = useState(null);
  const [isImageUpload, setisImageUpload] = useState(false);
  const [isaudioUpload, setisAudioUpload] = useState(false);

  const inputclass =
    "form-control bg-transparent !py-[0.7rem] !rounded-[0.3rem] text-black";

  const config = {
    headers: {
      Authorization: `Bearer ${Admin_Token}`,
    },
  };

  const handleImgChange = (e) => {
    setisImageUpload(true)
    if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      addToast("select valid image", {
        appearance: "error",
        autoDismiss: true,
      });
      setImgFile("");
      return;
    }
    setImgFile({
      url: e.target.files[0],
      fileName: e.target.files[0]?.name,
    });
    setImgFilePreview(URL.createObjectURL(e.target.files[0]));
  };

  const submitUpdate = async () => {
    if (!infoData) {
      addToast("Descriptioin is Required Field", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    try {
      let formData = new FormData();
      if(isImageUpload){
        formData.append("image", imgFile?.url);
        // formData.append("image['fileName']", imgFile?.fileName);
      }
      if(isaudioUpload){
        formData.append("audio", audioFile?.url || undefined);

      }

      // formData.append("audio['fileName']", audioFile?.fileName);
      formData.append("info", infoData);

      const response = await axios.patch(
        update_clubhouse(id),
        formData,
        config
      );
      if (response.status < 400) {
        addToast("Add menu successfully", {
          appearance: "success",
          autoDismiss: true,
          position: "top-center",
        });
        navigate("/admin/clubhouse");
        setToggle(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getsingleClubhouse = async () => {
    try {
      const response = await axios.get(get_single_clubhouse(id), config);
      if (response.status < 400) {
        console.log(response?.data, "daaa");
        setImgFile(response?.data?.clubhouse?.image);
        setAudioFile(response?.data?.clubhouse?.audio?.url);
        setAudioFileName(response?.data?.clubhouse?.audio?.fileName);
        setImgFilePreview(response?.data?.clubhouse?.image?.url);
        setInfoData(response?.data?.clubhouse?.info);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getsingleClubhouse();
  }, []);

  //   const changeMenuStatus = async (id) => {
  //     try {
  //       const response = await axios.patch(change_menu_status(id), id, config);
  //       if (response.status < 400) {
  //         addToast("Menu Status Change successfully", {
  //           appearance: "success",
  //           autoDismiss: true,
  //           position: "top-center",
  //         });
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  return (
    <>
      <div className="create-menu-body">
        <div className="flex items-center justify-between w-full">
          <h2>Edit Clubhouse</h2>
        </div>

        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          className="edit-club-house-form"
          onFinish={submitUpdate}
        >
          <ul>
            <li className="img-row">
              <h3>Image</h3>
              {imgFilePreview ? <img src={imgFilePreview} /> : "No Image Found"}

              <div
                className="upload-btn"
                onClick={() => {
                  EditInputFileImg.current.click();
                }}
              >
                +<br />
                Upload
              </div>
              <input
                type="file"
                id="customFile"
                onChange={(e) => handleImgChange(e)}
                className={inputclass}
                accept=".jpg,.jpeg, .png"
                ref={EditInputFileImg}
                style={{ display: "none" }}
              />
            </li>
            <li className="audio-row">
              <h3>Audio</h3>
              {/* <img src={audioIcon} alt="Audio Icon" /> */}
              <div
                className="upload-btn"
                onClick={() => {
                  EditInputFileAudio.current.click();
                }}
              >
                +<br />
                Upload
              </div>
              <input
                type="file"
                onChange={(e) => {
                  setisAudioUpload(true)
                  if (!e.target.files[0].name.match(/\.(mp3)$/)) {
                    addToast("Select Valid File", {
                      appearance: "error",
                      autoDismiss: true,
                    });
                    setAudioFile("");
                    return;
                  }
                  setAudioFile({
                    url: e.target.files[0],
                    fileName: e.target.files[0]?.name,
                  });
                  setAudioFileName(e.target.files[0]?.name);
                }}
                className={inputclass}
                accept=".mp3,audio/*"
                ref={EditInputFileAudio}
                style={{ display: "none" }}
              />
              <span className="audioSec">{audioFileName ? audioFileName : audioFile?.fileName} 🎵</span>
            </li>
            <li>
              <Editor
                // onInit={(evt, editor) => (editorRef.current = editor)}
                onEditorChange={(e) => {
                  setInfoData(e);
                }}
                init={{
                  height: 300,
                  // automatic_uploads: true,
                  // file_picker_types: "file image media",
                  // paste_data_images: true,
                  // image_advtab: true,
                  // file_picker_callback: function (callback, value, meta) {
                  //   if (meta.filetype == "image") {
                  //     var input = document.createElement("input");
                  //     input.setAttribute("type", "file");
                  //     input.setAttribute("accept", "image/*");
                  //     input.onchange = function () {
                  //       var file = this.files[0];
                  //       var reader = new FileReader();
                  //       reader.onload = function () {
                  //         callback(reader.result, {
                  //           alt: file.name,
                  //         });
                  //       };
                  //       reader.readAsDataURL(file);
                  //     };
                  //     input.click();
                  //   }
                  // },

                  advlist_bullet_styles: "square",
                  advlist_number_styles:
                    "lower-alpha,lower-roman,upper-alpha,upper-r",

                  /* and here's our custom image picker*/
                  toolbar_mode: "sliding",
                }}
                value={infoData || ""}
                plugins={[
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                  "textcolor",
                  "textpattern",
                  "image",
                  "imageupload",
                  "link",
                  "help",
                  "wordcount",
                  "code",
                  "preview",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "paste",
                  "charmap",
                  "searchreplace",
                  "visualblocks",
                  "emoticons",
                  "image paste",
                ]}
                toolbar={[
                  "undo redo | formatselect | bold italic backcolor forecolor   |alignleft aligncenter alignright alignjustify | fonts | bullist numlist outdent indent | removeformat | image| help",
                  "link imageupload | fontselect | fontsizeselect |fullscreen | insertdatetime | media | wordcount | charmap | searchreplace | visualblocks |  table | code | preview |emoticons |",
                ]}
              />
            </li>

            {/* <Switch defaultChecked={check} onChange={() => setCheck(!check)} /> */}

            <br />
            <br />

            <Form.Item>
              <Button
                type="submit"
                className="w-full py-2 rounded-md font-semibold text-[1.1rem] !bg-orange-500  text-center text-white hover:!border-none focus:!border-none"
              >
                Update
              </Button>
            </Form.Item>
          </ul>
        </Form>

        <br />
        <br />
      </div>
    </>
  );
};

export default EditClubhouse;
