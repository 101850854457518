import React, { useState } from "react";
import { IoVolumeMuteSharp } from "react-icons/io5";
import { IoVolumeHighSharp } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";
import { IoCheckmarkSharp } from "react-icons/io5";
import { AiOutlineSetting } from "react-icons/ai";
import Carousel from "react-bootstrap/Carousel";
import "./clubHouse.scss";
import infoIcon from "../../admin/images/Information-Icon.png";
import { useEffect } from "react";
import { get_clubhouse } from "../../api/Api-list";
import axios from "axios";
import { isInteger } from "lodash";
import { useRef } from "react";
function Clubhouse() {
  const [volume, setVolume] = useState(true);
  const [volumeIcon, setVolumeIcon] = useState(false);
  const [sliderItem, setSliderItem] = useState([]);
  const [isPlay, setIsPlay] = useState(false);
  const [isInterval, setisInerval] = useState(3000);
  const [isPlayAudio, setisPlayAudio] = useState(null);
  const audioref = useRef();
  const handleVolume = (item) => {
    setVolume(!volume);
    if (isPlay) {
      audioref.current.pause();
      setisInerval(3000);

      setIsPlay(false);
      return;
    }
    setTimeout(() => {
      audioref.current.src = item?.audio?.url;
      audioref.current.play();
      setisInerval(null);
      setIsPlay(true);
    }, 0);
  };

  const [setting, setSetting] = useState(true);
  const handleSetting = () => {
    setSetting(!setting);
  };

  const getSlider = async () => {
    try {
      const response = await axios.get(get_clubhouse());
      setSliderItem(
        response?.data?.clubhouses.map((element) => ({
          ...element,
          isPlay: false,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSlider();
  }, []);

  return (
    <div className="w-full pt-[9rem] relative">
      {/* <div className=" w-full flex absolute mt-1 justify-between sliderItem">
        <div className="w-full h-full flex d-none">
          <div className="px-4 py-3">
            <button
              className=" p-2 rounded-md bg-[#0c0c0c9f] border-0"
              onClick={handleVolume}
            >
              <span className="text-white text-2xl">{volume ? <IoVolumeMuteSharp /> : <IoVolumeHighSharp />}</span>
            </button>
            <button
              onClick={handleSetting}
              className=" p-2 rounded-md bg-transparent text-black border-0 text-2xl ml-3"
            >
              <AiOutlineSetting />
            </button>
            {setting ? (
              <div className="flex justify-start flex-column bg-[#0c0c0c] w-full py-2 px-4 rounded-md relative">
                <div className="flex justify-between items-center pt-2">
                  <h5 className="text-white text-lg m-0 p-0">Informations</h5>
                  <div className="ml-[5rem] group  ">
                    <button
                      onClick={handleSetting}
                      className="rounded-[50%] p-[0.1rem] bg-transparent border-2 !border-[#828183] transition-all duration-75 hover:!text-gray-100 group-hover:!border-gray-200 text-2xl"
                    >
                      <span className="text-[#828183] text-[1.12rem]  group-hover:text-gray-200">
                        <IoCloseSharp />
                      </span>
                    </button>
                  </div>
                </div>

                <ul className="flex flex-column pt-3 pl-0">
                  <li className="text-white text-md flex items-center my-1">
                    <span className="text-white text-xl ">
                      <IoCheckmarkSharp />
                    </span>
                    <span className="text-white pl-2">
                      Checkout our{" "}
                      <a
                        href="https://www.google.com"
                        target="_blank"
                        className="text-white underline hover:text-white"
                        rel="noreferrer"
                      >
                        wiki
                      </a>
                    </span>
                  </li>
                  {/* <li className="text-white text-md flex items-center my-1">
                    <span className="text-white text-xl ">
                      <IoCheckmarkSharp />
                    </span>
                    <span className="text-white pl-2">
                      Visit our{" "}
                      <a
                        href="https://www.google.com"
                        target="_blank"
                        className="text-white underline hover:text-white"
                        rel="noreferrer"
                      >
                        support forum
                      </a>
                    </span>
                  </li>
                  <li className="text-white text-md flex items-center my-1">
                    <span className="text-white text-xl ">
                      <IoCheckmarkSharp />
                    </span>
                    <span className="text-white pl-2">
                      Join the{" "}
                      <a
                        href="https://www.google.com"
                        target="_blank"
                        className="text-white underline hover:text-white"
                        rel="noreferrer"
                      >
                        community
                      </a>
                    </span>
                  </li>
                  <li className="text-white text-md flex items-center my-1">
                    <span className="text-white text-xl ">
                      <IoCheckmarkSharp />
                    </span>
                    <span className="text-white pl-2">
                      Message on{" "}
                      <a
                        href="https://www.google.com"
                        target="_blank"
                        className="text-white underline hover:text-white"
                        rel="noreferrer"
                      >
                        twitter
                      </a>
                    </span>
                  </li> 
                </ul>
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-full flex justify-end d-none">
          <div className="px-4 py-3">
            <button className="p-2 rounded-[50%] text-lg bg-[#0c0c0cd5] border-0">
              <IoCloseSharp />
            </button>
          </div>
        </div>
      </div> */}
      <Carousel
        interval={isInterval}
        onSelect={() => {
          if(!isPlay){
            setVolume(true)
            return
          }
          audioref.current.pause();
          setVolume(true)

        }}
      >
        {sliderItem?.length
          ? sliderItem?.map((item, i) => (
              <Carousel.Item>
                <img
                  src={item?.image?.url}
                  alt="clubhouse"
                  className="w-full h-full min-h-[20rem] min-w-[20rem] max-h-[35rem] 2xl:max-h-[60rem]"
                />
                <div className="slider-left-info-area">
                  <div className="flex slide-audio-prt">
                    {item?.audio?.url && (
                      <>
                        <audio id={i} ref={audioref} key={i} />

                        <button
                          className="p-2 rounded-md bg-[#0c0c0c9f] border-0"
                          onClick={() => handleVolume(item)}
                        >
                          <span className="text-white text-2xl">
                            {volume ? (
                              <IoVolumeMuteSharp />
                            ) : (
                              <IoVolumeHighSharp />
                            )}{" "}
                            {/* - {item?.isPlay ? "playing" : "paused"} */}
                          </span>
                        </button>
                      </>
                    )}
                    <button
                      onClick={handleSetting}
                      className="p-2 info-icon rounded-md bg-[#0c0c0c9f] border-0 text-2xl ml-3"
                    >
                      <img src={infoIcon} alt="Audio Icon" />
                    </button>
                  </div>
                  {setting ? (
                    <div className="slider-left-info-box">
                      <div className="slider-info-box-hdn flex justify-between items-center">
                        <h5>Informations</h5>
                        <button
                          onClick={handleSetting}
                          className="rounded-[50%] p-[0.1rem] bg-transparent border-2 !border-[#828183] transition-all duration-75 hover:!text-gray-100 group-hover:!border-gray-200 text-2xl"
                        >
                          <span className="text-[#828183] text-[1.12rem]  group-hover:text-gray-200">
                            <IoCloseSharp />
                          </span>
                        </button>
                      </div>
                      <div className="slider-info-list">
                        <div dangerouslySetInnerHTML={{ __html: item?.info }} />
                        {/* <li>
         <IoCheckmarkSharp /> Checkout our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> wiki</a>
       </li>
       <li>
         <IoCheckmarkSharp /> Visit our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> support forum</a>

       </li>
       <li>
         <IoCheckmarkSharp /> Join the{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> community</a>
       </li>
       <li>
         <IoCheckmarkSharp /> Message on{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> twitter</a>
       </li> */}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Carousel.Item>
            ))
          : ""}

        {/* <Carousel.Item>
          <img
            src={require("../../images/clubhouse_inside1.jpg")}
            alt="clubhouse_inside"
            className="w-full h-full min-h-[20rem] min-w-[20rem] max-h-[35rem] 2xl:max-h-[60rem]"
          />
          <div className="slider-left-info-area">
            <div className="flex slide-audio-prt">
              <button
                className=" p-2 rounded-md bg-[#0c0c0c9f] border-0"
                onClick={handleVolume}>
                <span className="text-white text-2xl">{volumeIcon}</span>
              </button>
              <button
                onClick={handleSetting}
                className=" p-2 rounded-md bg-transparent text-black border-0 text-2xl ml-3">
                <AiOutlineSetting />
              </button>
            </div>
            {setting ? (
            <div className="slider-left-info-box">
              <div className="slider-info-box-hdn flex justify-between items-center">
                <h5>Informations</h5>
                <button
                  onClick={handleSetting}
                  className="rounded-[50%] p-[0.1rem] bg-transparent border-2 !border-[#828183] transition-all duration-75 hover:!text-gray-100 group-hover:!border-gray-200 text-2xl"
                >
                  <span className="text-[#828183] text-[1.12rem]  group-hover:text-gray-200">
                    <IoCloseSharp />
                  </span>
                </button>
              </div>
              <ul className="slider-info-list">
                <li>
                  <IoCheckmarkSharp /> Checkout our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> wiki</a>
                </li>
                <li>
                  <IoCheckmarkSharp /> Visit our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> support forum</a>

                </li>
                <li>
                  <IoCheckmarkSharp /> Join the{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> community</a>
                </li>
                <li>
                  <IoCheckmarkSharp /> Message on{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> twitter</a>
                </li>
              </ul>
            </div>
            ) : null}
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={require("../../images/clubhouse_inside2.jpg")}
            alt="clubhouse_inside1"
            className="w-full h-full min-h-[20rem] min-w-[20rem] max-h-[35rem] 2xl:max-h-[60rem]"
          />
          <div className="slider-left-info-area">
            <div className="flex slide-audio-prt">
              <button
                className=" p-2 rounded-md bg-[#0c0c0c9f] border-0"
                onClick={handleVolume}>
                <span className="text-white text-2xl">{volumeIcon}</span>
              </button>
              <button
                onClick={handleSetting}
                className=" p-2 rounded-md bg-transparent text-black border-0 text-2xl ml-3">
                <AiOutlineSetting />
              </button>
            </div>
            {setting ? (
            <div className="slider-left-info-box">
              <div className="slider-info-box-hdn flex justify-between items-center">
                <h5>Informations</h5>
                <button
                  onClick={handleSetting}
                  className="rounded-[50%] p-[0.1rem] bg-transparent border-2 !border-[#828183] transition-all duration-75 hover:!text-gray-100 group-hover:!border-gray-200 text-2xl"
                >
                  <span className="text-[#828183] text-[1.12rem]  group-hover:text-gray-200">
                    <IoCloseSharp />
                  </span>
                </button>
              </div>
              <ul className="slider-info-list">
                <li>
                  <IoCheckmarkSharp /> Checkout our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> wiki</a>
                </li>
                <li>
                  <IoCheckmarkSharp /> Visit our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> support forum</a>

                </li>
                <li>
                  <IoCheckmarkSharp /> Join the{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> community</a>
                </li>
                <li>
                  <IoCheckmarkSharp /> Message on{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> twitter</a>
                </li>
              </ul>
            </div>
            ) : null}
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={require("../../images/clubhouse_inside3.jpg")}
            alt="clubhouse_inside2"
            className="w-full h-full min-h-[20rem] min-w-[20rem] max-h-[35rem] 2xl:max-h-[60rem]"
          />
          <div className="slider-left-info-area">
            <div className="flex slide-audio-prt">
              <button
                className=" p-2 rounded-md bg-[#0c0c0c9f] border-0"
                onClick={handleVolume}>
                <span className="text-white text-2xl">{volumeIcon}</span>
              </button>
              <button
                onClick={handleSetting}
                className=" p-2 rounded-md bg-transparent text-black border-0 text-2xl ml-3">
                <AiOutlineSetting />
              </button>
            </div>
            {setting ? (
            <div className="slider-left-info-box">
              <div className="slider-info-box-hdn flex justify-between items-center">
                <h5>Informations</h5>
                <button
                  onClick={handleSetting}
                  className="rounded-[50%] p-[0.1rem] bg-transparent border-2 !border-[#828183] transition-all duration-75 hover:!text-gray-100 group-hover:!border-gray-200 text-2xl"
                >
                  <span className="text-[#828183] text-[1.12rem]  group-hover:text-gray-200">
                    <IoCloseSharp />
                  </span>
                </button>
              </div>
              <ul className="slider-info-list">
                <li>
                  <IoCheckmarkSharp /> Checkout our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> wiki</a>
                </li>
                <li>
                  <IoCheckmarkSharp /> Visit our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> support forum</a>

                </li>
                <li>
                  <IoCheckmarkSharp /> Join the{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> community</a>
                </li>
                <li>
                  <IoCheckmarkSharp /> Message on{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> twitter</a>
                </li>
              </ul>
            </div>
            ) : null}
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={require("../../images/clubhouse_inside4.jpg")}
            alt="clubhouse_inside3"
            className="w-full h-full min-h-[20rem] min-w-[20rem] max-h-[35rem] 2xl:max-h-[60rem]"
          />
          <div className="slider-left-info-area">
            <div className="flex slide-audio-prt">
              <button
                className=" p-2 rounded-md bg-[#0c0c0c9f] border-0"
                onClick={handleVolume}>
                <span className="text-white text-2xl">{volumeIcon}</span>
              </button>
              <button
                onClick={handleSetting}
                className=" p-2 rounded-md bg-transparent text-black border-0 text-2xl ml-3">
                <AiOutlineSetting />
              </button>
            </div>
            {setting ? (
            <div className="slider-left-info-box">
              <div className="slider-info-box-hdn flex justify-between items-center">
                <h5>Informations</h5>
                <button
                  onClick={handleSetting}
                  className="rounded-[50%] p-[0.1rem] bg-transparent border-2 !border-[#828183] transition-all duration-75 hover:!text-gray-100 group-hover:!border-gray-200 text-2xl"
                >
                  <span className="text-[#828183] text-[1.12rem]  group-hover:text-gray-200">
                    <IoCloseSharp />
                  </span>
                </button>
              </div>
              <ul className="slider-info-list">
                <li>
                  <IoCheckmarkSharp /> Checkout our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> wiki</a>
                </li>
                <li>
                  <IoCheckmarkSharp /> Visit our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> support forum</a>

                </li>
                <li>
                  <IoCheckmarkSharp /> Join the{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> community</a>
                </li>
                <li>
                  <IoCheckmarkSharp /> Message on{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> twitter</a>
                </li>
              </ul>
            </div>
            ) : null}
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={require("../../images/clubhouse_inside5.jpg")}
            alt="clubhouse_inside4"
            className="w-full h-full min-h-[20rem] min-w-[20rem] max-h-[35rem] 2xl:max-h-[60rem]"
          />
          <div className="slider-left-info-area">
            <div className="flex slide-audio-prt">
              <button
                className=" p-2 rounded-md bg-[#0c0c0c9f] border-0"
                onClick={handleVolume}>
                <span className="text-white text-2xl">{volumeIcon}</span>
              </button>
              <button
                onClick={handleSetting}
                className=" p-2 rounded-md bg-transparent text-black border-0 text-2xl ml-3">
                <AiOutlineSetting />
              </button>
            </div>
            {setting ? (
            <div className="slider-left-info-box">
              <div className="slider-info-box-hdn flex justify-between items-center">
                <h5>Informations</h5>
                <button
                  onClick={handleSetting}
                  className="rounded-[50%] p-[0.1rem] bg-transparent border-2 !border-[#828183] transition-all duration-75 hover:!text-gray-100 group-hover:!border-gray-200 text-2xl"
                >
                  <span className="text-[#828183] text-[1.12rem]  group-hover:text-gray-200">
                    <IoCloseSharp />
                  </span>
                </button>
              </div>
              <ul className="slider-info-list">
                <li>
                  <IoCheckmarkSharp /> Checkout our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> wiki</a>
                </li>
                <li>
                  <IoCheckmarkSharp /> Visit our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> support forum</a>

                </li>
                <li>
                  <IoCheckmarkSharp /> Join the{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> community</a>
                </li>
                <li>
                  <IoCheckmarkSharp /> Message on{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> twitter</a>
                </li>
              </ul>
            </div>
            ) : null}
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={require("../../images/clubhouse_inside6.jpg")}
            alt="clubhouse_inside5"
            className="w-full h-full min-h-[20rem] min-w-[20rem] max-h-[35rem] 2xl:max-h-[60rem]"
          />
          <div className="slider-left-info-area">
            <div className="flex slide-audio-prt">
              <button
                className=" p-2 rounded-md bg-[#0c0c0c9f] border-0"
                onClick={handleVolume}>
                <span className="text-white text-2xl">{volumeIcon}</span>
              </button>
              <button
                onClick={handleSetting}
                className=" p-2 rounded-md bg-transparent text-black border-0 text-2xl ml-3">
                <AiOutlineSetting />
              </button>
            </div>
            {setting ? (
            <div className="slider-left-info-box">
              <div className="slider-info-box-hdn flex justify-between items-center">
                <h5>Informations</h5>
                <button
                  onClick={handleSetting}
                  className="rounded-[50%] p-[0.1rem] bg-transparent border-2 !border-[#828183] transition-all duration-75 hover:!text-gray-100 group-hover:!border-gray-200 text-2xl"
                >
                  <span className="text-[#828183] text-[1.12rem]  group-hover:text-gray-200">
                    <IoCloseSharp />
                  </span>
                </button>
              </div>
              <ul className="slider-info-list">
                <li>
                  <IoCheckmarkSharp /> Checkout our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> wiki</a>
                </li>
                <li>
                  <IoCheckmarkSharp /> Visit our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> support forum</a>

                </li>
                <li>
                  <IoCheckmarkSharp /> Join the{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> community</a>
                </li>
                <li>
                  <IoCheckmarkSharp /> Message on{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> twitter</a>
                </li>
              </ul>
            </div>
            ) : null}
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={require("../../images/clubhouse_inside.jpg")}
            alt="clubhouse_inside6"
            className="w-full h-full min-h-[20rem] min-w-[20rem] max-h-[35rem] 2xl:max-h-[60rem]"
          />
          <div className="slider-left-info-area">
            <div className="flex slide-audio-prt">
              <button
                className=" p-2 rounded-md bg-[#0c0c0c9f] border-0"
                onClick={handleVolume}>
                <span className="text-white text-2xl">{volumeIcon}</span>
              </button>
              <button
                onClick={handleSetting}
                className=" p-2 rounded-md bg-transparent text-black border-0 text-2xl ml-3">
                <AiOutlineSetting />
              </button>
            </div>
            {setting ? (
            <div className="slider-left-info-box">
              <div className="slider-info-box-hdn flex justify-between items-center">
                <h5>Informations</h5>
                <button
                  onClick={handleSetting}
                  className="rounded-[50%] p-[0.1rem] bg-transparent border-2 !border-[#828183] transition-all duration-75 hover:!text-gray-100 group-hover:!border-gray-200 text-2xl"
                >
                  <span className="text-[#828183] text-[1.12rem]  group-hover:text-gray-200">
                    <IoCloseSharp />
                  </span>
                </button>
              </div>
              <ul className="slider-info-list">
                <li>
                  <IoCheckmarkSharp /> Checkout our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> wiki</a>
                </li>
                <li>
                  <IoCheckmarkSharp /> Visit our{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> support forum</a>

                </li>
                <li>
                  <IoCheckmarkSharp /> Join the{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> community</a>
                </li>
                <li>
                  <IoCheckmarkSharp /> Message on{" "} <a href="https://www.google.com" target="_blank" className="text-white underline hover:text-white" rel="noreferrer"> twitter</a>
                </li>
              </ul>
            </div>
            ) : null}
          </div>
        </Carousel.Item> */}
      </Carousel>
    </div>
  );
}

export default Clubhouse;
