export const SET_CONNECTED_WALLET = "SET_CONNECTED_WALLET";
export const SET_WALLET_PROVIDER = "SET_WALLET_PROVIDER";
export const WRONG_NETWORK = "WRONG_NETWORK";
export const LOAD_CONTRACT = "LOAD_CONTRACT";
export const LOAD_WALLET_ADDRESS = "LOAD_WALLET_ADDRESS";
export const LOAD_NFT_DETAILS = "LOAD_NFT_DETAILS";
export const LOAD_MINT_AMOUNT = "LOAD_MINT_AMOUNT";
export const LOAD_NFT_IMAGE = "LOAD_NFT_IMAGE";
export const TOTAL_MINTED = "TOTAL_MINTED";
export const SHOW_MODAL = "SHOW_MODAL";
export const CONNECTED_WALLET_PROVIDER = "CONNECTED_WALLET_PROVIDER";
export const LOAD_LOADER = "LOAD_LOADER";
export const CONFORMATION_MODAL = "CONFORMATION_MODAL";
