import React, { useState, useEffect } from "react";
import "./payment.scss";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { get_order_details } from "../../../api/Api-list";

const PaymentSuccess = () => {
  const [paymentData, setPaymentData] = useState(null);
  const [oneEth, setOneEth] = useState(0);

  const { addToast } = useToasts();
  const { id } = useParams();

  const app_token = localStorage.getItem("tiger-token");

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  useEffect(() => {
    paymentSuccess();
  }, [id]);

  const paymentSuccess = async () => {
    try {
      const response = await axios.get(get_order_details(id), config);
      if (response.status < 400) {
        setPaymentData(response.data.order);
        setOneEth(response.data?.oneETH);
      }
    } catch (err) {
      console.log(err);
      addToast(err.response.data.msg, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <div className="py-8 profile_content sucs-ordr-page">
      <div className="py-4 flex flex-col items-center justify-center">
        <h1 className="font-bold text-white text-center pt-[5rem] sucs-ordr-page-title pb-3">
          Successfully Ordered
        </h1>
      </div>
      <div className="flex justify-between sucs-ordr-body">
        <div className="sucs-ordr-left-clm">
          <div className="sucs-ordr-left-box thanks-msg">
            <img src="/images/thanks-icon.png" alt="Thanks Icon" />
            <h2>Thanks for your order</h2>
            <h3>Order Number: {paymentData?.orderNumber}</h3>
            <p>You will receive an email confirmation shortly</p>
            {/* <p className="mt-10">Print Receipt</p> */}
          </div>
          <div className="sucs-ordr-left-box ship-adrs">
            <h2>Shipping Address</h2>
            <p>
              {paymentData?.address?.fullName}
              <br />
              {paymentData?.address?.address}, {paymentData?.address?.city} -{" "}
              {paymentData?.address?.pincode},{" "}
              {paymentData?.address?.state?.name},{" "}
              {paymentData?.address?.country?.countryName}
              <br />
              Landmark - {paymentData?.address?.landmark}
              <br />
              Contact Number - {paymentData?.address?.phone}
            </p>
          </div>
        </div>

        <div className="sucs-ordr-right-clm">
          <div className="ordr-sumry">
            <h2>Order Summary</h2>
            <h3>
              {paymentData?.items?.length}{" "}
              {paymentData?.items?.length > 1 ? "Products" : "Product"}
            </h3>
            <ul className="product-list">
              {paymentData?.items?.map((data) => {
                return (
                  <li>
                    <div className="product-img">
                      <img
                        src={data?.image?.[0]?.image || "/images/noimg.png"}
                        alt="product-img"
                      />
                    </div>
                    <div className="product-text">
                      <p className="product-code">PRODUCT CODE: {data._id}</p>
                      <p className="product-title">{data.productName}</p>
                      <div className="prdct-info flex">
                        <p>
                          Size: <span>{data.priceTier.size}</span>
                        </p>
                        <p>
                          Color:{" "}
                          <span
                            className="color-circle"
                            style={{
                              backgroundColor: data.priceTier.color,
                            }}
                          ></span>
                        </p>
                        <p>
                          Quantity: <span>{data.quantity}</span>
                        </p>
                        <p>
                          <span class="product-card-ethereum">
                            ${data.priceTier.price.toFixed(2)}
                          </span>
                          <span class="product-card-ethereum">
                            <img src="/images/eth-icon.png" alt="eth icon" />
                            {(data.priceTier.price / oneEth).toFixed(5)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </li>
                );
              })}
              <li>
                <table>
                  <tbody>
                    <tr>
                      <td>Product Total</td>
                      <td>${paymentData?.totalAmount.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>
                        Tax ({paymentData?.address?.state?.totalTaxAmount}%)
                      </td>
                      <td>${paymentData?.taxAmount.toFixed(2)}</td>
                    </tr>
                    {paymentData?.coupon && (
                      <tr>
                        <td>Coupon</td>
                        <td>
                          {paymentData?.coupon?.type === "fixed"
                            ? "$"
                            : paymentData?.coupon?.type === "percent"
                            ? ""
                            : ""}
                          {paymentData?.coupon?.value}
                          {paymentData?.coupon?.type === "fixed"
                            ? ""
                            : paymentData?.coupon?.type === "percent"
                            ? "%"
                            : ""}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Shipping Charge</td>
                      <td>${paymentData?.shippingAmount.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>${paymentData?.subTotalAmount.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
