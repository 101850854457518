import React, { useState } from "react";
import {
  Input,
  Form,
  Radio,
  DatePicker,
  Space,
  Switch,
  message,
  InputNumber,
} from "antd";
import { add_coupons } from "../../../api/Api-list";
import axios from "axios";
import * as moment from "moment";
import { useNavigate } from "react-router-dom";
const options = ["Fixed", "Percent"];
const AddCoupons = () => {
  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState(null);
  const navigate = useNavigate();
  const [discountType, setDiscountType] = useState("Fixed");
  const isActiveStatus = false;
  const multiuserStatus = false;
  const inputclass = "bg-transparent !py-[0.7rem] !rounded-[0.3rem] text-black";
  const app_token = localStorage.getItem("tiger");
  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };
  const format1 = "YYYY-MM-DD";
  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async (e) => {
        const values = await form.getFieldsValue();
        if (discountType === "Percent") {
          if (Number(values.setDiscount) > 100) {
            message.info("value must be less than or equal to 100");
            return;
          }
        }
        try {
          const payload = {
            code: values.couponCode,
            type:
              values.discountType === undefined
                ? discountType.toLowerCase()
                : values.discountType.toLowerCase(),
            value: values.setDiscount,
            numberOfUses: values.numberOfUses,
            startDate: moment(values.startDate).format(format1),
            expiryDate: moment(values.expiryDate).format(format1),
            isMulti:
              values.multiuserstatus === undefined
                ? multiuserStatus
                : values.multiuserstatus,
            isActive:
              values.activestatus === undefined
                ? isActiveStatus
                : values.activestatus,
          };
          const response = await axios.post(add_coupons, payload, config);

          if (response.status < 400) {
            message.success(response.data.msg);
            form.resetFields();
            navigate("/admin/get_all_coupons");
          }
        } catch (error) {
          message.error(error.response.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="container-fluid edit_product">
        <div className="row">
          <h2 className="cms-title">Add Coupon </h2>
          <div className="col-12 col-sm-12 col-md-12 bg-white shadow-sm p-5 cms-box">
            <Form layout="vertical" form={form}>
              <Form.Item
                name="couponCode"
                type="text"
                label="Coupon Code"
                rules={[{ required: true, message: "Coupon Code is required" }]}
              >
                <Input placeholder="Coupon Code" className={inputclass} />
              </Form.Item>
              <Form.Item name="discountType" label="Discount Type">
                <Radio.Group
                  options={options}
                  onChange={(e) => setDiscountType(e.target.value)}
                  value={discountType}
                  defaultValue="Fixed"
                />
              </Form.Item>
              <Form.Item
                name="setDiscount"
                type="text"
                label={
                  discountType === "Fixed"
                    ? "Set Discount amount"
                    : "Set Discount percentage"
                }
                rules={[{ required: true, message: "Set Discount required" }]}
              >
                <InputNumber
                  type="number"
                  placeholder="Set Discount"
                  min={1}
                  style={{ width: "100%", padding: 6, borderRadius: 5 }}
                />
              </Form.Item>

              <Form.Item
                name="numberOfUses"
                label="Number Of Uses"
                rules={[
                  { required: true, message: "Number Of Uses is required" },
                ]}
              >
                <InputNumber
                  type="number"
                  placeholder="Number Of Uses"
                  min={1}
                  style={{ width: "100%", padding: 6, borderRadius: 5 }}
                />
              </Form.Item>
              <Form.Item
                name="startDate"
                label="Start Date"
                rules={[{ required: true, message: "Start Date is required" }]}
              >
                <DatePicker
                  style={{
                    width: "100%",
                    height: "46px",
                    borderRadius: "4px",
                  }}
                  format="YYYY-MM-DD"
                  onChange={(e) => setStartDate(e)}
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current < moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                />
              </Form.Item>
              <Form.Item
                name="expiryDate"
                label="Expiry Date"
                rules={[{ required: true, message: "Expiry Date is required" }]}
              >
                <DatePicker
                  style={{
                    width: "100%",
                    height: "46px",
                    borderRadius: "4px",
                  }}
                  disabled={startDate === null ? true : false}
                  format="YYYY-MM-DD"
                  disabledDate={(d) =>
                    !d ||
                    d.isBefore(startDate) ||
                    d.isSameOrBefore("1960-01-01")
                  }
                />
              </Form.Item>
              <Form.Item name="multiuserstatus" label="Multiple Usage per user">
                <Switch style={{ marginBottom: "15px" }} />
              </Form.Item>
              <Form.Item name="activestatus" label="Coupon Active">
                <Switch style={{ marginBottom: "15px" }} />
              </Form.Item>
              <Form.Item>
                <button
                  type="submit"
                  className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] "
                  onClick={handleSubmit}
                >
                  Add Coupon
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCoupons;
