import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CONFORMATION_MODAL } from "../../../redux/types/blockchainType";
import "../login/login.scss";

export function TransactionCompleteModal() {
  const dispatch = useDispatch();
  const { openConformationModal, transactionHash } = useSelector(
    (state) => state.blockChain
  );

  const close = () => {
    dispatch({
      type: CONFORMATION_MODAL,
      payload: {
        openConformationModal: false,
        transactionHash: "",
      },
    });
  };

  return (
    <Modal
      open={openConformationModal}
      title="Your Transaction has been completed"
      footer={null}
      onCancel={close}
      centered={true}
      className="login_modal"
    >
      <div style={{ textAlign: "center", color: "white" }}>
        <p className="text-gray-400 text-lg">
          Your transaction with hash {transactionHash} has been completed.
        </p>
        <button
          style={{ display: "block", margin: "0 auto", marginBottom: "10px" }}
          className="bg-orange-500 py-2 px-4 rounded font-semibold"
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_BLOCKURL}/tx/${transactionHash}`,
              "_blank"
            );
            close();
          }}
        >
          View on Etherscan
        </button>
        <button
          style={{ display: "block", margin: "0 auto" }}
          className="text-gray-400 mt-3 text-base"
          onClick={close}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}
