import React from "react";
import "./eldrick.scss";

import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
// import { getSingleSectionBySectionNameInitiate } from "../../../redux/actions/cmsSettingAction";

const Eldrick = () => {
  const pages = useSelector((state) => state?.cmsPageSetting?.allPage);
  let data = pages?.filter((page) => page?.section === "ELDRICK");

  return (
    <div className="eldrick_container text-center">
      <Container>
        <Row>

          <Col className="col-lg-12 col-md-12 col-12 !py-[6rem]">
            {data[0]?.title.map((item) => {
              return (
                <div key={item._id}>
                  <h2 className="fontHeadingShloppy" style={{ fontSize: 45, color: item.headingColour }}>{item.heading}</h2>
                  <h3 className="outline">{item.subHeading}</h3>
                </div>
              );
            })}
            {/* <img
              alt="icon"
              className="img-fluid eldrick-tiger my-4"
              src="/images/tiger-icon.png"
            /> */}
            {data[0]?.title.map((item) => {
              return (
                <div
                  key={item._id}
                  className="my-2"
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                />
              );
            })}
          </Col>
          <Col className="col-lg-12 col-md-12 col-12 m-0">
            {
              data[0]?.image.length > 0 ? (
                <img
                  src={data[0]?.image[0].item}
                  className="!h-full"
                  alt="icon"
                />
              ) : (
                ""
              )
            }



          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Eldrick;
