import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Pagination, Col, Row } from "antd";
import ProductCard from "../product/ProductCard";
import "./paginationCard.scss";
import { TfiAngleLeft, TfiAngleRight } from "react-icons/tfi";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActiveProducts,
  fetchProducts,
  setProduct,
} from "../../../redux/actions/productAction";
import axios from "axios";
// import Meta from "antd/lib/card/Meta";
import { allProduct } from "../../../api/Api-list";
import ReactPaginate from "react-paginate";

// import ProductCardFIlter from "../product/ProductCardFIlter";

// const pageSize = 8;

// Custom pagination component
// const MyPagination = ({ total, onChange, current }) => {
//   return (
//     <Pagination
//       onChange={onChange}
//       total={total}
//       current={current}
//       pageSize={pageSize}
//     />
//   );
// };

const PaginationCard = ({
  paginate,
  totalItems,
  lastPage,
  pageNum,
  pageSize,
  setTotalItems,
  setLastPage,
  setPageNum,
  setPageSize,
  selectedCategory,
  orderKey,
  sort,
}) => {
  const app_token = localStorage.getItem("tiger-token");

  const products = useSelector((state) => state.allProducts?.getactiveproducts);
  const oneEth = useSelector((state) => state.allProducts?.oneEth);
  const [current, setCurrent] = useState(1);
  const dispatch = useDispatch();
  console.log(sort, "sorttttt");
  useEffect(() => {
    dispatch(
      fetchActiveProducts(
        pageNum,
        pageSize,
        "",
        orderKey,
        sort,
        selectedCategory
      )
    );
  }, [pageNum, pageSize, orderKey, sort, selectedCategory]);

  useEffect(() => {
    setTotalItems(products?.metadata?.totalItems);
    setLastPage(products?.metadata?.lastPage);
  }, [products]);

  return (
    <React.Fragment>
      <Row gutter={[24, 24]} justify="center" className="pagination_card">
        {products?.data?.length > 0 ? (
          products?.data
            ?.filter((ele) => ele?.isActive == true)
            // ?.slice((current - 1) * pageSize, current * pageSize)
            ?.map((product) => {
              return (
                <>
                  <div className="site-card-wrapper" key={product._id}>
                    <Col>
                      <ProductCard
                        key={product._id}
                        id={product._id}
                        data={product}
                        title={product.title}
                        image={product.image}
                        color={product.color}
                        price={product.price}
                        pageNum={pageNum}
                        pageSize={pageSize}
                        orderKey={orderKey}
                        sort={sort}
                        oneEth={oneEth}
                      />
                    </Col>
                  </div>
                </>
              );
            })
        ) : (
          <h3 className="text-white text-center mt-2 font-bold text-[40px]">
            No Products Found 😴
          </h3>
        )}
      </Row>
      <div className="text-center mx-auto mt-8 pagination_block">
        {totalItems > pageSize ? (
          <ReactPaginate
            previousLabel={<TfiAngleLeft />}
            nextLabel={<TfiAngleRight />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={lastPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={paginate}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}

            // activeClassName={"active"}
          />
        ) : null}

        {/* <MyPagination
          total={products?.data?.length}
          current={current}
          onChange={setCurrent}
        /> */}
      </div>
    </React.Fragment>
  );
};
export default PaginationCard;
