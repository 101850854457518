import * as actionTypes from "../types/cartSectionType.js";

const initialState = {
  cartStep: 1,
  totalCartItem: 0,
  shippingRates: [],
  selectedShippingRate: null,
};

const cartSectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CART_STEP:
      return {
        ...state,
        cartStep: action.payload,
      };

    case actionTypes.UPDATE_TOTAL_CART_ITEM:
      return {
        ...state,
        totalCartItem: action.payload,
      };

    case actionTypes.UPDATE_SHIPPING_RATES:
      return {
        ...state,
        shippingRates: action.payload,
      };

    case actionTypes.UPDATE_SELECTED_SHIPPING_RATE:
      return {
        ...state,
        selectedShippingRate: action.payload,
      };

    default:
      return state;
  }
};

export default cartSectionReducer;
