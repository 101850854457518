import React, { useEffect, useState } from "react";
import { Form, Input, Col, Row } from "antd";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Switch } from "antd";
import "./MyAccount.scss";
import { adminProfile, notification_update } from "../../../api/Api-list";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
function MyAccount() {
  const [notificationtype, setNotificationType] = useState({
    type: "",
    value: false,
  });
  const [currentUser, setCurrentUser] = useState(null);
  const [toggleCheck, setToggleCheck] = useState(null);

  const [isNewsAndOffersNotificationOn, setisNewsAndOffersNotificationOn] =
    useState();
  const [isEmailNotificationOn, setisEmailNotificationOn] = useState();
  const { addToast } = useToasts();

  const app_token = localStorage.getItem("tiger-token");

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  const me = async () => {
    try {
      const res = await axios.get(adminProfile, config);
      if (res.status < 400) {
        setCurrentUser(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    me();
  }, []);

  const notificationToggle = async (type, value) => {
    let data ={
      type:type,
      value:value
    }
    try {
      const response = await axios.patch(
        notification_update,
         data ,
        config
      );
      if (response.status < 400) {
        addToast(response?.data?.message, {
          appearance: "success",
          autoDismiss: true,
          position: "top-center",
        });
        me();
      }
    } catch (err) {
      console.log(err);
      addToast(err?.response?.data?.msg, {
        appearance: "error",
        autoDismiss: true,
        position: "top-center",
      });
    }
  };

  const inputclass =
    "bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black";
  return (
    <Wrapper>
      <Container>
        <Row justify="center">
          <Col lg={8} md={20} xs={24} sm={24} className="mt-5 account_form">
            <div className="bg-[#0f0f0f] border-[.3px] border-[#2B2B2B] rounded-xl bg-opacity-70 p-11 mb-4 my-account">
              <h5 className="text-white font-bold heading-design ">
                Account Details
              </h5>
              <Form name="basic" layout="vertical" autoComplete="off">
                <Form.Item
                  name="name"
                  label=" Your First & Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name !",
                    },
                  ]}
                  className="my-1"
                >
                  <Input
                    type="text"
                    placeholder="Your First & Last Name "
                    className={inputclass}
                    name="name"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email Address"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email !",
                    },
                    {
                      type: "email",
                      message: "Please input a valid email !",
                    },
                  ]}
                  className="my-1"
                >
                  <Input
                    type="text"
                    placeholder="Email"
                    className={inputclass}
                    name="email"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password !",
                    },
                  ]}
                  className="my-1"
                >
                  <Input.Password
                    placeholder="Password"
                    className={inputclass}
                  />
                </Form.Item>
                <button className="text-[20px] font-bold text-center w-full mt-2 bg-[#ff8500] transition-all duration-75 hover:bg-orange-600 text-white py-[10px] rounded-3xl">
                  Update
                </button>
              </Form>
            </div>


{currentUser ? (
  <div className="bg-[#0f0f0f] border-[.3px] border-[#2B2B2B] rounded-xl bg-opacity-70 p-11 mb-4 my-account">
  <h5 className="text-white font-bold heading-design">
    Notifications
  </h5>
  <div>
    <div className="flex justify-between text-white my-3">
      <span className="text-[16px] ">
        Receieved Email Notifications 
      </span>
      <Switch
        defaultChecked={currentUser?.userSettings?.notification?.isEmailNotificationOn}  
        checkedChildren="Yes" unCheckedChildren="No"

        onChange={(checked) => {
         notificationToggle('email', checked)

        }}
      />
    </div>
    <div className="flex justify-between text-white my-3">
      <span className="text-[16px]">Received News and Offers </span>
      <Switch
        defaultChecked={currentUser?.userSettings?.notification?.isNewsAndOffersNotificationOn}
        checkedChildren="Yes" unCheckedChildren="No"
        onChange={(checked) => {
          notificationToggle('newsAndOffers', checked)
        }}
      />
    </div>
  </div>
</div>
) : ""}
            


          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default MyAccount;

const Wrapper = styled.div`
  .ant-input-affix-wrapper > input.ant-input {
    padding: 0;
    border: none;
    outline: none;
    background: #0e0e0e;
    color: white;
  }

  .ant-input-password-icon.anticon {
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-form-item {
    margin-bottom: 10px !important;
  }

  .ant-switch-checked {
    background-color: #ff8500 !important;
  }
  .ant-switch-checked:focus {
    box-shadow: 0 0 0 0px !important;
  }
`;
