import React from "react";
import "./mintSold.scss";
const MintSold = (desc) => {
  console.log(desc, "desc");
  return (
    <div className="flex items-center justify-center flex-col mint_sold">
      <h2 className="text-white mb-2 text-[35px] font-extrabold text-center">
        Sold Out
      </h2>
      <p
        className="text-gray-300 max-w-xl text-center text-[25px]"
        dangerouslySetInnerHTML={{ __html: desc.desc }}
      />
    </div>
  );
};

export default MintSold;
