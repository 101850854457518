import React, { useEffect, useState } from "react";
import * as Modals from "../modals";
import "./header.scss";
import { BsCart2 } from "react-icons/bs";
// import { GiTigerHead } from "react-icons/gi";
import {} from "react-router-dom";
import { Menu, Dropdown, Avatar, Badge, message } from "antd";
import HeaderMenuItems from "./Header-menu-items";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import { UserOutlined } from "@ant-design/icons";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
// tootlip Import
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useDispatch, useSelector } from "react-redux";
import {
  get_all_cart_items,
  get_menu,
  update_fav_product,
} from "../../api/Api-list";
import axios from "axios";
import { USER_LOGOUT } from "../../redux/types/usertypes";
import { getTotalCartItem } from "../../redux/actions/cartSectionAction";
import { CART_STEP } from "../../redux/types/cartSectionType";
import Seo from "../Seo";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";

const token = "tiger-token" in localStorage;
function Header() {
  const userToken = localStorage.getItem("tiger-token");
  const rtgcCartId = localStorage.getItem("rtgcCartId");
  console.log(userToken, "usertoken");
  const cart = useSelector((state) => state.cartItem.cart);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(async () => {
    if (location.pathname === "/my-profile" && userToken === null) {
      setLoginModalVisible(true);
    }

    if (location?.hash && userToken === null) {
      setLoginModalVisible(true);
    }
  }, [location.pathname, location?.hash, userToken]);

  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [colorChange, setColorchange] = useState(false);
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState(token);
  const [show, setShow] = useState("hide");
  const [headerMenu, setHeaderMenu] = useState([]);
  const [seoData, setSeoData] = useState(null);

  const isAuthenticated = useSelector(
    (state) => state.userProfile.isAuthenticated
  );

  const totalCartItem = useSelector((state) => state.cartSection.totalCartItem);
  // const header_logo = useSelector((state) => state.cmsPageSetting?.payload?.cmsPageSetting?.settingPage?.data?.[0]?.image);

  const PageSettingpage = useSelector(
    (state) => state?.cmsPageSetting?.settingpage
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };

  const [pathOfHelmet, setPathOfHelmet] = useState({
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
  });

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const hideLoginModal = () => {
    setLoginModalVisible(false);
    if (location?.hash == "#?show=true" && userToken === null) {
      setLoginModalVisible(false);
    }
    location.hash = "";
  };

  const handleLogin = () => {
    setLoginModalVisible(true);
  };

  const handleLogout = () => {
    setUser(false);
    localStorage.removeItem("tiger-token");
    dispatch({ type: USER_LOGOUT });
    setNavbarContainer(
      "navbar_container menu_bar text-white border-gradient  md:ml-[7.7rem]"
    );
    navigate("/");
    localStorage.setItem("rtgcCartId", uuidv4());
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const handleMenuClick = (e) => {
    if (e.key === "3") {
      setVisible(false);
    }
  };

  // const getMenuItem = (item) => {
  //   let data = seoData?.find((ele) => ele.link === `/${item.link}`);
  //   setPathOfHelmet({
  //     metaTitle: data?.pageTitle,
  //     metaDescription: data?.pageDesc,
  //     metaKeywords: data?.pageKeyword,
  //   });
  // };

  useEffect(() => {
    dispatch(getTotalCartItem(userToken, isAuthenticated, rtgcCartId));
  }, [isAuthenticated, rtgcCartId]);

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        <Link to="/my-profile" className="flex items-center">
          {" "}
          <UserOutlined />
          <span className="ml-3">My Account</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={(e) => {
          handleLogout();
        }}
      >
        <div className="flex items-center">
          <LogoutOutlined />
          <span className="ml-3 mb-[0.2rem]">Logout</span>
        </div>
      </Menu.Item>
    </Menu>
  );
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Shopping Cart
    </Tooltip>
  );
  const [NavbarContainer, setNavbarContainer] = useState(
    "navbar_container menu_bar text-white border-gradient md:ml-[7.7rem]"
  );
  useEffect(() => {
    localStorage.getItem("tiger-token")
      ? setNavbarContainer(
          "navbar_container menu_bar text-white border-gradient"
        )
      : setNavbarContainer(
          "navbar_container menu_bar text-white border-gradient md:ml-[7.7rem]"
        );
  }, []);

  const getMenu = async (type) => {
    try {
      const response = await axios.get(get_menu(type), config);
      if (response.status < 400) {
        setHeaderMenu(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const getSeoData = async (type) => {
  //   try {
  //     const response = await axios.get(get_menu(type), config);
  //     if (response.status < 400) {
  //       setSeoData(response?.data?.data);
  //       let data = response?.data?.data?.find(
  //         (ele) => ele.link === `${location.pathname}`
  //       );
  //       setPathOfHelmet({
  //         metaTitle: data?.pageTitle,
  //         metaDescription: data?.pageDesc,
  //         metaKeywords: data?.pageKeyword,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    getMenu("Header");
    // getSeoData("Seo");
  }, []);

  return (
    <div
      className={
        colorChange ? "header_bg_color header_container" : "header_container"
      }
    >
      {/* <Seo
        title={pathOfHelmet?.metaTitle}
        desc={pathOfHelmet?.metaDescription}
        keywords={pathOfHelmet?.metaKeywords}
      /> */}

      <div className="container ">
        <span id="top"></span>

        <nav className="navbar navbar-expand-lg navbar-light fontshloppy">
          <div className="container-fluid p-0 sm:px-px">
            <Link className="navbar-brand flex_order_one" to={"/"}>
              <div className="header_logo">
                <a
                  href="/"
                  target="_blank"
                  className="cursor-pointer text-center"
                >
                  <img
                    alt="logo"
                    className="w-full max-w-[4rem] 2xl:max-w-[6rem]"
                    src={"/images/logo.png"}
                    title={
                      PageSettingpage.data
                        ? PageSettingpage?.data[0]?.title
                        : ""
                    }
                  />
                </a>
              </div>
            </Link>

            <div className="flex space-x-3 flex_order_two items-center">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 40, hide: 40 }}
                overlay={renderTooltip}
              >
                <div data-tooltip-target="tooltip-dark" className="">
                  <Link
                    to="/cart"
                    className="decoration-0 text-white cart-icon"
                    onClick={() => {
                      dispatch({
                        payload: 1,
                        type: CART_STEP,
                      });
                    }}
                  >
                    <Badge count={totalCartItem} className="font-global">
                      <Avatar shape="circle" size="medium" icon={<BsCart2 />} />
                    </Badge>
                    {/* <Badge count={2} className="relative" /> */}
                  </Link>
                </div>
              </OverlayTrigger>

              <div className="login-btn">
                {isAuthenticated ? (
                  <Dropdown
                    className="float-right"
                    overlay={menu}
                    onVisibleChange={handleVisibleChange}
                    visible={visible}
                  >
                    <div>
                      <button className="ant-dropdown-link">
                        <Avatar icon={<UserOutlined />} />
                      </button>
                    </div>
                  </Dropdown>
                ) : (
                  <button
                    className="border-[1px] !font-thin !font-[1.4rem] tracking-wider sm:border-[2px] sm:py-2 sm:px-3 sm:font-bold rounded-full px-2 py-1 text-[12px]  transition-all duration-200  hover:!bg-gray-800 login-register-btn"
                    onClick={() => handleLogin()}
                  >
                    Login / Register
                  </button>
                )}
              </div>
              {show === "show" ? (
                <button
                  className="navbar-toggler flex_order_three px-1 text-sm sm:text-base "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavAltMarkup"
                  aria-controls="navbarNavAltMarkup"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  style={{
                    filter: "brightness(0) invert(1)",
                  }}
                  onClick={() => {
                    setShow("hide");
                  }}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              ) : (
                <button
                  className="navbar-toggler flex_order_three px-1 text-sm sm:text-base "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavAltMarkup"
                  aria-controls="navbarNavAltMarkup"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  style={{
                    filter: "brightness(0) invert(1)",
                  }}
                  onClick={() => {
                    setShow("show");
                  }}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              )}
            </div>
            {console.log(show)}
            <div
              className={`${"collapse navbar-collapse flex_order_three justify-center"} ${show} `}
              id="navbarNavAltMarkup"
            >
              <div className="navbar-nav">
                <div className={NavbarContainer}>
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0 text-white">
                    {headerMenu
                      .filter((active) => active?.isActive)
                      ?.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className="font-bold md:mx-2 lg:!mx-2 nav-item"
                          >
                            <NavLink
                              to={item?.link}
                              className="text-white nav-link"
                              onClick={() => {
                                // getMenuItem(item);
                                setShow("hide");
                              }}
                            >
                              {item?.pageName}
                            </NavLink>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <Modals.LoginModal
          loginModalVisible={loginModalVisible}
          hideLoginModal={hideLoginModal}
          setUser={setUser}
          setNavbarContainer={setNavbarContainer}
          setLoginModalVisible={setLoginModalVisible}
        />
      </div>
    </div>
  );
}

export default Header;
