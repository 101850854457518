import React from "react";
import "./Collage.scss";
import Benefit from "../benefit_membership/Benefit";
import Card_Container from "../card_container/Card_Container";
import TeamCardComponent from "../teamcardcomponent/TeamCardComponent";

function Collage() {
  return (
    <div className="collage-bg">
      <Benefit />
      <div className="second_section">
        <Card_Container />
        <TeamCardComponent />
      </div>
    </div>
  );
}

export default Collage;
