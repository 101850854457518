import { ActionType } from "../types/seoTypes";

const initialstate = {
  allpages: [],
  singlepage: {},
  updatepage: [],
};
export const SeoReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
    case ActionType.GET_ALL_PAGES:
      return { ...state, allpages: payload };
    case ActionType.GET_SINGLE_PAGE:
      return { ...state, singlepage: payload };
    case ActionType.UPDATE_PAGE:
      return { ...state, updatepage: payload };

    default:
      return state;
  }
};
