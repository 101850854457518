import { STORE_SINGLE_PRODUCT_DATA } from "../types/singleProductType";

const initialState = {
  data: {},
  oneEth: 0,
};

const singleProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_SINGLE_PRODUCT_DATA:
      return {
        ...state,
        data: action.payload.data,
        oneEth: action.payload.oneEth,
      };

    default:
      return state;
  }
};

export default singleProductReducer;
