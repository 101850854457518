export const FETCH_PAGE_BY_ID = "FETCH_PAGE_BY_ID";
export const FETCH_PAGE_BY_ID_SUCCESS = "FETCH_PAGE_BY_ID_SUCCESS";
export const FETCH_PAGE_BY_ID_FAILURE = "FETCH_PAGE_BY_ID_FAILURE";

export const FETCH_SINGLE_SECTION_BY_SECTION_NAME =
  "FETCH_SINGLE_SECTION_BY_SECTION_NAME";

export const FETCH_HOME_PAGE_TEAM_SECTION = "FETCH_HOME_PAGE_TEAM_SECTION";

export const FETCH_SETTING_PAGE = "FETCH_SETTING_PAGE";
export const ADD_FETCH_SETTING_PAGE = "ADD_FETCH_SETTING_PAGE";
export const UPDATE_FETCH_SETTING_PAGE_SUCCESS =
  "UPDATE_FETCH_SETTING_PAGE_SUCCESS";

export const DELETE_FETCH_SETTING_PAGE = "DELETE_FETCH_SETTING_PAGE";
