import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PaginationCard from "../proshop-page/paginationCard/PaginationCard";
import ProductCardFIlter from "../proshop-page/product/ProductCardFIlter";
import "./propcategory.scss";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActiveProducts,
  setProduct,
} from "../../redux/actions/productAction";

import "antd/dist/antd.css";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { CiFilter } from "react-icons/ci";
import { BiSortAlt2 } from "react-icons/bi";
import { Checkbox, Form, Input, Select, Tooltip } from "antd";

import _debounce from "lodash/debounce";
import PaginationCardCategory from "../proshop-page/paginationCard/PaginationCardCategory";

const { Option } = Select;

const PropCategory = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [totalItems, setTotalItems] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [orderKey, setOrderKey] = useState("minPrice");
  const [sort, setSort] = useState("asc");
  const [search, setSearch] = useState("");
  const [sizes, setSizes] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSelected, setIsSelected] = useState({});

  const [category, setCategory] = useState(null);
  const fetchCategories = useSelector(
    (state) => state?.allProducts?.categories?.data
  );
  const actProducts = useSelector(
    (state) => state.allProducts?.getactiveproducts?.data
  );
  console.log(fetchCategories, " iddd");
  function onChange(checkedValues) {
    setLoading(true);
    let newVal = checkedValues.join(", ");
    setSelectedSize(newVal);
    dispatch(
      fetchActiveProducts(
        pageNum,
        pageSize,
        search,
        orderKey,
        sort,
        "",
        id,
        selectedColor,
        newVal
      )
    );
    setLoading(false);
  }

  useEffect(() => {
    // if (!fetchCategories) {
    //   navigate(`/proshop`)
    //   return
    // }
    setLoading(true);
    setTimeout(() => {
      dispatch(
        fetchActiveProducts(
          pageNum,
          pageSize,
          search,
          orderKey,
          sort,
          "",
          id,
          selectedColor,
          selectedSize
        )
      );
      let category = fetchCategories.find((item) => item.categoryUrl == id);
      setCategory(category);
    }, 1000);
    setLoading(false);
  }, [id]);

  const paginate = (e) => {
    setPageNum(e.selected + 1);
  };

  const onSearch = (sort) => {
    setLoading(true);
    let params = {};
    if (search) {
      params.title = search;
      setPageNum(1);
      setOrderKey("");
    }

    if (sort && sort != "null") {
      params.sort = sort;
    }
    // params.skip = 1
    // params.limit = 2
    dispatch(
      fetchActiveProducts(
        pageNum,
        pageSize,
        search,
        orderKey,
        sort,
        "",
        id,
        selectedColor,
        selectedSize
      )
    );
    setLoading(false);
  };

  const productColor = ["Blue", "Brown", "Green", "Grey", "Red", "Yellow", "Black", "White", "Orange", "Pink", "Purple"];
  const productSize = [
    "Free",
    "XS",
    "S",
    "M",
    "L",
    "XL",
    "XXL",
  ];
  const inputCheck =
    "shadow-check-none form-check-input !outline-none   appearance-none !h-[1.3rem] !w-[1.3rem]  border-[4px] !border-[#6D6D6D] rounded-sm bg-black checked:!bg-orange-500 checked:!border-orange-500 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer";
  return (
    <div className="Prop_category !pt-[6rem]">
      <Container>
        <Row>
          <Col className="col-12 text-center my-5">
            <div className="category-block">
              <div className="d-flex align-items-center ">
                <div className="category-block-thumbnail">
                  <img
                    src={
                      category?.image
                        ? category?.image
                        : "/images/loading-icon.jpg"
                    }
                    alt={category?.categoryName}
                  />
                </div>
                <div className="text-left">
                  <p>Categories</p>
                  <h2>{category?.categoryName}</h2>
                  <p className="items position-relative">
                    {actProducts?.length} Items
                  </p>
                </div>
              </div>
            </div>
          </Col>
          {/* filter */}

          <div className="mb-12 product_card">
            <Form
              initialValues={{ remember: true }}
              autoComplete="off"
              name="horizontal_login"
              className="mx-auto flex items-center flex-col sm:flex-row gap-4 justify-between "
            >
              <div className=" !w-full !max-w-[450px] relative">
                <Input
                  icon="search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    onSearch();
                  }}
                  placeholder="Search..."
                  className="!w-full focus:!shadow-none h-[50px] !bg-[#ffffff08] relative "
                />
                <span
                  style={{ cursor: "pointer" }}
                  className="absolute  right-[0] top-[8px] z-20 p-[8px] mr-2 text-white"
                >
                  <AiOutlineSearch onClick={() => onSearch()} size={20} />
                </span>
              </div>
              <div className="flex flex-row items-center justify-center sm:justify-content-end  !gap-4 ">
                <Form.Item
                  name="sortby"
                  label="sortby"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  className="product-card-select-field !bg-[#ffffff08] !mb-0 "
                >
                  <BiSortAlt2 className="filter-icon" size={20} />
                  <Select
                    placeholder="Sort By"
                    value={sort}
                    onChange={(val) => {
                      setPageNum(1);

                      if (val == "null") return;
                      setOrderKey("minPrice");
                      setSort(val);
                      dispatch(
                        fetchActiveProducts(
                          pageNum,
                          pageSize,
                          search,
                          orderKey,
                          val,
                          "",
                          id,
                          selectedColor,
                          selectedSize
                        )
                      );
                    }}
                    className="!text-white focus:!shadow-none !bg-[#ffffff08]"
                  >
                    <Option value="asc">Price: Low to High</Option>
                    <Option value="desc">Price: High to Low</Option>
                  </Select>
                </Form.Item>
              </div>
            </Form>
          </div>

          {/* filter end */}
          <Row>
            <Col className="col-lg-2 col-md-4 col-12">
              <div className="left-filter-clm">
                <div className="category-filter-block">
                  <h2>Color</h2>
                  <div className="filter-color-list-area d-flex mt-4">
                    {productColor?.map((col, i) => (
                      <Tooltip placement="top" title={col}>
                        <div
                          className={`filter-color-list-itm ${
                            isSelected?.[i] ? "selected" : ""
                          }`}
                          style={{ background: col }}
                          onClick={() => {
                            setSelectedColor(col?.toLowerCase());

                            setIsSelected((prev) => ({
                              [i]: !prev[i],
                            }));

                            dispatch(
                              fetchActiveProducts(
                                pageNum,
                                pageSize,
                                search,
                                orderKey,
                                sort,
                                "",
                                id,
                                col?.toLowerCase(),
                                selectedSize
                              )
                            );
                          }}
                        ></div>
                      </Tooltip>
                    ))}
                  </div>
                </div>
                <div className="category-filter-block">
                  <h2>Size</h2>
                  <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
                    <div className="filter-size-list-area">
                      {productSize?.map((size) => (
                        <div span={8} className="filter-size-list-item">
                          <Checkbox
                            className={inputCheck}
                            value={size}
                            id="flexCheckChecked"
                          >
                            {size}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </Checkbox.Group>
                </div>
              </div>
            </Col>
            <Col className="col-lg-10 col-md-8 col-12 ">
              {loading ? (
                <h1>loading....</h1>
              ) : (
                <PaginationCardCategory
                  paginate={paginate}
                  totalItems={totalItems}
                  lastPage={lastPage}
                  pageNum={pageNum}
                  pageSize={pageSize}
                  setTotalItems={setTotalItems}
                  setLastPage={setLastPage}
                  setPageNum={setPageNum}
                  setPageSize={setPageSize}
                  selectedCategory={selectedCategory}
                  orderKey={orderKey}
                  sort={sort}
                  setSort={setSort}
                  category={id}
                  color={selectedColor}
                  itemSize={selectedSize}
                />
              )}
            </Col>
          </Row>
        </Row>
      </Container>
    </div>
  );
};

export default PropCategory;
