import React, { useRef, useEffect } from "react";
import { BsArrowRightCircle } from "react-icons/bs";
import { Container, Row } from "react-bootstrap";
import "./rtgc.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Player, ControlBar } from "video-react";
import "video-react/dist/video-react.css";
//import RTGCVideo from "../../../images/RTGC-Video.mp4";
import ReactPlayer from "react-player/lazy";

const RTGC = () => {
  const pages = useSelector((state) => state?.cmsPageSetting?.allPage);
  let data = pages?.filter((page) => page?.section === "RTGC");
  const player = useRef(null);
  console.log(data?.[0]?.videoUrl, "vid");
  console.log(data?.[0]?.files?.length, "vid");
  let VideoData = data?.[0]?.files?.length
    ? data?.[0]?.files?.[0]?.url
    : data?.[0]?.videoUrl;

  useEffect(() => {
    // player.play();
  }, []);

  return (
    <div className="rtgc_container" id="rtgc">
      <Container>
        <Row>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="video-responsive">
              {VideoData && (
                <Player ref={player}>
                  <source src={VideoData} />
                  <ControlBar autoHide={false} />
                </Player>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="rtgc_content">
              {data[0]?.title.map((item) => {
                return (
                  <div key={item._id}>
                    <h2
                      className="fontHeadingShloppy"
                      style={{ color: `${item?.headingColour}` }}
                    >
                      {item.heading}
                    </h2>
                    <div
                      className="my-2"
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    />
                  </div>
                );
              })}
              <div>
                {data[0]?.buttonText ? (
                  <Link to="/about" className="no-underline">
                    <button>
                      <span>
                        <BsArrowRightCircle />
                      </span>
                      <span>{data[0]?.buttonText}</span>
                    </button>
                  </Link>
                ) : (
                  ""
                )}
              </div>

              {/* <ReadMore>
               Introducing Eldrick the Roaring Tiger, Metaverse founder of RTGC the greatest golfer the Metaverse has ever seen. Eldrick the Roaring Tiger was so good he would play shoeless and still win by ten. ETRT was so good... he just got bored. Now, 4,000 other Roaring Tigers follow in his footsteps.
              </ReadMore> */}
            </div>
          </div>
        </Row>
      </Container>

      <div className="rtgc_image"></div>
    </div>
  );
};

export default RTGC;
