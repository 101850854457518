import React from "react";

import BillingDetail from "./billingDetail/BillingDetail";
import BillingOrder from "./orderSummary/BillingOrder";

import "./Checkout.scss";
import OrderSummary from "../orderConfirmation/OrderSummary/OrderSummary";

const Checkout = () => {
  return (
    <section className="opacity-2 bg-black   bg-no-repeat">
      <div className=" container-fluid py-77 mx-auto  pt-[10rem]  text-white 2xl:container">
        <div className=" sm:mx-auto md:mx-auto md:px-4   lg:mx-1">
          <div className="text-center">
            <h2 className="position-relative tex-center flex-wrap pb-3 text-[2.3rem] font-bold leading-[3.4rem] text-white">
              Secure Checkout
            </h2>
            <img
              src="/images/dot_border.png"
              alt="dot-border"
              className=" mx-auto"
            />
          </div>
          <div className=" mt-10 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {/* -----Left Box Start-------*/}

            <BillingDetail />

            {/* Left Box End */}

            {/* Center Box  start*/}
            <BillingOrder />

            {/* Center Box End */}

            {/* Right Box Start */}
            <div className=" ml-[0] mr-0 xs:mx-6 xs:mr-[0]  xs:ml-[0rem] sm:mx-0 sm:mr-[1px] sm:ml-[18px] lg:mr-[0rem] lg:ml-[2rem] ">
              <OrderSummary padding={20} />
            </div>
            {/* Right Box End */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Checkout;
