import React from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { remove_fav_product, update_fav_product } from "../../../api/Api-list";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import getSingleProduct from "../../../redux/actions/singleProductActions";
import { fetchActiveProducts } from "../../../redux/actions/productAction";

function ProductCard({
  data,
  productId,
  setSize,
  pageNum = "",
  pageSize = "",
  orderKey = "",
  sort = "",
  oneEth,
}) {
  console.log("dataaaa", data);
  const location = useLocation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  let navigate = useNavigate();

  const app_token = localStorage.getItem("tiger-token");

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  const handleLike = async (id) => {
    try {
      let res = await axios.patch(update_fav_product(id), {}, config);
      if (res.status < 400) {
        // addToast(res.data.message, {
        //   appearance: "success",
        //   autoDismiss: true,
        // });
        dispatch(getSingleProduct(productId));
        dispatch(fetchActiveProducts(pageNum, pageSize, "", orderKey, sort));
        setSize();
      }
    } catch (error) {
      console.log(error);
      // addToast(error.response.data.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  };
  const handleUnlike = async (id) => {
    try {
      let res = await axios.delete(remove_fav_product(id), config);
      if (res.status < 400) {
        // addToast(res.data.message, {
        //   appearance: "success",
        //   autoDismiss: true,
        // });
        dispatch(getSingleProduct(productId));
        dispatch(fetchActiveProducts(pageNum, pageSize, "", orderKey, sort));
        setSize();
      }
    } catch (error) {
      console.log(error);
      // addToast(error.response.data.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  };

  const innerPage =
    location.pathname === "/prop-category" || location.pathname === "/proshop";
  const goToDetails = (product) => {
    navigate(`/prop-detail/${product?.pageUrl}`);
  };

  return (
    <div
      className="w-full max-w-[100%] bg-[#161616] rounded-xl mx-auto product_card cursor-pointer group"
      key={data._id}
    >
      <div
        className="no-underline text-white"
        onClick={() => goToDetails(data)}
      >
        <img
          src={data?.images[0]?.image || "../../images/noimg.png"}
          className="rounded-xl w-full object-cover cart-main-img"
          title={data?.title}
          alt={data?.title}
        />
      </div>
      <div className="p-4 w-full space-y-4">
        <p className="text-[#979797] font-bold text-[15px]">
          {data?.category?.[0]?.categoryName}
        </p>

        {/* <h4 className="text-[#f97316] font-bold text-[21px]">
          {data?.isComingSoon ? "Coming Soon" : ""}
        </h4> */}

        <h2
          // dangerouslySetInnerHTML={{
          //   __html: data?.description ? data?.description : "",
          // }}
          className="font-semibold text-[25px] transition-all duration-75 text-white group-hover:!text-[#f97316]" //  hover:!text-orange-500
          onClick={() => goToDetails(data)}
        >
          {data?.title}
        </h2>

        {data?.isComingSoon ? (
          <p className="product-card-usd mt-40">Coming Soon</p>
        ) : (
          <div className="flex space-x-2 space-between">
            <span className="product-card-usd">${data?.minPrice}</span>

            <span className="product-card-ethereum">
              {(data?.minPrice / oneEth).toFixed(5)}
              <img src="/images/eth-icon.png" alt="ethicons" />
            </span>
            {/* {data?.priceEth && (<><span className="font-semibold text-[25px]">{data?.priceEth}
          </span><img
              src={data?.categoryId?.image}
              className="w-3 object-cover "
              alt="team"
            /></>)}
          {data?.price && (<span>( ${data?.price} )</span>)} */}
          </div>
        )}

        <div className="flex justify-between p-1 items-center">
          {data?.category.length > 0 && (
            <div>
              <img
                src={data?.category?.[0]?.image}
                alt="team"
                className="d-inline w-8 object-cover rounded-full "
              />
              <span className="font-semibold text-[16px] pl-2">
                {" "}
                {data?.category?.[0]?.categoryName}
              </span>
            </div>
          )}
          <div className="flex items-center justify-center space-x-1">
            <div className="relative">
              {app_token ? (
                <>
                  {data?.isFavourite ? (
                    <AiFillHeart
                      onClick={() => handleUnlike(data._id)}
                      className="d-inline animate-pulse cursor-pointer text-red-500 delay-[1s] duration-75 ease-in-out hover:transition"
                      size={21}
                    />
                  ) : (
                    <AiOutlineHeart
                      onClick={() => handleLike(data._id)}
                      className="d-inline animate-pulse cursor-pointer text-white delay-[1s] duration-75 ease-in-out hover:transition"
                      size={21}
                    />
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="text-center">
          {innerPage && (
            <button
              className="border-2 text-white px-4 py-2 rounded-full m-1 my-3 font-semibold text-[15px] hover:bg-orange-500/30 hover:border-orange-500 "
              onClick={() => goToDetails(data)}
            >
              View Product
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
