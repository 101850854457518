import axios from "axios";
import {
  get_all_cart_items,
  get_all_cart_items_with_cookie,
} from "../../api/Api-list";
import { UPDATE_TOTAL_CART_ITEM } from "../types/cartSectionType";

export const getTotalCartItem = (userToken, isAuthenticated, rtgcCartId) => {
  return async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    if (isAuthenticated) {
      try {
        const response = await axios.get(get_all_cart_items, config);
        if (response.status < 400) {
          dispatch({
            type: UPDATE_TOTAL_CART_ITEM,
            payload: response.data.cart.items.length,
          });
        }
      } catch (err) {
        console.log(err);
      }
    } else if (!isAuthenticated && rtgcCartId !== null) {
      try {
        const response = await axios.get(
          get_all_cart_items_with_cookie(rtgcCartId)
        );
        if (response.status < 400) {
          dispatch({
            type: UPDATE_TOTAL_CART_ITEM,
            payload: response.data.cart.items.length,
          });
        }
      } catch (err) {
        console.log(err);
        dispatch({
          type: UPDATE_TOTAL_CART_ITEM,
          payload: 0,
        });
      }
    } else {
      dispatch({
        type: UPDATE_TOTAL_CART_ITEM,
        payload: 0,
      });
    }
  };
};
