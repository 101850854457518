import React, { useState, useEffect } from "react";
import "./mintBuy.scss";
import { useDispatch, useSelector } from "react-redux";
import { mintToken } from "../../../redux/actions/blockchain";
import {
  LOAD_MINT_AMOUNT,
  LOAD_NFT_IMAGE,
  SHOW_MODAL
} from "../../../redux/types/blockchainType";
import { switchNetwork } from "../../../redux/actions/blockchain";
import ChooseHowConnect from "../../modals/wallet/WalletModal";
import LoadingModal from "../../modals/loading/Loading";
import { TransactionCompleteModal } from "../../modals/transactionconfirmation/TransactionConformation";
const { ethereum } = window;

const MintBuy = () => {
  const dispatch = useDispatch();

  const [count, setCount] = useState(0);
  const [metamaskAvailable, setMetaMaskAvailable] = useState(true);
  const { isWrongNetwork, isConnected, totalMinted } = useSelector(
    (state) => state.blockChain
  );

  const handleSetCount = (newValue) => {
    if (newValue >= 0) {
      setCount(newValue);
    }
  };

  useEffect(() => {
    dispatch({ type: LOAD_NFT_IMAGE, payload: { loadImage: false } });
  }, []);

  if (ethereum) {
    ethereum.on("chainChanged", (chainId) => {
      window.location.reload();
    });

    ethereum.on("accountsChanged", (accounts) => {
      window.location.reload();
    });
  }

  const clickForMetamaskInstall = () => {
    window.location.href = "https://metamask.io/download/";
  };

  const connectWallet = async () => {
    dispatch({
      type: SHOW_MODAL,
      payload: { walletConnectModalOpen: true }
    });
  };

  const changeNetwork = async () => {
    await dispatch(switchNetwork)();
  };

  const mint = () => {
    dispatch({
      type: LOAD_MINT_AMOUNT,
      payload: { amount: count }
    });
    dispatch(mintToken);
  };

  return (
    <div>
      {" "}
      <ChooseHowConnect setMetaMaskAvailable={setMetaMaskAvailable} />
      <div className="max-w-[450px] mx-auto  mint_success_content ">
        <h2 className="text-white mb-0 md:text-[35px]  font-extrabold text-center mt-4 ">
          Mint Your Roaring Tiger!
        </h2>
        <div className="flex items-center justify-center">
          <button
            onClick={() => handleSetCount(count - 1)}
            className="text-white my-4 text-3xl  bg-[#ffffff40] px-3 py-1 rounded-l-lg border  "
          >
            -
          </button>
          <input
            type="number"
            className="focus:outline-none text-center  text-white my-4 text-3xl  bg-[#fff0] px-3 py-1 border-y-2 border focus:outline-0"
            value={count}
          />
          <button
            onClick={() => handleSetCount(count + 1)}
            className="text-white my-4 text-3xl  bg-[#ffffff40] px-3 py-1 rounded-r-lg border"
          >
            {" "}
            +{" "}
          </button>
        </div>
        {<LoadingModal />}
        {<TransactionCompleteModal />}
        <p className="text-white md:text-[17px]  text-xl text-center m-0 ">
          Buy/Mint a Roaring Tiger for 0 Eth{" "}
        </p>
        <button
          disabled={isConnected && count === 0 && !isWrongNetwork}
          hidden={!metamaskAvailable}
          onClick={
            !isConnected
              ? connectWallet
              : isConnected && isWrongNetwork
              ? changeNetwork
              : mint
          }
          className="text-[22px] font-semibold text-center w-full bg-[#FF8500] text-white !my-4 !py-2 mb-1 rounded-lg"
        >
          {!isConnected
            ? "Connect Wallet"
            : isConnected && isWrongNetwork
            ? "Wrong Network! Click To Go Right Network"
            : "Mint Now"}
        </button>

        <button
          hidden={metamaskAvailable}
          onClick={clickForMetamaskInstall}
          className="text-[22px] font-semibold text-center w-full bg-[#FF8500] text-white !my-4 !py-2 mb-1 rounded-lg"
        >
          Click Here to Install Metamask
        </button>
        <p className="text-white md:text-[17px]  text-xl text-center m-0 p-0 ">
          Total Sold {totalMinted}/3600
        </p>
      </div>
    </div>
  );
};

export default MintBuy;
