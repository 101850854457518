import { message } from "antd";
import axios from "axios";
import { forgot_password } from "../../api/Api-list";
import { FORGOT_PASSWORD, FORGOT_PASSWORD_FAILURE } from "../types/usertypes";

export const forgotPasswordAction = (data) => {
  return (dispatch) => {
    axios
      .post(forgot_password, data)
      .then((response) => {
        dispatch({
          type: FORGOT_PASSWORD,
          payload: response.data,
        });

        message.success(response.data.msg);
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: FORGOT_PASSWORD_FAILURE,
          payload: error.response.data,
        });
        message.error(error.response.data.message);
        return error.response.data;
      });
  };
};
