import React, { useEffect, useState } from "react";
import editIcon from "../../images/edit.png";
import { Col, Row, Table } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { get_all_email_templates } from "../../../api/Api-list";

const EmailTemplateList = () => {
  const [data, setData] = useState();
  const app_token = localStorage.getItem("tiger");
  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };
  console.log(config);
  useEffect(() => {
    getAllEmailTemplates();
  }, []);

  const getAllEmailTemplates = async () => {
    try {
      const response = await axios.get(get_all_email_templates, config);
      if (response.status < 400) {
        setData(response.data.allEmailTemplates.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  let columns = [
    {
      title: "Template Type",
      dataIndex: "templateType",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "Template Text",
      dataIndex: "templateHtml",
      render: (item) => {
        return <span>{item.replace(/(<([^>]+)>)/gi, "")}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "actions",
      render: (item, data, index) => {
        return (
          <div className="d-flex align-items-center">
            &nbsp;&nbsp;&nbsp;
            <Link to={`${data._id}`}>
              <img src={editIcon} alt="Edit" className="h-[15px]" />
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="prd-manage">
        <Row gutter={16}>
          <div className="flex items-center justify-between w-full px-2">
            <h3 className="m-0">Email Templates</h3>
          </div>
          <Col span={24} className="mt-4 user-table">
            <Table
              rowKey="_id"
              rowSelection={null}
              key={data?.length}
              columns={columns}
              defaultCurrent={1}
              dataSource={data || []}
              scroll={{ x: 1000 }}
              pagination={false}
              //   pagination={{
              //     pageSize: 10,
              //     total: totalPage,
              //     onChange: (page) => {
              //       getAllEmailTemplates();
              //     },
              //   }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EmailTemplateList;
