import React from "react";
import TeamCard from "../team-card/TeamCard";
import { Row, Col } from "antd";
import { Container } from "react-bootstrap";
import "./teamcardcomponent.scss";
import Footer from "../../footer/Footer";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";


function TeamCardComponent() {
  const RTCGTeams = useSelector((state) => state?.cmsPageSetting?.teamSection);
  const location = useLocation();
  return (
    <>
      {location.pathname === "/ourteam" ? (
        <div className="pt-[8rem]"></div>
      ) : (
        ""
      )}
      <div className="team_wrapper" id="ourteam">
        <div className="flex justify-center items-center text-white font-bold text-[20px] md:text-[25px] my-4 ">
          <h1 className="heading fontHeadingShloppy" style={{ color: '#ed47c0' }}>RTGC Founders</h1>
        </div>
        <Container>
          <Row gutter={[64, 18]} justify="center">
            {RTCGTeams?.data?.map((team, index) => (
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={6}
                className="!flex !justify-center"
                key={index}
              >
                <TeamCard key={index} team={team} />
              </Col>
            ))}
          </Row>
        </Container>
        {location.pathname === "/ourteam" ? "" : <Footer />}
      </div>
    </>
  );
}

export default TeamCardComponent;
