import { message } from "antd";
import {
  LOAD_CONTRACT,
  SET_WALLET_PROVIDER,
  WRONG_NETWORK,
  LOAD_WALLET_ADDRESS,
  LOAD_NFT_DETAILS,
  LOAD_MINT_AMOUNT,
  LOAD_NFT_IMAGE,
  TOTAL_MINTED,
  SHOW_MODAL,
  CONNECTED_WALLET_PROVIDER,
  LOAD_LOADER,
  CONFORMATION_MODAL
} from "../types/blockchainType";

const initialState = {
  walletProvider: null,
  isWrongNetwork: false,
  erc721Methods: null,
  walletAddress: "",
  nftDetails: [],
  amount: 0,
  isConnected: false,
  loadImage: false,
  totalMinted: 0,
  walletConnectModalOpen: false,
  connectedWalletProvider: "",
  loadLoader: false,
  message: "",
  openConformationModal: false,
  transactionHash: ""
};

export default function blockChainReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WALLET_PROVIDER:
      return {
        ...state,
        walletProvider: action.payload.walletProvider
      };
    case LOAD_WALLET_ADDRESS:
      return {
        ...state,
        walletAddress: action.payload.walletAddress,
        isConnected: action.payload.isConnected
      };
    case WRONG_NETWORK:
      return {
        ...state,
        isWrongNetwork: action.payload.isWrongNetwork
      };
    case LOAD_CONTRACT:
      return {
        ...state,
        erc721Methods: action.payload.erc721Methods
      };
    case LOAD_NFT_DETAILS:
      return {
        ...state,
        nftDetails: action.payload.nftDetails
      };
    case LOAD_MINT_AMOUNT:
      return {
        ...state,
        amount: action.payload.amount
      };
    case LOAD_NFT_IMAGE:
      return {
        ...state,
        loadImage: action.payload.loadImage
      };
    case TOTAL_MINTED:
      return {
        ...state,
        totalMinted: action.payload.minted
      };

    case SHOW_MODAL:
      return {
        ...state,
        walletConnectModalOpen: action.payload.walletConnectModalOpen
      };
    case CONNECTED_WALLET_PROVIDER: {
      return {
        ...state,
        connectedWalletProvider: action.payload.connectedWalletProvider
      };
    }
    case LOAD_LOADER: {
      return {
        ...state,
        loadLoader: action.payload.loadLoader,
        message: action.payload.message
      };
    }

    case CONFORMATION_MODAL: {
      return {
        ...state,
        openConformationModal: action.payload.openConformationModal,
        transactionHash: action.payload.transactionHash
      };
    }

    default:
      return state;
  }
}
