import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { Upload, Input, Form, Modal, message } from "antd";
// import "./addproduct.scss";
// tiny Mce

import { useDispatch } from "react-redux";
import { AddNewCategory, CategoryList } from "../../../../redux/actions/productAction";
import { addProductCategory } from "../../../../api/Api-list";
import axios from "axios";
// component to add product Category --------------------------------------------
function AddCategory() {
  const dispatch = useDispatch();
  const Admin_Token = localStorage.getItem("tiger");

  // conver image to base64
  const imgtoBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const inputclass = "bg-transparent !py-[0.7rem] !rounded-[0.3rem] text-black";
  // const inputnumberclass =
  //   "bg-transparent !py-[0.5rem] !w-[100%]  !rounded-[0.3rem] text-black";
  const [form] = Form.useForm();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [imgerr, setImgerr] = useState(false);
  const [fileList, setFileList] = useState([]);

  const navigate = useNavigate();
  const beforeUpload = () => false;
  const handleCancel = () => setPreviewVisible(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    // remove the old values in fileseLis

    var singleFile = newFileList.slice(-1);

    setFileList(singleFile);
  };

  const submitProduct = async (e) => {
    const imagetoBase64 = await imgtoBase64(fileList[0].originFileObj);
    const images = { image: imagetoBase64 };
    form
      .validateFields()
      .then(async (e) => {
        const values = await form.getFieldsValue();
        console.log(values, "formdata");

        const regex = new RegExp(/^\S*$/);


    if(!regex.test(values?.categoryUrl)){
      message.error("Please Enter a valid URL (Ex - roaringtiger)");
      return
    }


        // dispatch(AddNewCategory({ ...e, ...images }, Admin_Token));
        // dispatch(CategoryList())

        try {
          const config = {
            headers: {
              Authorization: `Bearer ${Admin_Token}`,
            },
          };

          let data = {
            categoryName: values.categoryName,
            categoryUrl: values.categoryUrl,
            image:images?.image,
          }
          let res = await axios.post(addProductCategory, data, config);
          if (res.status < 400) {
            message.success(`${res.data.message}`);
            // getHomeData();
            // setFiles([])
           
              navigate("/admin/category-management");
          }
        } catch (error) {
          message.error(
            `${
              error?.response?.data?.message || error?.response?.data?.msg
            }`
          );
        }


        form.resetFields();
        setFileList([]);

        
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  // Image Upload Button
  const uploadButton = (
    <div className="dft">
      <AiOutlinePlus
        className="!text-black mx-auto 
      !text-[1.4rem]"
      />
      <div className="mt-2">Upload</div>
    </div>
  );

  return (
    <>
      <div className="container-fluid edit_product">
        <div className="row">
          <h2 className="cms-title">Add Category </h2>

          <div className="col-12 col-sm-12 col-md-12 bg-white shadow-sm p-5 cms-box">
            <Form layout="vertical" form={form}>
              <Form.Item
                name="categoryName"
                type="text"
                label=" Category Name"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Category Name" className={inputclass} />
              </Form.Item>

              <Form.Item
                name="categoryUrl"
                type="text"
                label=" Category URL"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Category URL" className={inputclass} />
              </Form.Item>
                <small>Example - Roaringtiger</small>

              <Form.Item label="Product Image">
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  beforeUpload={beforeUpload}
                  name="images"
                  type="file"
                >
                  {fileList.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal
                  visible={previewVisible}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img alt="example" className="w-full" src={previewImage} />
                </Modal>
              </Form.Item>
              {imgerr ? (
                <p className="relative text-[#f10606] mt-[-2rem]">
                  This field is required
                </p>
              ) : (
                ""
              )}

              <Form.Item>
                <button
                  type="submit"
                  className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] "
                  onClick={submitProduct}
                >
                  Add Category
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCategory;