import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

import "./cms.scss";
import axios from "axios";
import { get_setting_page_data } from "../../../api/Api-list";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminSettingUpdate } from "../../../redux/actions/cmsSettingAction";
import { BsCameraFill } from "react-icons/bs";
import { Switch } from "antd";
import { PaymentSettingsConfirmationModal } from "../../../components/modals/paymentSettingsConfirmation/PaymentSettingsConfirmation";
function CmsForm() {
  const [message, setMessage] = useState("");
  const [imageerror, setImageerror] = useState("");
  const [error, setError] = useState("");

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [wantToEditPaymentSettings, setWantToEditPaymentSettings] =
    useState(false);

  const Admin_Token = localStorage.getItem("tiger");
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selector = useSelector((state) => state?.cmsPageSetting?.settingpage);
  const [allValues, setAllValues] = useState({
    address: {
      address: "",
      city: "",
      state: "",
      country: "",
      countryCode: "",
      postalCode: "",
    },
    email: "",
    mobile_no: "",
    title: "",
    copyright: "",
    twitter: "",
    instagram: "",
    linkedin: "",
    image: "",
    isPayPalLive: null,
  });

  useEffect(() => {
    async function getdata() {
      await axios
        .get(get_setting_page_data)
        .then((res) => {
          console.log(
            res.data.data
              ?.filter((t, index) => index === 0)
              .reduce((prev, curr) => curr, {}),
            "response"
          );
          setAllValues(
            res.data.data
              ?.filter((t, index) => index === 0)
              .reduce((prev, curr) => curr, {})
          );
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
    getdata();
  }, [selector.data]);

  function mailValidate(e) {
    // email validation
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailRegex.test(e.target.value)) {
      document.getElementById("email").className = "form-control";
      // setEmail(e.target.value);
      setAllValues({ ...allValues, email: e.target.value });

      setError("");
    } else {
      document.getElementById("email").className = "form-control error-msg";
      setError("Email is not valid");
      setAllValues({
        email: e.target.value,
      });
    }
  }

  function phoneValidate(e) {
    // phone number validation
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneRegex.test(e.target.value)) {
      document.getElementById("phone").className = "form-control";
      setAllValues({ ...allValues, mobile_no: e.target.value });
      setMessage("");
    } else {
      document.getElementById("phone").className = "form-control error-msg";
      setMessage("Phone number is not valid");
    }
  }
  async function imageValidate(e) {
    // image validation
    const imageRegex = /.(?:jpg|gif|png)$/;

    if (imageRegex.test(e.target.value)) {
      document.getElementById("image").className = "form-control";
      let file = e.target.files[0];
      const convertBase64 = (file) => {
        return new (window.Promise || Promise)((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (errors) => {
            reject(errors);
          };
        });
      };
      const base64 = await convertBase64(file);
      // console.log(base64);
      // setImage(base64);
      setAllValues({ ...allValues, image: base64 });

      setImageerror("");
    } else {
      document.getElementById("image").className = "form-control error-msg";
      setImageerror("Image is not valid");
    }
  }
  function CmsSubmit(e) {
    e.preventDefault();
    if (
      allValues.email === "" ||
      allValues.mobile_no === "" ||
      allValues.image === ""
    ) {
      if (allValues.email === "") {
        document.getElementById("email").className = "form-control error-msg";
        setError("Email is required");
      }
      if (allValues.mobile_no === "") {
        document.getElementById("mobile_no").className =
          "form-control error-msg";
        setMessage("Phone number is required");
      }
      if (allValues.image === "") {
        document.getElementById("image").className = "form-control error-msg";
        setImageerror("Image is required");
      }
    } else {
      console.log(allValues, "redsdfsdfsdfsdf");
      if (allValues?.image?.includes("http")) {
        dispatch(
          adminSettingUpdate(
            Object.keys(allValues)
              .filter((key) => key !== "image")
              .reduce(
                (prev, curr) => ({ ...prev, [curr]: allValues[curr] }),
                {}
              ),
            id,
            Admin_Token,
            navigate
          )
        );
      } else {
        dispatch(adminSettingUpdate(allValues, id, Admin_Token, navigate));
      }
      // navigate("/admin/setting", { replace: true });
      // alert("Form Submitted");
    }
  } // prevent default submit

  return (
    <>
      {isConfirmationModalOpen && (
        <PaymentSettingsConfirmationModal
          isConfirmationModalOpen={isConfirmationModalOpen}
          setIsConfirmationModalOpen={setIsConfirmationModalOpen}
          wantToEditPaymentSettings={wantToEditPaymentSettings}
          setWantToEditPaymentSettings={setWantToEditPaymentSettings}
        />
      )}

      <div className="container-fluid">
        <div className="row">
          <h2 className="cms-title">CMS Management</h2>

          <div className="col-12 col-sm-12 col-md-12 bg-white shadow-sm p-5 cms-box">
            <Form onSubmit={CmsSubmit} className="form-horizontal">
              <Row>
                <Col>
                  <Form.Label className="label-cms text-dark">Email</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    placeholder="Enter email"
                    onChange={(e) => mailValidate(e)}
                    value={allValues.email}
                  />
                  {error && (
                    <p className="error-text text-red-500 absolute">{error}</p>
                  )}
                </Col>
                <Col>
                  <Form.Label className="label-cms text-dark">Phone</Form.Label>
                  <Form.Control
                    id="mobile_no"
                    onChange={(e) => {
                      setAllValues({
                        ...allValues,
                        mobile_no: e.target.value,
                      });
                      phoneValidate(e);
                    }}
                    type="tel"
                    placeholder="Enter phone"
                    value={allValues.mobile_no}
                  />
                  {message && (
                    <p className="error-text" style={{ position: "absolute" }}>
                      {message}
                    </p>
                  )}
                </Col>
              </Row>

              <div className="image_data_container">
                <Form.Label className="my-3 label-cms text-dark">
                  <div className="image_data">
                    <img src={allValues.image || ""} alt="allimage" />
                    <Form.Control
                      type="file"
                      id="image"
                      src={allValues.image || ""}
                      onChange={(e) => {
                        imageValidate(e);
                        setAllValues({
                          ...allValues,
                          image: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <h2 className="camera_icon">
                    <BsCameraFill size={"30px"} />
                  </h2>
                </Form.Label>
              </div>
              {imageerror && (
                <p className="error-text" style={{ position: "absolute" }}>
                  {imageerror}
                </p>
              )}
              {/* <Form.Label className="label-cms my-3 text-dark">
                Quick Links
              </Form.Label>
              <Form.Control
                onChange={(e) => {
                  setAllValues({
                    ...allValues,
                    quicklink: e.target.value,
                  });
                }}
                type="text"
                placeholder="Quick Links"
                value={allValues.quicklink}
              /> */}
              <Row className="pt-4">
                <Col>
                  <Form.Label className="label-cms text-dark">
                    Twitter
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="twitter"
                    placeholder="Enter Twiiter Link"
                    onChange={(e) => {
                      setAllValues({
                        ...allValues,
                        twitter: e.target.value,
                      });
                    }}
                    value={allValues.twitter}
                  />
                </Col>

                <Col>
                  <Form.Label className="label-cms text-dark">
                    Instagram
                  </Form.Label>
                  <Form.Control
                    id="mobile_no"
                    onChange={(e) => {
                      setAllValues({
                        ...allValues,
                        instagram: e.target.value,
                      });
                      phoneValidate(e);
                    }}
                    type="tel"
                    placeholder="Enter phone"
                    value={allValues.instagram}
                  />
                  {message && (
                    <p className="error-text" style={{ position: "absolute" }}>
                      {message}
                    </p>
                  )}
                </Col>
                <Col>
                  <Form.Label className="label-cms text-dark">
                    LinkedIn
                  </Form.Label>
                  <Form.Control
                    id="linkedIn"
                    onChange={(e) => {
                      setAllValues({
                        ...allValues,
                        linkedin: e.target.value,
                      });
                      phoneValidate(e);
                    }}
                    type="text"
                    placeholder="Enter phone"
                    value={allValues.linkedin}
                  />
                  {message && (
                    <p className="error-text" style={{ position: "absolute" }}>
                      {message}
                    </p>
                  )}
                </Col>
              </Row>
              <Form.Group className="my-3" controlId="cmstitle">
                <Form.Label className="label-cms text-dark">Title</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setAllValues({
                      ...allValues,
                      title: e.target.value,
                    });
                  }}
                  type="text"
                  placeholder="Title"
                  value={allValues.title}
                />
              </Form.Group>
              <Form.Group className="my-3" controlId="cmstitle">
                <Form.Label className="label-cms text-dark">
                  Copyright
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setAllValues({
                      ...allValues,
                      copyright: e.target.value,
                    });
                  }}
                  type="text"
                  placeholder="Copyright"
                  value={allValues.copyright}
                />
              </Form.Group>

              <Form.Group className="my-3" controlId="cmstitle">
                <Form.Label className="label-cms text-dark">Address</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setAllValues((prev) => ({
                      ...allValues,
                      address: {
                        ...allValues?.address,
                        address: e.target.value,
                      },
                    }));
                  }}
                  type="text"
                  placeholder="Address"
                  value={allValues?.address?.address}
                />
              </Form.Group>

              <Form.Group className="my-3" controlId="cmstitle">
                <Form.Label className="label-cms text-dark">City</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setAllValues((prev) => ({
                      ...allValues,
                      address: {
                        ...allValues?.address,
                        city: e.target.value,
                      },
                    }));
                  }}
                  type="text"
                  placeholder="City"
                  value={allValues?.address?.city}
                />
              </Form.Group>

              <Form.Group className="my-3" controlId="cmstitle">
                <Form.Label className="label-cms text-dark">State</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setAllValues((prev) => ({
                      ...allValues,
                      address: {
                        ...allValues?.address,
                        state: e.target.value,
                      },
                    }));
                  }}
                  type="text"
                  placeholder="State"
                  value={allValues?.address?.state}
                />
              </Form.Group>

              <Form.Group className="my-3" controlId="cmstitle">
                <Form.Label className="label-cms text-dark">Country</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setAllValues((prev) => ({
                      ...allValues,
                      address: {
                        ...allValues?.address,
                        country: e.target.value,
                      },
                    }));
                  }}
                  type="text"
                  placeholder="Country"
                  value={allValues?.address?.country}
                />
              </Form.Group>

              <Form.Group className="my-3" controlId="cmstitle">
                <Form.Label className="label-cms text-dark">
                  Country Code
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setAllValues((prev) => ({
                      ...allValues,
                      address: {
                        ...allValues?.address,
                        countryCode: e.target.value,
                      },
                    }));
                  }}
                  type="text"
                  placeholder=" Country Code"
                  value={allValues?.address?.countryCode}
                />
              </Form.Group>

              <Form.Group className="my-3" controlId="cmstitle">
                <Form.Label className="label-cms text-dark">
                  Postal Code
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    // setAllValues({
                    //   ...allValues,
                    //   postalCode: e.target.value,
                    // });
                    setAllValues((prev) => ({
                      ...allValues,
                      address: {
                        ...allValues?.address,

                        postalCode: e.target.value,
                      },
                    }));
                  }}
                  type="text"
                  placeholder="Postal Code"
                  value={allValues?.address?.postalCode}
                />
              </Form.Group>

              <Col>
                <Form.Label className="my-3 label-cms text-dark">
                  Paypal Live
                </Form.Label>
                &nbsp; &nbsp;
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  checked={allValues?.isPayPalLive}
                  onChange={(values) =>
                    setAllValues({
                      ...allValues,
                      isPayPalLive: values,
                    })
                  }
                />
                <button
                  type="button"
                  onClick={() => {
                    setIsConfirmationModalOpen(true);
                  }}
                  className="bg-gray-200 text-black cursor-pointer px-4 py-2 rounded-full ml-5 hover:bg-gray-300 transition-all"
                >
                  Edit
                </button>
              </Col>

              {/* payment settings change section */}
              <div className="mb-10">
                <Form.Group className="my-3" controlId="cmstitle">
                  <Form.Label className="label-cms text-dark">
                    Braintree Merchant ID
                  </Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setAllValues((prev) => ({
                        ...allValues,
                        brainTreeMerchantId: e.target.value,
                      }));
                    }}
                    type="text"
                    placeholder="Braintree Merchant ID"
                    value={allValues?.brainTreeMerchantId}
                    disabled={!wantToEditPaymentSettings}
                  />
                </Form.Group>
                <Form.Group className="my-3" controlId="cmstitle">
                  <Form.Label className="label-cms text-dark">
                    Braintree Private Key
                  </Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setAllValues((prev) => ({
                        ...allValues,
                        brainTreePrivateKey: e.target.value,
                      }));
                    }}
                    type="text"
                    placeholder="Braintree Private Key"
                    value={allValues?.brainTreePrivateKey}
                    disabled={!wantToEditPaymentSettings}
                  />
                </Form.Group>
                <Form.Group className="my-3" controlId="cmstitle">
                  <Form.Label className="label-cms text-dark">
                    Braintree Public Key
                  </Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setAllValues((prev) => ({
                        ...allValues,
                        brainTreePublicKey: e.target.value,
                      }));
                    }}
                    type="text"
                    placeholder="Braintree Public Key"
                    value={allValues?.brainTreePublicKey}
                    disabled={!wantToEditPaymentSettings}
                  />
                </Form.Group>
              </div>

              <button
                type="submit"
                className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] "
              >
                Submit
              </button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CmsForm;
