import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

import "./cms.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllContentSingleInitiate,
  updateSectionContentInitiate,
} from "../../../redux/actions/cmsPageAction";
import { useParams } from "react-router-dom";
import { BsCameraFill } from "react-icons/bs";

// tiny Mce
import { Editor } from "@tinymce/tinymce-react";

import { RiDeleteBin6Line } from "react-icons/ri";
import { DatePicker, Popconfirm } from "antd";

import ColorPicker from "../../../components/ColorPicker";

import { DeleteCmsImage } from "../../../redux/actions/cmsPageAction";
import axios from "axios";
import { update_page_content } from "../../../api/Api-list";

import { useDropzone } from "react-dropzone";
import moment from "moment";
import dayjs from "dayjs";
import DateTimePicker from "react-datetime-picker";

import { message } from "antd";
// import "~video-react/dist/video-react.css";
import { Player } from "video-react";

// ----------------------------------Manage Cms  Component------------------------- //
function Cms() {
  const [section, setSection] = useState("");
  const [titleId, setTitleId] = useState("");
  const [_id, setId] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const Admin_Token = localStorage.getItem("tiger");
  const [imageId, setImageId] = useState("");
  const [isSingle, setIsSingle] = useState(true);
  const PageContent = useSelector(
    (state) => state.cmsPageContent.allPageContent
  );

  // ----------------------------------------------------
  const [videoUploadType, setVideoUploadType] = useState("file");
  // const [filesName, setfilesName] = useState("");
  const [selectedSection, setSelectedSection] = useState(null);
  const [uploadedVideoFile, setUploadedVideoFile] = useState(null);
  // ----------------------------------------------------

  const { id } = useParams();
  const [allValues, setAllValues] = useState({
    heading: "",
    headingColour: "",
    subHeading: "",
    description: "",
    image: "",
    buttonText: "",
    sectionName: "",
    counterDate: null,

    // ----------------------------------------------------
    // files: null,
    // videoUrl: null,
    ...(selectedSection === "RTGC" && {
      files: [],
      videoUrl: "",
    }),
    // ----------------------------------------------------
  });

  console.log(allValues);

  // validate image
  async function imageValidate(e) {
    const imageRegex = /.(?:jpg|gif|png|jpeg|webp|gif)$/;

    if (imageRegex.test(e.target.value)) {
      document.getElementById("image").className = "form-control";
      let file = e.target.files[0];
      const convertBase64 = (file) => {
        return new (window.Promise || Promise)((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (errors) => {
            reject(errors);
          };
        });
      };
      const base64 = await convertBase64(file);
      setAllValues({ ...allValues, image: base64 });
      // setImage(base64);
    } else {
      document.getElementById("image").className = "form-control error-msg";
    }
  }
  // submit form
  function hanglePageSubmit(e) {
    e.preventDefault();
    if (section === "") {
      document.getElementById("sectionName").className =
        "form-control error-msg";
      setError("Please select section");
    } else {
      document.getElementById("sectionName").className = "form-control";
      setError("");
      dispatch(
        updateSectionContentInitiate(
          Object.keys(allValues)
            .filter((key) => key !== "title")
            .reduce((prev, curr) => ({ ...prev, [curr]: allValues[curr] }), {}),
          _id,
          titleId,
          section,
          Admin_Token
        )
      );
    }
  }
  // function to get all content
  function handleSectionChange(e) {
    console.log(
      PageContent?.data?.filter((t, index) => t.section === "HERO")?.[0]
        ?.counterDate,
      "page"
    );
    document.getElementById("sectionName").className = "form-control";
    setError("");
    setSection(e.target.value);
    setId(getSectionId(e.target.value));

    // ----------------------------------------------
    setSelectedSection(e.target.value);
    // ----------------------------------------------

    setTitleId(
      PageContent?.data
        ?.filter((item) => item?.section === e.target.value)
        ?.reduce((prev, curr) => curr, {})
        ?.title?.filter((t, index) => index === 0)
        .reduce((prev, curr) => curr, {})?._id
    );
    setImageId(
      PageContent?.data
        ?.filter((item) => item?.section === e.target.value)
        ?.reduce((prev, curr) => curr, {})
        ?.image?.filter((i, index) => index === 0)
        ?.reduce((prev, curr) => curr, {})?._id
    );

    console.log(
      PageContent?.data?.filter((item) => item?.section === e.target.value)[0]
        .files
    );

    setAllValues({
      ...PageContent?.data
        ?.filter((item) => item?.section === e.target.value)
        ?.reduce((prev, curr) => curr, {})
        ?.title?.filter((t, index) => index === 0)
        .reduce((prev, curr) => curr, {}),
      image: PageContent?.data
        ?.filter((item) => item?.section === e.target.value)
        ?.reduce((prev, curr) => curr, {})
        ?.image?.filter((i, index) => index === 0)
        ?.reduce((prev, curr) => curr, {})?.item,
      buttonText: PageContent?.data
        ?.filter((item) => item?.section === e.target.value)
        ?.reduce((prev, curr) => curr, {})?.buttonText,
      sectionName: PageContent?.data
        ?.filter((item) => item?.section === e.target.value)
        ?.reduce((prev, curr) => curr, {})?.sectionName,
      counterDate: moment(
        PageContent?.data?.filter((t, index) => t.section === "HERO")?.[0]
          ?.counterDate
      ).format("YYYY-MM-DD HH:mm:ss"),
      ...(e.target.value === "RTGC" && {
        files: PageContent?.data?.filter(
          (item) => item?.section === e.target.value
        )[0]?.files,
        videoUrl:
          PageContent?.data?.filter(
            (item) => item?.section === e.target.value
          )[0]?.videoUrl || "",
      }),
    });
  }

  const handleSubSectionChange = (_id) => {
    setTitleId(_id?.target?.value);
    const selectdTitle = getSubSection()
      .filter((t) => t._id === _id?.target?.value)
      .reduce((_prev, curr) => curr, {});
    setImageId(
      PageContent?.data
        ?.filter((t, index) => t.section === section)
        .reduce((prev, curr) => curr, {})
        .image?.filter((item, i) => i === i)
        .reduce((pre, curr) => curr, {})._id
    );
    setAllValues({
      ...selectdTitle,

      sectionName: PageContent?.data
        ?.filter((t, index) => t.section === section)
        .reduce((prev, curr) => curr, {}).sectionName,
      buttonText: PageContent?.data
        ?.filter((t, index) => t.section === section)
        .reduce((prev, curr) => curr, {}).buttonText,
      image: PageContent?.data
        ?.filter((t, index) => t.section === section)
        .reduce((prev, curr) => curr, {})
        .image?.filter((item, i) => i === i)
        .reduce((pre, curr) => curr, {}).item,

      // --------------------------------------------------------------
      ...(section === "RTGC" && {
        files: PageContent?.data?.filter((item) => item?.section === section)[0]
          ?.files,
        videoUrl:
          PageContent?.data?.filter((item) => item?.section === section)[0]
            ?.videoUrl || "",
      }),
      // --------------------------------------------------------------
    });
  };

  const handleDeleteImage = (id, itemId) => {
    console.log(itemId, "dd");
    dispatch(DeleteCmsImage(id, itemId));
  };

  const getHomeData = async () => {
    dispatch(getAllContentSingleInitiate(id)).then((response) => {
      const selectedSection = response?.data
        ?.filter((_item, index) => index === 0)
        .reduce((_prev, curr) => curr, {});

      setId(selectedSection?._id);
      setSection(selectedSection?.section);
      setTitleId(
        selectedSection.title
          ?.filter((t, index) => index === 0)
          .reduce((prev, curr) => curr, {})?._id
      );
      setImageId(
        selectedSection.image
          ?.filter((item, i) => i === i)
          .reduce((pre, curr) => curr, {})._id
      );
      setAllValues({
        // ...selectedSection,
        ...selectedSection.title
          ?.filter((t, index) => index === 0)
          .reduce((prev, curr) => curr, {}),
        sectionName: selectedSection.sectionName,
        buttonText: selectedSection.buttonText,
        image: selectedSection.image
          ?.filter((item, i) => i === i)
          .reduce((pre, curr) => curr, {}).item,
        counterDate: moment(
          PageContent?.data?.filter((t, index) => t.section === "HERO")?.[0]
            ?.counterDate
        ).format("YYYY-MM-DD HH:mm:ss"),
      });
    });

    setUploadedVideoFile(null);

    return () => {
      setAllValues({
        heading: "",
        headingColour: "",
        subHeading: "",
        description: "",
        image: "",
        buttonText: "",
        sectionName: "",

        // ---------------------------------------------
        files: [],
        videoUrl: "",
        // ---------------------------------------------
      });
      setSection("");
    };
  };

  useEffect(() => {
    getHomeData();
  }, [id]);

  const getSectionId = (section) =>
    section &&
    PageContent?.data
      ?.filter((item) => item?.section === section)
      ?.reduce((prev, curr) => curr, {})?._id
      ? PageContent?.data
          ?.filter((item) => item?.section === section)
          ?.reduce((prev, current) => current, {})?._id
      : PageContent?.data[0]?._id;

  const getSubSection = () =>
    section &&
    PageContent?.data
      ?.filter((item) => item?.section === section)
      ?.reduce((prev, curr) => curr, {})?.title?.length
      ? PageContent?.data
          ?.filter((item) => item?.section === section)
          ?.reduce((prev, current) => current, {})
          ?.title.map((t) => t)
      : PageContent?.data?.filter((item) => item?.section === section);

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div className="thumb-img-itm" key={file.name}>
      <img style={{ width: "250px" }} src={file.preview} alt="" />
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  let oldfile = PageContent?.data
    ?.filter((t, index) => t.section === section)
    .reduce((prev, curr) => curr, {})?.files;

  const config = {
    headers: {
      Authorization: `Bearer ${Admin_Token}`,
    },
  };

  const submitGtgc = async () => {
    console.log(_id, 'id')
    let formData = new FormData();
    if (section == "GTGC") {
      formData.append("buttonText", allValues?.buttonText);
      formData.append("description", allValues?.description);
      formData.append("heading", allValues?.heading);
      formData.append("headingColour", String(allValues?.headingColour || ""));
      formData.append("section", section);
      formData.append("sectionName", allValues?.sectionName);
      formData.append("subHeading", allValues?.subHeading || "");
      formData.append("titleId", titleId);
      formData.append("_id", _id);
      files.forEach((image, index) => {
        formData.append("files[]", image);
      });

      try {
        let res = await axios.patch(update_page_content, formData, config);
        if (res.status < 400) {
          message.success(`${res.data.message}`);
          getHomeData();
          setFiles([])
        }
      } catch (error) {
        console.log(error);
      }
    }
    return;
  };

  const submitRtgc = async () => {
    if (
      uploadedVideoFile !== null ||
      allValues?.files?.length > 0 ||
      allValues?.videoUrl !== ""
    ) {
      let formData = new FormData();

      formData.append("buttonText", allValues?.buttonText);
      formData.append("description", allValues?.description);
      formData.append("heading", allValues?.heading);
      formData.append("headingColour", allValues?.headingColour);
      formData.append("section", section);
      formData.append("sectionName", allValues?.sectionName);
      // formData.append("subheading", allValues?.subHeading);
      formData.append("titleId", titleId);
      formData.append("_id", _id);

      if (uploadedVideoFile) {
        uploadedVideoFile?.forEach((item) => {
          formData.append("files[]", item);
        });
      }
      if (allValues?.videoUrl !== "" && uploadedVideoFile === null) {
        formData.append("videoUrl", allValues?.videoUrl);
      }

      try {
        const response = await axios.patch(
          update_page_content,
          formData,
          config
        );
        console.log(response);
        if (response.status < 400) {
          message.success(`${response.data.message}`);
          getHomeData();
        }
      } catch (error) {
        console.log(error);
      }
    } else
      message.error("Please upload video file or share a video url with us");
  };

  

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h2 className="cms-title">CMS Management </h2>

          <div className="col-12 col-sm-12 col-md-12 bg-white shadow-sm p-5 cms-box">
            <Form className="form-horizontal">
              <Row>
                <Col>
                  <Form.Label className="label-cms text-dark">
                    Select Section
                  </Form.Label>
                  <Form.Select onChange={handleSectionChange} value={section}>
                    {PageContent?.data?.map((data) => {
                      return (
                        <option value={data.section} key={data._id}>
                          {data.section}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Label className="label-cms text-dark">
                    Section Name
                  </Form.Label>
                  <Form.Control
                    name="sectionName"
                    id="sectionName"
                    type="text"
                    value={allValues?.sectionName || ""}
                    onChange={(e) => {
                      setAllValues({
                        ...allValues,
                        sectionName: e.target.value,
                      });
                    }}
                    placeholder="Enter Section  Text"
                  />
                  {error && (
                    <p className="error-text text-red absolute">{error}</p>
                  )}
                </Col>
              </Row>
              {PageContent?.data && (
                <Row>
                  <Col>
                    <Form.Label className="label-cms text-dark pt-3">
                      Select Sub section
                    </Form.Label>
                    <Form.Select
                      onChange={handleSubSectionChange}
                      disabled={getSubSection()
                        .map((t) => Object.keys(t))
                        .reduce((p, cur) => cur, [])
                        .every((item) => item === "_id")}
                    >
                      {getSubSection().map((t) => (
                        <option value={t?._id} key={t?._id}>
                          {t?.heading || "no sub section"}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <Form.Label className="label-cms text-dark pt-3">
                    Heading
                  </Form.Label>
                  <Form.Control
                    id="heading"
                    onChange={(e) => {
                      setAllValues({ ...allValues, heading: e.target.value });
                    }}
                    type="tel"
                    placeholder="Enter Main Heading"
                    value={allValues?.heading || " "}
                  />
                </Col>
                <Col>
                  <Form.Label className="label-cms text-dark pt-3">
                    Heading Color
                  </Form.Label>
                  <ColorPicker
                    value={allValues?.headingColour || '#fff'}
                    onChange={(e) => {
                      setAllValues({ ...allValues, headingColour: e });
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Form.Label className="label-cms text-dark">
                    Sub Heading
                  </Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setAllValues({
                        ...allValues,
                        subHeading: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="Title"
                    value={allValues?.subHeading || " "}
                  />
                </Col>
                <Col>
                  <Form.Label className="label-cms text-dark">
                    Button Text
                  </Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setAllValues({
                        ...allValues,
                        buttonText: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="Heading"
                    value={allValues?.buttonText || " "}
                  />
                </Col>
              </Row>
              <Form.Group className="my-3" controlId="cmstitle">
                <Form.Label className="label-cms text-dark">
                  Description
                </Form.Label>
                <Editor
                  // onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={(e) => {
                    setAllValues({ ...allValues, description: e });
                  }}
                  init={{
                    height: 300,
                    automatic_uploads: true,
                    file_picker_types: "file image media",
                    paste_data_images: true,
                    image_advtab: true,
                    file_picker_callback: function (callback, value, meta) {
                      if (meta.filetype == "image") {
                        var input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "image/*");
                        input.onchange = function () {
                          var file = this.files[0];
                          var reader = new FileReader();
                          reader.onload = function () {
                            callback(reader.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                        input.click();
                      }
                    },

                    advlist_bullet_styles: "square",
                    advlist_number_styles:
                      "lower-alpha,lower-roman,upper-alpha,upper-r",

                    /* and here's our custom image picker*/
                    toolbar_mode: "sliding",
                  }}
                  value={allValues?.description || ""}
                  plugins={[
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor",
                    "textpattern",
                    "image",
                    "imageupload",
                    "link",
                    "help",
                    "wordcount",
                    "code",
                    "preview",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "paste",
                    "charmap",
                    "searchreplace",
                    "visualblocks",
                    "emoticons",
                    "image paste",
                  ]}
                  toolbar={[
                    "undo redo | formatselect | bold italic backcolor forecolor   |alignleft aligncenter alignright alignjustify | fonts | bullist numlist outdent indent | removeformat | image| help",
                    "link imageupload | fontselect | fontsizeselect |fullscreen | insertdatetime | media | wordcount | charmap | searchreplace | visualblocks |  table | code | preview |emoticons |",
                  ]}
                />
              </Form.Group>

              {/* add counter for hero section */}
              {section == "HERO" ? (
                <Row className="mt-3">
                  <Col>
                    <Form.Label className="label-cms text-dark">
                      Date -{" "}
                      {/* {moment(allValues?.counterDate).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )} */}
                    </Form.Label>

                    <DatePicker
                      showTime
                      className="form-control"
                      defaultValue={moment(allValues?.counterDate)}
                      onChange={(value, dateString) =>
                        setAllValues({
                          ...allValues,
                          counterDate: moment(dateString).format(
                            "YYYY-MM-DD HH:mm:ss"
                          ),
                        })
                      }
                    />

                    {/* <DateTimePicker
                      onChange={
                        (e) => console.log(e)
                        // setAllValues({
                        //   ...allValues,
                        //   counterDate: e.target.value,
                        // })
                      }
                      // value={allValues?.counterDate}
                    /> */}
                  </Col>
                </Row>
              ) : (
                ""
              )}

              {section == "GTGC" ? (
                <>
                  <div className="radioSec">
                    <div className="radioCol">
                      <input
                        type="radio"
                        name="isSingle"
                        value="true"
                        checked={isSingle === true}
                        onChange={() => setIsSingle(true)}
                      />
                      <label>Single Image Upload</label>
                    </div>

                    <div className="radioCol">
                      <input
                        type="radio"
                        name="isSingle"
                        value="false"
                        checked={isSingle === false}
                        onChange={() => setIsSingle(false)}
                      />
                      <label>Multiple Image upoad</label>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}


             

              {section !== "RTGC" ? (
                <>
                  {section != "GTGC" ? (
                    <div className="image_data_container group">
                      <Form.Label className="my-3 label-cms text-dark">
                        <div className="image_data">
                          {console.log(allValues?.image, "allValues?.image ")}
                          <img
                            src={allValues?.image || ""}
                            alt=" "
                            className="group-hover:opacity-10 mix-blend-multiply "
                          />

                          <Form.Control
                            type="file"
                            id="image"
                            onChange={(e) => {
                              imageValidate(e);
                              setAllValues({
                                ...allValues,
                                image: e.target.value,
                              });
                            }}
                            accept="image/png, image/jpg, image/gif, image/jpeg ,image/gif"
                          />
                        </div>
                        <h2 className="camera_icon">
                          <BsCameraFill size={"30px"} />
                        </h2>
                      </Form.Label>
                      {_id && imageId && (
                        <>
                          <Popconfirm
                            title="Delete this Image? This cannot be undone."
                            onConfirm={() => {
                              handleDeleteImage(_id, imageId);
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <button
                              type="button"
                              className="absolute hidden group-hover:block text-[#B70100] transition-all ease-out duration-100 mb-5 hover:text-[#313E49] "
                            >
                              <RiDeleteBin6Line size={"20px"} />
                            </button>
                          </Popconfirm>
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {section == "GTGC" && isSingle == true && (
                    <div className="image_data_container group">
                      <Form.Label className="my-3 label-cms text-dark">
                        <div className="image_data">
                          {console.log(allValues?.image, "allValues?.image ")}
                          <img
                            src={allValues?.image || ""}
                            alt=" "
                            className="group-hover:opacity-10 mix-blend-multiply "
                          />

                          <Form.Control
                            type="file"
                            id="image"
                            onChange={(e) => {
                              imageValidate(e);
                              setAllValues({
                                ...allValues,
                                image: e.target.value,
                              });
                            }}
                            accept="image/png, image/jpg, image/gif, image/jpeg ,image/gif"
                          />
                        </div>
                        <h2 className="camera_icon">
                          <BsCameraFill size={"30px"} />
                        </h2>
                      </Form.Label>
                      {_id && imageId && (
                        <>
                          <Popconfirm
                            title="Delete this Image? This cannot be undone."
                            onConfirm={() => {
                              handleDeleteImage(_id, imageId);
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <button
                              type="button"
                              className="absolute hidden group-hover:block text-[#B70100] transition-all ease-out duration-100 mb-5 hover:text-[#313E49] "
                            >
                              <RiDeleteBin6Line size={"20px"} />
                            </button>
                          </Popconfirm>
                        </>
                      )}
                    </div>
                  )}

                  {section == "GTGC" && !isSingle ? (
                    <>
                      {/* <h2>sec</h2> */}
                      <div className="imageThumble">
                        <section className="">
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <h2 className="camera_icon">
                              <BsCameraFill size={"30px"} />
                            </h2>
                            <p>
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          </div>
                          <aside className="viewThumbImgSmall">{thumbs}</aside>
                        </section>
                      </div>

                      <ul className="viewThumbImg">
                        {oldfile?.map((item) => (
                          <li>
                            <img src={item.url} alt="upload" />
                          </li>
                        ))}
                      </ul>

                      <span
                        onClick={submitGtgc}
                        className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] cursor-pointer"
                      >
                        Submit
                      </span>
                    </>
                  ) : (
                    <button
                      type="button"
                      onClick={hanglePageSubmit}
                      className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] "
                    >
                      Submit
                    </button>
                  )}
                </>
              ) : (
                <>
                  {/* <h2>sec</h2> */}
                 

                    <div className="upload-radio-sec">
                      <Form.Label className="text-dark">
                        <p>
                          Do you want to upload a video file or just share a
                          video link with us?
                        </p>
                      </Form.Label>
                      <div className="upload-video-radio">
                        <Form.Label className="text-dark">
                          <input
                            type="radio"
                            name="filesUpload"
                            id="filesUpload"
                            onChange={() => {
                              // setAllValues({ ...allValues, files: {} });
                              // setfilesName("");
                              // setAllValues({ ...allValues, videoUrl: "" });
                              setVideoUploadType("file");
                            }}
                            checked={videoUploadType === "file"}
                          />
                          <label htmlFor="filesUpload">
                            Upload a video file
                          </label>
                        </Form.Label>
                      </div>
                      <div className="share-video-radio">
                        <Form.Label className="text-dark">
                          <input
                            type="radio"
                            name="videoUrlUpload"
                            id="videoUrlUpload"
                            onChange={() => {
                              // setAllValues({ ...allValues, files: {} });
                              // setfilesName("");
                              // setAllValues({ ...allValues, videoUrl: "" });
                              setVideoUploadType("url");
                            }}
                            checked={videoUploadType === "url"}
                          />
                          <label htmlFor="videoUrlUpload">
                            Share a video url
                          </label>
                        </Form.Label>
                      </div>
                      {videoUploadType === "file" ? (
                        <div className="choose-video-file">
                          <Form.Label className="text-dark">
                            <input
                              type="file"
                              name="files"
                              id="files"
                              accept="video/mp4,video/x-m4v,video/*"
                              onChange={(e) => {
                                if (
                                  e.target.files[0].type ===
                                    "video/quicktime" ||
                                  e.target.files[0].type === "video/mp4" ||
                                  e.target.files[0].type === "video/x-m4v" ||
                                  e.target.files[0].type === "video/mkv"
                                ) {
                                  // setfilesName(e.target.files[0].name);
                                  // setAllValues({
                                  //   ...allValues,
                                  //   files: [e.target.files[0]],
                                  // });
                                  setUploadedVideoFile([e.target.files[0]]);
                                } else {
                                  message.error("Please select a video file");
                                }
                                console.log(allValues);
                              }}
                              style={{ display: "none" }}
                            />
                            <label htmlFor="files">Choose a video file</label>
                            <p>{uploadedVideoFile?.[0]?.name}</p>
                            {allValues?.files?.length > 0 ? (
                              <>
                                <Player>
                                  <source src={allValues?.files?.[0]?.url} />
                                </Player>
                              </>
                            ) : null}
                          </Form.Label>
                        </div>
                      ) : videoUploadType === "url" ? (
                        <Form.Label className="text-dark video-url-field">
                          <input
                            type="text"
                            placeholder="Video url"
                            name="videoUrl"
                            value={allValues?.videoUrl}
                            onChange={(e) => {
                              setAllValues({
                                ...allValues,
                                videoUrl: e.target.value,
                              });
                            }}
                          />
                        </Form.Label>
                      ) : null}
                    </div>
                    <span
                      onClick={submitRtgc}
                      className="upload-submit-btn bg-[#00b728] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] cursor-pointer"
                    >
                      Submit
                    </span>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cms;
