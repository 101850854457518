import Modal from "antd/lib/modal/Modal";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "../login/login.scss";

const LoadingModal = () => {
  const dispatch = useDispatch();
  const { loadLoader, message } = useSelector((state) => state.blockChain);
  return (
    <Modal
      open={loadLoader}
      //   onCancel={hideModal}
      footer={null}
      destroyOnClose
      centered
      className="login_modal"
      closable={false}
    >
      <div className="loading-modal">
        <div className="loading-modal-content">
          <div className="loading-spinner" />
          <div className="loading-message text-gray-400 text-lg text-center">
            {message}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoadingModal;
