import React, { useState } from "react";

import { Button, Modal, Form, Input } from "antd";
import "./register.scss";
import { postRequest } from "../../../api/API";
import { register } from "../../../api/Api-list";
// import { useNavigate } from "react-router-dom";
import VerifyModal from "../verify/VerifyModal";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import { USER_REGISTRATION_SUCCESS } from "../../../redux/types/usertypes";

const RegisterModal = ({
  registerModalVisible,
  hideRegisterModal,
  setLoginModalVisible,
}) => {
  const [VerifyModalVisible, setVerifyModalVisible] = useState(false);
  const hideVerifyModal = () => {
    setVerifyModalVisible(false);
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const [useremail, setUserEmail] = useState("");
  const [otptime, setOtpTime] = useState(0);
  const [form] = Form.useForm();
  // const [loginModalVisible, setLoginModalVisible] = useState(false);
  const hideModal = () => {
    hideRegisterModal();
  };
  // const visibleLoginModal = () => {
  //   setLoginModalVisible(true);
  // };
  const submitRegister = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          await postRequest(register, {
            ...values,
            role: "user",
          })
            .then((res) => {
              // console.log(res.data.data.email);
              setUserEmail(res.data.data.email);
              dispatch({
                type: USER_REGISTRATION_SUCCESS,
                payload: res.data.data.email,
              });
              form.resetFields();
              setLoading(false);
              setError("");
              hideModal();
              // console.log(useremail, "usermail");
              // console.log(res.data.data.email, "day");
              addToast(
                "Thank you for registering for Roaring Tiger! Please check your email for confirmation",
                {
                  appearance: "success",
                  autoDismiss: true,
                  position: "top-center",
                }
              );
              setOtpTime(2);
              setVerifyModalVisible(true);
            })
            .catch((err) => {
              console.log("errror", err.response.data.msg);
              setError(err.response.data.msg);
              setLoading(true);
            });
        } catch (err) {
          console.log("register err", err);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const inputclass =
    "!rounded-lg bg-transparent  appearance-none border-[1.4px] focus:!shadow-none !border-[#838280] w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline login-textbox";
  return (
    <>
      <Modal
        visible={registerModalVisible}
        title="Register"
        okText="Register"
        cancelText="no"
        onCancel={hideModal}
        className="register_modal"
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
          onFinish={submitRegister}
        >
          <Form.Item>
            <Button
              type="primary"
              htmlType="button"
              className="Register-form-button w-full  text-center connect_button"
            >
              Connect Wallet
            </Button>
          </Form.Item>
          <Form.Item>
            <div className="or_border">
              <hr className="text-gray-100"></hr>
              <span className="text-gray-100">or</span>
            </div>
          </Form.Item>
          {loading && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <Form.Item
            type="text"
            name="firstName"
            rules={[{ required: true, message: "Enter your First Name" }]}
          >
            <Input
              type="text"
              name="firstName"
              className={inputclass}
              placeholder="First Name"
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            type="text"
            rules={[{ required: true, message: "Enter your Password" }]}
          >
            <Input
              type="text"
              name="lastName"
              className={inputclass}
              placeholder="Last Name"
            />
          </Form.Item>
          <Form.Item
            name="email"
            type="email"
            rules={[
              { required: true, message: "Enter your Email" },
              {
                type: "email",
                message: "please enter valid input",
              },
            ]}
          >
            <Input
              placeholder="Email Address"
              name="email"
              type="email"
              className={inputclass}
            />
            {/* <input
              type="email"
              name="email"
              className="rounded-lg bg-transparent shadow appearance-none border border-gray-300 w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline login-textbox"
              placeholder="Email Address"
            /> */}
          </Form.Item>
          <Form.Item
            name="password"
            type="password"
            placeholder="Password"
            rules={[
              { required: true, message: "Enter your Password" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value && value.length < 6) {
                    return Promise.reject(
                      "Password must be at least 6 characters"
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password Placeholder="Password" className={inputclass} />
            {/* <input
              type="password"
              name="password"
              className="rounded-lg bg-transparent  appearance-none border border-gray-300 w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline login-textbox"
              placeholder="Password"
            /> */}
          </Form.Item>
          <Form.Item
            type="password"
            name="confirm_password"
            rules={[
              { required: true, message: "Enter your confirm Password" },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords dont Match !!"));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm Password"
              className={inputclass}
            />
            {/* <input
              type="password"
              name="confirm_password"
              className="rounded-lg  bg-transparent  appearance-none border border-gray-300 w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline login-textbox"
              placeholder="Confirm Password"
            /> */}
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="Register-form-button w-full  text-center register_button"
            >
              Register
            </Button>

            <div className=" w-full  text-center text-gray-100">
              Do you have an account?
              <span
                className="text-gray-100 hover:text-gray-300 cursor-pointer pl-2 "
                onClick={() => {
                  setLoginModalVisible(true);
                  hideModal();
                }}
              >
                Log in
              </span>
            </div>
          </Form.Item>
          {/* <Form.Item>
            <div className=" w-full  text-center text-gray-100">
              <span
                className="text-gray-100 hover:text-gray-300 cursor-pointer pl-2 "
                onClick={() => {
                  setOtpTime(2);
                  setVerifyModalVisible(true);
                  hideModal();
                }}
              >

                Verify Modal
              </span>
            </div>
          </Form.Item> */}
        </Form>
      </Modal>
      <VerifyModal
        verifyModalVisible={VerifyModalVisible}
        hideverifyModal={hideVerifyModal}
        email={useremail}
        otpTime={otptime}
      />
      {/* <LoginModal
        loginModalVisible={loginModalVisible}
        hideLoginModal={hideLoginModal}
      /> */}
    </>
  );
};

export default RegisterModal;
