import React from "react";
import "./orderProduct.scss";
const OrderProduct = () => {
  return (
    <div>
      <div className="inline-flex py-4 align-middle">
        <div className="mr-2">
          <img
            src="images/collection-image.jpg"
            width="90px"
            height="90px"
            alt="collection"
            className="relative z-[1] mr-3 rounded"
          />
        </div>
        <div className="ml-2">
          <p className="mt-[-4px] mb-0 text-[12px] text-gray-400">
            PRODUCT CODE:#26419
          </p>
          <h6 className="text-[16px] font-bold text-white sm:leading-3 lg:leading-[0.8]">
            Loream ipsu dolor sit amet
          </h6>
          <div className="mt-2 flex flex-wrap">
            <div className="flex pr-2 text-[14px]">
              Size:&nbsp;<div className="pr-1 text-gray-400"> Large </div>
            </div>
            <div className="flex pr-2 text-[14px]">
              Color:
              <div className="ml-2 mt-[1px] h-4 w-4 rounded-full bg-[#5e0de0] pl-1">
                {" "}
              </div>
            </div>
            <div className="flex pr-2 text-[14px]">
              Quantity:&nbsp;<div className="pr-1"> 1</div>
            </div>
          </div>

          {/* Etheruim Icon */}
          <div className="flex py-1">
            <img
              src="images/eth-icon.png"
              alt="eth-icon"
              width="10px"
              height="5px"
            />
            <span className="pl-3 text-[16px] font-bold  ">5.18</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderProduct;
