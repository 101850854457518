import React from "react";
import SliderItem from "../slider-element/SliderItem";
import Slider from "react-slick";
import styled from "styled-components";
import "./ProShopSlider.scss";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "black",
        border: "1px solid ",
      }}
      onClick={onClick}
    >
      <FaChevronRight className="hover:border-orange-500 mt-[-10px] hover:text-orange-500 border-3 rounded-[50%] opacity-70 hover:opacity-100 border-[#909090] p-[0.3rem] pl-2 text-[#909090] text-[2.4rem]" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "black",
        border: "1px solid ",
      }}
      onClick={onClick}
    >
      <FaChevronLeft className="hover:border-orange-500 mt-[-10px] hover:text-orange-500 border-3 rounded-[50%] opacity-70  hover:opacity-100 border-[#909090] p-[0.3rem] mx-auto pr-2 text-[#909090] text-[2.4rem]" />
    </div>
  );
}

function ProShopSlider(props) {
  const { data } = props;
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    lazyLoad: true,
    // centerMode: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1524,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 964,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 855,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
        },
      },

      {
        breakpoint: 698,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 590,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
    ],
  };
  return (
    <Wrapper className="w-full px-10 my-4">
      {data?.length > 7 ? (
        <Slider {...settings} className="top-ctgry-slide">
          {data?.map((item) => (
            <SliderItem key={item._id} item={item} />
          ))}
        </Slider>
      ) : (
        <div className="flex justify-center items-start underSevenItems">
          {data?.map((item) => (
            <SliderItem key={item._id} item={item} />
          ))}
        </div>
      )}
    </Wrapper>
  );
}

export default ProShopSlider;

const Wrapper = styled.div`
  .slick-list {
    position: relative;

    display: block !important;
    overflow: hidden;

    width: 100%;
  }
  .slick-prev {
    left: -40px;
  }
  .slick-next {
    opactiy: 0;
  }
  .slick-next::before {
    content: "";
  }
  .slick-prev::before {
    content: "";
  }
  @media (max-width: 992px) {
    .slick-next:before,
    .slick-prev:before {
      font-size: 30px;
    }
    .slick-slider {
      padding: 0 10px;
    }
  }
`;
