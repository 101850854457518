import "./roaring_tiger.scss";
import { AiOutlineArrowDown } from "react-icons/ai";
import Timer from "../timer/Timer";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment"

function RoaringTiger() {
  const pages = useSelector((state) => state?.cmsPageSetting?.allPage);
  let data = pages?.filter((page) => page?.section === "HERO");
  console.log(data, "data")


  return (
    <div className="roaring_tiger_wrapper ">
      <div className="container roaring_tiger_container">
        <div className="row">
          <div className="col-lg-3 col-md-12 col-12"></div>
          <div className="col-lg-6 col-md-12 col-12">
            {data[0]?.title.map((item) => {
              return (
                <div className="roaring_tiger_heading" key={item._id}>
                  <h2 className="fontHeadingShloppy" style={{ color: item.headingColour }}>{item.heading}</h2>
                  <div
                    className="my-2"
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                  <h4 className="my-3">
                    <Timer deadline={new Date(data?.[0]?.counterDate)} />
                  </h4>

                  <h3>
                    <span>
                      <a href="/#rtgc" className="text-white">
                        <AiOutlineArrowDown />
                      </a>
                    </span>
                  </h3>
                </div>
              );
            })}
          </div>
          <div className="col-lg-3 col-md-12 col-12">
            {data.map((item) => {
              return (
                <div className="roaring_tiger_image" key={item._id}>
                  {
                    item.image[0] !== undefined ? (

                      <img src={item.image[0].item} alt="tiger" />
                    ) : (
                      ""
                    )
                  }

                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoaringTiger;
