import * as types from "../types/usertypes";
const initialState = {
  isAuthenticated: false,
  registrationEmail: null,
  user: {},
  loading: true,
  error: false,
};

const UserActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_REGISTRATION_SUCCESS:
      return {
        ...state,
        registrationEmail: action.payload,
      };
    case types.USER_OTP_VERIFICATION_SUCCESS:
      return {
        ...state,
        registrationEmail: null,
      };
    case types.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: true,
        error: null,
        isAuthenticated: true,
      };
    case types.USER_RESEND_VERIFICATION:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.USER_LOGOUT:
      return {
        // ...state,
        // isAuthenticated: false,
        isAuthenticated: false,
        registrationEmail: null,
        user: {},
        loading: true,
        error: false,
      };

    default:
      return state;
  }
};

export default UserActionReducer;
