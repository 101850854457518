import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { CiFilter } from "react-icons/ci";
import { BiSortAlt2 } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import "./productCardFilter.scss";
import { Form, Input, Select } from "antd";
import {
  fetchActiveProducts,
  setProduct,
} from "../../../redux/actions/productAction";
import _, { debounce } from "lodash";
import { useLocation } from "react-router";

const { Option } = Select;
const ProductCardFIlter = ({
  pageNum,
  pageSize,
  setPageNum,
  selectedCategory,
  orderKey,
  setSelectedCategory,
  setOrderKey,
  sort,
  setSort,
  category,
  color,
  itemSize,
  setCategory,
  setColor,
  setItemSize,
}) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const location = useLocation();

  const productCategories = useSelector(
    (state) => state.allProducts?.categories?.data
  );

  // useEffect(() => {
  //   onSearch();
  // }, []);

  const onSearch = (sort) => {
    let params = {};
    if (search) {
      params.title = search;
      setPageNum(1);
      setOrderKey("");
    }

    if (sort && sort != "null") {
      params.sort = sort;
    }
    // params.skip = 1
    // params.limit = 2
    dispatch(
      fetchActiveProducts(
        pageNum,
        pageSize,
        search,
        orderKey,
        sort,
        selectedCategory
      )
    );
  };

  return (
    <div className="mb-12 product_card">
      <Form
        initialValues={{ remember: true }}
        autoComplete="off"
        name="horizontal_login"
        className="mx-auto flex items-center flex-col sm:flex-row gap-4 justify-between "
      >
        <div className=" !w-full !max-w-[450px] relative">
          <Input
            icon="search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              onSearch();
            }}
            placeholder="Search..."
            className="!w-full focus:!shadow-none h-[50px] !bg-[#ffffff08] relative "
          />
          <span
            style={{ cursor: "pointer" }}
            className="absolute  right-[0] top-[8px] z-20 p-[8px] mr-2 text-white"
          >
            <AiOutlineSearch onClick={() => onSearch()} size={20} />
          </span>
        </div>
        <div className="flex flex-row items-center justify-center sm:justify-content-end  !gap-4 ">
          {/* <span className="text-xl !text-[#cfcfcf] !bg-[#ffffff08] ">
            <AiOutlineAppstoreAdd />
          </span> */}
          {location?.pathname?.includes("proshop") && (
            <Form.Item
              name="filter"
              label="filter"
              className="product-card-select-field !w-ful !bg-[#ffffff08] !mb-0"
            >
              <CiFilter className="filter-icon" size={20} />
              <Select
                placeholder="Category"
                className="!text-white focus:!shadow-none"
                onChange={(value) => {
                  if (value == "all") {
                    setSelectedCategory(null);
                    dispatch(fetchActiveProducts(1, 8, search, orderKey, sort));
                  } else {
                    setSelectedCategory(value);
                    setPageNum(1);
                    setOrderKey("minPrice");
                    setSort("asc");
                    dispatch(
                      fetchActiveProducts(
                        1,
                        pageSize,
                        search,
                        "",
                        "",
                        value || selectedCategory
                      )
                    );
                  }
                }}
              >
                <Option value="all">All</Option>

                {productCategories?.map((cat) => (
                  <Option value={cat?._id}>{cat.categoryName}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="sortby"
            label="sortby"
            rules={[
              {
                required: true,
              },
            ]}
            className="product-card-select-field !bg-[#ffffff08] !mb-0 "
          >
            <BiSortAlt2 className="filter-icon" size={20} />
            <Select
              placeholder="Sort By"
              value={sort}
              onChange={(val) => {
                setPageNum(1);

                if (val == "null") return;
                setOrderKey("minPrice");
                setSort(val);
                onSearch(val);
              }}
              className="!text-white focus:!shadow-none !bg-[#ffffff08]"
            >
              <Option value="asc">Price: Low to High</Option>
              <Option value="desc">Price: High to Low</Option>
            </Select>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ProductCardFIlter;
