import { combineReducers } from "redux";
import cartReducer from "./cartReducer";
import cartSectionReducer from "./cartSectionReducer";
import storage from "redux-persist/lib/storage";
import { ProductReducer } from "./productReducer";
import { persistReducer } from "redux-persist";
import AdminActionReducer from "./adminLoginRegisterReducer";
import UserActionReducer from "./userLoginRegisterReducer";
import fetchUsersDataByAdminReducer from "./adminUsersReducer";
import cmsPageSettingReducer from "./cmsSettingReducer";
import cmsPageReducer from "./cmsPageReducer";
import aboutPageReducer from "./aboutPageReducer";
import myOrderReducer from "./myOrderReducer";
import holderPageReducer from "./holdersPageReducer";
import mintSoldReducer from "./mintPageReducer";
import { SeoReducer } from "./seoReducer";
import { forgotReducer } from "./forgotReducer";
import singleProductReducer from "./singleProductReducer";
import blockChainReducer from "./blockChainReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["adminProfile", "userProfile", "cmsPageSetting", "allProducts"]
};

const rootReducer = combineReducers({
  // multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  // currencyData: currencyReducer,
  // productData: productReducer,
  // blogsData: blogsReducer,
  // blogData: blogDetailReducer,
  // cartData: cartReducer,
  // wishlistData: wishlistReducer,
  // compareData: compareReducer,
  // testimonialData: testimonialReducer,
  // bannerData: bannerReducer,
  // auctionData: auctionReducer,

  seoData: SeoReducer,
  allProducts: ProductReducer,
  adminProfile: AdminActionReducer,
  usersDataByAdmin: fetchUsersDataByAdminReducer,
  cartItem: cartReducer,
  cartSection: cartSectionReducer,
  singleProduct: singleProductReducer,
  cmsPageSetting: cmsPageSettingReducer,
  aboutPage: aboutPageReducer,
  userForgotPassword: forgotReducer,
  myOrder: myOrderReducer,
  mintsold: mintSoldReducer,
  holdersPage: holderPageReducer,
  cmsPageContent: cmsPageReducer,
  userProfile: UserActionReducer,
  blockChain: blockChainReducer
  // todayTopPickProductsData: topPickProductReducer,
  // reportVendorData: reportVendorReducer,
  // categoriesData: categoryReducer,
  // newArrivalsData: newArrivalsReducer
});

export default persistReducer(persistConfig, rootReducer);
