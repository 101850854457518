import React, { useEffect, useState } from "react";
import { Table, Form, Select } from "antd";
import { Link } from "react-router-dom";
import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import { TbTruckDelivery } from "react-icons/tb";
import { MdOutlineCancel } from "react-icons/md";
import { CiFilter } from "react-icons/ci";
import axios from "axios";
import viewIcon from "../../../images/view-icon.png";
import "./adminOrderList.scss";
import {
  filter_by_order_status,
  get_admin_order_list,
} from "../../../../api/Api-list";
const { Option } = Select;

export default function OrderList() {
  const [orderlist, setOrderList] = useState([]);
  const [liststatus, setListStatus] = useState([
    "Pending",
    "Payment Failed",
    "Confirmed",
    "Canceled By User",
    "Reject By Admin",
    "Shipped",
    "Delivered",
    "Return Request",
    "Return Request Accept",
    "Return Request Reject",
    "Return request Pending",
    "Return Request Processing",
    "Return Request Complete",
  ]);

  const Admin_Token = localStorage.getItem("tiger");

  const config = {
    headers: {
      Authorization: `Bearer ${Admin_Token}`,
    },
  };

  const filterStatus = async (val) => {
    try {
      const response = await axios.get(filter_by_order_status(val), config);
      if (response.status < 400) {
        // console.log(response?.data, "daaa2");
        setOrderList(response?.data?.orders);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getorderlist = async () => {
    try {
      const response = await axios.get(get_admin_order_list(), config);
      if (response.status < 400) {
        // console.log(response?.data, "daaa2");
        setOrderList(response?.data?.orders);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getorderlist();
  }, []);

  const columns = [
    {
      title: "Order Id",
      dataIndex: "orderNumber",
      key: "orderNumber",
      render: (item) => {
        return <>{item}</>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (item, data) => {
        return <>{data?.address?.fullName}</>;
      },
    },
    {
      title: "Items",
      dataIndex: "totalItems",
      key: "totalItems",
      render: (item, data) => {
        return <>Total items ({data?.items?.map(item => item.quantity)?.reduce((a, b) => a + b, 0)})</>;
      },
    },

    {
      title: "Discount",
      dataIndex: "couponDiscountAmount",
      key: "couponDiscountAmount",
      render: (item, data) => {
        return <>{item ? `${item?.toFixed(2)}${data?.coupon?.type == 'percent' ? "%" : ""}` : 'NA'}</>;
      },
    },
    {
      title: "Subtotal",
      dataIndex: "subTotalAmount",
      key: "subTotalAmount",
      render: (item, data) => {
        return <>{data?.currency === 'usd' ? `$${item}` : <div className="flex"><img src='/images/eth-icon.png' style={{ height: '30px', marginRight:'3px' }} /> {`${data?.amountInETH?.toFixed(5)}`}</div>} </>;
      },
    },
    {
      title: "Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (item, i) => {
        return (
          <p
            className={`status-${i?.orderStatus
              ?.replace(/\s+/g, "-")
              ?.toLowerCase()}`}
          >
            {item}
          </p>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (item, data) => {
        return (
          <div className="action-icon-clm">
            <ul className="d-flex align-items-center">
              <li>
                <Link to={`/admin/order/${data?._id}`}>
                  <img
                    src={viewIcon}
                    alt="View"
                    style={{ height: "15px" }}
                    title="View Details"
                  />
                </Link>
              </li>
              {/* <li>
                <Link to={`#`}>
                  <MdOutlineCancel className="cancel-icon" title="Cancel" />
                </Link>
              </li> */}
              {/* <li>
								<Link to={`#`}>
									<TbTruckDelivery
									className="ship-icon"
									title="Ship" />
								</Link>
							</li>
							<li>
								<Link to={`#`}>
									<AiOutlineDeliveredProcedure
									className="deliver-icon"
									title="Delivered" />
								</Link>
							</li> */}
            </ul>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex justify-between">
        <h2>Order List</h2>
        <div className="ordr-list-filter">
          <Form layout="vertical">
            <Form.Item>
              <CiFilter className="filter-icon" />
              <Select
                placeholder="Choose Status"
                onChange={(values) => {
                  if (values == "all") {
                    getorderlist();
                  } else {
                    filterStatus(values);
                  }
                }}
              >
                <Option value="all">All</Option>

                {liststatus?.map((list) => (
                  <Option value={list}>{list}</Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="order-list-content">
        <Table dataSource={orderlist || []} columns={columns} pagination={{}} />
        ;
      </div>
    </>
  );
}
