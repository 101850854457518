import React from "react";
import { useSelector } from "react-redux";
import "./card.scss";

const Card = () => {
  const pages = useSelector((state) => state?.cmsPageSetting?.allPage);
  let data = pages?.filter((page) => page?.section === "ROAD MAP");

  return (
    <>
      {data[0]?.title?.map((card, k) => {
        return (
          <div
            className={"card_container group w-full max-w-[25rem] sm:max-w-[30rem ] md:max-w-[30rem] " + `item_${k}`}
            key={card._id}
          >
            <h2 className="main_heading"> {card?.heading}</h2>

            <div className="card_sub_container">
              <h3 className="card_sub_heading">{card?.subHeading}</h3>

              <div className="group-hover:!text-orange-500 transition-all ease-in duration-200" dangerouslySetInnerHTML={{ __html: card?.description }} />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Card;
