import React, { useEffect, useRef, useState } from "react";
import { Form, message } from "antd";
import {
  get_email_template_details,
  update_email_template,
} from "../../../api/Api-list";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

const UpdateEmailTemplate = () => {
  const Admin_Token = localStorage.getItem("tiger");
  const [form] = Form.useForm();

  const [editorText, setEditorText] = useState("");
  const [selectedTemplateType, setSelectedTemplateType] = useState("");
  const [emailTEmplateDetails, setEmailTemplateDetails] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();

  const editorRef = useRef(null);

  const config = {
    headers: {
      Authorization: `Bearer ${Admin_Token}`,
    },
  };

  useEffect(() => {
    getEmailTemplateDetails();
  }, [id]);

  const getEmailTemplateDetails = async () => {
    try {
      const response = await axios.get(get_email_template_details(id), config);
      if (response.status < 400) {
        setEmailTemplateDetails(response.data.data);
        form.setFieldsValue({
          templateType: response.data.data.templateType,
        });
        setSelectedTemplateType(response.data.data.templateType);
        setEditorText(response.data.data.templateHtml);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.patch(
        update_email_template(id),
        { templateType: selectedTemplateType, templateHtml: editorText },
        config
      );
      if (response.status < 400) {
        message.success(response.data.msg);
        navigate("/admin/email-template");
      }
    } catch (err) {
      console.log(err);
      message.error(err.response.data.msg);
    }
  };

  return (
    <>
      <div className="container-fluid edit_product">
        <div className="row">
          <h2 className="cms-title">Update Email Template</h2>
          <div className="col-12 col-sm-12 col-md-12 bg-white shadow-sm p-5 cms-box">
            <Form layout="vertical" form={form} onFinish={handleSubmit}>
              <Form.Item label="Template Text">
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  init={{
                    height: 300,
                    advlist_bullet_styles: "square",
                    advlist_number_styles:
                      "lower-alpha,lower-roman,upper-alpha,upper-r",
                    toolbar_mode: "sliding",
                  }}
                  value={editorText}
                  onEditorChange={(e) => {
                    setEditorText(e);
                  }}
                  plugins={[
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor",
                    "textpattern",
                    "image",
                    "imageupload",
                    "link",
                    "help",
                    "wordcount",
                    "code",
                    "preview",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "paste",
                    "charmap",
                    "searchreplace",
                    "visualblocks",
                    "emoticons",
                    "image paste",
                  ]}
                  toolbar={[
                    "undo redo | formatselect | bold italic backcolor forecolor   |alignleft aligncenter alignright alignjustify | fonts | bullist numlist outdent indent | removeformat | image| help",
                    "link imageupload | fontselect | fontsizeselect |fullscreen | insertdatetime | media | wordcount | charmap | searchreplace | visualblocks |  table | code | preview |emoticons |",
                  ]}
                />
              </Form.Item>

              {selectedTemplateType !== "" && (
                <div>
                  <h6 className="font-semibold">
                    Template text legends:{" "}
                    <span className="font-normal text-sm">(click to add)</span>
                  </h6>
                  {emailTEmplateDetails?.allowedLegends?.map((legend) => {
                    return (
                      <div className="flex items-center my-3">
                        <button
                          type="button"
                          className="bg-[#eeeeee] rounded-3xl px-3 py-1 font-semibold hover:bg-[#dddddd]"
                          onClick={() => {
                            editorRef.current.insertContent(legend.title);
                          }}
                        >
                          {legend.title}
                        </button>
                        <span className="ml-2 font-semibold">
                          {legend.description}
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}

              <Form.Item>
                <button
                  type="submit"
                  className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] mt-2"
                >
                  Update Email Template
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateEmailTemplate;
