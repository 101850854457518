import React, { useEffect } from "react";
import { Menu, Layout } from "antd";
import { Link, useLocation } from "react-router-dom";
import DashboardIcons from "../../images/icons_dashboard.png";
import { GiThorHammer } from "react-icons/gi";
import { MdOutlineCategory } from "react-icons/md";
import { RiProductHuntLine } from "react-icons/ri";
import Profile from "../../images/profile.png";
import {
  GrCreditCard,
  GrHome,
  GrSettingsOption,
  GrGrid,
  GrGroup,
  GrUserSettings,
} from "react-icons/gr";
import { MdPrivacyTip } from "react-icons/md";
import { RiTerminalWindowLine } from "react-icons/ri";
import { AiOutlineShop, AiOutlineShoppingCart } from "react-icons/ai";
import { FaSearchengin } from "react-icons/fa";
import "./sider.scss";
const { Sider } = Layout;

function AdminSidebar() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/admin-dashboard") {
      document
        .querySelector(".dash")
        ?.classList?.remove("ant-menu-item-selected");
      //   document.querySelector(".dash").firstChild.classList.remove("ant-menu-item ant-menu-item-only-child dash ant-menu-item-selected")
    }
  }, [location]);
  return (
    <div className="admin_sidebar">
      <Sider
        breakpoint="lg"
        className="h-100"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <Menu
          className="mt-5"
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[""]}
        >
          {/* Dashboard */}
          <Menu.Item
            key="1"
            className={
              location.pathname !== "/admin-dashboard" &&
              "ant-menu-item ant-menu-item-only-child dash"
            }
          >
            <Link to="/admin/admin-dashboard">
              <img src={DashboardIcons} alt="dashboard" /> Dashboard
            </Link>
          </Menu.Item>

          {/* Profile */}
          <Menu.Item
            key="2"
            className={
              location.pathname === "/profile" &&
              "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
            }
          >
            <Link to="/admin/profile">
              <img src={Profile} alt="Profile" />
              Profile
            </Link>
          </Menu.Item>
          {/* Customer List */}
          <Menu.Item
            key="3"
            className={
              location.pathname === "/customer-list" &&
              "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
            }
          >
            <Link to="/admin/customer-list">
              <span className="admin_sidebar_icon">
                {" "}
                <GrUserSettings />
              </span>
              Users
            </Link>
          </Menu.Item>
          {/* Product List */}



          <Menu.Item
            key="14"
            className={
              location.pathname === "/order-list" &&
              "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
            }
          >
            <Link to="/admin/order-list">
              <span className="admin_sidebar_icon">
                {" "}
                <AiOutlineShoppingCart />
              </span>
              Order
            </Link>
          </Menu.Item>
          {/* Order List */}


          <Menu.SubMenu
            key="4"
            title={
              <>
                <span className="admin_sidebar_icon">
                  {" "}
                  <AiOutlineShop className="text-[#AF1605]" />{" "}
                </span>

                <span className={" text-white "}> Products</span>
              </>
            }
          >
            <Menu.Item
              key="89"
              className={
                location.pathname === "/admin/product-managemente" &&
                "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
              }
            >
              <Link to="/admin/product-management">
                <span className="pr-1 text-[1.4rem]">
                  <RiProductHuntLine className="text-[#AF1605]" />{" "}
                </span>
                <span className=" admin_sidebar_sub_text">
                  Manage Products{" "}
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="88"
              className={
                location.pathname === "/cms/privacy" &&
                "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
              }
            >
              <Link to="category-management">
                <span className="pr-1 text-[1.4rem]">
                  <MdOutlineCategory className=" text-[#AF1605]" />
                </span>
                <span className=" admin_sidebar_sub_text">
                  {" "}
                  Manage Category{" "}
                </span>
              </Link>
            </Menu.Item>
          </Menu.SubMenu>

          {/* manage location */}
          <Menu.SubMenu
            key="90"
            title={
              <>
                <span className="admin_sidebar_icon">
                  {" "}
                  <AiOutlineShop className="text-[#AF1605]" />{" "}
                </span>

                <span className={" text-white "}>Location & Tax</span>
              </>
            }
          >
            <Menu.Item
              key="91"
              className={
                location.pathname === "/admin/country" &&
                "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
              }
            >
              <Link to="/admin/country">
                <span className="pr-1 text-[1.4rem]">
                  <RiProductHuntLine className="text-[#AF1605]" />{" "}
                </span>
                <span className=" admin_sidebar_sub_text">Manage Country </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="92"
              className={
                location.pathname === "/admin/state" &&
                "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
              }
            >
              <Link to="/admin/state">
                <span className="pr-1 text-[1.4rem]">
                  <MdOutlineCategory className=" text-[#AF1605]" />
                </span>
                <span className=" admin_sidebar_sub_text"> Manage State </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="93"
              className={
                location.pathname === "/admin/tax" &&
                "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
              }
            >
              <Link to="/admin/tax">
                <span className="pr-1 text-[1.4rem]">
                  <MdOutlineCategory className=" text-[#AF1605]" />
                </span>
                <span className=" admin_sidebar_sub_text"> Manage Tax </span>
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
          {/* Page Seo Management */}
          <Menu.Item
            key="17"
            className={
              location.pathname === "/pageseo-management" &&
              "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
            }
          >
            <Link to="/admin/pageseo-management">
              {" "}
              <span className="admin_sidebar_icon">
                {" "}
                <FaSearchengin className="text-[#D51800]" />
              </span>
              <span className=" admin_sidebar_text">Manage Page </span>
            </Link>
          </Menu.Item>
          {/* Team Management */}
          <Menu.Item
            key="50"
            className={
              location.pathname === "/admin/team" &&
              "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
            }
          >
            <Link to="/admin/team">
              {" "}
              <span className="admin_sidebar_icon">
                {" "}
                <GrGroup />
              </span>
              <span className=" admin_sidebar_text"> Team </span>
            </Link>
          </Menu.Item>
          {/* Coupons Generator */}
          <Menu.Item
            key="65"
            className={
              location.pathname === "/admin/get_all_coupons" &&
              "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
            }
          >
            <Link to="/admin/get_all_coupons">
              <span className="admin_sidebar_icon">
                <FaSearchengin className="text-[#D51800]" />
              </span>
              <span className="admin_sidebar_text">Coupons</span>
            </Link>
          </Menu.Item>
          {/* Cms Management */}
          <Menu.SubMenu
            key="6"
            title={
              <>
                <span className="admin_sidebar_icon">
                  {" "}
                  <GrCreditCard />{" "}
                </span>

                <span className={" text-white "}>CMS</span>
              </>
            }
          >
            <Menu.Item
              key="7"
              className={
                location.pathname === "/cms/innerpage" &&
                "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
              }
            >
              <Link to="cms/innerpage">
                <span className="admin_sidebar_sub_icon">
                  <GrGrid />
                </span>
                <span className=" admin_sidebar_sub_text"> Inner Content </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="8"
              className={
                location.pathname === "/cms/privacy" &&
                "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
              }
            >
              <Link to="cms/privacy">
                <span className="admin_sidebar_sub_icon">
                  <MdPrivacyTip />
                </span>
                <span className=" admin_sidebar_sub_text">
                  {" "}
                  Privacy Policy{" "}
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="9"
              className={
                location.pathname === "/cms/terms" &&
                "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
              }
            >
              <Link to="cms/terms">
                <span className="admin_sidebar_sub_icon">
                  <RiTerminalWindowLine />
                </span>
                <span className=" admin_sidebar_sub_text">Terms of Use</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="10"
              className={
                location.pathname === "/cms/sitemap" &&
                "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
              }
            >
              <Link to="cms/sitemap">
                <span className="admin_sidebar_sub_icon">
                  <RiTerminalWindowLine />
                </span>
                <span className=" admin_sidebar_sub_text">Sitemap</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="11"
              className={
                location.pathname === "/cms/mintsold" &&
                "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
              }
            >
              <Link to="cms/mintsold">
                <span className="admin_sidebar_sub_icon">
                  <GiThorHammer />
                </span>
                <span className=" admin_sidebar_sub_text">Mint Sold</span>
              </Link>
            </Menu.Item>
            <Menu.SubMenu
              key="12"
              title={
                <>
                  <span className="admin_sidebar_icon">
                    {" "}
                    <GrHome />{" "}
                  </span>

                  <span className="text-white  ">Pages </span>
                </>
              }
            >
              <Menu.Item
                key="13"
                className={
                  location.pathname === "/cms/home" &&
                  "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
                }
              >
                <Link to="cms/home">Home</Link>
              </Menu.Item>
              <Menu.Item
                key="14"
                className={
                  location.pathname === "/cms/about" &&
                  "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
                }
              >
                <Link to="cms/about">About</Link>
              </Menu.Item>

              <Menu.Item
                key="15"
                className={
                  location.pathname === "/cms/holders" &&
                  "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
                }
              >
                <Link to="cms/holders">Holders</Link>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu.SubMenu>

          <Menu.SubMenu
            key="5"
            title={
              <>
                <span className="admin_sidebar_icon">
                  {" "}
                  <GrSettingsOption />{" "}
                </span>

                <span className={" text-white "}>Settings</span>
              </>
            }
          >
            <Menu.Item
              key="6"
              className={
                location.pathname === "/admin/setting" &&
                "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
              }
            >
              <Link to="/admin/setting">
                <span className="admin_sidebar_sub_icon">
                  <GrGrid />
                </span>
                <span className=" admin_sidebar_sub_text"> Site Settings </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="7"
              className={
                location.pathname === "/admin/menu" &&
                "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
              }
            >
              <Link to="/admin/menu">
                <span className="admin_sidebar_sub_icon">
                  <GrGrid />
                </span>
                <span className=" admin_sidebar_sub_text"> Menu </span>
              </Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.Item
            key="8"
            className={
              location.pathname === "/admin/clubhouse" &&
              "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
            }
          >
            <Link to="/admin/clubhouse">
              <span className="admin_sidebar_sub_icon">
                <GrGrid />
              </span>
              <span className=" admin_sidebar_sub_text"> Clubhouse </span>
            </Link>
          </Menu.Item>

          <Menu.Item
            key="9"
            className={
              location.pathname === "/admin/email-template" &&
              "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
            }
          >
            <Link to="/admin/email-template">
              <span className="admin_sidebar_sub_icon">
                <GrGrid />
              </span>
              <span className=" admin_sidebar_sub_text"> Email Template </span>
            </Link>
          </Menu.Item>
          {/* <Menu.Item
            key="5"
            className={
              location.pathname === "/admin/setting" &&
              "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
            }
          >
            <Link to="/admin/setting">
              <span className="admin_sidebar_icon">
                {" "}
                <GrSettingsOption />
              </span>

              <span className=" admin_sidebar_text"> Settings </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="6" className={location.pathname==="/payments" && "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"}>
                        <Link to="/payments">Payments</Link>
                    </Menu.Item> */}
          {/*<Menu.Item key="6" className={location.pathname==="/payments" && "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"}>
                        <Link to="/payments"><img src={Tiers} alt="Membership" />Payments</Link>
                    </Menu.Item>
                    <Menu.Item key="7" className={location.pathname==="/withdraw" && "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"}>
                        <Link to="/withdraw"><img src={Payments} alt="Earning" />Withdrawals</Link>
                    </Menu.Item>
                    <Menu.Item key="4" className={location.pathname==="/orders-list" && "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"}>
                        <Link to="/orders-list"><img src={Orders} alt="Orders" />Orders</Link>
                    </Menu.Item>
                    <Menu.Item key="6" className={location.pathname==="/payments" && "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"}>
                        <Link to="/payments"><img src={Tiers} alt="Membership" />Payments</Link>
                    </Menu.Item>
                    <Menu.Item key="7" className={location.pathname==="/withdraw" && "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"}>
                        <Link to="/withdraw"><img src={Payments} alt="Earning" />Withdrawals</Link>
                    </Menu.Item>
                    <Menu.Item key="8" className={location.pathname==="/taxonomy" && "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"}>
                        <Link to="/taxonomy"><img src={Taxonomy} alt="Earning" />Taxonomy</Link>
                    </Menu.Item>
                    <Menu.Item key="9" className={location.pathname==="/review" && "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"}>
                        <Link to="/review"><img src={Reviews} alt="Earning" />Reviews</Link>
                    </Menu.Item>*/}
        </Menu>
      </Sider>
    </div>
  );
}
export default AdminSidebar;
