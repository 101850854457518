// export const USER_PROFILE_FAILURE = "USER_PROFILE_FAILURE";
// export const PASSWORD_RESET = "PASSWORD_RESET";
// export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
// export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";

// export const REGISTER_USER = "REGISTER_USER";
// export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
// export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";

// user profile
export const USER_RESEND_VERIFICATION = "USER_RESEND_VERIFICATION";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_REGISTRATION_SUCCESS = "USER_REGISTRATION_SUCCESS";
export const USER_OTP_VERIFICATION_SUCCESS = "USER_OTP_VERIFICATION_SUCCESS";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_PROFILE = "USER_PROFILE";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
//admin profile
export const ADMIN_PROFILE = "ADMIN_PROFILE";
export const CHANGE_ADMIN_PASSWORD = "CHANGE_ADMIN_PASSWORD";
export const UPDATE_ADMIN_PROFILE = "UPDATE_ADMIN_PROFILE";

// login admin
export const LOGIN_ADMIN = "LOGIN_ADMIN";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAILURE = "LOGIN_ADMIN_FAILURE";
export const LOGOUT_ADMIN = "LOGOUT_ADMIN";

// admin action on user data
export const FETCH_USERS_BY_ADMIN = "FETCH_USERS_BY_ADMIN";
export const DELETE_USER_BY_ADMIN = "DELETE_USER_BY_ADMIN";
export const ADD_USER_BY_ADMIN = "ADD_USER_BY_ADMIN";
export const EDIT_USER_BY_ADMIN = "EDIT_USER_BY_ADMIN";
export const VIEW_USER_BY_ADMIN = "VIEW_USER_BY_ADMIN";
