import React, { useLayoutEffect } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
// import SecondaryFooter from "../components/secondaryfooter/SecondaryFooter";
import { Outlet, useLocation } from "react-router-dom";
// import { url } from "inspector";

const UserLayout = () => {
  const location = useLocation();
  const home = location.pathname === "/";
  const MintPage = location.pathname === "/mint-sale";
  const MintSuccess = location.pathname === "/mint-sucess";
  const MintSold = location.pathname === "/mint-sold";
  const MintSale = location.pathname === "/mint-sale";
  const innerPage = location.pathname === "/innerpage";
  const proshop = location.pathname === "/proshop";

  // const customUrl = (home, innerPage) => {
  //   if (home || innerPage) {

  //   }
  // }
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  // const Background = "/images/footer_background.png";
  return (
    <div>
      <Header />
      <div className={proshop ? "inner-page-background" : ""}>
        <Outlet />
      </div>
      <div className={innerPage || home ? "home_footer" : ""}>
        {MintPage || MintSuccess || MintSold || MintSale ? (
          ""
        ) : home ? null : (
          <Footer />
        )}
      </div>
    </div>
  );
};

export default UserLayout;
