import React, { useState } from "react";
import { Form, Input, Modal } from "antd";
import "./orderItem.scss";
import { Link } from "react-router-dom";
import { Checkbox } from "antd";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import { return_order } from "../../../../api/Api-list";
import { useDispatch } from "react-redux";
import getAllOrders from "../../../../redux/actions/myOrderAction";

const { TextArea } = Input;

const OrderItem = ({ order, retryPayment, cancelOrder }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const [form] = Form.useForm();
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const app_token = localStorage.getItem("tiger-token");

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  function handleOnClick() {
    setModalOpen(true);
  }

  const handleOk = () => {
    setModalOpen(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setModalOpen(false);
    form.resetFields();
  };

  const returnRequest = async (e) => {
    const values = await form.validateFields();

    console.log("selected items", values.item);

    const payload = {
      orderId: order._id,
      items: values.item,
      returnReason: values.description,
    };

    try {
      const response = await axios.post(return_order, payload, config);

      if (response.status < 400) {
        setModalOpen(false);
        form.resetFields();
        addToast(response.data.msg, {
          appearance: "success",
          autoDismiss: true,
        });
        dispatch(getAllOrders());
      }
    } catch (err) {
      setModalOpen(false);
      form.resetFields();
      addToast(err.response.data.msg, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      <div className="w-full md:max-w-[1270px] mx-auto order_item my-4">
        <div className="bg-[#101010] p-4 rounded-xl flex flex-col md:flex-row md:justify-between w-full order_item_container">
          <div className="!!flex-col md:flex  order_item_img">
            <img
              src={order.items?.[0]?.image?.[0]?.image || "images/noimg.png"}
              className="rounded-xl object-cover w-full md:max-w-[200px] order_image"
            />
            <div className="py-2 px-4 flex flex-col justify-between  order_item_content">
              <div className="space-y-2">
                {/* <div className=" text-[13px] text-gray-400">
                  Invoice Number: #26419
                </div> */}
                <div className="font-bold text-[22px] text-gray-100">
                  Order Number - {order.orderNumber}
                </div>
                <div className="flex items-center justify-between order_item_content_detail">
                  <div>
                    <span className="text-[15px] text-gray-100 ">
                      {" "}
                      Total Items: {order.totalItems}
                    </span>{" "}
                    {/* <span className=" text-[15px] mx-2 text-gray-400 ">
                      {" "}
                      Large
                    </span> */}
                  </div>
                  {/* <div>
                    <span className=" text-[15px] text-gray-100 ">
                      {" "}
                      Quantity:
                    </span>{" "}
                    <span className=" text-[15px] mx-2 text-gray-100 "> 1</span>
                  </div> */}
                  {/* <div className="flex items-center justify-center">
                    <span className="text-[15px]  text-gray-100 "> Color:</span>
                    <div className="w-4 h-4 ml-2 rounded-full bg-[#5e0de0] "></div>
                  </div> */}
                </div>
              </div>
              <div className="my-4  flex items-center  text-gray-100 order_price_btn">
                <div className="flex items-center justify-start  h-10 pr-8">
                  <div className="font-bold text-lg ml-4 text-[25px] ">
                    ${order.subTotalAmount.toFixed(2)}
                  </div>
                </div>

                {/* <div className="flex items-center justify-start  h-10 pr-8">
                  <img
                    src="/images/eth-icon.png"
                    alt="proshop"
                    className="object-contain w-3.5 "
                  />
                  <div className="font-bold text-lg ml-4 text-[25px] ">
                    5.18
                  </div>
                </div> */}
                <span className="border-[#11c767] border-[1px] sm:py-2 sm:px-3 sm:font-bold rounded-lg px-3 py-1 text-[12px] transition-all duration-200 text-[#11c767]">
                  {order.orderStatus}
                </span>
                {/* {order.orderStatus === "Payment Failed" && (
                  <button
                    className="ml-3 sm:font-bold text-gray-400 border-gray-400 border-[1px] rounded-lg sm:py-2 sm:px-3 px-3 py-1 text-[12px]"
                    onClick={retryPayment}
                  >
                    Retry Payment
                  </button>
                )} */}
              </div>
            </div>
          </div>
          <div className="flex flex-column ">
            <Link to={`/order-details/${order._id}`}>
              <button className="border-[2px] sm:border-[1px] sm:py-2 sm:px-3 sm:font-semibold rounded-full px-4 py-1 text-[15px]  transition-all duration-200  hover:!bg-gray-800 text-gray-100">
                View Order Details
              </button>
            </Link>
            {order.orderStatus === "Delivered" && (
              <button
                onClick={() => handleOnClick()}
                className="border-[2px] mt-2  sm:border-[1px] sm:py-2 sm:px-3 sm:font-semibold rounded-full px-4 py-1 text-[15px]  transition-all duration-200  hover:!bg-gray-800 text-gray-100 "
              >
                Request Return
              </button>
            )}
            {order.orderStatus === "Confirmed" && (
              <button
                onClick={cancelOrder}
                className="border-[2px] mt-2  sm:border-[1px] sm:py-2 sm:px-3 sm:font-semibold rounded-full px-4 py-1 text-[15px]  transition-all duration-200  hover:!bg-gray-800 text-gray-100 "
              >
                Cancel Order
              </button>
            )}
          </div>
        </div>
      </div>

      {isModalOpen ? (
        <Modal
          title={`Order Number - ${order.orderNumber}`}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <Form layout="vertical" form={form} onFinish={returnRequest}>
            <Form.Item
              name="item"
              type="text"
              label="Select Products"
              rules={[
                {
                  required: true,
                  message: "Please select atleast one product",
                },
              ]}
            >
              <Checkbox.Group
                options={order.items.map((item) => {
                  console.log("itemssss", item);
                  return {
                    label: `${item.productName} (${item.priceTier.size})`,
                    value: {
                      prodId: item._id,
                      variantId: item.priceTier._id,
                    },
                  };
                })}
              />
            </Form.Item>

            <Form.Item
              name="description"
              type="text"
              label="Please describe the reason"
              rules={[
                {
                  required: true,
                  message: "Why do you want to return this product?",
                },
              ]}
            >
              <TextArea
                showCount
                rows={8}
                placeholder="Description"
                maxLength={100}
              />
            </Form.Item>
            <Form.Item>
              <button
                type="submit"
                className="bg-[#ff8500] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] "
              >
                Submit
              </button>
            </Form.Item>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

export default OrderItem;
