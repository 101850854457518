import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import deleteIcon from "../../images/delete.png";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";


import {
  Upload,
  Input,
  Select,
  InputNumber,
  Form,
  Modal,
  Switch,
  message,
  Image,
} from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../images/plus-icon.png";
import {
  CategoryList,
  deleteProductImage,
  fetchProducts,
  fetchSingleProduct,
  ProductUpdate,
} from "../../../redux/actions/productAction";
import "./addproduct.scss";
// tiny mce Editor
import { Editor } from "@tinymce/tinymce-react";
import { data } from "autoprefixer";
import {
  fetchAllActiveProducts,
  fetchAllProducts,
  update_product,
} from "../../../api/Api-list";
import axios from "axios";
// import checkSmall from "../../images/checkSmall.png";
import ReactSelect from "react-select";
import { useToasts } from "react-toast-notifications";
import CreatableSelect from "react-select/creatable";

function EditProduct() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Admin_Token = localStorage.getItem("tiger");
  const allCategories = useSelector((state) => state?.allProducts?.categories);
  const [imageVal, setImageVal] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [isTop, setIsTop] = useState();

  const id = useParams();
  const singleProduct = useSelector(
    (state) => state?.allProducts?.selectsingleproduct
  );

  // console.log(sl?.title, "Inder");
  const inputclass = "bg-transparent !py-[0.7rem] !rounded-[0.3rem] text-black";
  const inputnumberclass =
    "bg-transparent !py-[0.5rem] !w-[100%]  !rounded-[0.3rem] text-black";
  const [form] = Form.useForm();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  // description

  const beforeUpload = () => false;
  const handleCancel = () => setPreviewVisible(false);
  const [imgstate, setImgstate] = useState([]);
  const initialArrayValue = [];
  const [fileList, setFileList] = useState(initialArrayValue);
  const [selectedColor, setSelectedColor] = useState([]);
  const [selectedSize, setSelectedSize] = useState([]);
  const [selectedRelatedProduct, setselectedRelatedProduct] = useState(null);
  const [saveNext, setsaveNext] = useState(false);
  const [allfieldValue, setAllFieldValue] = useState();
  const { addToast } = useToasts();
  const { TextArea } = Input;

  const [productList, setProductList] = useState([]);
  const [priceListField, setpriceListField] = useState([
    {
      color: "",
      size: "",
      price: "",
      // ethPrice: "",
      quantity: "",
    },
  ]);
  const [fileListEdit, setFileListEdit] = useState([
    {
      image: "",
      text: "",
      _id: "",
    },
  ]);

  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    setKeywords(() => {
      if (singleProduct?.data?.metaInfo?.keywords === "") return [];
      return singleProduct?.data?.metaInfo?.keywords.split(",").map((item) => {
        return {
          label: item,
          value: item,
          __isNew__: false,
        };
      });
    });
  }, [singleProduct]);

  // price list start

  // const handleAddPriceListClick = () => {
  //   setpriceListField([
  //     ...priceListField,
  //     {
  //       color: "",
  //       size: "",
  //       price: "",
  //       ethPrice: "",
  //       quantity: "",
  //     },
  //   ]);
  // };

  const onHandlePriceChange = (e, item, key) => {
    let list = [...productList];
    list.forEach((ele) => {
      if (ele.size == item?.size && ele.color == item?.color) {
        if (key == "price") {
          ele.price = e.target.value;
        } else if (key == "ethPrice") {
          ele.ethPrice = e.target.value;
        } else {
          ele.quantity = e.target.value;
        }
      }
    });
    setProductList(list);
  };

  const handleRemoveFileList = (index) => {
    const list = [...fileListEdit];
    list.splice(index, 1);
    setFileListEdit(list);
  };

  // pricelist end
  const config = {
    headers: {
      Authorization: `Bearer ${Admin_Token}`,
    },
  };
  const getAllProduct = async () => {
    try {
      const response = await axios.get(fetchAllProducts(1, 100), config);
      setAllProducts(response?.data?.allProduct);
    } catch (error) {
      console.log(error);
    }
  };

  // conver image to base64
  const imgtoBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setImageVal(true);
  };

  const getThumbnailImage = async (e, fileIndex) => {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event) => {
        const list = [...fileListEdit];
        list[fileIndex]["image"] = event.target.result;
        setFileListEdit(list);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleChangeEdit = async (e, i, type, id) => {
    e.persist();
    console.log(id, "id");

    // check edited or not, if not then send [] in data
    setIsEdited(true);
    //

    if (type === "image") {
      if (e.target.files[0]) {
        getThumbnailImage(e, i);
      }
    } else {
      if (typeof e.target.value === "string") {
        const list = [...fileListEdit];
        list[i]["text"] = e.target.value;
        setFileListEdit(list);
      }
    }
  };

  const [desc, setDesc] = useState(singleProduct?.data?.description);

  const submitProduct = async (e) => {
    const tempArr = [];
    const ImgArr = [];

    for (let i = 0; i < fileList.length; i++) {
      const base6Image = await imgtoBase64(fileList[i].originFileObj);

      tempArr.push(base6Image);
    }

    for (let i = 0; i < tempArr.length; i++) {
      const datas = {
        image: tempArr[i],
      };
      ImgArr.push(datas);
    }

    form
      .validateFields()
      .then(async (values) => {
        console.log(values, "dd");

        const data = {
          // ...values,
          _id: id.id,
          description: desc,
          // images: isEdited ? fileListEdit : [],
          images: fileListEdit,
          categoryId: allfieldValue?.categoryId,
          color: allfieldValue?.color,
          // price: allfieldValue?.price,
          // priceEth: allfieldValue?.priceEth,
          pageUrl: allfieldValue?.pageUrl,
          isActive: checked,
          isTop: isTop,
          isComingSoon: isComing,
          size: allfieldValue?.size,
          title: allfieldValue?.title,
          weight: allfieldValue?.weight,
          relatedProducts: selectedRelatedProduct?.map((item) => item.value),
          priceList: productList || [],
          metaInfo: {
            pageTitle: values.pageTitle,
            pageDesc: values.pageDesc,
            keywords: keywords
              .map((item) => {
                return item.value;
              })
              .join(","),
          },
        };

        setFileList([]);

        // dispatch(ProductUpdate(data, Admin_Token));

        try {
          const response = await axios.post(
            update_product,
            { ...data },
            config
          );
          if (response.status < 400) {
            message.success(response.data.msg);
            navigate("/admin/product-management");
          }
        } catch (err) {
          message.error(err.response.data.msg);
        }

        // setTimeout(() => {
        //   dispatch(fetchProducts());
        //   navigate("/admin/product-management");
        // }, 2000);
        // dispatch(ProductUpdate(data, Admin_Token));
      })
      .catch((error) => {
        console.log("Validate Failed:", error);
      });
  };
  const uploadButton = (
    <div className="dft">
      <AiOutlinePlus className="!text-black mx-auto !text-[1.4rem]" />
      <div className="mt-1">Upload</div>
    </div>
  );

  const generateProductItemsGrid = (colorList, sizeList) => {
    const gridItems = [];

    colorList.forEach((color) => {
      // 1D  Approach
      sizeList.forEach((size) => {
        // For already saved items
        let product = singleProduct?.data?.priceList?.find(
          (ele) => ele.size == size && ele.color == color
        );

        if (!product) {
          // Unsaved item on UI
          product = {
            color: color,
            size: size,
            price: "",
            // ethPrice: "",
            quantity: "",
          };
        }

        gridItems.push({
          color: product?.color,
          size: product?.size,
          price: product?.price || 0,
          // ethPrice: product?.ethPrice || 0,
          quantity: product?.quantity || 0,
        });
      });

      // 2D  Approach
      //  const arr =  sizeList.map(size => {
      //     return {
      //       color: colorName,
      //       size: size,
      //       price: "",
      //       ethPrice: "",
      //       quantity: "",
      //     }
      //   });

      //   gridItems.push(arr);
    });

    console.log("arr", gridItems);
    setProductList(gridItems);
  };

  useEffect(() => {
    dispatch(fetchSingleProduct(id, Admin_Token));
    getAllProduct();

    dispatch(CategoryList());
    setTimeout(() => {
      setChecked(singleProduct?.data?.isActive);
      setIsTop(singleProduct?.data?.isTop);
      setpriceListField(singleProduct?.data?.priceList);
      setSelectedColor(singleProduct?.data?.color);
      setSelectedSize(singleProduct?.data?.size);
      setselectedRelatedProduct(
        singleProduct?.data?.relatedProducts?.map((item) => ({
          value: item._id,
          label: item.title,
        }))
      );
      generateProductItemsGrid(
        singleProduct?.data?.color,
        singleProduct?.data?.size
      );
    }, 1000);
    form.setFieldsValue({
      ...singleProduct?.data,
    });
    var imgArray = [];
    if (singleProduct?.data?.images !== undefined) {
      imgArray = singleProduct?.data?.images;
    }

    const mg = (prevState) => [
      prevState.map((item) => ({
        uid: item._id,
        url: item.image,
      })),
    ];
    setImgstate(mg(imgArray));
  }, [singleProduct?.data?.title]);

  const handleRemove = async (file) => {
    console.log(file.uid, "ll");
    dispatch(deleteProductImage(id.id, file.uid, Admin_Token));
    dispatch(fetchSingleProduct(id, Admin_Token));

    setImgstate([]);
    console.log(imgstate, "inner");
  };
  const [descerror, setDescError] = useState(false);
  const handleDesc = (e) => {
    setDesc(e); // set desc to state
    if (e.length < 1) {
      setDescError(true);
    } else {
      setDescError(false);
    }
  };
  const [checked, setChecked] = useState(singleProduct?.data?.isActive);
  const [isComing, setIsComing] = useState(singleProduct?.data?.isComingSoon);
  const handleChangeActive = (checked) => {
    setChecked(checked);
  };

  const handleChangeIsComingSoon = (checked) => {
    setIsComing(checked);
  };

  useEffect(() => {
    setFileListEdit(
      singleProduct?.data?.images?.map((img) => ({
        image: img.image,
        text: img.text,
        _id: img._id,
      }))
    );
  }, [singleProduct?.data?.images]);

  return (
    <>
      <div className="container-fluid edit_product">
        <div className="row">
          <h2 className="cms-title">Edit product </h2>

          <div className="col-12 col-sm-12 col-md-12 bg-white shadow-sm p-5 cms-box">
            <div className="stapesSec">
              <ul>
                <li className={!saveNext ? "active" : ""}>
                  <span>01</span> Step 1
                </li>
                <li className={saveNext ? "active" : ""}>
                  <span>02</span> Step 2
                </li>
                {/* <li><span>03</span> Submit</li> */}
              </ul>
            </div>

            <Form layout="vertical" form={form} onFinish={submitProduct}>
              {saveNext == false ? (
                <>
                  <Form.Item
                    name="title"
                    type="text"
                    initialValue={singleProduct?.data?.title}
                    label=" Product Title"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input name="title" className={inputclass} />
                  </Form.Item>

                  <div className="my-3">
                    <label className="text-black font-bold mb-2">
                      Product Description
                    </label>
                    <Editor
                      className="absolute top-[-1rem]"
                      name="description"
                      value={desc}
                      initialValue={singleProduct?.data?.description}
                      onEditorChange={(e) => handleDesc(e)}
                      init={{
                        height: 300,
                        automatic_uploads: true,
                        file_picker_types: "file image media",
                        advlist_bullet_styles: "square",
                        advlist_number_styles:
                          "lower-alpha,lower-roman,upper-alpha,upper-r",
                        /* and here's our custom image picker*/
                        toolbar_mode: "sliding",
                      }}
                      plugins={[
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                        "textcolor",
                        "textpattern",
                        "image",
                        "imageupload",
                        "link",
                        "help",
                        "wordcount",
                        "code",
                        "preview",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "paste",
                        "charmap",
                        "searchreplace",
                        "visualblocks",
                        "emoticons",
                      ]}
                      toolbar={[
                        "undo redo | formatselect | bold italic backcolor forecolor   |alignleft aligncenter alignright alignjustify | fonts | bullist numlist outdent indent | removeformat | image| help",
                        "link imageupload | fontselect | fontsizeselect |fullscreen | insertdatetime | media | wordcount | charmap | searchreplace | visualblocks |  table | code | preview |emoticons |",
                      ]}
                    />
                  </div>
                  {descerror ? (
                    desc === "" ? (
                      <div className="text-[#F10606]">
                        This field is required
                      </div>
                    ) : null
                  ) : (
                    ""
                  )}
                  <Form.Item
                    label="Category"
                    name="categoryId"
                    type="select"
                    initialValue={singleProduct?.data?.categoryId}
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Select
                      className="!h-[40px]"
                      placeholder="Select Product Category"
                    >
                      {allCategories?.data?.map((item) => (
                        <Select.Option key={item._id} value={item._id}>
                          {item.categoryName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Row>
                    {/* <Col md={2}>
                      <Form.Item
                        type="number"
                        name="price"
                        label="Price in $"
                        initialValue={singleProduct?.data?.price}
                        rules={[
                          {
                            required: false,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <InputNumber
                          min={1}
                          placeholder="Price"
                          className={inputnumberclass}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={2}>
                      <Form.Item
                        type="number"
                        name="priceEth"
                        label="Price in Eth"
                        initialValue={singleProduct?.data?.priceEth}
                        rules={[
                          {
                            required: false,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <InputNumber
                          min="0"
                          step="0.01"
                          placeholder="Price"
                          className={inputnumberclass}
                          stringMode
                        />
                      </Form.Item>
                    </Col>

                    <Col md={2}>
                      <Form.Item
                        type="number"
                        name="quantity"
                        label="Quantity"
                        initialValue={singleProduct?.data?.quantity}
                        rules={[
                          {
                            required: false,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <InputNumber
                          min="0"
                          step="1"
                          placeholder="Quantity"
                          className={inputnumberclass}
                          stringMode
                        />
                      </Form.Item>
                    </Col> */}

                    {/* {sl?.images.map((item) => (
                  <img src={item} width="20px" height="30px" />
                ))} */}

                    <Col md={4}>
                      <Form.Item
                        type="text"
                        label="Color"
                        name="color"
                        initialValue={singleProduct?.data?.color}
                        rules={[
                          { required: true, message: "This field is required" },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          className="
                    !leading-[2.563rem] !rounded-lg slt"
                          placeholder="Select Color"
                          onChange={(values) => {
                            setSelectedColor(values);
                            generateProductItemsGrid(
                              values,
                              selectedSize || []
                            );
                          }}
                        >
                          <Select.Option value="NA">N/A</Select.Option>
                          <Select.Option
                            disabled={
                              selectedColor?.includes("NA") ? true : false
                            }
                            value="blue"
                          >
                            Blue
                          </Select.Option>

                          <Select.Option
                            disabled={
                              selectedColor?.includes("NA") ? true : false
                            }
                            value="brown"
                          >
                            Brown
                          </Select.Option>

                          <Select.Option
                            disabled={
                              selectedColor?.includes("NA") ? true : false
                            }
                            value="green"
                          >
                            Green
                          </Select.Option>

                          <Select.Option
                            disabled={
                              selectedColor?.includes("NA") ? true : false
                            }
                            value="grey"
                          >
                            Grey
                          </Select.Option>

                          <Select.Option
                            disabled={
                              selectedColor?.includes("NA") ? true : false
                            }
                            value="red"
                          >
                            Red
                          </Select.Option>

                          <Select.Option
                            disabled={
                              selectedColor?.includes("NA") ? true : false
                            }
                            value="yellow"
                          >
                            Yellow
                          </Select.Option>


                          <Select.Option
                            disabled={
                              selectedColor?.includes("NA") ? true : false
                            }
                            value="black"
                          >
                            Black
                          </Select.Option>
                          <Select.Option
                            disabled={
                              selectedColor?.includes("NA") ? true : false
                            }
                            value="white"
                          >
                            White
                          </Select.Option>
                          <Select.Option
                            disabled={
                              selectedColor?.includes("NA") ? true : false
                            }
                            value="orange"
                          >
                            Orange
                          </Select.Option>
                          <Select.Option
                            disabled={
                              selectedColor?.includes("NA") ? true : false
                            }
                            value="pink"
                          >
                            Pink
                          </Select.Option>
                          <Select.Option
                            disabled={
                              selectedColor?.includes("NA") ? true : false
                            }
                            value="purple"
                          >
                            Purple
                          </Select.Option>
                        </Select>
                        {/* <Input placeholder="Color" className={inputclass} /> */}
                      </Form.Item>
                    </Col>
                    <Col md={4}>
                      <Form.Item
                        type="select"
                        name="size"
                        label="Size"
                        initialValue={singleProduct?.data?.size}
                        rules={[
                          { required: true, message: "This field is required" },
                        ]}
                      >
                        <Select
                          className="
                    !leading-[2.563rem] !rounded-lg slt"
                          placeholder="Select Size"
                          mode="multiple"
                          onChange={(values) => {
                            setSelectedSize(values);
                            generateProductItemsGrid(
                              selectedColor || [],
                              values
                            );
                          }}
                        >
                          <Select.Option value="XS">XS</Select.Option>
                          <Select.Option value="S">S</Select.Option>
                          <Select.Option value="M">M</Select.Option>
                          <Select.Option value="L">L</Select.Option>
                          <Select.Option value="XL">XL</Select.Option>
                          <Select.Option value="XXL">XXL</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <h5>Product Image </h5>
                  <div className="row-border-box">

                    {/* <Form.Item label="Product Image">
                      <Upload
                        listType="picture-card"
                        fileList={imgstate[0]}
                        onPreview={handlePreview}
                        onRemove={handleRemove}
                      ></Upload>
                      <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="example"
                          className="w-full"
                          src={previewImage}
                        />
                      </Modal>
                    </Form.Item> */}
                    {/* <Form.Item label="Add New Image">
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    beforeUpload={beforeUpload}
                  >
                    {fileList.length >= 10 ? null : uploadButton}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img alt="example" className="w-full" src={previewImage} />
                  </Modal>
                </Form.Item> */}

                    <Row className="align-items-center">
                      {fileListEdit?.map((x, i) => (
                        <>
                          <Col md={1}>
                            <div className="productNoImg">
                              <img src={x.image ? x?.image : '/images/noimg.png'} />
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="mb-3">
                              <label>
                                <strong>Add New Image or Video</strong>
                              </label>
                              <input
                                name="image"
                                type="file"
                                className="form-control"
                                onChange={(e) =>
                                  handleChangeEdit(e, i, "image", x)
                                }
                              />
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="mb-3">
                              <label>
                                <strong>Add Text</strong>
                              </label>

                              <input
                                name="text"
                                type="text"
                                value={x?.text}
                                className="form-control"
                                onChange={(e) =>
                                  handleChangeEdit(e, i, "text", x)
                                }
                              />
                            </div>
                          </Col>
                          <Col md={1}>
                            <div className="add-delete-btn-clm">
                              {fileListEdit.length - 1 === i && (
                                <button
                                  className="transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl add-product-btn"
                                  onClick={() =>
                                    setFileListEdit([
                                      ...fileListEdit,
                                      { image: "", text: "" },
                                    ])
                                  }
                                >
                                  <img
                                    src={plusIcon}
                                    alt="Add"
                                    className="h-[23px]"
                                  />
                                </button>
                              )}

                              <span onClick={() => handleRemoveFileList(i)}>
                                <img
                                  src={deleteIcon}
                                  alt="Delete"
                                  className="h-[23px]"
                                />
                              </span>



                            </div>





                          </Col>
                        </>
                      ))}

                      <div className="text-center">
                        <Col md={12}>

                          {
                            !fileListEdit?.length ? (
                              <button
                                type="button"
                                className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] "
                                onClick={() =>
                                  setFileListEdit([
                                    ...fileListEdit,
                                    { image: "", text: "" },
                                  ])
                                }
                              >
                                Add Images
                              </button>
                            ) : ""
                          }
                        </Col>
                      </div>
                    </Row>
                  </div>
                  <Form.Item
                    name="isActive"
                    type="switch"
                    initialValue={singleProduct?.data?.isActive}
                    label="Status"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Switch
                      checkedChildren="Active"
                      checked={checked}
                      unCheckedChildren="Inactive"
                      onChange={handleChangeActive}
                    />
                  </Form.Item>


                  <Form.Item
                    name="isComing"
                    type="switch"
                    initialValue={singleProduct?.data?.isComingSoon}
                    label="Is Coming Soon"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Switch
                      checkedChildren="Yes"
                      checked={isComing}
                      unCheckedChildren="No"
                      onChange={handleChangeIsComingSoon}
                    />
                  </Form.Item>



                  <Form.Item
                    name="isTop"
                    type="switch"
                    initialValue={singleProduct?.data?.isTop}
                    label={`Is Feature Item`}
                  // rules={[{ required: true, message: "This field is required" }]}
                  >
                    <Switch
                      checkedChildren="Enable Feature"
                      checked={isTop}
                      unCheckedChildren="Disable feature"
                      onChange={(checked) => setIsTop(checked)}
                    />
                  </Form.Item>

                  <Form.Item
                    name="pageUrl"
                    type="text"
                    label="Product URL"
                    initialValue={singleProduct?.data?.pageUrl}
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(
                          /^[a-zA-Z-]+$/i
                        ),
                        message: "This field is required & Symbol Not Allowed (Ex: @ % // )"
                      },
                    ]}
                  >
                    <Input placeholder="Product URL" className={inputclass} />
                  </Form.Item>
                  <small className="url-error-msg">
                    Please Enter URL properly (Ex - roaringtiger)
                  </small>
                  <br />

                  <Form.Item
                    name="weight"
                    type="text"
                    label="weight"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input
                      placeholder="Weight"
                      className={inputclass}
                      suffix="Gram"
                    />
                  </Form.Item>

                  <br />
                  <label>Related Products</label>
                  <ReactSelect
                    //  defaultValue={selectedRelatedProduct?.map((item) => ({
                    //   value: item._id,
                    //   label: item.title,
                    // }))}
                    value={selectedRelatedProduct?.map((item) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                    options={allProducts?.data?.map((item) => ({
                      value: item._id,
                      label: item.title,
                    }))}
                    isMulti
                    onChange={(e) => {
                      console.log(e);
                      setselectedRelatedProduct(e);
                    }}
                  />

                  <span
                    type="button"
                    className="mt20 bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] "
                    onClick={() => {
                      const values = form.getFieldsValue();
                      const regex = new RegExp(/^\S*$/);

                      setAllFieldValue(values);
                      if (!regex.test(values?.pageUrl)) {
                        message.error(
                          "Please Enter a valid URL (Ex - roaringtiger)"
                        );
                        return;
                      }
                      let f = form
                        .validateFields()
                        .then((res) => {
                          Object.keys(res).map(function (key) {
                            if (res[key] == undefined) {
                              setsaveNext(false);
                            } else {
                              setsaveNext(true);
                            }
                          });
                        })
                        .catch((err) => console.log(err));
                      // console.log(f, 'ffff')
                    }}
                  >
                    Next
                  </span>
                </>
              ) : (
                <>
                  <div className="row-border-box add-prdct-price-color-row product-table">
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th className="color-clm">Color</th>
                          <th className="size-clm">size</th>
                          <th className="usd-clm">Price ($)</th>
                          {/* <th className="eth-clm">Price Eth</th> */}
                          <th className="qua-clm">quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productList?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-capitalize">{item?.color}</td>
                              <td>{item?.size}</td>
                              <td>
                                <input
                                  name="price[]"
                                  min="0"
                                  type="number"
                                  placeholder={`Price`}
                                  className={inputnumberclass}
                                  value={item?.price}
                                  onChange={(e) =>
                                    onHandlePriceChange(e, item, "price")
                                  }
                                />
                              </td>

                              {/* <td>
                                <input
                                  name="ethPrice"
                                  type="number"
                                  min="0"
                                  step="0.01"
                                  value={item?.ethPrice}
                                  placeholder="Price Eth"
                                  className={inputnumberclass}
                                  onChange={(e) =>
                                    onHandlePriceChange(e, item, "ethPrice")
                                  }
                                />
                              </td> */}

                              <td>
                                <input
                                  name="Quantity"
                                  min="0"
                                  type="number"
                                  value={item?.quantity}
                                  placeholder="quantity"
                                  className={inputnumberclass}
                                  onChange={(e) =>
                                    onHandlePriceChange(e, item, "quantity")
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <Form.Item
                    name="pageTitle"
                    type="text"
                    label="Page Title"
                    // rules={[
                    //   { required: true, message: "This field is required" },
                    // ]}
                    initialValue={singleProduct?.data?.metaInfo?.pageTitle}
                  >
                    <Input placeholder="Page Title" className={inputclass} />
                  </Form.Item>

                  <Form.Item
                    name="pageDesc"
                    type="text"
                    label="Page Description"
                    // rules={[
                    //   { required: true, message: "This field is required" },
                    // ]}
                    initialValue={singleProduct?.data?.metaInfo?.pageDesc}
                  >
                    <TextArea
                      placeholder="Page Description"
                      className={inputclass}
                    />
                  </Form.Item>

                  {/* <Form.Item
                    name="keywords"
                    label="Keywords"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  > */}
                  <label>Keywords</label>
                  <CreatableSelect
                    className={inputclass}
                    isMulti
                    // onChange={(newValue) => {
                    //   console.log(newValue);
                    // }}
                    onChange={(newValue) => {
                      setKeywords(newValue);
                    }}
                    noOptionsMessage={() => null}
                    value={keywords}
                  />
                  {/* </Form.Item> */}

                  <Form.Item>
                    <button
                      type="submit"
                      className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] "
                    >
                      Update Product
                    </button>
                  </Form.Item>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditProduct;
