import React, { useState } from "react";
import "./myOrder.scss";
import OrderItem from "./orderItem/OrderItem";
import { Form, Select } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import getAllOrders from "../../../redux/actions/myOrderAction";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import { cancel_order, retry_cart_payment } from "../../../api/Api-list";

const { Option } = Select;

const MyOrder = () => {
  const [selectedFilterOption, setSelectedFilterOption] = useState("");

  const allOrders = useSelector((state) => state.myOrder.data);

  const filterOptions = [
    "Payment Failed",
    "Confirmed",
    "Reject By Admin",
    "Shipped",
    "Delivered",
    "Return Request",
    "Return Request Accept",
    "Return request Pending",
    "Return Request Processing",
    "Return Request Complete",
  ];

  const app_token = localStorage.getItem("tiger-token");

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    dispatch(getAllOrders(selectedFilterOption));
  }, [selectedFilterOption]);

  const retryPayment = async (orderId) => {
    try {
      const response = await axios.get(retry_cart_payment(orderId), config);
      if (response.status < 400) {
        window.location.href = response.data.paymentUrl;
      }
    } catch (err) {
      console.log(err);
      addToast(err.response.data.msg, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const cancelOrder = async (orderId) => {
    try {
      const response = await axios.patch(cancel_order(orderId), {}, config);
      if (response.status < 400) {
        dispatch(getAllOrders(selectedFilterOption));
        addToast(response.data.msg, {
          appearance: "success",
          autoDismiss: true,
        });
      }
    } catch (err) {
      console.log(err);
      addToast(err.response.data.msg, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <div className=" mx-auto pt-8 container row flex flex-row  my_order">
      <div className="!max-w-[1270px] mx-auto">
        <div className="flex items-center justify-between flex-row">
          <div className="flex flex-col order_content ">
            <h2 className="text-[25px] font-bold text-white order_heading_design">
              {" "}
              {allOrders.length} {allOrders.length > 1 ? "Orders" : "Order"}
            </h2>
          </div>
          <div className="flex flex-col  my-2">
            <Form
              initialValues={{ remember: true }}
              autoComplete="off"
              name="horizontal_login"
              className=" my_order_form !w-full focus:!shadow-none "
            >
              <Form.Item
                name="filter"
                label=" filter"
                className=" !w-ful !bg-[#191919] !mb-0"
              >
                <Select
                  placeholder="Filter By"
                  className="!text-white focus:!shadow-none"
                  onChange={(e) => setSelectedFilterOption(e)}
                >
                  {filterOptions.map((option) => {
                    return <Option value={option}>{option}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="w-full !max-w-[25rem] xs:max-w-[25rem] sm:!max-w-[29rem] md:!max-w-[70rem] lg:!max-w-[100rem] flex !justify-center flex-col">
          {allOrders.length > 0 ? (
            allOrders.map((order) => {
              return (
                <OrderItem
                  order={order}
                  retryPayment={() => retryPayment(order._id)}
                  cancelOrder={() => cancelOrder(order._id)}
                />
              );
            })
          ) : (
            <p className="text-gray-500 text-center text-lg mt-10">
              No order has been found
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyOrder;
