import { useDispatch, useSelector } from "react-redux";
import MetaMaskLogo from "../../../images/metamask.svg";
import WalletConnectLogo from "../../../images/walletconnect.svg";
import { Modal } from "antd";
import { SHOW_MODAL } from "../../../redux/types/blockchainType";
import {
  connectToWalletConnect,
  handleMetaMaskConnection,
} from "../../../redux/actions/blockchain";
import "../login/login.scss";

const { ethereum } = window;

const ChooseHowConnect = ({ setMetaMaskAvailable }) => {
  const dispatch = useDispatch();
  const { walletConnectModalOpen } = useSelector((state) => state.blockChain);

  const hideModal = () => {
    dispatch({
      type: SHOW_MODAL,
      payload: { walletConnectModalOpen: false },
    });
  };

  const onClickMetamask = () => {
    hideModal();
    if (ethereum) {
      dispatch(handleMetaMaskConnection());
    } else {
      setMetaMaskAvailable(false);
    }
  };

  const onClickWalletConnect = () => {
    hideModal();
    dispatch(connectToWalletConnect);
  };

  return (
    <Modal
      open={walletConnectModalOpen}
      onCancel={hideModal}
      footer={null}
      destroyOnClose
      centered
      className="login_modal"
    >
      <div style={{ backgroundColor: "black", padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
          }}
        >
          <h5 style={{ margin: 0, color: "white", textAlign: "center" }}>
            Connect Your Wallet
          </h5>
        </div>
        <div style={{ margin: "20px 0" }}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div
              style={{
                cursor: "pointer",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
              className="blockchain-connect"
              onClick={onClickMetamask}
            >
              <img src={MetaMaskLogo} alt="MetaMask" height={50} width={50} />
              <p style={{ margin: "5px 0", color: "white" }}>MetaMask</p>
            </div>
            <div
              style={{
                cursor: "pointer",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
              className="blockchain-connect"
              onClick={onClickWalletConnect}
            >
              <img
                src={WalletConnectLogo}
                alt="WalletConnect"
                height={50}
                width={50}
              />
              <p style={{ margin: "5px 0", color: "white" }}>WalletConnect</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChooseHowConnect;
