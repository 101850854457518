export const ActionType = {
  SET_PRODUCTS: "SET_PRODUCTS",
  SELECTED_PRODUCT: "SELECTED_PRODUCT",
  REMOVE_SELECTED_PRODUCT: " REMOVE_SELECTED_PRODUCT",
  PRODUCT_CATEGORY: "PRODUCT_CATEGORY",
  ADD_PRODUCT: "ADD_PRODUCT",
  ADD_CATEGORY: "ADD_CATEGORY",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  SELECT_SINGLE_CATEGORY: "SELECT_SINGLE_CATEGORY",
  SELECT_PRODUCT: "SELECT_PRODUCT",
  SELECT_SINGLE_PRODUCT: "SELECT_SINGLE_PRODUCT",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  GET_ACTIVE_PRODUCTS: "GET_ACTIVE_PRODUCTS",
  DELETE_PRODUCT_IMAGE: " DELETE_PRODUCT_IMAGE",
};
