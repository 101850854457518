import React from "react";
import { useSelector } from "react-redux";
import "./benefitCard.scss";

const BenefitCard = () => {
  const holderPagesCard =
    useSelector((state) => state?.holdersPage?.holdersContent) || [];
  return (
    <div className="benefit_card">
      <div className="container">
        <h2 className="text-white text-center mt-4 member-benefit-heading">
          {holderPagesCard.data && holderPagesCard.data[1].sectionName}
        </h2>
        <div className="membership-curve-container position-relative">
          <div className="d-flex">
            {holderPagesCard.data &&
              holderPagesCard.data[1].title.map((card, index) => {
                return (
                  <div className="membership-curve" key={card._id}>
                    <h2 className=" font-bold text-white text-center m-0">
                      {index + 1}
                    </h2>
                    <div
                      className="  text-gray-100 w-full xs:!max-w-[17rem] mx-auto text-center "
                      dangerouslySetInnerHTML={{ __html: card.description }}
                    />
                  </div>
                );
              })}
          </div>
          <div className="membership-curve-image position-absolute">
            <img alt="" src="/images/membership-curve.png" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitCard;
