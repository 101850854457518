import React, { useState } from "react";
import { Row, Col, Input, Menu, Dropdown } from "antd";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
// import Logo from "../../images/logo.png";
import { AdminLogout } from "../../../redux/actions/adminLoginRegisterActions";
import "antd/dist/antd.css";
import "./admin-header.scss";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// const { Search } = Input;
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import { GrSettingsOption } from "react-icons/gr";
const AdminHeader = () => {
  const PageSettingpage = useSelector(
    (state) => state?.cmsPageSetting?.settingpage
  );
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();
  const history = useNavigate();

  const handleMenuClick = (e) => {
    if (e.key === "3") {
      setVisible(false);
    }
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  //logout function
  const logout = (e) => {
    localStorage.removeItem("tiger");
    dispatch(AdminLogout(history));
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        <Link
          to="profile"
          className="flex items-center font-normal transition-all ease-linear duration-100 !text-black "
        >
          {" "}
          <UserOutlined />
          <span className="mx-2">My Profile</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link
          to="setting"
          className="flex items-center font-normal transition-all ease-linear duration-100 !text-black "
        >
          {" "}
          <GrSettingsOption className="!text-red-800 " />
          <span className="mx-2">Settings</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={(e) => {
          logout();
        }}
      >
        <div className="flex items-center">
          <LogoutOutlined /> <span className="mx-2 mt-[-3px]">Logout</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="admin-header">
        <header>
          <Row gutter={[16, 10]} align="middle">
            <Col xs={8} sm={4} xl={4} className="logo">
              <img
                className="w-full max-w-[4rem]"
                src={
                  PageSettingpage.data ? PageSettingpage?.data[0]?.image : ""
                }
                title={
                  PageSettingpage.data ? PageSettingpage?.data[0]?.title : ""
                }
                alt="eatfundu logo"
              />
            </Col>

            <Col
              xs={{ span: 16, push: 0 }}
              sm={{ span: 10, push: 10 }}
              xl={{ span: 10, push: 10 }}
              className="text-right"
            >
              <Dropdown
                className="float-right"
                overlay={menu}
                onVisibleChange={handleVisibleChange}
                visible={visible}
              >
                <button
                  className="ant-dropdown-link fw-bold"
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="profile-img">
                    {/* {sel.picture && <img style={{borderRadius:"50%"}} src={sel.picture} alt="profile image" />} */}
                  </div>
                  Admin
                  <DownOutlined />
                </button>
              </Dropdown>
            </Col>
          </Row>
        </header>
      </div>
    </>
  );
};

export default AdminHeader;
