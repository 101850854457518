import { CMS_PAGE_MINSOLD } from "../types/cmsPageType";

const initState = {
  mintContent: [],
};
const mintSoldReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case CMS_PAGE_MINSOLD:
      return {
        ...state,
        mintContent: payload,
      };

    default:
      return {
        ...state,
        payload: payload,
      };
  }
};
export default mintSoldReducer;
