import React from "react";
import "./gtc_gallery.scss";
import { BsArrowRightCircle } from "react-icons/bs";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import Marquee from "react-fast-marquee";

import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

import { Carousel, Image } from "antd";
const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const GTCGallery = () => {
  const location = useLocation();
  const pages = useSelector((state) => state?.cmsPageSetting?.allPage);
  let data = pages?.filter((page) => page?.section === "GTGC");
  // const route = useRoute();

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  console.log(data?.[0]?.files, "files");

  let imageItems = data?.[0]?.files;
  var middleIndex = Math.ceil(imageItems?.length / 2);
  // console.log(middleIndex, "middle")

// var first = imageItems?.slice(0, middleIndex); 
// var sec = imageItems?.slice(-middleIndex);
// console.log(first, "first")
// console.log(sec, "first")

  return (
    <div className="gtc_container" id="gallery">
      {location.pathname === "/gallery" ? (
        <div className="pt-[5rem]"></div>
      ) : (
        ""
      )}
      <Container style={{paddingLeft: '0px', paddingRight: '0px'}}>
        <Row style={{marginLeft: '-15px', marginRight: '-15px'}}>
          <Col className="col-lg-12 col-md-12 col-12 text-center" style={{paddingLeft: '0px', paddingRight: '0px'}}>
            <h2
              className="position-relative fontHeadingShloppy"
              style={{ color: "#1c84ff" }}
            >
              {" "}
              {data[0]?.sectionName}
            </h2>

            
          </Col>
        </Row>
      </Container>

{location.pathname !== "/gallery" ? (
              <Link to="/gallery">
                <button>
                  <span>
                    <BsArrowRightCircle className="text-[1rem]" />
                  </span>
                  <span>{data[0]?.buttonText}</span>
                </button>
              </Link>
            ) : (
              ""
            )}

            {location.pathname == "/gallery" ? (
              <>

           
                {data?.[0]?.files?.length ? (
                  <SimpleReactLightbox>
                    <SRLWrapper>
                      <Marquee 
                        className="glry-marquee-left"
                        direction='right'
                        speed='50'
                      > 
                      {data?.[0]?.files?.length
                          ? data?.[0]?.files?.slice(0, middleIndex)?.map((file) => (
                            <a href={file.url}>
                              <img src={file.url} />
                            </a>
                          ))
                        : ""}
                      </Marquee>

                      <Marquee 
                        className="glry-marquee-left"
                        direction='left'
                        speed='50'
                      > 
                      {data?.[0]?.files?.length
                          ? data?.[0]?.files?.slice(middleIndex, imageItems?.length)?.reverse()?.map((file) => (
                            <a href={file.url}>
                              <img src={file.url} />
                            </a>
                          ))
                        : ""}
                      </Marquee>
                    </SRLWrapper>
                  </SimpleReactLightbox>
                ) : (
                  <div className="gtc_gallery_img">
                    {data[0]?.image.length > 0 ? (
                      <img src={data[0]?.image[0].item} alt="tiger" />
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </>
            ) : (
              <div className="gtc_gallery_img">
                <img src="/images/bagh.gif" />
              </div>
            )}

    </div>
  );
};

export default GTCGallery;
