import React from "react";
import { Link } from "react-router-dom";

import { Row, Col } from "antd";
import Viewicon from "../../admin/images/view-icon.png";
import Ordericon from "../../admin/images/orders-icon2.png";
import Revenueicon from "../../admin/images/revenue-icon.png";

import Clienticon from "../../admin/images/client-icon.png";
import "./dashboard.scss";
// import { total_list } from '../../../Api/Api';

function AdminDashboard() {
  // const selector = useSelector((state) => state.adminReducer);

  // const columns = [
  //   {
  //     title: "Order ID",
  //     dataIndex: "orderid",
  //   },
  //   {
  //     title: "Date",
  //     dataIndex: "date",
  //   },
  //   {
  //     title: "Chef Name",
  //     dataIndex: "price",
  //   },
  //   {
  //     title: "Package",
  //     dataIndex: "status",
  //   },
  //   {
  //     title: "Total price",
  //     dataIndex: "status",
  //   },
  //   {
  //     title: "Duration",
  //     dataIndex: "status",
  //   },
  //   {
  //     title: "Details",
  //     dataIndex: "details",
  //     width: 130,
  //     fixed: "right",
  //   },
  // ];

  const dat = [];
  for (let i = 0; i < 20; i++) {
    dat.push({
      orderid: "2260645",
      date: "23 - April - 2021",
      price: "$10",
      status: "Completed",
      details: (
        <div className="d-flex align-items-center">
          <Link to="/order-details">
            <img src={Viewicon} alt="View" />
          </Link>
        </div>
      ),
    });
  }

  // const dashboardTotalList=()=>{
  //    axios.get(total_list, { headers: { Authorization: 'Bearer: ' + selector.adToken } })
  //    .then(response=>{
  //       // console.log(response)
  //       setTotal_count(response.data)
  //    })
  //    .catch(err=>{
  //       console.log(err)
  //    })
  // }

  // useEffect(()=>{
  //    dashboardTotalList()
  // },[])

  return (
    <div className="chef-dashboard">
      <h2>Dashboard</h2>
      <Row gutter={[16, 16]}>
        <Col lg={8} md={8} sm={8} xs={24}>
          <div className="top-d">
            <Row gutter={[16]} align="middle">
              <Col span={16}>
                <strong>57</strong>
                <span>Total Customers</span>
              </Col>
              <Col span={8}>
                <Link to="/customer-list">
                  <img
                    className="img-fluid float-right"
                    src={Clienticon}
                    alt="Total Client"
                  />
                </Link>
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={8} md={8} sm={8} xs={24}>
          <div className="top-d">
            <Row gutter={16} align="middle">
              <Col span={16}>
                <strong>35</strong>
                <span>Total Order</span>
              </Col>
              <Col span={8}>
                <img
                  className="img-fluid float-right"
                  src={Ordericon}
                  alt="Total Orders"
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={8} md={8} sm={8} xs={24}>
          <div className="top-d">
            <Row gutter={16} align="middle">
              <Col span={16}>
                <strong>$5600</strong>
                <span>Total Revenue</span>
              </Col>
              <Col span={8}>
                <img
                  className="img-fluid float-right"
                  src={Revenueicon}
                  alt="Total Revenue"
                />
                {/* <img className="img-fluid float-right" src={Dishicon} alt="Total Dishes" /> */}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default AdminDashboard;
