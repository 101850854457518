import React from "react";
import { InputNumber } from "antd";
import "./cart.scss";
import styled from "styled-components";

function CartItem(props) {
  const { item, removeCartItem, incQuantity, decQuantity, oneEth } = props;

  const app_token = localStorage.getItem("tiger-token");

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  return (
    <Wrapper className=" w-full py-2  max-w-[25rem] md:max-w-[100%] mx-auto cart-item">
      <div className="bg-[#111111] p-4 rounded-xl flex flex-col md:flex-row md:justify-between w-full ">
        <div className=" md:flex md:basis-1/2 product_img">
          <img
            src={item.product?.images?.[0]?.image || "images/noimg.png"}
            className="rounded-xl object-cover w-full md:max-w-[200px] min-w-[100px]"
            alt="man_card"
          />
          <div className="py-2 px-4 flex flex-col justify-between card_content ">
            <div className="space-y-2">
              <div className="cart-prdct-title text-[#a6a6a6] text-[13px]">
                Name : {item.product.title}
              </div>
              <p
                dangerouslySetInnerHTML={{ __html: item.description }}
                className="font-bold text-[22px]"
              ></p>
              <div className="pl-14 xs:pl-13 sm:pl-0 md:pl-0">
                <span className="text-[15px]">Size :</span>{" "}
                <span className="mx-2 text-[15px] text-[#a6a6a6] ">
                  {" "}
                  {item.priceTier.size}
                </span>
              </div>
              <div className="cart-color-row pl-14 xs:pl-13 sm:pl-0 md:pl-0">
                <span className="text-[15px]">Color :</span>
                <div
                  className={`w-4 h-4 ml-2 rounded-full  d-inline-block`}
                  style={{ background: item.priceTier.color }}
                >
                  <span className="color-name text-[#a6a6a6]">
                    {item.priceTier.color.charAt(0).toUpperCase() +
                      item.priceTier.color.slice(1)}
                  </span>
                </div>
              </div>
            </div>
            <div className="my-4">
              <button
                onClick={removeCartItem}
                className="text-[16px] text-[#a6a6a6]"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between md:basis-1/2 md:!flex-wrap">
          <div className="w-1/2 ml-4 flex justify-center cart-quantity-field">
            <InputNumber
              addonBefore={
                <span style={{ cursor: "pointer" }} onClick={decQuantity}>
                  -
                </span>
              }
              addonAfter={
                <span style={{ cursor: "pointer" }} onClick={incQuantity}>
                  +
                </span>
              }
              value={item.quantity}
            />
          </div>
          <div className="space-x-3 w-1/3 h-10 cart-price-content">
            <span className="product-card-usd">{`$${item.priceTier.price}`}</span>
            <span className="product-card-ethereum">
              <img
                src="/images/eth-icon.png"
                className="object-contain w-4 "
                alt="ethicon"
              />
              {(item.priceTier.price / oneEth).toFixed(5)}
            </span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default CartItem;

const Wrapper = styled.div`
  .ant-select.ant-select-single.ant-select-show-arrow {
    color: white;
    background: transparent;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #191919;
    border: 1px solid #191919;
    border-radius: 11px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 50px;
    display: flex;
    align-items: center;
    padding: 21px;
    width: 125px;
  }

  .ant-select-arrow {
    display: none;
  }

  .ant-select.ant-select-single.ant-select-show-arrow {
    position: relative;
  }

  .ant-select.ant-select-single.ant-select-show-arrow::before {
    content: "";
    position: absolute;
    display: block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    z-index: 9;
    top: 20px;
    right: 9px;
    border-top: 6px solid #ffffff;
  }

  .ant-select-dropdown {
    background-color: #191919 !important;
  }
  @media (max-width: 992px) {
    p {
      font-size: 16px !important;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      height: 40px;

      padding: 15px;
      width: 120px;
    }
    .price_content span {
      font-size: 18px;
    }
    .price_content img {
      width: 12px;
    }
    @media (max-width: 770px) {
    .product_img img{
    width:200px;
    margin:0 auto;
    padding-bottom:20px;
    }
    .card_content{
      text-align:center;
    }
  }
`;
