import React from "react";
import { Form, Input, Col, Row } from "antd";
import { Container } from "react-bootstrap";

import "./mintWhitelist.scss";
import { Link } from "react-router-dom";
import Footer from "../../footer/Footer";
const MintWhitelist = () => {
  return (
    <>
      <div className="mint_page ">
        {" "}
        <Container>
          <div className=" flex items-center flex-col justify-center mint_content mint_heading">
            <h2 className=" text-center pt-3 pb-0 mb-0">
              Mint Your Roaring Tiger{" "}
            </h2>
            <p className="text-gray-300 max-w-4xl text-center sm:text-base text-md pb-5">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse nec sapien enim. In et suscipit libero, non molestie
              risus.
            </p>
          </div>

          <Row justify="center">
            <Col lg={8} md={13} xs={24} sm={24}>
              <div className="bg-[#000] border-[4px] border-[#ffffff24] rounded-xl bg-opacity-70 px-5 py-8 my-3 Mint_form">
                <Form name="basic" layout="vertical" autoComplete="off">
                  <Form.Item
                    name=" crypto_wallet_connect"
                    label="Crypto Wallet Connect"
                    type="text"
                    rules={[
                      {
                        required: true,
                        message: "Please input  !"
                      }
                    ]}
                    className="my-1 "
                  >
                    <Input
                      placeholder="Crypto Wallet Connect"
                      className="!border-[#ffffff85] focus:!shadow-none !text-white !bg-transparent !py-[0.7rem] !rounded-[0.4rem]  !text-[#ffffff85]"
                    />
                    {/* <input
                    type="text"
                    className="border-[#ffffff85] text-white border-[.3px]  w-full h-[50px] rounded-lg px-3 bg-[#0E0E0E] bg-opacity-85 mb-3"
                  /> */}
                  </Form.Item>

                  <Form.Item
                    name=" discord_username"
                    label="Discord username"
                    type="text"
                    rules={[
                      {
                        required: true,
                        message: "Please  enter username !"
                      }
                    ]}
                    className="!border-[#ffffff85] my-1 !bg-transparent !text-white"
                  >
                    <Input
                      placeholder="Discord username"
                      className="!border-[#ffffff85] focus:!shadow-none !text-white  !bg-transparent !py-[0.7rem] !rounded-[0.4rem]  !text-[#ffffff85]"
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password !"
                      }
                    ]}
                    className="border-[#ffffff85] my-1 focus:!shadow-none focus:!outline-none !bg-transparent !text-white"
                  >
                    <Input.Password
                      placeholder="******"
                      className="!border-0 focus:!shadow-none focus:!outline-none  !text-[ffffff85]"
                    />
                  </Form.Item>
                  <button className="text-[22px] font-semibold text-center w-full bg-[#FF8500] text-white !my-4 !py-[11px] rounded-lg">
                    <Link to="/mint-sale" className="text-white no-underline">
                      Mint Now
                    </Link>
                  </button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="mint-footer"></div>
      </div>
      <Footer />
    </>
  );
};

export default MintWhitelist;
