// Import Swiper React components

import "./memberBenefitsSider.scss";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Slider from "react-slick";
import styled from "styled-components";
// import required modules

import TeamCard from "../homepage components/team-card/TeamCard";
import "./memberBenefitsSider.scss";
import { useSelector } from "react-redux";

const MemberBenefitSlider = () => {
  const RTCGTeams = useSelector((state) => state?.cmsPageSetting?.teamSection);
  console.log("TEAM", RTCGTeams);
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1580,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 2,
          infinite: true,
        },
      },

      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1190,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },

      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 690,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 0,
        },
      },
    ],
  };
  // const RTCGTeams = [
  //   {
  //     id: 1,
  //     name: "Baumgardner",
  //     title: "Nulla et nisi massa",
  //   },
  //   {
  //     id: 2,
  //     name: "Geoffey Pement",
  //     title: "Nulla et nisi massa",
  //   },
  //   {
  //     id: 3,
  //     name: "David Williams",
  //     title: "Nulla et nisi massa",
  //   },
  //   {
  //     id: 4,
  //     name: "Jeo Mortallaro",
  //     title: "Nulla et nisi massa",
  //   },
  //   {
  //     id: 5,
  //     name: "Julia Aesthetics",
  //     title: "Nulla et nisi massa",
  //   },
  //   {
  //     id: 6,
  //     name: "Bianka Kamber",
  //     title: "Nulla et nisi massa",
  //   },
  //   {
  //     id: 7,
  //     name: "Katarina Marjanovic",
  //     title: "Nulla et nisi massa",
  //   },
  //   {
  //     id: 8,
  //     name: "Damon Miller",
  //     title: "Nulla et nisi massa",
  //   },
  // ];
  return (
    <>
      <Wrapper className=" w-full px-10 my-4 member_benefit_slider">
        <Slider {...settings}>
          {RTCGTeams?.data?.map((team, index) => (
            <TeamCard key={index} team={team} />
          ))}
        </Slider>
      </Wrapper>
    </>
  );
};

export default MemberBenefitSlider;

const Wrapper = styled.div`
  .slick-list {
    position: relative;
    display: block !important;
    overflow: hidden;
    padding: 0 10px;
    width: 100%;
  }
  .slick-prev {
    left: -40px;
  }
  .slick-prev:before {
    background-image: url(/images/previous_btn.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 40px;
    color: transparent;
  }
  .slick-next:before {
    background-image: url(/images/next_btn.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 40px;
    color: transparent;
  }
  .slick-slider {
    padding: 0 20px;
  }
  @media (max-width: 992px) {
    .slick-next:before,
    .slick-prev:before {
      font-size: 30px;
    }
    .slick-slider {
      padding: 0 10px;
    }
  }
  @media (max-width: 992px) {
    .slick-list {
      height: 300px;
    }
  }
`;
