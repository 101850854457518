import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'

function ColorPicker(props) {
    const { value } = props
    const [showPicker, setshowPicker] = useState(false)
    const [color, setColor] = useState({
        r: '225',
        g: '155',
        b: '99',
        a: '2',
    })

    useEffect(() => {
        if (value) {
            let [r, g, b] = value.match(/\w\w/g).map(x => parseInt(x, 16));
            let color = {
                r: r,
                g: g,
                b: b,
                a: '2',
            }
            setColor(color)
        }
    }, [value]);

    const onClick = () => {
        setshowPicker(!showPicker)
    };

    const onClose = () => {
        setshowPicker(false)
    };

    const onChange = (color) => {
        setColor(color.rgb)
        const colors = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
        const rgba = colors.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
        const hex = `#${((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)}`;
        props.onChange(hex)
    };


    const styles = reactCSS({
        'default': {
            color: {
                width: '40px',
                height: '15px',
                borderRadius: '3px',
                background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
            },
            popover: {
                position: 'absolute',
                zIndex: '3',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
            swatch: {
                padding: '6px',
                background: '#ffffff',
                borderRadius: '2px',
                cursor: 'pointer',
                display: 'inline-block',
                boxShadow: '0 0 0 1px rgba(0,0,0,.2)',
            },
        },
    });

    return (
        <div>
            <div style={styles.swatch} onClick={onClick}>
                <div style={styles.color} />
            </div>
            {showPicker ? <div style={styles.popover}>
                <div style={styles.cover} onClick={onClose} />
                <SketchPicker color={color} onChange={onChange} />
            </div> : null}

        </div>
    )
}

export default ColorPicker