import axios from "axios";
import { get_all_orders } from "../../api/Api-list";
import { STORE_ORDER_DATA } from "../types/myOrderType";

const getAllOrders = (orderStatus = "") => {
  return async (dispatch) => {
    const app_token = localStorage.getItem("tiger-token");

    const config = {
      headers: {
        Authorization: `Bearer ${app_token}`,
      },
      params: {
        orderStatus: orderStatus || undefined,
      },
    };

    try {
      const response = await axios.get(get_all_orders, config);
      if (response.status < 400) {
        dispatch({ type: STORE_ORDER_DATA, payload: response.data.orders });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export default getAllOrders;
