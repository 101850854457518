import React, { useState, useEffect } from "react";

import { Button, Modal, Form, Input } from "antd";
import { postRequest } from "../../../api/API";
import { verify } from "../../../api/Api-list";

import { useDispatch, useSelector } from "react-redux";
import { resendVerifyLink } from "../../../redux/actions/userLoginRegisteraction";
import "./verify.scss";
import { useToasts } from "react-toast-notifications";
import { USER_OTP_VERIFICATION_SUCCESS } from "../../../redux/types/usertypes";

const VerifyModal = ({
  verifyModalVisible,
  hideverifyModal,
  setRegisterModalVisible,
  email,
  otpTime,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { addToast } = useToasts();
  // const navigate = useNavigate();
  const [form] = Form.useForm();
  const [resendbutton, setResendbutton] = useState(false);
  const [timer, setTimer] = useState(0);
  // create a function to count 5 minutes and show the timer

  const resigteredUserEmail = useSelector(
    (state) => state.userProfile.registrationEmail
  );

  const countDown = (count) => {
    let minutes = count;
    let seconds = 0;
    let timer = setInterval(() => {
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(timer);
        } else {
          minutes--;
          seconds = 60;
        }
      }
      seconds--;
      seconds = seconds < 0 ? "0" : seconds;

      setTimer(`${minutes}:${seconds}`);
      if (seconds === 0 && minutes === 0) {
        setTimer(0);
        setResendbutton(true);
      }
      // console.log(minutes + ":" + seconds, "counter");
    }, 1000);
  };
  useEffect(() => {
    if (otpTime !== 0) {
      countDown(otpTime);
    }
  }, [otpTime]);
  const dispatch = useDispatch();

  const ResendOtp = () => {
    setTimer(0);
    countDown(2);
    setResendbutton(false);
    dispatch(resendVerifyLink(email));
  };

  useEffect(() => {
    setInterval(() => {
      if (timer === 0 && resendbutton === true) {
        ResendOtp();
      }
    }, 1000);
  }, []);
  const hideModal = () => {
    hideverifyModal();
  };

  // const hideRegisterModal = () => {
  //   setRegisterModalVisible(false);
  // };
  const submitRegister = () => {
    form
      .validateFields()
      .then(async (values) => {
        // values.email = email;
        try {
          await postRequest(verify, {
            ...values,
            email: resigteredUserEmail,
          })
            .then((res) => {
              // console.log(res);
              dispatch({ type: USER_OTP_VERIFICATION_SUCCESS });
              form.resetFields();
              setLoading(false);
              setError("");
              hideModal();
              addToast("Your Account has been Verified", {
                appearance: "success",
                autoDismiss: true,
                // position: "top-center",
              });
            })
            .catch((err) => {
              console.log("errror", err.response.data.msg);
              setError(err.response.data.msg);
              setLoading(true);
              addToast(err.response.data.msg, {
                appearance: "error",
                autoDismiss: true,
                // position: "top-center",
              });
            });
        } catch (err) {
          console.log("register err", err);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  return (
    <>
      <Modal
        visible={verifyModalVisible}
        title="Verify Email"
        okText="Verify Email"
        cancelText="no"
        onCancel={hideModal}
        className="login_modal v-modal"
      >
        <h5 className="text-white text-center text-[1rem] my-4">
          Enter Otp to Verify Your Account
        </h5>

        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
          onFinish={submitRegister}
        >
          {/* {loading && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )} */}

          <Form.Item
            name="otp"
            type="number"
            placeholder="Enter Otp"
            rules={[
              { required: true, message: "Enter Valid otp" },
              ({ getFieldValue }) => ({
                validateFields(value) {
                  if (value === "") {
                    return Promise.reject("Enter Your Otp");
                  } else if (typeof value !== "number") {
                    return Promise.reject("Enter a valid Otp");
                  } else if (value.length < 6) {
                    return Promise.reject("Otp Length Must be  6 ");
                  }
                  return Promise.resolve();

                  // }
                },
              }),
            ]}
          >
            <Input
              placeholder="Enter Otp"
              type="number"
              className="bg-transparent py-2  !rounded-[0.4rem] !text-[#fff] !border-[#7C7B78] focus:!shadow-none "
            />
            {/* <input
              type="number"
              name="email"
              className="rounded-lg bg-transparent shadow appearance-none border border-gray-300 w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline login-textbox"
              placeholder="Otp"
            /> */}
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="Register-form-button w-full  text-center login_button"
            >
              Submit
            </Button>
          </Form.Item>
          {/* <div className="!flex justify-between">
            <div className="text-white">
              <p className="text-white">
                {timer===  ? (
                  <span>Resend Otp</span>
                ) : (
                  <span>Resend Otp in {timer}</span>
                )}
                {timer === 0 ? (
                  <span>Resend Otp</span>
                ) : (
                  <span>Resend Otp in {timer}</span>
                )}
              </p>
            </div>
            {resendbutton ? (
              <Button
                onClick={ResendOtp}
                className="!bg-[#FF8500]  !border-none text-white"
              >
                Resend Otp
              </Button>
            ) : (
              <Button className="!bg-[#ff840081]  !border-none text-white">
                Resend Otp
              </Button>
            )}
          </div> */}
        </Form>
      </Modal>
    </>
  );
};

export default VerifyModal;
