import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "antd";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import editIcon from "../../images/edit.png";
import { FaUsers } from 'react-icons/fa'
import { AiFillSetting } from "react-icons/ai";
import { PropTypes } from "prop-types";
import { fetchSettingPageInitiate } from "../../../redux/actions/cmsSettingAction";
import "./teamPageList.scss";
import Swal from "sweetalert2";
import { delete_team_member } from "../../../api/Api-list";
import axios from "axios";
import deleteIcon from "../../images/delete.png";



const TeamPageList = () => {
  const [page, setPage] = useState(1);
  const selector = useSelector((state) => state?.cmsPageSetting?.teamSection);
  console.log(selector, "teamSection");
  const activeSettingId = selector?.data?.length;
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const Admin_Token = localStorage.getItem("tiger");

  // const Admin_Token = localStorage.getItem("tiger");
  useEffect(() => {
    dispatch(fetchSettingPageInitiate());
  }, [dispatch, activeSettingId]);


  const deleteMember = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Admin_Token}`,
       
      },
   
    };
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Successfully!", "Category Deleted Successfully", "success");

        axios
          .delete(delete_team_member(id), config)
          .then((res) => {

            if (res.status === 200) {
              dispatch(fetchSettingPageInitiate())
              navigate('/admin/team')
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };



  let columns = [
    {
      title: "Sr no",
      dataIndex: "sr_no",
      render: (item, data, index) => {
        return <>{(page - 1) * 10 + index + 1}</>;
      },
    },
    {
      title: "Name",
      dataIndex: "firstName",
      render: (item, data) => {
        return <span>{item} {data?.lastName}</span>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   render: (item) => {
    //     return <span>{item}</span>
    //   }
    // },
    {
      title: "Twitter",
      dataIndex: "twitter",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "Linked In",
      dataIndex: "linkedin",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "Instagram",
      dataIndex: "instagram",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "Profile",
      dataIndex: "image",
      render: (item) => {
        return (
          <>
            <img
              src={item}
              style={{ width: "100%", maxWidth: "80px" }}
              alt="pro"
            />
          </>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "actions",
      render: (item, data, index) => {
        return (
         <>
          <div className="d-flex align-items-center team-list">
            <Link to={`team-form/${data._id}`}>
              <img src={editIcon} alt="Edit" style={{ height: "15px" }} />
            </Link>&nbsp;&nbsp;&nbsp;<img src={deleteIcon} alt="Edit" style={{ height: "15px" }} onClick={() => deleteMember(data?._id)} />
          </div>
         </>
        );
      },
    },
  ];

  return (
    <>
      <div className="team-list">
        {true && (
          <Row gutter={16}>
            <Col className="teamPageHdn" sm={24} xl={12}>
              <div className="flex items-center justify-between">
                <h2 className="m-0"><FaUsers size={"30px"} color={"Black"} /> Manage Team</h2>
                <Link to="add">
                <button className="add-btn">Add Team Member</button>
              </Link>
              </div>
            </Col>
            <Col span={24} className="mt-4 user-table">
              <Table
                rowKey="_id"
                rowSelection={null}
                key={selector?.data?.length}
                columns={columns}
                defaultCurrent={1}
                dataSource={selector?.data ? selector?.data : []}
                scroll={{ x: 1000 }}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  },
                }}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
TeamPageList.propTypes = {
  users: PropTypes.array,
};

export default TeamPageList;
