import React, { useState, useEffect } from "react";
import DropIn from "braintree-web-drop-in-react";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import {
  cart_payment,
  generate_braintree_token,
  get_all_cart_items,
  get_signature,
} from "../../../api/Api-list";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTotalCartItem } from "../../../redux/actions/cartSectionAction";
import "./payment.scss";
import styled from "styled-components";
import paymentprocessorabi from "../../../abis/paymentprocessorabi";
import {
  handleMetaMaskConnection,
  switchNetwork,
} from "../../../redux/actions/blockchain";
import Web3 from "web3";
import { useCookies } from "react-cookie";
const { ethereum } = window;

const Payment = () => {
  const [instance, setInstance] = useState(null);
  const [clientToken, setClientToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [cart, setCart] = useState(null);
  const [cartDetails, setCartDetails] = useState(null);
  const [couponValue, setCouponValue] = useState(0);
  const [couponType, setCouponType] = useState("");
  const [oneEth, setOneEth] = useState(0);

  const isAuthenticated = useSelector(
    (state) => state.userProfile.isAuthenticated
  );
  const { isWrongNetwork, isConnected, walletAddress } = useSelector(
    (state) => state.blockChain
  );

  const { addToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const app_token = localStorage.getItem("tiger-token");
  const rtgcCartId = localStorage.getItem("rtgcCartId");

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  useEffect(() => {
    getAllCartItems();
  }, []);

  const getAllCartItems = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(get_all_cart_items, config);
      if (response.status < 400) {
        setPaymentMethod(response.data?.cart?.paymentMethod);
        setSelectedPaymentMethod(() =>
          response.data?.cart?.paymentMethod === "both" ||
          response.data?.cart?.paymentMethod === "usd"
            ? "usd"
            : "eth"
        );
        setCart(response.data.cart);
        setCartDetails(response.data.details);
        setOneEth(response.data.oneETH);
        setCouponValue(() => {
          return !response.data?.cart?.coupon
            ? 0
            : response.data?.cart?.coupon?.value;
        });
        setCouponType(() => {
          return !response.data?.cart?.coupon
            ? ""
            : response.data?.cart?.coupon?.type;
        });
        if (response.data?.cart?.paymentMethod === "eth") return;
        await generateBraintreeToken();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const generateBraintreeToken = async () => {
    try {
      const response = await axios.get(generate_braintree_token, config);
      if (response.status < 400) {
        setIsLoading(false);
        setClientToken(response.data.braintreeToken);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      addToast(err.response.data.msg, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const pay = async () => {
    try {
      const { nonce } = await instance.requestPaymentMethod();
      // console.log(nonce);
      // return;
      setIsProcessing(true);
      setIsProcessing(true);
      const response = await axios.patch(cart_payment(nonce), {}, config);
      if (response.status < 400) {
        dispatch(getTotalCartItem(app_token, isAuthenticated, rtgcCartId));
        addToast(response.data.msg, {
          appearance: "success",
          autoDismiss: true,
        });
        navigate(`/payment/success/${response.data.orderId}`);
        setIsProcessing(false);
        setIsProcessing(false);
      }
    } catch (err) {
      dispatch(getTotalCartItem(app_token, isAuthenticated, rtgcCartId));
      addToast(err.response.data.msg, {
        appearance: "error",
        autoDismiss: true,
      });
      setIsProcessing(false);
      setIsProcessing(false);
    }
  };

  if (ethereum) {
    ethereum.on("chainChanged", (chainId) => {
      window.location.reload();
    });

    ethereum.on("accountsChanged", (accounts) => {
      window.location.reload();
    });
  }

  const connectToMetaMask = async () => {
    if (ethereum) {
      dispatch(handleMetaMaskConnection());
    } else {
      //setMetaMaskAvailable(false);
    }
  };

  const changeNetwork = async () => {
    await dispatch(switchNetwork)();
  };

  const getSignatureAndPay = async () => {
    try {
      const {
        data: {
          payload: { amount, orderId, tokenAddress, tokenType },
          signature,
        },
      } = await axios.get(get_signature, config);

      await ethereum.enable();
      const web3 = new Web3(ethereum);
      const methods = new web3.eth.Contract(
        paymentprocessorabi.abi,
        process.env.REACT_APP_PAYMENT_PROCESSOR
      ).methods;

      const { transactionHash } = await methods
        .payForProduct(orderId, [amount, tokenAddress, tokenType], signature)
        .send({ from: walletAddress, value: amount });

      // Todo send this transaction hash to validate if transaction is processed successfully of not
      console.log(transactionHash);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="py-8 payment-page-body">
      <div className="py-4 flex flex-col items-center justify-center">
        <h2 className="text-[40px] font-bold text-white mt-3 mb-3 text-center pt-[4rem] payment-page-title">
          Payment
        </h2>
        <img
          src="images/dot_border.png"
          alt="borderimage"
          className="text-center d-inline"
        />
      </div>
      {isLoading && !clientToken ? (
        <div className="loader text-center">
          <img
            src="images/loading-icon.jpg"
            alt="Loader"
            className="loading-img"
          />
          <p className="text-white">Loading...</p>
        </div>
      ) : isProcessing ? (
        <div className="loader text-center">
          <img
            src="images/loading-icon.jpg"
            alt="Loader"
            className="loading-img"
          />
          <p className="text-white">Please don't refresh or close this page</p>
        </div>
      ) : !isLoading && !isProcessing && clientToken ? (
        <>
          {paymentMethod === "both" && (
            <div className="flex items-center justify-center mt-4">
              <div className="flex items-center justify-center mr-7">
                <input
                  type="radio"
                  id="cardPayment"
                  checked={selectedPaymentMethod === "usd"}
                  onChange={() => {
                    setSelectedPaymentMethod("usd");
                  }}
                  className="w-4 h-4"
                />
                <label
                  htmlFor="cardPayment"
                  className=" text-gray-500 font-bold ml-2 text-lg"
                >
                  Card Payment
                </label>
              </div>
              <div className="flex items-center justify-center mr-7">
                <input
                  type="radio"
                  id="ethPayment"
                  checked={selectedPaymentMethod === "eth"}
                  onChange={() => {
                    setSelectedPaymentMethod("eth");
                  }}
                  className="w-4 h-4"
                />
                <label
                  htmlFor="ethPayment"
                  className=" text-gray-500 font-bold ml-2 text-lg"
                >
                  Ethereum Payment
                </label>
              </div>
            </div>
          )}
          {selectedPaymentMethod === "usd" ? (
            <div className="proced-pymnt-box">
              <DropIn
                options={{ authorization: clientToken }}
                onInstance={(instance) => {
                  setInstance(instance);
                }}
              />
              {instance && (
                <div className="proced-pay-btn">
                  <button
                    className="text-lg  transition-all ease-in duration-150 hover:bg-orange-600 font-bold text-center w-full bg-[#FF8500] text-white py-3 rounded-full"
                    onClick={pay}
                  >
                    Proceed to Pay
                  </button>
                </div>
              )}
            </div>
          ) : selectedPaymentMethod === "eth" ? (
            <Wrapper className="max-w-[29.375rem] mx-auto w-full  text-white order_summary mt-10">
              <div className="w-full flex flex-col bg-[#191919] bg-opacity-75 text-white rounded-xl p-4">
                <div className="text-white font-bold text-[20px] ">
                  Order Summary
                </div>
                <span className="text-[15px] text-gray-500">
                  {cart?.items?.length}{" "}
                  {cart?.items?.length > 1 ? "Products" : "Product"}
                </span>

                <div className=" product_detail">
                  <hr className="border-b-black border-white" />
                  <div className="flex justify-between">
                    <span>Product total</span>
                    <span className="flex items-center justify-center">
                      ${cartDetails?.totalPriceAmount?.toFixed(2)} ({" "}
                      <img
                        src="/images/eth-icon.png"
                        className="object-contain w-3"
                        alt="ethicon"
                      />
                      {(cartDetails?.totalPriceAmount / oneEth).toFixed(5)})
                    </span>
                  </div>
                  <hr className="border-b-black border-white " />
                  <div className="flex justify-between">
                    <span>Tax({cart?.address?.state?.totalTaxAmount}%)</span>
                    <span>
                      $
                      {(
                        cartDetails?.totalPriceAmount *
                        (cart?.address?.state?.totalTaxAmount / 100)
                      ).toFixed(2)}
                    </span>
                  </div>
                  <hr className="border-b-black border-white" />
                  <div className="flex justify-between">
                    <span>Shipping charge</span>
                    <span>${cartDetails?.shippingAmount?.toFixed(2)}</span>
                  </div>
                  <hr className="border-b-black border-white" />
                  <div className="flex justify-between">
                    <span>Sub total</span>
                    <span>
                      $
                      {(
                        cartDetails?.shippingAmount +
                        cartDetails?.totalPriceAmount *
                          (cart?.address?.state?.totalTaxAmount / 100) +
                        cartDetails?.totalPriceAmount
                      ).toFixed(2)}
                    </span>
                  </div>
                  <hr className="border-b-black border-white " />
                  {cart?.coupon && (
                    <>
                      <div className="w-full flex mb-2 mt-1 items-center justify-between">
                        <span>
                          <span>Coupon</span>
                        </span>
                        {couponType !== "" && (
                          <span>
                            {couponType === "fixed"
                              ? "$"
                              : couponType === "percent"
                              ? ""
                              : ""}
                            {couponValue}
                            {couponType === "fixed"
                              ? ""
                              : couponType === "percent"
                              ? "%"
                              : ""}
                          </span>
                        )}
                      </div>
                      <hr className="border-b-black border-white" />
                    </>
                  )}
                  <div className="flex justify-between sub_total_order">
                    <span>Sub total</span>
                    <span className="flex items-center justify-center">
                      $
                      {couponType === ""
                        ? (
                            cartDetails?.shippingAmount +
                            cartDetails?.totalPriceAmount *
                              (cart?.address?.state?.totalTaxAmount / 100) +
                            cartDetails?.totalPriceAmount
                          ).toFixed(2)
                        : couponType === "fixed"
                        ? (
                            cartDetails?.shippingAmount +
                            cartDetails?.totalPriceAmount *
                              (cart?.address?.state?.totalTaxAmount / 100) +
                            cartDetails?.totalPriceAmount -
                            couponValue
                          ).toFixed(2)
                        : couponType === "percent"
                        ? (
                            cartDetails?.shippingAmount +
                            cartDetails?.totalPriceAmount *
                              (cart?.address?.state?.totalTaxAmount / 100) +
                            cartDetails?.totalPriceAmount -
                            ((cartDetails?.totalPriceAmount *
                              (cart?.address?.state?.totalTaxAmount / 100) +
                              cartDetails?.totalPriceAmount) *
                              couponValue) /
                              100
                          ).toFixed(2)
                        : ""}{" "}
                      (
                      <img
                        src="/images/eth-icon.png"
                        className="object-contain w-3"
                        alt="ethicon"
                      />
                      {couponType === ""
                        ? (
                            (cartDetails?.shippingAmount +
                              cartDetails?.totalPriceAmount *
                                (cart?.address?.state?.totalTaxAmount / 100) +
                              cartDetails?.totalPriceAmount) /
                            oneEth
                          ).toFixed(5)
                        : couponType === "fixed"
                        ? (
                            (cartDetails?.shippingAmount +
                              cartDetails?.totalPriceAmount *
                                (cart?.address?.state?.totalTaxAmount / 100) +
                              cartDetails?.totalPriceAmount -
                              couponValue) /
                            oneEth
                          ).toFixed(5)
                        : couponType === "percent"
                        ? (
                            (cartDetails?.shippingAmount +
                              cartDetails?.totalPriceAmount *
                                (cart?.address?.state?.totalTaxAmount / 100) +
                              cartDetails?.totalPriceAmount -
                              ((cartDetails?.totalPriceAmount *
                                (cart?.address?.state?.totalTaxAmount / 100) +
                                cartDetails?.totalPriceAmount) *
                                couponValue) /
                                100) /
                            oneEth
                          ).toFixed(5)
                        : ""}
                      )
                    </span>
                  </div>
                  <hr className="border-b-black border-white" />
                  <div className="flex justify-center checkout_button_order mt-5">
                    {/* <button
                      className="text-lg  transition-all ease-in duration-150 hover:bg-orange-600 font-bold text-center w-full bg-[#FF8500] text-white py-2 rounded-full mx-1"
                      onClick={getSignature} // handler
                    >
                      Pay with ETH
                    </button> */}
                    <button
                      className="text-lg  transition-all ease-in duration-150 hover:bg-orange-600 font-bold text-center w-full bg-[#FF8500] text-white py-2 rounded-full mx-1"
                      onClick={
                        !isConnected
                          ? connectToMetaMask
                          : isConnected && isWrongNetwork
                          ? changeNetwork
                          : getSignatureAndPay
                      }
                    >
                      {!isConnected
                        ? "Connect Wallet"
                        : isConnected && isWrongNetwork
                        ? "Wrong Network! Click To Go Right Network"
                        : "Pay"}
                    </button>
                  </div>
                </div>
              </div>
            </Wrapper>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default Payment;

const Wrapper = styled.div``;
