import React, { useState, useEffect } from "react";
// Import Swiper React components

import "./detailSlider.scss";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Slider from "react-slick";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../../proshop-page/product/ProductCard";
import {
  fetchActiveProducts,
  setProduct,
} from "../../../redux/actions/productAction";
import { useLocation, useNavigate } from "react-router-dom";
import { remove_fav_product, update_fav_product } from "../../../api/Api-list";
import axios from "axios";
import { message } from "antd";

const DetailSlider = ({ relatedProducts, productId, setSize, oneEth }) => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.allProducts?.products);
  const actProducts = useSelector(
    (state) => state.allProducts?.getactiveproducts?.data
  );
  // const isAuth = useSelector((state) => state.userProfile?.isAuthenticated);

  const app_token = localStorage.getItem("tiger-token");

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  useEffect(() => {
    dispatch(fetchActiveProducts({ categoryId: products?.categoryId?._id }));
  }, [products]);

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1670,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1470,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 975,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 855,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 698,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 590,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <div className="related-product-slide">
      <Wrapper className="w-full px-10 my-4 ">
        {relatedProducts?.length >= 4 ? (
          <Slider {...settings}>
            {relatedProducts?.map((item) => {
              return (
                <ProductCard
                  data={item}
                  productId={productId}
                  setSize={setSize}
                  oneEth={oneEth}
                />
              );
            })}
          </Slider>
        ) : (
          <div className="flex justify-center items-start underFourItems">
            {relatedProducts?.map((item) => {
              return (
                <ProductCard
                  data={item}
                  productId={productId}
                  setSize={setSize}
                  oneEth={oneEth}
                />
              );
            })}
          </div>
        )}
      </Wrapper>
    </div>
  );
};

export default DetailSlider;
const Wrapper = styled.div`
  .slick-list {
    position: relative;
    display: block !important;
    overflow: hidden;
    padding: 0 10px;
    width: 100%;
  }
  .slick-prev {
    left: -40px;
  }
  .slick-prev:before {
    background-image: url(/images/previous_btn.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 40px;
    color: transparent;
  }
  .slick-next:before {
    background-image: url(/images/next_btn.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 40px;
    color: transparent;
  }
  .slick-slider {
    padding: 0 20px;
  }
  @media (max-width: 992px) {
    .slick-next:before,
    .slick-prev:before {
      font-size: 30px;
    }
    .slick-slider {
      padding: 0 10px;
    }
  }
`;
// {...(relatedProducts?.length > 1 ? { ...settings } : "")}
