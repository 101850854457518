import React, { useEffect, useState } from "react";
import { Form, Input, message, Modal, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
// import { editUserByAdminInitiate } from "../../../redux/actions/adminUsersActions";
import { get_home_page_team_section } from "../../../api/Api-list";
import axios from "axios";
import { Button } from "../../admin-components/Buttons/Button";
import { addHomeTeamSectionInitiate, updateHomeTeamSectionInitiate } from "../../../redux/actions/cmsSettingAction";
const AddTeamForm = () => {

  const Admin_Token = localStorage.getItem("tiger");
  const [form] = Form.useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [data, setData] = useState({});
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [imgLink, setImgLink] = useState("");

  const [imageVal, setImageVal] = useState(false);
  const [fileList, setFileList] = useState([
    // {
    //   uid: "1652872389210",
    //   name: "1652872389210.png",
    //   status: "done",
    //   url: imgLink,
    // },
  ]);

  // console.log(id);

  const submitUpdate = async () => {
    const imgdata = {
      image: null,
    };

    if (imageVal && fileList?.length) {
      imgdata.image = await imgtoBase64(fileList[0].originFileObj);
    } else {
       imgdata.image = null;
    }

    if(imgdata?.image == null){
        message.error(`Image Field is Required`);

      return
    }
  

    form
      .validateFields()
      .then(async (values) => {
        dispatch(
          addHomeTeamSectionInitiate(
            { ...values, ...imgdata },
            Admin_Token,
            id,
            history
          )
        );
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  // conver image to base64
  const imgtoBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });
  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  // console.log(fileList, "fileList");
  const handleChange = ({ fileList: newFileList }) => {
    setImageVal(true);
    // select last image using filter function
    const lastImage = newFileList.filter(
      (file, index) => index === newFileList.length - 1
    );

    setFileList(lastImage);
  };

  // antd before upload function
  const beforeUpload = () => false;

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="mt-1">Upload</div>
    </div>
  );

  const inputclass = "bg-transparent !py-[0.7rem] !rounded-[0.3rem] text-black";
//   useEffect(() => {
//     async function getdata() {
//       const config = {
//         headers: { Authorization: `Bearer ${Admin_Token}` },
//       };
//       await axios
//         .get(`${get_home_page_team_section}/${id}`, config)
//         .then((res) => {
//           // console.log("ewrwe", res);
//           setData(res.data.data);
//           form.setFieldsValue({
//             ...data,
//           });
//         })
//         .catch((err) => {
//           console.log(err, "err");
//         });
//     }
//     getdata();
//     setImgLink(data.image);
//     setFileList([
//       {
//         uid: "1652872389210",
//         name: "1652872389210.png",
//         status: "done",
//         url: data.image,
//       },
//     ]);
//     // dispatch(viewUserByAdminInitiate(id));
//   }, [data?.firstName]);

  return (
    <>
      <div className="edit_user_form ">
        <h2 className="text-center ">Add Team Member</h2>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          onFinish={submitUpdate}
        >
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: "Enter your First Name" }]}
            className="text-red-100"
            initialValue={data?.firstName}
          >
            <Input
              type="text"
              value={data?.firstNames}
              className={inputclass}
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            initialValue={data.lastName}
            rules={[{ required: true, message: "Enter your Last Name" }]}
          >
            <Input className={inputclass} />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email Address"
            initialValue={data.email}
            rules={[
              { required: true, message: "Enter your Email" },
              {
                type: "email",
                message: "please enter valid input",
              },
            ]}
          >
            <Input className={inputclass} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Enter your description" }]}
            className="text-red-100"
            initialValue={data?.description}
          >
            <Input
              type="text"
              value={data?.description}
              className={inputclass}
            />
          </Form.Item>
          <Form.Item
            name="twitter"
            label="Twitter Link"
            initialValue={data.twitter}
            rules={[
              { required: true, message: "Enter your Twitter Link" },
              {
                type: "url",
                message: "please enter valid input",
              },
            ]}
          >
            <Input className={inputclass} />
          </Form.Item>

          <Form.Item
            name="instagram"
            label="Instagram Link"
            initialValue={data.instagram}
            rules={[
              { required: true, message: "Enter your Instagram Link" },
              {
                type: "url",
                message: "please enter valid input",
              },
            ]}
          >
            <Input className={inputclass} />
          </Form.Item>

          <Form.Item
            name="linkedin"
            label="LinkedIn Link"
            initialValue={data.email}
            rules={[
              { required: true, message: "Enter your LinkedIn Link" },
              {
                type: "url",
                message: "please enter valid input",
              },
            ]}
          >
            <Input className={inputclass} />
          </Form.Item>



          <Upload
            listType="picture-card"
            fileList={fileList}
            multiple={false}
            onPreview={handlePreview}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            name="image"
            className="avatar-uploader"
            id="image"
            type="file"
          >
            {fileList.length > 1 ? null : uploadButton}
          </Upload>
          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" className="w-full" src={previewImage} />
          </Modal>

          <Form.Item>
            <Button
              type="submit"
              className="w-full py-2 rounded-md font-semibold text-[1.1rem] !bg-orange-500  text-center text-white hover:!border-none focus:!border-none"
            >
              Add Member
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default AddTeamForm;
