import axios from "axios";
import { get_single_page_sections } from "../../api/Api-list";

export const aboutPageInitiate = (page) => {
  return function (dispatch) {
    // const config = {
    //   headers: { Authorization: `Bearer ${Admin_Token}` },
    // };
    axios
      .get(`${get_single_page_sections}/${page}`)
      .then((response) => {
        dispatch({
          type: `GET_${page.toUpperCase()}_PAGE`,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};
