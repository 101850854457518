import React, { useEffect } from "react";
import { Layout } from "antd";
import { useNavigate, Outlet, useParams } from "react-router-dom";
import { fetchAdminProfile } from "../redux/actions/adminLoginRegisterActions";
import { useDispatch } from "react-redux";
import AdminSidebar from "../admin/admin-components/admin-sidebar/Admin_Sidebar";
import AdminHeader from "../admin/admin-components/admin-header/Admin_header";

const { Header, Content, Footer } = Layout;

const useAuth = () => {
  const Admin_Token = localStorage.getItem("tiger");

  if (Admin_Token) {
    return true;
  } else {
    return false;
  }
};

const AdminLayout = (props) => {
  const Admin_Token = localStorage.getItem("tiger");
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAdminProfile(Admin_Token));
  }, []);

  const navigate = useNavigate();

  const auth = useAuth();
  useEffect(() => {
    if (!auth) {
      navigate("/admin-login");
    }
  }, [auth, navigate]);

  return (
    <>
      <Layout>
        {/* <Adminlogin /> */}
        <header className="p-0">
          <AdminHeader />
        </header>
        <Layout>
          <AdminSidebar />
          <Content
            className="!p-[35px] bg-[#fcfcfc] "
            style={{
              minHeight: "calc(100vh - 160px) ",
            }}
          >
            {auth ? <Outlet /> : navigate("/admin-login")}
            {/* OUTLET */}
          </Content>
        </Layout>
      </Layout>
      <Footer style={{ textAlign: "center" }}>
        Copyright &#169; 2022. Roaring Tiger Golf Club. All Rights Reserved
      </Footer>
    </>
  );
};
export default AdminLayout;
