import axios from "axios";
const API =  process.env.REACT_APP_API_URL;
const axiosClient = axios.create({
  baseURL: API
});

axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*"
};

axiosClient.defaults.timeout = 10000;
axiosClient.interceptors.request.use(
  function (_config) {
    _config.headers.Authorization =
      "Bearer " + localStorage.getItem("tiger-token");
    return _config;
  },
  null,
  { synchronous: true }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      //Add Logic to
      //1. Redirect to login page or
      //2. Request refresh token
    }
    return Promise.reject(error);
  }
);

export function postRequest(URL, payload) {
  return axiosClient.post(URL, payload);
}

export function getRequest(URL) {
  return axiosClient.get(URL);
}
