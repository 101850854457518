import React, { useEffect, useState } from "react";
import {
  Input,
  Form,
  Radio,
  DatePicker,
  Space,
  Switch,
  message,
  InputNumber,
} from "antd";
import { get_Single_Coupons, update_Coupons } from "../../../api/Api-list";
import axios from "axios";
import * as moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
const options = ["Fixed", "Percent"];
const EditCoupons = () => {
  const id = useParams();
  const [startDate, setStartDate] = useState(null);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [discountType, setDiscountType] = useState("Fixed");
  console.log(discountType);
  const [multiuserStatus, setMultiuserStatus] = useState(false);
  const [isActiveStatus, setIsActiveStatus] = useState(false);
  const inputclass = "bg-transparent !py-[0.7rem] !rounded-[0.3rem] text-black";
  const app_token = localStorage.getItem("tiger");
  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };
  const format2 = "YYYY-MM-DD";
  useEffect(() => {
    getSingleData();
  }, []);
  const getSingleData = async () => {
    try {
      const response = await axios.get(get_Single_Coupons(id.id), config);
      if (response.status < 400) {
        const data = response.data.data;
        form.setFieldsValue({
          couponCode: data?.code,
          discountType:
            (data?.type).charAt(0).toUpperCase() + (data?.type).slice(1),
          setDiscount: data?.value,
          numberOfUses: data?.numberOfUses,
          startDate: moment(data?.startDate, format2),
          expiryDate: moment(data?.expiryDate, format2),
        });
        setMultiuserStatus(data?.isMulti);
        setIsActiveStatus(data?.isActive);
        setStartDate(data?.startDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async () => {
    form
      .validateFields()
      .then(async (e) => {
        const values = await form.getFieldsValue();
        if (values.discountType === "Percent") {
          if (Number(values.setDiscount) > 100) {
            message.info("value must be less than or equal to 100");
            return;
          }
        }
        try {
          const payload = {
            code: values.couponCode,
            type: values.discountType.toLowerCase(),
            value: values.setDiscount,
            numberOfUses: values.numberOfUses,
            startDate: moment(values.startDate).format(format2),
            expiryDate: moment(values.expiryDate).format(format2),
            isMulti:
              values.multiuserstatus === undefined
                ? multiuserStatus
                : values.multiuserstatus,
            isActive:
              values.activestatus === undefined
                ? isActiveStatus
                : values.activestatus,
          };
          console.log(payload);
          const response = await axios.patch(
            update_Coupons(id.id),
            payload,
            config
          );
          if (response.status < 400) {
            message.success(response.data.msg);
            getSingleData();
            navigate("/admin/get_all_coupons");
          }
        } catch (error) {
          message.error(error.response.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="container-fluid edit_product">
        <div className="row">
          <h2 className="cms-title">Edit Coupon </h2>
          <div className="col-12 col-sm-12 col-md-12 bg-white shadow-sm p-5 cms-box">
            <Form layout="vertical" form={form}>
              <Form.Item
                name="couponCode"
                type="text"
                label="Coupon Code"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Coupon Code" className={inputclass} />
              </Form.Item>
              <Form.Item name="discountType" label="Discount Type">
                <Radio.Group
                  options={options}
                  onChange={(e) => setDiscountType(e.target.value)}
                  value={discountType}
                  defaultValue="Fixed"
                />
              </Form.Item>
              <Form.Item
                name="setDiscount"
                type="text"
                label={
                  discountType === "Fixed"
                    ? "Set Discount amount"
                    : "Set Discount percentage"
                }
                rules={[{ required: true, message: "This field is required" }]}
              >
                <InputNumber
                  placeholder="Set Discount"
                  type="number"
                  min={1}
                  style={{ width: "100%", padding: 6, borderRadius: 5 }}
                />
              </Form.Item>

              <Form.Item
                name="numberOfUses"
                type="text"
                label="Number Of Uses"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <InputNumber
                  placeholder="Number Of Uses"
                  type="number"
                  min={1}
                  style={{ width: "100%", padding: 6, borderRadius: 5 }}
                />
              </Form.Item>
              <Form.Item
                name="startDate"
                label="Start Date"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <DatePicker
                  style={{
                    width: "100%",
                    height: "46px",
                    borderRadius: "4px",
                  }}
                  format="YYYY-MM-DD"
                  onChange={(e) => setStartDate(e)}
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current < moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                />
              </Form.Item>
              <Form.Item
                name="expiryDate"
                label="Expiry Date"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <DatePicker
                  style={{
                    width: "100%",
                    height: "46px",
                    borderRadius: "4px",
                  }}
                  format="YYYY-MM-DD"
                  disabled={startDate === null ? true : false}
                  disabledDate={(d) =>
                    !d ||
                    d.isBefore(startDate) ||
                    d.isSameOrBefore("1960-01-01")
                  }
                />
              </Form.Item>
              <Form.Item name="multiuserstatus" label="Multiple Usage per user">
                <Switch
                  style={{ marginBottom: "15px" }}
                  checked={multiuserStatus}
                  onChange={() => setMultiuserStatus(!multiuserStatus)}
                />
              </Form.Item>
              <Form.Item name="activestatus" label="Coupon Active">
                <Switch
                  style={{ marginBottom: "15px" }}
                  checked={isActiveStatus}
                  onChange={() => setIsActiveStatus(!isActiveStatus)}
                />
              </Form.Item>
              <Form.Item>
                <button
                  type="submit"
                  className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] "
                  onClick={handleUpdate}
                >
                  Update Coupon
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCoupons;
