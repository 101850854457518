import React from "react";
import { Form, Input, message } from "antd";
import {
  get_all_countries,
  get_single_state,
  update_a_state,
} from "../../../../api/Api-list";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { useEffect } from "react";
import { useState } from "react";

const EditState = () => {
  const [countryOptions, setCountryOptions] = useState([]);

  const Admin_Token = localStorage.getItem("tiger");
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getAllCountry();
    getStateDetails();
  }, [id]);

  const config = {
    headers: {
      Authorization: `Bearer ${Admin_Token}`,
    },
  };

  const getStateDetails = async () => {
    try {
      const response = await axios.get(get_single_state(id), config);
      if (response.status < 400) {
        const data = response.data.data;
        form.setFieldsValue({
          countryName: { value: data.countryId, label: data.countryName },
          stateName: data.stateName,
          stateSortCode: data.stateSortCode,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllCountry = async () => {
    try {
      const response = await axios.get(get_all_countries, config);
      if (response.status < 400) {
        const countryOptions = response.data.countries.map((country) => {
          return {
            value: country._id,
            label: country.countryName,
          };
        });
        setCountryOptions(countryOptions);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const response = await axios.patch(
        update_a_state,
        {
          stateId: id,
          name: values.stateName,
          countryId: values.countryName.value,
          sortCode: values.stateSortCode,
        },
        config
      );
      if (response.status < 400) {
        message.success(response.data.msg);
        navigate("/admin/state");
      }
    } catch (err) {
      console.log(err);
      message.error(err.response.data.msg);
    }
  };

  return (
    <>
      <div className="container-fluid edit_product">
        <div className="row">
          <h2 className="cms-title">Edit State</h2>
          <div className="col-12 col-sm-12 col-md-12 bg-white shadow-sm p-5 cms-box">
            <Form layout="vertical" form={form} onFinish={handleSubmit}>
              <Form.Item
                name="countryName"
                label="Select Country"
                rules={[{ required: true, message: "Select a country" }]}
              >
                <ReactSelect
                  value={form.getFieldValue("countryName")}
                  onChange={(e) => {
                    console.log(e);
                  }}
                  options={countryOptions}
                />
              </Form.Item>

              <Form.Item
                name="stateName"
                label="State Name"
                type="text"
                rules={[{ required: true, message: "State name is required" }]}
              >
                <Input placeholder="State Name" />
              </Form.Item>

              <Form.Item
                name="stateSortCode"
                label="State Short Code"
                type="text"
                rules={[
                  { required: true, message: "State short code is required" },
                ]}
              >
                <Input placeholder="State Short Code" />
              </Form.Item>

              <Form.Item>
                <button
                  type="submit"
                  className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] mt-2"
                >
                  Edit State
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditState;
