import React from "react";
import { Helmet } from "react-helmet";

export default function Seo({title, desc, keywords}) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{ `RTGC  ${ title ? "|" : "" } ${ title }` } </title>
      <meta name="description" content={desc} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
}
