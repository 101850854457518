import { Row } from "antd";
import React, { useEffect } from "react";
import "./innerPage.scss";
import { BsCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { aboutPageInitiate } from "../../redux/actions/aboutPage";
import { useLocation } from "react-router-dom";
const InnerPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const raw = location.pathname.split("/");
  console.log(raw, "raw");
  const pageName = raw[raw.length - 1];

  const content = useSelector((state) => state?.aboutPage[pageName]) || [];

  useEffect(() => {
    dispatch(aboutPageInitiate(pageName));
  }, [location.pathname]);

  return (
    <div className="inner_page">
      <img
        src={
          content?.data &&
          content?.data
            ?.filter((t, i) => i === 0)
            ?.reduce((pre, curr) => curr, {})
            .image?.filter((t, i) => i === 0)
            ?.reduce((pre, curr) => curr, {}).item
        }
        className="w-full "
        alt=""
      />
      <div className="container">
        <Row>
          <div className="col-lg-12 col-md-12 col-12 inner_page_content">
            <h5 className="text-white text-Left ">
              <span className="inner_page_span"> RTGC </span>
              <BsCircleFill
                size={"5px"}
                className="inline-block ml-2 text-white"
              />
              <strong> {content?.data && content?.data[0]?.sectionName}</strong>
            </h5>
            <h2 className="font-bold text-white heading-pattern position-relative">
              {content?.data && content?.data[0]?.sectionName}
            </h2>
            <div>
              {content?.data &&
                content?.data[0]?.title?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    />
                  );
                })}
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default InnerPage;
