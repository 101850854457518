import React from "react";
import { useEffect } from "react";
import { Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  handleMetaMaskConnection,
  switchNetwork
} from "../../../../redux/actions/blockchain";
import { LOAD_NFT_IMAGE } from "../../../../redux/types/blockchainType";

const { ethereum } = window;

function MyNftsDetails() {
  const dispatch = useDispatch();

  const connectToMetaMask = async () => {
    dispatch(handleMetaMaskConnection());
  };

  const { nftDetails, isWrongNetwork } = useSelector(
    (state) => state.blockChain
  );

  const changeNetwork = async () => {
    dispatch(switchNetwork);
  };

  if (ethereum) {
    ethereum.on("chainChanged", (chainId) => {
      window.location.reload();
    });

    ethereum.on("accountsChanged", (accounts) => {
      window.location.reload();
    });
  }

  console.log(nftDetails);
  useEffect(() => {
    dispatch({ type: LOAD_NFT_IMAGE, payload: { loadImage: true } });
    connectToMetaMask();
  }, []);
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap justify-center -m-4">
          {isWrongNetwork ? (
            <div className="flex items-center justify-center flex-col mt-20">
              <p className="text-lg">
                You are in wrong network. Please change the network to proceed.
              </p>
              <button
                // hidden={!isWrongNetwork}
                onClick={changeNetwork}
                className="text-lg  transition-all ease-in duration-150 hover:bg-orange-600 font-bold text-center w-80 bg-[#FF8500] text-white py-2 rounded-full mt-2"
              >
                Change Network
              </button>
            </div>
          ) : nftDetails === null ? (
            <div className="flex justify-center items-center">
              <div
                className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : nftDetails && nftDetails?.length > 0 ? (
            nftDetails?.map((data) => {
              return (
                <div className="block relative h-48 rounded overflow-hidden mx-2 my-2 ">
                  <Image
                    width={200}
                    className=" rounded object-contain w-full max-w-[27rem] h-full sm:max-w-[20rem] lg:max-w-[17rem] "
                    src={data.image}
                    preview={false}
                    placeholder={
                      <Image
                        preview={false}
                        src="images/loading-icon.jpg"
                        width={50}
                        style={{ margin: "75px 0 0 75px" }}
                      />
                    }
                  />
                </div>
              );
            })
          ) : nftDetails && nftDetails?.length === 0 ? (
            <div className="flex items-center justify-center flex-col mt-20">
              <p className="text-lg">
                Sorry, you don't have enough amount of NFT right now.
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}

export default MyNftsDetails;
