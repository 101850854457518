export const CMS_PAGE_GET_ALL_PAGE = "CMS_PAGE_GET_ALL_PAGE";
export const CMS_PAGE_MINSOLD = "CMS_PAGE_MINSOLD";
export const CMS_PAGE_GET_ALL_PAGE_CONTENT = "CMS_PAGE_GET_ALL_PAGE_CONTENT";
export const CMS_PAGE_GET_BY_PAGE = "CMS_PAGE_GET_BY_PAGE";
export const CMS_PAGE_GET_PAGE_CONTENT = "CMS_PAGE_GET_PAGE_CONTENT";
export const CMS_PAGE_GET_BY_SECTION = "CMS_PAGE_GET_BY_SECTION";
export const CMS_PAGE_DELETE = "CMS_PAGE_DELETE";
export const CMS_PAGE_UPDATE = " CMS_PAGE_UPDATE";
export const CMS_IMAGE_DELETE = "CMS_IMAGE_DELETE";
export const CMS_PAGE_SECTION_CONTENT_UPDATE =
  "CMS_PAGE_SECTION_CONTENT_UPDATE";
export const CMS_PAGE_SECTION_CONTENT_GET = "CMS_PAGE_SECTION_CONTENT_GET";

export const GET_ABOUT_PAGE = "GET_ABOUT_PAGE";
export const GET_PRIVACY_PAGE = "GET_PRIVACY_PAGE";
export const GET_INNERPAGE_PAGE = "GET_INNERPAGE_PAGE";
export const GET_TERMS_PAGE = "GET_TERMS_PAGE";
export const GET_SITEMAP_PAGE = "GET_SITEMAP_PAGE";
export const GET_HOLDER_PAGE = "GET_HOLDER_PAGE";
