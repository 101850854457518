import React from "react";

// fucntion to create custom button with tailwind
export const Button = ({
  children,
  className,
  type,
  onClick,
  disabled,
  loading,
  ...props
}) => {
  return (
    <button
      className={`${className} ${type}`}
      onClick={onClick}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? <span className="spinner"></span> : children}
    </button>
  );
};
