import { Button, Form, Modal } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { addUserByAdminInitiate } from "../../../../../redux/actions/adminUsersActions";
import "./AddNewUser.scss";
function AddNewUser({ addUserModalVisible, hideLoginModal }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useNavigate();
  const Admin_Token = localStorage.getItem("tiger");

  const hideModal = () => {
    hideLoginModal();
  };
  const addUser = () => {
    form
      .validateFields()
      .then(async (values) => {
        dispatch(
          addUserByAdminInitiate(values, Admin_Token, history, hideModal)
        );
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  return (
    <div>
      <Modal
        visible={addUserModalVisible}
        title="New User Register"
        okText="Register "
        cancelText="no"
        onCancel={hideModal}
        className="addUser_modal"
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
          onFinish={addUser}
        >
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: "Enter your First Name" }]}
          >
            <input
              type="text"
              name="firstName"
              className=" rounded-lg bg-transparent shadow appearance-none border border-gray-300 w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: "Enter your Password" }]}
          >
            <input
              type="text"
              name="lastName"
              className="rounded-lg bg-transparent  appearance-none border border-gray-300 w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email Address"
            rules={[
              { required: true, message: "Enter your Email" },
              {
                type: "email",
                message: "please enter valid input",
              },
            ]}
          >
            <input
              type="email"
              name="email"
              className="rounded-lg bg-transparent shadow appearance-none border border-gray-300 w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: "Enter your Password" }]}
          >
            <input
              type="password"
              name="password"
              className="rounded-lg bg-transparent  appearance-none border border-gray-300 w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
            />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label=" Confirm Password"
            rules={[
              { required: true, message: "Enter your confirm Password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error("Passwords dont Match !!"));
                },
              }),
            ]}
          >
            <input
              type="password"
              name="confirm_password"
              className="rounded-lg  bg-transparent  appearance-none border border-gray-300 w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="Register-form-button w-full  text-center register_button"
            >
              Register User
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default AddNewUser;
