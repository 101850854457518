import { createContext } from "react";
import axios from "axios";
import * as types from "../types/usertypes";
import {
  adminProfile,
  change_Admin_Password,
  editAdmin,
  loginAdmin,
} from "../../api/Api-list";
// import { toast } from "react-toastify";
import { message } from "antd";

const initialUserState = {
  loading: true,
  error: false,
  user: null,
};

export const UserContext = createContext(initialUserState);

// export const UserProvider = ({children}) => {
//     const [stateUser, dispatch] = useReducer(LoginRegister, initialUserState);

//     const UserReset = () => {
//         dispatch({
//         type: types.USER_START,
//         });
//     };

//     const AdminLogin = (
//         username,
//         password

//     ) => {
//         dispatch({
//         type: types.LOGIN_ADMIN,
//         });
//         axios.post(USER_LOGIN, {
//             username: username,
//             password: password
//         })
//         .then(res => {
//             console.log(res,"========");
//             dispatch({
//                 type: types.LOGIN_ADMIN_SUCCESS,
//                 payload: res.data
//             });
//         })
//         .catch(err => {
//             console.log(err);
//             dispatch({
//                 type: types.LOGIN_ADMIN_FAILURE,
//                 payload: err
//             });
//         }
//         )
//     };

//     const UserRegister = (
//         email,
//         password,
//         confirm_pass,
//         first_name,
//         last_name
//     ) => {
//         dispatch({
//             type:types.REGISTER_USER,
//         });
//         axios.post(USER_REGISTER, {
//             email: email,
//             password: password,
//             confirm_pass: confirm_pass,
//             first_name: first_name,
//             last_name: last_name
//         })
//         .then(res => {
//             dispatch({
//                 type:types.REGISTER_USER_SUCCESS,
//                 payload: res.data
//             });
//         }
//         )
//         .catch(err => {
//             console.log(err);
//             dispatch({
//                 type: types.REGISTER_USER_FAILURE,
//                 payload: err
//             });
//         }
//         )
//     };

//     return (
//         <UserContext.Provider
//           value={{
//             stateUser,
//             AdminLogin,
//             UserRegister,

//           }}
//         >
//           {" "}
//           {children}{" "}
//         </UserContext.Provider>
//       );

// };

const fetchAdminProfileSuccess = (user) => ({
  type: types.ADMIN_PROFILE,
  payload: user,
});
const changeAdminPasswordA = (user) => ({
  type: types.CHANGE_ADMIN_PASSWORD,
  payload: user,
});
const loginStart = () => ({
  type: types.LOGIN_ADMIN,
});

const loginAdminFail = (error) => ({
  type: types.LOGIN_ADMIN_FAILURE,
  payload: error,
});

const loginAdminSuccess = (user) => ({
  type: types.LOGIN_ADMIN_SUCCESS,
  payload: user,
});

const logOut = () => {
  return {
    type: types.LOGOUT_ADMIN,
    payload: null,
  };
};

export const AdminLogin = (email, password, history) => {
  return (dispatch) => {
    dispatch(loginStart());
    axios
      .post(loginAdmin, {
        email: email,
        password: password,
      })
      .then((response) => {
        localStorage.setItem("tiger", response.data.access_token);
        dispatch(loginAdminSuccess(response.data));
        if (response.status === 200) {
          console.log(response.data, "response");
          message.success(`${response.data.msg}`);
        }
        history("/admin");
      })
      .catch((error) => {
        console.log(error.response);
        message.error(`${error.response.data.msg}`);
        dispatch(loginAdminFail(error.response));
      });
  };
};
//logout
export const AdminLogout = (history) => {
  return (dispatch) => {
    console.log("user logout data");
    dispatch(logOut());
    history("/admin-login");
  };
};

export const fetchAdminProfile = (Token) => {
  return (dispatch) => {
    axios
      .get(adminProfile, {
        headers: { Authorization: "Bearer: " + Token },
      })
      .then((response) => {
        // console.log("fetch admin success", response.data);
        dispatch(fetchAdminProfileSuccess(response.data.data));
      })
      .catch((err) => {
        console.log("fetch admin err", err);
        // message.error(`${err.response.data.msg}`);
      });
  };
};
// UPDATE_ADMIN_PROFILE
export const updateAdminProfile = (
  values,
  Token,
  profilehandleCancel,
  history
) => {
  console.log("updateAdminProfile");
  return (dispatch) => {
    axios
      .patch(
        editAdmin,
        {
          ...values,
        },
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      )
      .then((response) => {
        console.log("updateAdminProfile success", response.data);
        dispatch(fetchAdminProfileSuccess(response.data.data));
        message.success(`${response.data.message}`);
        profilehandleCancel();
      })
      .catch((err) => {
        console.log("updateAdminProfile err", err.response.data);
        // message.error(`${err.response.data.msg}`);
        message.error(`email already exist`);
      });
  };
};

//UPDATE ADMIN PASSWORD CHANGE_ADMIN_PASSWORD

export const changeAdminPassword = (values, Token, passwordhandleCancel) => {
  console.log("changeAdminPassword");
  return (dispatch) => {
    axios
      .put(
        change_Admin_Password,
        {
          ...values,
        },
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      )
      .then((response) => {
        console.log("changeAdminPassword success", response.data);
        dispatch(changeAdminPasswordA(response.data));
        message.success(`${response.data.msg}`);
        passwordhandleCancel();
      })
      .catch((err) => {
        console.log("changeAdminPassword err", err.response.data);
        message.error(`${err.response.data.message}`);
      });
  };
};

// export const UserRegister = (
//     email,
//     password,
//     confirm_pass,
//     first_name,
//     last_name,
//     history
// ) => {
//     return dispatch => {
//         axios.post(USER_REGISTER, {
//             email: email,
//             password: password,
//             confirm_pass: confirm_pass,
//             first_name: first_name,
//             last_name: last_name
//         })
//         .then(response => {
//             dispatch({
//                 type: types.REGISTER_USER_SUCCESS,
//                 payload: response.data
//             });
//             history.push("/login-register");
//         })
//         .catch(err => {
//             console.log(err);
//             dispatch({
//                 type: types.REGISTER_USER_FAILURE,
//                 payload: err
//             });
//         }
//         )
//     };
// }

// export const UserForgotPassword = (email) => {
//     return dispatch => {
//         axios.post(PASSWORD_RESET, {
//             email: email
//         })
//         .then(response => {
//             dispatch({
//                 type: types.PASSWORD_RESET,
//                 payload: response.data
//             });
//         })
//         .catch(err => {
//             console.log(err);
//             dispatch({
//                 type: types.PASSWORD_RESET_FAILURE,
//                 payload: err
//             });
//         }
//         )
//     };
// }
