import { message } from "antd";
import axios from "axios";
import {
  activeUser,
  add_user_by_admin,
  delete_user_by_admin,
  edit_user_by_admin,
  get_single_user,
} from "../../api/Api-list";

import {
  FETCH_USERS_BY_ADMIN,
  DELETE_USER_BY_ADMIN,
  ADD_USER_BY_ADMIN,
  EDIT_USER_BY_ADMIN,
  VIEW_USER_BY_ADMIN,
} from "../types/usertypes";

const fetchUserSuccess = (users) => {
  return {
    type: FETCH_USERS_BY_ADMIN,
    payload: users,
  };
};
const deleteUser = (id) => {
  return {
    type: DELETE_USER_BY_ADMIN,
    payload: id,
  };
};

const addUserByAdmin = (user) => {
  return {
    type: ADD_USER_BY_ADMIN,
    payload: user,
  };
};
const editUserByAdmin = (user) => {
  return {
    type: EDIT_USER_BY_ADMIN,
    payload: user,
  };
};
const viewUserByAdmin = (id) => {
  return {
    type: VIEW_USER_BY_ADMIN,
    payload: id,
  };
};
export const deletUserInitiate = (id, Admin_Token) => {
  return (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${Admin_Token}` },
    };
    axios
      .delete(`${delete_user_by_admin}/${id}`, config)
      .then((response) => {
        console.log(response.data, "response.data");
        dispatch(deleteUser(id));
        message.success(`${response.data.message}`);
      })
      .catch((error) => {
        console.log(error.response);
        message.success(`${error.response.data.msg}`);
      });
  };
};

export const fetchUsers = (Admin_Token) => {
  console.log("Admin_Token", Admin_Token);
  return function (dispatch) {
    const config = {
      headers: {
        Authorization: `Bearer ${Admin_Token}`,
      },
    };
    axios
      .get(
        activeUser,
        {
          role: "user",
        },
        config
      )
      .then((response) => {
        console.log(response.data, "response.data");
        console.log("fetching users");
        dispatch(fetchUserSuccess(response.data));
      })
      .catch((error) => {
        console.log(error.response);
        console.log("error.message", error.response.data.msg);
      });
  };
};
export const addUserByAdminInitiate = (
  user,
  Admin_Token,
  history,
  hideModal
) => {
  console.log("addUserByAdminInitiate start");
  return (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${Admin_Token}` },
    };
    axios
      .post(add_user_by_admin, user, config)
      .then(async (response) => {
        console.log(response.data, "add user by admin response.data");
        dispatch(addUserByAdmin(user));
        await axios
          .post(activeUser, {
            role: "user",
          })
          .then((response) => {
            console.log(response.data, "all user response.data");
            dispatch(fetchUserSuccess(response.data));
          })
          .catch((error) => {
            console.log(error.response);
          });
        message.success("Add User Successfully, Email has been sent to user");
        hideModal();
        history("/admin/customer-list");
      })
      .catch((error) => {
        message.error(`${error.response.data.msg}`);
      });
  };
};

export const editUserByAdminInitiate = (user, Admin_Token, id, history) => {
  return (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${Admin_Token}` },
    };
    axios
      .patch(`${edit_user_by_admin}/${id}`, user, config)
      .then((response) => {
        console.log(response.data, "response.data");
        message.success(`${response.data.message}`);
        dispatch(editUserByAdmin(user));
        history("/admin/customer-list");
      })
      .catch((error) => {
        console.log(error.response);
        message.error(`${error.response.data.msg}`);
      });
  };
};

export const viewUserByAdminInitiate = (id, Admin_Token) => {
  return (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${Admin_Token}` },
    };
    console.log(id);
    axios
      .post(
        get_single_user,
        {
          _id: id,
        },
        config
      )
      .then((response) => {
        dispatch(viewUserByAdmin(response.data.data));
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};
