import React, { useEffect, useState } from "react";
import { Form, Input, List, Modal, Switch, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
// import { editUserByAdminInitiate } from "../../../redux/actions/adminUsersActions";
import {
  change_menu_status,
  create_menu,
  delete_menu,
  get_home_page_team_section,
  get_menu,
  menu_rearrange,
} from "../../../api/Api-list";
import axios from "axios";
import { Button } from "../../admin-components/Buttons/Button";
import { updateHomeTeamSectionInitiate } from "../../../redux/actions/cmsSettingAction";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./menu.scss";

import dragIcon from "../../images/drag-icon.png";
import editIcon from "../../images/edit.png";
import deleteIcon from "../../images/delete.png";

import { get } from "http";
import { Link } from "react-router-dom";
const Menu = () => {
  const Admin_Token = localStorage.getItem("tiger");
  const [form] = Form.useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { addToast } = useToasts();

  const [menus, setMenus] = useState([]);
  const [check, setCheck] = useState(true);
  const [pageType, setPageType] = useState("Header");
  const [getpageType, setGetPageType] = useState("Header");
  const [pageName, setPageName] = useState("");
  const [menuLink, setMenuLink] = useState("");
  const [toggle, setToggle] = useState(false);

  const inputclass =
    "form-control bg-transparent !py-[0.7rem] !rounded-[0.3rem] text-black";

  const config = {
    headers: {
      Authorization: `Bearer ${Admin_Token}`,
    },
  };

  const submitUpdate = async () => {
    try {
      let payload = {
        pageType: pageType,
        pageName: pageName,
        isActive: check,
        link: menuLink,
      };
      const response = await axios.post(create_menu, payload, config);
      if (response.status < 400) {
        addToast("Add menu successfully", {
          appearance: "success",
          autoDismiss: true,
          position: "top-center",
        });
        getMenu(getpageType);
        setPageName("");
        setMenuLink("");
        setToggle(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getMenu = async (type) => {
    try {
      const response = await axios.get(get_menu(type), config);
      if (response.status < 400) {
        setMenus(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMenu(getpageType);
  }, [getpageType]);

  const deleteMenu = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Successfully!", "Address Deleted Successfully", "success");

        axios
          .delete(delete_menu(id), config)
          .then((res) => {
            if (res.status === 200) {
              getMenu("Header");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const changeMenuStatus = async (id) => {
    try {
      const response = await axios.patch(change_menu_status(id), id, config);
      if (response.status < 400) {
        addToast("Menu Status Change successfully", {
          appearance: "success",
          autoDismiss: true,
          position: "top-center",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(menus);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setMenus(tempData);
    console.log(tempData, "fff");

    let data = tempData.map((temp) => temp._id);
    console.log(data, "data");

    axios
      .patch(
        menu_rearrange(pageType),
        {
          headerFooterIds: data,
        },
        config
      )
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          getMenu("Header");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="create-menu-body">
        <div className="flex items-center justify-between w-full">
          <h2>Create Menu</h2>
          {!toggle ? (
            <button
              onClick={() => setToggle(!toggle)}
              className="text-[20px] font-bold text-center w-full mt-2 bg-[#245ef3] transition-all duration-75 hover:bg-orange-600 text-white py-[10px] rounded-3xl create-menu-btn"
            >
              Add Menu
            </button>
          ) : (
            ""
          )}
        </div>
        {toggle ? (
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={submitUpdate}
          >
            <Form.Item
              name="pageType"
              label="Page Type"
              rules={[
                { required: true, message: "Please Select Your Page Type" },
              ]}
              className="text-red-100"
              // initialValue={data?.pageType}
            >
              <select
                className={inputclass}
                onChange={(e) => setPageType(e.target.value)}
                name="pageType"
              >
                <option value="">Select Page Type</option>
                <option value="Header">Header</option>
                <option value="Footer">Footer</option>
              </select>
            </Form.Item>
            <Form.Item
              name="pageName"
              label="Page Name"
              // initialValue={data.pageName}
              rules={[{ required: true, message: "Enter Your Page Name" }]}
            >
              <Input
                onChange={(e) => setPageName(e.target.value)}
                className={inputclass}
              />
            </Form.Item>

            <Form.Item
              name="link"
              label="Link"
              // initialValue={data.pageName}
              // rules={[{ required: true, message: "Enter Your Link Name" }]}
            >
              <Input
                onChange={(e) => setMenuLink(e.target.value)}
                className={inputclass}
              />
              <small>For Example - menu</small>
            </Form.Item>

            <Switch defaultChecked={check} onChange={() => setCheck(!check)} />

            <br />
            <br />

            <Form.Item>
              <Button
                type="submit"
                className="w-full py-2 rounded-md font-semibold text-[1.1rem] !bg-orange-500  text-center text-white hover:!border-none focus:!border-none"
              >
                Create Menu
              </Button>
            </Form.Item>
          </Form>
        ) : (
          ""
        )}

        <br />
        <br />
        <label>Select Your Page Type</label>
        <select
          className={inputclass}
          onChange={(e) => {
            getMenu(e.target.value);
            setGetPageType(e.target.value);
          }}
          name="getdata"
          value={getpageType}
        >
          <option value="Header">Header</option>
          <option value="Footer">Footer</option>
          <option value="Seo">Seo</option>
        </select>

        {getpageType === "Seo" ? (
          <table className="table borderd" style={{ width: "100%" }}>
            <tbody className="text-capitalize">
              {menus?.map((menu, index) => (
                <tr>
                  <td>{menu?.pageName}</td>
                  <td>{menu?.pageType}</td>
                  <td className="create-menu-action">
                    <span>
                      {" "}
                      <Link to={`/admin/pageseo-management/${menu?._id}`}>
                        <img src={editIcon} alt="Edit Icon" />
                      </Link>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <table className="table borderd" style={{ width: "100%" }}>
              {/* <thead>
              {/* <tr>
                <th />
                <th>Menu Name</th>
                <th>Menu Type</th>
                <th>Action</th>
                <th>Active Menu</th>
              </tr> 
            </thead> */}
              <Droppable droppableId="droppable-1">
                {(provider) => (
                  <tbody
                    className="text-capitalize"
                    ref={provider.innerRef}
                    {...provider.droppableProps}
                  >
                    {menus?.map((menu, index) => (
                      <Draggable
                        key={menu?._id}
                        draggableId={menu?._id}
                        index={index}
                      >
                        {(provider) => (
                          <tr
                            {...provider.draggableProps}
                            ref={provider.innerRef}
                          >
                            <td
                              {...provider.dragHandleProps}
                              className="drag-icon"
                            >
                              <img src={dragIcon} alt="Edit Icon" />
                            </td>
                            <td>{menu?.pageName}</td>
                            <td>{menu?.pageType}</td>
                            <td className="create-menu-action">
                              <span>
                                {" "}
                                <Link to={`/admin/menu/${menu?._id}`}>
                                  <img src={editIcon} alt="Edit Icon" />
                                </Link>
                              </span>
                              <span onClick={() => deleteMenu(menu?._id)}>
                                <img src={deleteIcon} alt="Edit Icon" />
                              </span>
                            </td>
                            <td>
                              <Switch
                                defaultChecked={menu?.isActive}
                                onChange={() => changeMenuStatus(menu?._id)}
                              />
                            </td>
                            <td>
                              <div></div>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provider.placeholder}
                  </tbody>
                )}
              </Droppable>
            </table>
          </DragDropContext>
        )}
      </div>
    </>
  );
};

export default Menu;
