import React, { useEffect } from "react";
import Helmet from "react-helmet";
import Collage from "../../components/homepage components/collage/Collage";
import Eldrick from "../../components/homepage components/eldrick/Eldrick";
import GTCGallery from "../../components/homepage components/gtc_gallery/GTC_Gallery";
import RoaringTiger from "../../components/homepage components/roaring_tiger/RoaringTiger";
import RTGC from "../../components/homepage components/rtgc/RTGC";
// import TeamCardComponent from "../../components/homepage components/teamcardcomponent/TeamCardComponent";

function Homepage() {
  // useEffect(() => {
  //   // This is temporary hack to load mint-sale page will remove later
  //   window.location.href = "/mint-sale";
  // }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roaring Tiger Golf Club | RTGC</title>
      </Helmet>
      <RoaringTiger />
      <RTGC />
      <Eldrick />
      <GTCGallery />
      <Collage />
    </div>
  );
}

export default Homepage;
