import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store/Store";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
// import "antd/dist/antd.css";
import "antd/dist/antd.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, useLocation } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { useEffect } from "react";
import { CookiesProvider } from "react-cookie";

ReactDOM.render(
  <BrowserRouter>
    <CookiesProvider>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={5000}
        PlacementType="top-center"
        position="top-center"
      >
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </ToastProvider>
    </CookiesProvider>
  </BrowserRouter>,

  document.getElementById("root")
);

reportWebVitals();
