import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { Upload, Input, Form, Modal, Switch, message } from "antd";
// import "./addproduct.scss";
// tiny Mce

import { useDispatch, useSelector } from "react-redux";
import {
  CategoryList,
  selectSingleCategory,
  UpdateCategory,
} from "../../../../redux/actions/productAction";
import { fetch_Category_by_Slug, productCategory } from "../../../../api/Api-list";
import axios from "axios";
// ----------------------------------Edit Product Category Component-------------------------
function EditCategory() {
  const dispatch = useDispatch();
  const Admin_Token = localStorage.getItem("tiger");
  const {id} = useParams();
  const [singleCategory, setSingleCategory] = useState()
console.log(id, 'iii')

  const getSingleproductCategory = async () => {
    try {
      const response = await axios.get(`${fetch_Category_by_Slug(id)}`);
      setSingleCategory(response?.data);
    } catch (error) {
      console.log(error);
    }
  }
  // const singleCategory = useSelector(
  //   (state) => state?.allProducts?.selectsinglecategory
  // );
  // console.log(singleCategory?.data?.categoryName, "singleCategory");
  useEffect(() => {
    getSingleproductCategory()
    setTimeout(() => {
      setChecked(singleCategory?.data?.isActive);
      setIsTop(singleCategory?.data?.isTop);

      if (fileList.length === 0) {
        setFileList([
          {
            uid: "1",
            url: singleCategory?.data?.image,
          },
        ]);
      }
    }, 1000);

    form.setFieldsValue({
      ...singleCategory?.data,
    });
  }, [singleCategory?.data?.categoryName]);

  // conver image to base64
  const imgtoBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const inputclass = "bg-transparent !py-[0.7rem] !rounded-[0.3rem] text-black";

  const [form] = Form.useForm();
  // preview image  visible
  const [previewVisible, setPreviewVisible] = useState(false);
  // preview image url
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [imgerr, setImgerr] = useState(false);
  const [imgurl, setImgurl] = useState(false);
  const [fileList, setFileList] = useState([]);

  const navigate = useNavigate();
  const beforeUpload = () => false;
  const handleCancel = () => setPreviewVisible(false);

  // image to base 64
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    // remove the old values in fileseLis
    setImgurl(true);
    var singleFile = newFileList.slice(-1);

    setFileList(singleFile);
  };


  



  const submitProduct = async (e) => {
    e.preventDefault();
    var images = {};
    if (imgurl) {
      const imagetoBase64 = await imgtoBase64(fileList[0].originFileObj);
      images = { image: imagetoBase64 };
    }
    const categoryId = {
      _id: singleCategory?.data?._id,
    };
    form
      .validateFields()
      .then(async (e) => {
        const values = await form.getFieldsValue();
        console.log(values, "formdata");
        const regex = new RegExp(/^\S*$/);

        if(!regex.test(values?.categoryUrl)){
          message.error("Please Enter a valid URL (Ex - roaringtiger)");
          return
        }

        dispatch(
          UpdateCategory({ ...e, ...images, ...categoryId }, Admin_Token)
        );
        

        setTimeout(() => {
          navigate("/admin/category-management");
        }, 200);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  // Image Upload Button
  const uploadButton = (
    <div className="dft">
      <AiOutlinePlus className="!text-black mx-auto !text-[1.4rem]" />
      <div className="mt-2">Upload</div>
    </div>
  );
  const [checked, setChecked] = useState(
    singleCategory?.data?.isActive ? true : false
  );

  const [isTop, setIsTop] = useState(
    singleCategory?.data?.isTop ? true : false
  );

  const handleChangeActive = (checked) => {
    console.log(`switch to ${checked}`);
    setChecked(checked);
  };
  return (
    <>
      <div className="container-fluid edit_product">
        <div className="row">
          <h2 className="cms-title">Edit Category</h2>

          <div className="col-12 col-sm-12 col-md-12 bg-white shadow-sm p-5 cms-box">
            <Form layout="vertical" form={form}>
              <Form.Item
                name="categoryName"
                type="text"
                initialValue={singleCategory?.data?.categoryName}
                label="Category Name"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Category Name" className={inputclass} />
              </Form.Item>

              <Form.Item
                name="categoryUrl"
                type="text"
                initialValue={singleCategory?.data?.categoryUrl}
                label="Category URL"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Category URL" className={inputclass} />
              </Form.Item>


              <Form.Item
                name="isActive"
                type="switch"
                initialValue={singleCategory?.data?.isActive}
                label="Status"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Switch
                  checkedChildren="Active"
                  checked={checked}
                  unCheckedChildren="Inactive"
                  onChange={handleChangeActive}
                />
              </Form.Item>

              <Form.Item
                name="isTop"
                type="switch"
                initialValue={singleCategory?.data?.isTop}
                label={`Is Feature Item`}
                // rules={[{ required: true, message: "This field is required" }]}
              >
                <Switch
                  checkedChildren="Enable Feature"
                  checked={isTop}
                  unCheckedChildren="Disable feature"
                  onChange={(checked) => setIsTop(checked)}
                />
              </Form.Item>

              <Form.Item label="Product Image">
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  beforeUpload={beforeUpload}
                  name="images"
                  type="file"
                >
                  {fileList.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal
                  visible={previewVisible}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img alt="example" className="w-full" src={previewImage} />
                </Modal>
              </Form.Item>
              {imgerr ? (
                <p className="relative text-[#f10606] mt-[-2rem]">
                  This field is required
                </p>
              ) : (
                ""
              )}

              <Form.Item>
                <button
                  type="submit"
                  className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] "
                  onClick={submitProduct}
                >
                  Update Category
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCategory;