import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { get_order_details } from "../../api/Api-list";
import Seo from "../../components/Seo";
import "./OrderDetails.scss";

export default function OrderDetails() {
  const [orderDetails, setOrderDetails] = useState(null);
  const [oneEth, setOneEth] = useState(0);

  const { id } = useParams();

  const app_token = localStorage.getItem("tiger-token");

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  useEffect(() => {
    getOrderDetails();
  }, [id]);

  const getOrderDetails = async () => {
    try {
      const response = await axios.get(get_order_details(id), config);
      if (response.status < 400) {
        setOrderDetails(response.data.order);
        setOneEth(response.data?.oneETH);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Seo
        title={`Order - ${
          orderDetails?.orderNumber ? orderDetails?.orderNumber : ""
        }`}
        // desc={products?.metaInfo?.pageDesc}
        // keywords={products?.metaInfo?.keywords}
      />

      <div className="py-8 profile_content">
        <div className="py-4 flex flex-col items-center justify-center">
          <h2 className="font-bold text-white text-center pt-[5rem] profile_title pb-3">
            Order Details
          </h2>
        </div>

        <div className="product-delivery-info">
          <Container>
            <ul className="flex justify-between delivery-row">
              <li>
                <h2>Delivery Address</h2>
                <h3>{orderDetails?.address?.fullName}</h3>
                <p>
                  Address : {orderDetails?.address?.address},{" "}
                  {orderDetails?.address?.city} -{" "}
                  {orderDetails?.address?.pincode},{" "}
                  {orderDetails?.address?.state?.name},{" "}
                  {orderDetails?.address?.country?.countryName}
                </p>
                <p>Landmark : {orderDetails?.address?.landmark}</p>
                <h3>Phone number</h3>
                <p>{orderDetails?.address?.phone}</p>
              </li>
              <li>
                <h2>Total Payment</h2>
                <div class="flex items-center shipping-total-payment">
                  <span class="product-card-usd">
                    ${orderDetails?.subTotalAmount.toFixed(2)}
                  </span>
                  {/* <span class="product-card-ethereum">
                  0.04
                  <img src="/images/eth-icon.png" alt="ethicons" />
                </span> */}
                </div>
              </li>
              <li>
                <h2>Payment Summary</h2>
                <table className="shipping-payment-summary">
                  <tbody>
                    <tr>
                      <td>Total</td>
                      <td>${orderDetails?.totalAmount.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>
                        Tax ({orderDetails?.address?.state?.totalTaxAmount}%)
                      </td>
                      <td>${orderDetails?.taxAmount.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Shipping</td>
                      <td>${orderDetails?.shippingAmount.toFixed(2)}</td>
                    </tr>
                    {orderDetails?.coupon && (
                      <tr>
                        <td>Coupon</td>
                        <td>
                          ${orderDetails?.couponDiscountAmount.toFixed(2)}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Sub Total</td>
                      <td>${orderDetails?.subTotalAmount.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ul>
          </Container>
        </div>

        {/* top card */}
        <div className="product-shiping-info mb-10">
          <Container>
            <div className="flex justify-between product-shiping-row">
              {orderDetails?.orderStatus === "Confirmed" ||
              orderDetails?.orderStatus === "Shipped" ||
              orderDetails?.orderStatus === "Delivered" ? (
                <div className="product-shiping-delivery-clm">
                  <ul className="flex">
                    <li
                      className={
                        orderDetails?.orderStatus === "Confirmed" ||
                        orderDetails?.orderStatus === "Shipped" ||
                        orderDetails?.orderStatus === "Delivered"
                          ? "active"
                          : null
                      }
                    >
                      <h3>Order Confirmed</h3>
                      <span className="dot"></span>
                    </li>
                    <li
                      className={
                        orderDetails?.orderStatus === "Shipped" ||
                        orderDetails?.orderStatus === "Delivered"
                          ? "active"
                          : null
                      }
                    >
                      <h3>Shipped</h3>
                      <span className="dot"></span>
                    </li>
                    <li
                      className={
                        orderDetails?.orderStatus === "Delivered"
                          ? "active"
                          : null
                      }
                    >
                      <h3>Delivered</h3>
                      <span className="dot"></span>
                    </li>
                  </ul>
                </div>
              ) : orderDetails?.orderStatus === "Return Request" ||
                orderDetails?.orderStatus === "Return Request Accept" ||
                orderDetails?.orderStatus === "Return Request Reject" ||
                orderDetails?.orderStatus === "Return Request Complete" ? (
                <div
                  className={
                    orderDetails?.orderStatus === "Return Request Reject"
                      ? "product-shiping-return-reject-clm"
                      : "product-shiping-return-accept-clm"
                  }
                >
                  <ul className="flex">
                    <li
                      className={
                        orderDetails?.orderStatus === "Return Request" ||
                        orderDetails?.orderStatus === "Return Request Accept" ||
                        orderDetails?.orderStatus === "Return Request Reject" ||
                        orderDetails?.orderStatus === "Return Request Complete"
                          ? "active"
                          : null
                      }
                    >
                      <h3>Return Requested</h3>
                      <span className="dot"></span>
                    </li>
                    {/* <li
                    className={
                      orderDetails?.orderStatus === "Return Request Accept" ||
                      orderDetails?.orderStatus === "Return Request Reject" ||
                      orderDetails?.orderStatus === "Return Request Complete"
                        ? "active"
                        : null
                    }
                  >
                    <h3>
                      {orderDetails?.orderStatus === "Return Request Accept"
                        ? "Accepted"
                        : orderDetails?.orderStatus === "Return Request Reject"
                        ? "Rejected"
                        : "Pending"}
                    </h3>
                    <span className="dot"></span>
                  </li> */}
                    <li
                      className={
                        orderDetails?.orderStatus === "Return Request Accept" ||
                        orderDetails?.orderStatus === "Return Request Reject" ||
                        orderDetails?.orderStatus === "Return Request Complete"
                          ? "active"
                          : null
                      }
                    >
                      <h3>
                        {orderDetails?.orderStatus === "Return Request Complete"
                          ? "Return Completed"
                          : orderDetails?.orderStatus ===
                            "Return Request Reject"
                          ? "Return Request Rejected"
                          : orderDetails?.orderStatus ===
                            "Return Request Accept"
                          ? "Return Request Accepted"
                          : "Return Request Pending"}
                      </h3>
                      <span className="dot"></span>
                    </li>
                  </ul>
                </div>
              ) : null}
              <div className="product-order-id-clm">
                <p>
                  <span>Order Number :</span> {orderDetails?.orderNumber}
                </p>

                {orderDetails?.shippingId &&
                  orderDetails?.deliveryDate &&
                  (orderDetails?.orderStatus === "Shipped" ||
                    orderDetails?.orderStatus === "Delivered") && (
                    <>
                      <p>
                        <span>Shipping ID :</span> {orderDetails?.shippingId}
                      </p>

                      <p>
                        <span>Delivery Date :</span>{" "}
                        {moment(orderDetails?.deliveryDate)?.format(
                          "YYYY-MM-DD"
                        )}
                      </p>
                    </>
                  )}
              </div>
              <div className="product-shiping-product-clm">
                {(orderDetails?.orderStatus === "Return Request" ||
                  orderDetails?.orderStatus === "Return Request Accept" ||
                  orderDetails?.orderStatus === "Return Request Reject" ||
                  orderDetails?.orderStatus === "Return Request Complete") &&
                orderDetails?.items.filter(
                  (item) => item.orderStatus === "Delivered"
                ).length > 0
                  ? orderDetails?.items
                      ?.filter((item) => item.orderStatus !== "Delivered")
                      ?.map((item) => {
                        return (
                          <div className="flex shiping-product-dtl">
                            <div className="product-shiping-product-img">
                              <img
                                src={
                                  item?.image?.[0]?.image || "/images/noimg.png"
                                }
                                alt="product-img"
                                className="rounded-xl"
                              />
                            </div>
                            <div className="product-shiping-product-info-text">
                              <h2>{item.productName}</h2>
                              <ul>
                                <li>
                                  Size : <span>{item.priceTier.size}</span>
                                </li>
                                <li>
                                  Quantity : <span>{item.quantity}</span>
                                </li>
                                <li>
                                  Color :{" "}
                                  <span
                                    className="w-4 h-4 rounded-full color"
                                    style={{
                                      backgroundColor: item.priceTier.color,
                                    }}
                                  >
                                    {item.priceTier.color}
                                  </span>
                                </li>
                              </ul>
                              <div class="flex items-center mt-2">
                                <span class="product-card-usd">
                                  ${item.priceTier.price.toFixed(2)}
                                </span>
                                <span class="product-card-ethereum">
                                  {(item.priceTier.price / oneEth).toFixed(5)}
                                  <img
                                    src="/images/eth-icon.png"
                                    alt="ethicons"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })
                  : orderDetails?.items?.map((item) => {
                      return (
                        <div className="flex shiping-product-dtl">
                          <div className="product-shiping-product-img">
                            <img
                              src={
                                item?.image?.[0]?.image || "/images/noimg.png"
                              }
                              alt="product-img"
                              className="rounded-xl"
                            />
                          </div>
                          <div className="product-shiping-product-info-text">
                            <h2>{item.productName}</h2>
                            <ul>
                              <li>
                                Size : <span>{item.priceTier.size}</span>
                              </li>
                              <li>
                                Quantity : <span>{item.quantity}</span>
                              </li>
                              <li>
                                Color :{" "}
                                <span
                                  className="w-4 h-4 rounded-full color"
                                  style={{
                                    backgroundColor: item.priceTier.color,
                                  }}
                                >
                                  {item.priceTier.color}
                                </span>
                              </li>
                            </ul>
                            <div class="flex items-center mt-2">
                              <span class="product-card-usd">
                                ${item.priceTier.price.toFixed(2)}
                              </span>
                              <span class="product-card-ethereum">
                                {(item.priceTier.price / oneEth).toFixed(5)}
                                <img
                                  src="/images/eth-icon.png"
                                  alt="ethicons"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </Container>
        </div>

        {/* bottom card for delivery section */}
        {(orderDetails?.orderStatus === "Return Request" ||
          orderDetails?.orderStatus === "Return Request Accept" ||
          orderDetails?.orderStatus === "Return Request Reject" ||
          orderDetails?.orderStatus === "Return Request Complete") &&
        orderDetails?.items.filter((item) => item.orderStatus === "Delivered")
          .length > 0 ? (
          <div className="product-shiping-info mb-10">
            <Container>
              <div className="flex justify-between product-shiping-row">
                <div className="product-shiping-delivery-clm">
                  <ul className="flex">
                    <li className="active">
                      <h3>Order Confirmed</h3>
                      <span className="dot"></span>
                    </li>
                    <li className="active">
                      <h3>Shipped</h3>
                      <span className="dot"></span>
                    </li>
                    <li className="active">
                      <h3>Delivered</h3>
                      <span className="dot"></span>
                    </li>
                  </ul>
                </div>

                <div className="product-order-id-clm">
                  <p>
                    <span>Order Number :</span> {orderDetails?.orderNumber}
                  </p>

                  {orderDetails?.shippingId &&
                    orderDetails?.deliveryDate &&
                    (orderDetails?.orderStatus === "Shipped" ||
                      orderDetails?.orderStatus === "Delivered") && (
                      <>
                        <p>
                          <span>Shipping ID :</span> {orderDetails?.shippingId}
                        </p>

                        <p>
                          <span>Delivery Date :</span>{" "}
                          {moment(orderDetails?.deliveryDate)?.format(
                            "YYYY-MM-DD"
                          )}
                        </p>
                      </>
                    )}
                </div>
                <div className="product-shiping-product-clm">
                  {orderDetails?.items
                    ?.filter((item) => item.orderStatus === "Delivered")
                    ?.map((item) => {
                      return (
                        <div className="flex shiping-product-dtl">
                          <div className="product-shiping-product-img">
                            <img
                              src={
                                item?.image?.[0]?.image || "/images/noimg.png"
                              }
                              alt="product-img"
                              className="rounded-xl"
                            />
                          </div>
                          <div className="product-shiping-product-info-text">
                            <h2>{item.productName}</h2>
                            <ul>
                              <li>
                                Size : <span>{item.priceTier.size}</span>
                              </li>
                              <li>
                                Quantity : <span>{item.quantity}</span>
                              </li>
                              <li>
                                Color :{" "}
                                <span
                                  className="w-4 h-4 rounded-full color"
                                  style={{
                                    backgroundColor: item.priceTier.color,
                                  }}
                                >
                                  {item.priceTier.color}
                                </span>
                              </li>
                            </ul>
                            <div class="flex items-center mt-2">
                              <span class="product-card-usd">
                                ${item.priceTier.price.toFixed(2)}
                              </span>
                              <span class="product-card-ethereum">
                                {(item.priceTier.price / oneEth).toFixed(5)}
                                <img
                                  src="/images/eth-icon.png"
                                  alt="ethicons"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </Container>
          </div>
        ) : null}
      </div>
    </>
  );
}
