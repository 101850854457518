import React from "react";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import {
  get_all_countries,
  get_all_states,
  get_single_address,
  save_address,
  update_address,
} from "../../../api/Api-list";
import { Container } from "react-bootstrap";
import { Col, Row } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import { useToasts } from "react-toast-notifications";
import "./MyAddress.scss";
import { Switch } from "antd";

const EditAddress = () => {
  const [allStates, setAllStates] = useState([]);
  const [allCountries, setAllCountries] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState("");

  const addressType = ["Home", "Work"];
  const app_token = localStorage.getItem("tiger-token");
  let { id } = useParams();
  let navigate = useNavigate();
  const { addToast } = useToasts();

  const formikRef = useRef();

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  const editAddress = async (id) => {
    try {
      const res = await axios.get(get_single_address(id), config);
      if (res.status < 400) {
        const { address } = res?.data;
        const { setFieldValue } = formikRef.current;
        setFieldValue("fullName", address?.fullName);
        setFieldValue("phone", address?.phone);
        setFieldValue("pincode", address?.pincode);
        setFieldValue("address", address?.address);
        setFieldValue("city", address?.city);
        setFieldValue("country", address?.country?._id);
        setFieldValue("state", address?.state?._id);
        setFieldValue("landmark", address?.landmark);
        setFieldValue("addressType", address?.addressType);

        setSelectedCountry(address?.country?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllStates = async (id) => {
    try {
      const response = await axios.get(get_all_states(id), config);
      if (response.status < 400) {
        setAllStates(response.data.states);
        formikRef.current.setFieldValue(
          "state",
          formikRef.current.values.state !== ""
            ? formikRef.current.values.state
            : formikRef.current.values.state === "" &&
              response.data.states.length > 0
            ? response.data.states[0].stateId
            : ""
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllCountry = async () => {
    try {
      const response = await axios.get(get_all_countries, config);
      if (response.status < 400) {
        setAllCountries(response.data.countries);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    editAddress(id);
    getAllCountry();
  }, [id]);

  useEffect(() => {
    if (selectedCountry === "") return;
    getAllStates(selectedCountry);
  }, [selectedCountry]);

  const initialValues = {
    fullName: "",
    phone: "",
    pincode: "",
    address: "",
    city: "",
    state: "",
    country: "",
    landmark: "",
    addressType: addressType[0],
    isDefault: false,
  };

  const validationSchema = yup.object().shape({
    fullName: yup.string().required("Name is required").trim(),
    phone: yup
      .number("This field should be in number")
      .required("Please provide a contact number"),
    pincode: yup.string().required("Pincode is required"),
    address: yup.string().required("Address is required").trim(),
    city: yup.string().required("City is required").trim(),
    state: yup.string().required("State is required").trim(),
    country: yup.string().required("Country is required").trim(),
    landmark: yup.string().required("Landmark is required").trim(),
    addressType: yup
      .mixed()
      .oneOf(addressType)
      .required("This field is required"),
  });

  const updateAddress = async (values, resetForm) => {
    try {
      const {
        fullName,
        phone,
        pincode,
        address,
        city,
        state,
        country,
        landmark,
        addressType,
        isDefault,
      } = values;

      const payload = {
        fullName,
        phone: Number(phone),
        pincode,
        address,
        city,
        state,
        country,
        landmark,
        addressType,
        isDefault,
      };

      const response = await axios.patch(update_address(id), payload, config);
      addToast("Update Address successfully", {
        appearance: "success",
        autoDismiss: true,
        position: "top-center",
      });
      navigate("/my-profile?address=true");
      console.log(response);
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
        position: "top-center",
      });
      console.log(err);
    }
  };

  return (
    <Container>
      <Row justify="center">
        <Col lg={10} md={20} xs={24} sm={24} className="mt-5">
          <br />
          <br />
          <br />
          <br />
          <br />

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            innerRef={formikRef}
            onSubmit={(values, { resetForm }) => {
              updateAddress(values, resetForm);
            }}
          >
            {({
              values,
              setFieldValue,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => {
              return (
                <form className="my-account" onSubmit={handleSubmit}>
                  <div
                    className="backSec cursor-pointer"
                    onClick={() => navigate("/my-profile?address=true")}
                  >
                    {/* <img alt="icon" src="../../../../../" />  */}
                    🡠 Back
                  </div>
                  <div className="ant-form-item">
                    <input
                      type="text"
                      placeholder="Full Name"
                      name="fullName"
                      value={values.fullName}
                      className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <div className="ant-form-item-explain-error">
                      <ErrorMessage name="fullName" />
                    </div>
                  </div>

                  <div className="ant-form-item">
                    <input
                      type="text"
                      placeholder="Contact Number"
                      name="phone"
                      className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                      value={values.phone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <div className="ant-form-item-explain-error">
                      <ErrorMessage name="phone" />
                    </div>
                  </div>

                  <div className="ant-form-item">
                    <input
                      type="text"
                      placeholder="Pin Code"
                      name="pincode"
                      className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                      value={values.pincode}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <div className="ant-form-item-explain-error">
                      <ErrorMessage name="pincode" />
                    </div>
                  </div>

                  <div className="ant-form-item">
                    <input
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={values.address}
                      className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <div className="ant-form-item-explain-error">
                      <ErrorMessage name="address" />
                    </div>
                  </div>

                  <div className="ant-form-item">
                    <input
                      type="text"
                      placeholder="City"
                      name="city"
                      className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                      value={values.city}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <div className="ant-form-item-explain-error">
                      <ErrorMessage name="city" />
                    </div>
                  </div>

                  <div className="ant-form-item">
                    <select
                      className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                      onChange={(e) => {
                        formikRef.current.setFieldValue("state", "");
                        setFieldValue("country", e.target.value);
                        setSelectedCountry(e.target.value);
                      }}
                      value={values.country}
                    >
                      {allCountries.map((country) => {
                        return (
                          <option value={country._id}>
                            {country.countryName}
                          </option>
                        );
                      })}
                    </select>
                    <div className="ant-form-item-explain-error">
                      <ErrorMessage name="country" />
                    </div>
                  </div>

                  <div className="ant-form-item">
                    <select
                      className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                      onChange={(e) => {
                        setFieldValue("state", e.target.value);
                      }}
                      disabled={values.country === ""}
                      value={values.state}
                    >
                      {/* {values.state !== "" && (
                        <option value="">Select a state</option>
                      )} */}
                      {allStates.map((state) => {
                        return (
                          <option value={state.stateId}>
                            {state.stateName}
                          </option>
                        );
                      })}
                    </select>
                    <div className="ant-form-item-explain-error">
                      <ErrorMessage name="state" />
                    </div>
                  </div>

                  <div className="ant-form-item">
                    <input
                      type="text"
                      placeholder="Landmark"
                      name="landmark"
                      className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                      value={values.landmark}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <div className="ant-form-item-explain-error">
                      <ErrorMessage name="landmark" />
                    </div>
                  </div>

                  <div className="flxRedio">
                    <div className="mr20">
                      <input
                        type="radio"
                        name="addressType"
                        id={addressType[0]}
                        value={addressType[0]}
                        onBlur={handleBlur}
                        onChange={() => {
                          setFieldValue("addressType", addressType[0]);
                        }}
                        checked={values.addressType === addressType[0]}
                      />
                      <label
                        htmlFor={addressType[0]}
                        style={{ color: "white" }}
                      >
                        Home
                      </label>
                    </div>
                    <div className="mr20">
                      <input
                        type="radio"
                        name="addressType"
                        id={addressType[1]}
                        value={addressType[1]}
                        onBlur={handleBlur}
                        onChange={() => {
                          setFieldValue("addressType", addressType[1]);
                        }}
                        checked={values.addressType === addressType[1]}
                      />
                      <label
                        htmlFor={addressType[1]}
                        style={{ color: "white" }}
                      >
                        Work
                      </label>
                    </div>
                    <ErrorMessage name="addressType" />

                    <div className="swichSec">
                      <Switch
                        defaultChecked={values?.isDefault}
                        onChange={() =>
                          setFieldValue("isDefault", !values?.isDefault)
                        }
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="text-[20px] font-bold text-center w-full mt-2 bg-[#ff8500] transition-all duration-75 hover:bg-orange-600 text-white py-[10px] rounded-3xl"
                  >
                    Update Address
                  </button>
                </form>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export default EditAddress;
