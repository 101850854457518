import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../shoppingCart.scss";
import * as actionTypes from "../../../redux/types/cartSectionType";
import axios from "axios";
import {
  add_to_cart,
  get_address,
  get_all_cart_items,
} from "../../../api/Api-list";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { SpinnerCircular } from "spinners-react";

function AddressSelection({ previousStep, nextStep }) {
  const cartStep = useSelector((state) => state.cartSection.cartStep);

  const [loading, setLoading] = useState(false);
  const [allAddresses, setAllAddresses] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const app_token = localStorage.getItem("tiger-token");

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  useEffect(() => {
    getAllAddresses();
  }, []);

  const getAllAddresses = async () => {
    setLoading(true);
    try {
      const response = await axios.get(get_address, config);

      if (response.status < 400) {
        setAllAddresses(response.data.address);
        const defaultAddress = response.data.address.filter((address) => {
          return address.isDefault === true;
        });
        setSelectedAddressId(defaultAddress[0]._id);
        setLoading(false);
        await getSelectedAddress();
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getSelectedAddress = async () => {
    try {
      const response = await axios.get(get_all_cart_items, config);

      if (response.status < 400) {
        if (!response.data?.cart?.address) return;
        setSelectedAddressId(response.data?.cart?.address?._id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setAddress = async () => {
    setLoading(true);
    const payload = {
      address: selectedAddressId,
    };
    try {
      const response = await axios.post(add_to_cart, payload, config);
      if (response.status < 400) {
        await getSelectedAddress();
        dispatch({
          type: actionTypes.UPDATE_SHIPPING_RATES,
          payload: response.data.shippingRates,
        });
        nextStep();
        setLoading(false);

        dispatch({
          payload: 3,
          type: actionTypes.CART_STEP,
        });
      }
    } catch (err) {
      setLoading(false);
      addToast(err.response.data.message || err.response.data.msg, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-start">
          <SpinnerCircular
            size={50}
            thickness={100}
            speed={100}
            color="rgba(255, 133, 0, 1)"
            secondaryColor="rgba(0, 0, 0, 0.44)"
          />
        </div>
      ) : (
        <>
          {allAddresses.length > 0 ? (
            <div className="cart-step-adrs-sec">
              {allAddresses.map((address) => {
                return (
                  <>
                    <div className="no-border cart-add-dtl">
                      <h3>{address.fullName}</h3>
                      <p>
                        <span>Contact Number :</span> {address.phone}
                      </p>
                      <p>
                        <span>Address :</span> {address?.address},{" "}
                        {address?.city} - {address?.pincode},{" "}
                        {address?.state?.name}, {address?.country?.countryName}
                      </p>
                      <p>
                        <span>Address Type :</span> {address.addressType}
                      </p>
                      <div className="cart-step-radio">
                        <input
                          type="radio"
                          // checked={
                          //   (selectedAddressId === null &&
                          //     address.isDefault === true) ||
                          //   selectedAddressId === address._id
                          // }
                          checked={selectedAddressId === address._id}
                          onChange={() => {
                            setSelectedAddressId(address._id);
                          }}
                        />
                      </div>
                    </div>
                    <hr className="border-b-black border-white" />
                  </>
                );
              })}
              <div className="flex justify-center checkout_button_order mt-5">
                <button
                  className="text-lg  transition-all ease-in duration-150 hover:bg-orange-600 font-bold text-center w-full bg-[#FF8500] text-white py-2 rounded-full mx-2"
                  onClick={() => {
                    previousStep();

                    dispatch({
                      payload: 1,
                      type: actionTypes.CART_STEP,
                    });
                  }}
                >
                  Previous
                </button>
                <button
                  className="text-lg  transition-all ease-in duration-150 hover:bg-orange-600 font-bold text-center w-full bg-[#FF8500] text-white py-2 rounded-full mx-2"
                  onClick={() => {
                    setAddress();
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          ) : (
            <div className="w-full text-center flex flex-col items-center justify-center">
              <span className="text-lg text-gray-600">
                Add address to continue
              </span>

              <Link
                className="text-lg  transition-all ease-in duration-150 hover:bg-orange-600 font-bold text-center w-80 bg-[#FF8500] text-white py-2 rounded-full mt-3"
                to="/my-profile?address=true"
              >
                Add Address
              </Link>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default AddressSelection;
