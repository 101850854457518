import React from "react";
import Helmet from "react-helmet";
import { Button, Form, Input } from "antd";
import { postRequest } from "../../api/API";
import { useParams } from "react-router-dom";
import { changePassword } from "../../api/Api-list";
import { useToasts } from "react-toast-notifications";
import "./changepass.scss";
import { useNavigate } from "react-router-dom";

function ChangePassword() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  console.log(form);
  const ResetPassword = () => {
    form
      .validateFields()
      .then(async (values) => {
        // console.log(values);
        try {
          await postRequest(`${changePassword}/${id}`, {
            ...values,
          })
            .then((res) => {
              console.log(res);
              form.resetFields();
              addToast("Password has been Changed Successfully ", {
                appearance: "success",
                autoDismiss: true,
                position: "top-center",
              });
              navigate("/");
            })
            .catch((err) => {
              console.log("errror", err.response.data.msg);
              addToast("Page has been Expired ", {
                appearance: "error",
                autoDismiss: true,
                position: "top-center",
              });
            });
        } catch (err) {
          console.log("register err", err);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roaring Tiger Golf Club | RTGC</title>
      </Helmet>
      <div className="container  pt-[10rem] !bg-black changepass">
        <div className="row">
          <div className="col-md-12">
            <div className="row px-4">
              <div className="col-md-3">&nbsp;</div>
              <div
                className="col-md-6 p-4 rounded-lg "
                style={{
                  boxShadow: " 0rem 1rem 3rem 1rem rgba(244, 237, 227, 0.23)",
                }}
              >
                <div className="">
                  <div className="">
                    <h4 className="text-white pl-4 !text-[1.5rem] pt-[1.4rem] font-semibold">
                      Change Password
                    </h4>
                    <img
                      src="../images/dot_border.png"
                      className="pl-5 pt-2 pb-4"
                      alt="logo"
                    />
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <Form
                          className=""
                          onFinish={ResetPassword}
                          form={form}
                          autoComplete="off"
                          layout="vertical"
                          name="form_in_modal"
                          initialValues={{
                            modifier: "public",
                          }}
                        >
                          <Form.Item
                            id="password"
                            name="newPassword"
                            rules={[
                              {
                                required: true,
                                message: " New Password is Required",
                              },
                            ]}
                          >
                            <Input.Password placeholder="New Password" />
                          </Form.Item>
                          <Form.Item
                            id="confirmPassword"
                            name="confirmPassword"
                            rules={[
                              {
                                required: true,
                                message: "Confrim Password is Required",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("newPassword") === value
                                  ) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject(
                                    new Error("Passwords do not match!")
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password placeholder="Confirm Password" />
                          </Form.Item>
                          <Form.Item>
                            <button
                              type="submit"
                              className="!bg-orange-500 text-white border-orange-500 py-2 hover:bg-orange-600 rounded-lg  w-full  text-center register_button"
                            >
                              Change Password
                            </button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
