import React from "react";
import { Link } from "react-router-dom";
import "./sliderItem.scss";
import { useNavigate } from "react-router-dom";

const SliderItem = (props) => {
  const { item } = props;

  const navigate = useNavigate();

  return (
    <div
      className="top-ctgry-itm flex-col justify-center cursor-pointer items-center w-[170px] mx-auto space-y-2 group"
      onClick={() => {
        navigate(`/prop-category/${item?.categoryUrl}`);
      }}
    >
      <div className="w-full cursor-pointer">
        <img src={item.image} className="bject-cover rounded-xl" alt="team" />
      </div>
      <p className="top-ctgry-itm-text z-[1000]  flex items-center justify-center text-[18px] group-hover:!text-[#f97316]">
        {item.categoryName}
      </p>
    </div>
  );
};

export default SliderItem;
