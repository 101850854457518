import React, { useState } from "react";

import { Row, Col, Form, Input } from "antd";
import "./admin-login.scss";

import { useNavigate } from "react-router-dom";
import { AdminLogin } from "../../redux/actions/adminLoginRegisterActions";
import { useDispatch } from "react-redux";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

// import { useToasts } from "react-toast-notifications";

const layout = {};
// const tailLayout = {};

const Adminlogin = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // const selector = useSelector((state) => state.);
  // console.log(selector.user, "selector");

  // const handleSubmit = () => {
  //   dispatch(AdminLogin(email, password, history, addToast));
  // };

  const handleSubmit = () => {
    try {
      form
        .validateFields()
        .then(async (values) => {
          dispatch(AdminLogin(email, password, history));
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    } catch (err) {
      console.log("login err", err);
    }
  };

  return (
    <>
      <div className="container">
        <div className="login-container">
          <Row justify="center" align="middle">
            <Col xs={24} sm={16} md={13} lg={10}>
              <div className="admin-login">
                <h1>Admin LogIn</h1>
                <Form
                  name="basic"
                  {...layout}
                  form={form}
                  onFinish={handleSubmit}
                >
                  <Row gutter={[30]} className="flex-wrap">
                    <Col span={24}>
                      <Form.Item
                        className="login-form-row"
                        label="Email Id"
                        name="email"
                        rules={[
                          {
                            type: "email",
                            required: true,
                            message: "Please input your email!",
                          },
                        ]}
                      >
                        <Input
                          value={email}
                          type="text"
                          className="focus:!shadow-none "
                          placeholder="Enter your Email"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        className="login-form-row"
                        label="Password"
                        name="password"
                        rules={[
                          {
                            type:"password",
                            required: true,
                            min: 6,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <Input.Password
                          value={password}
                          name="password"
                          className="focus:!shadow-none"
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          placeholder="Enter your Password"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <button type="submit" className="custom-btn2">
                      Sign in
                    </button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default Adminlogin;
