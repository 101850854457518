import React, { useEffect, useState } from "react";
import editIcon from "../../../images/edit.png";
import { Col, message, Popconfirm, Row, Table } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  delete_a_state,
  get_all_countries,
  get_all_states,
} from "../../../../api/Api-list";
import Delete from "../../../images/delete.png";
import ReactSelect from "react-select";

const StateList = () => {
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [data, setData] = useState();
  const app_token = localStorage.getItem("tiger");
  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  useEffect(() => {
    getAllCountry();
  }, []);
  useEffect(() => {
    getAllStates(selectedCountry?.value);
  }, [selectedCountry?.value]);

  const getAllCountry = async () => {
    try {
      const response = await axios.get(get_all_countries, config);
      if (response.status < 400) {
        const countryOptions = response.data.countries.map((country) => {
          return {
            value: country._id,
            label: country.countryName,
          };
        });
        setCountryOptions(countryOptions);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllStates = async (id = "") => {
    try {
      const response = await axios.get(get_all_states(id), config);
      if (response.status < 400) {
        setData(response.data.states);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(delete_a_state(id), config);
      if (response.status < 400) {
        message.success(response.data.msg);
        getAllStates();
      }
    } catch (error) {
      console.log(error);
      message.error(error.response.data.msg);
    }
  };

  let columns = [
    {
      title: "Country Name",
      dataIndex: "countryName",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "State Name",
      dataIndex: "stateName",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "State Short Code",
      dataIndex: "stateSortCode",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "actions",
      render: (item, data, index) => {
        return (
          <div className="d-flex align-items-center">
            &nbsp;&nbsp;&nbsp;
            <Link to={`${data.stateId}`}>
              <img src={editIcon} alt="Edit" className="h-[15px]" />
            </Link>
            <Popconfirm
              title="Delete this item? This cannot be undone."
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(data.stateId)}
            >
              <img className="w-[16px] ml-[5px]" src={Delete} alt="delete" />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="prd-manage">
        <Row gutter={16}>
          <div className="flex items-center justify-between w-full px-2">
            <h3 className="m-0">All States</h3>

            <ReactSelect
              styles={{
                option: (provided) => ({
                  ...provided,
                  color: "black",
                }),
              }}
              className="stateText"
              value={selectedCountry}
              onChange={(e) => {
                setSelectedCountry(e);
              }}
              options={countryOptions}
              placeholder="Filter by country"
            />
            <Link to="/admin/add-state">
              <button className="add-btn">Add State</button>
            </Link>
          </div>
          <Col span={24} className="mt-4 user-table">
            <Table
              rowKey="_id"
              rowSelection={null}
              key={data?.length}
              columns={columns}
              defaultCurrent={1}
              dataSource={data || []}
              scroll={{ x: 1000 }}
              pagination={false}
              //   pagination={{
              //     pageSize: 10,
              //     total: totalPage,
              //     onChange: (page) => {
              //       getAllStates();
              //     },
              //   }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default StateList;
