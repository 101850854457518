import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { Upload, Select, Input, InputNumber, Form, Modal, message, Switch } from "antd";
import "./addproduct.scss";
import plusIcon from "../../images/plus-icon.png";
import deleteIcon from "../../images/delete.png";
// tiny Mce
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch, useSelector } from "react-redux";
import {
  CategoryList,
  fetchProducts,
  NewProductAdd,
} from "../../../redux/actions/productAction";
import axios from "axios";
import {
  addProduct,
  fetchAllActiveProducts,
  fetchAllProducts,
} from "../../../api/Api-list";
import imageToBase64 from "image-to-base64/browser";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";

import qs from "qs";
import { useToasts } from "react-toast-notifications";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";

function AddProduct() {
  const dispatch = useDispatch();
  const Admin_Token = localStorage.getItem("tiger");
  const allCategories = useSelector((state) => state?.allProducts?.categories);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [imgErr, setImgErr] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  // const [imgerr, setImgerr] = useState(false);
  const [saveNext, setsaveNext] = useState(false);
  const [allfieldValue, setAllFieldValue] = useState();
  const { addToast } = useToasts();

  const { TextArea } = Input;

  let [priceListField, setpriceListField] = useState([]);

  let tempArr = [];

  const [fileList, setFileList] = useState([
    {
      image: "",
      text: "",
    },
  ]);
  const [desc, setDesc] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [selectedRelatedProduct, setselectedRelatedProduct] = useState(null);

  // price list start

  const handleAddPriceListClick = () => {
    setpriceListField([
      ...priceListField,
      {
        color: "",
        size: "",
        price: "",
        // ethPrice: "",
        quantity: "",
      },
    ]);
  };

  let finalPriceData = [];

  const onHandleChangeaddPrice = (e, index) => {
    const { name, value } = e.target;
    const list = [...priceListField];
    list[index][name] = value;

    setpriceListField(list);
  };

  const handleRemoveFileList = (index) => {
    const list = [...fileList];
    list.splice(index, 1);
    setFileList(list);
  };


  const [checked, setChecked] = useState(true);
  const [isComing, setIsComing] = useState(false);
  const [isTop, setIsTop] = useState(false);
  const handleChangeActive = (checked) => {
    setChecked(checked);
  };


  const handleChangeIsComingSoon = (checked) => {
    setIsComing(checked);
  };

  // pricelist end

  const getAllProduct = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Admin_Token}`,
      },
    };
    try {
      const response = await axios.get(fetchAllProducts(1, 100), config);
      setAllProducts(response?.data?.allProduct);
    } catch (error) {
      console.log(error);
    }
  };

  // conver image to base64
  const imgtoBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const inputclass = "bg-transparent !py-[0.7rem] !rounded-[0.3rem] text-black";
  const inputnumberclass =
    "bg-transparent !py-[0.5rem] !w-[100%]  !rounded-[0.3rem] text-black";
  const [form] = Form.useForm();
  useEffect(() => {
    dispatch(CategoryList());
    getAllProduct();
  }, [dispatch]);

  const navigate = useNavigate();
  const beforeUpload = () => false;
  const handleCancel = () => setPreviewVisible(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    console.log(file, "filll");
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const conv64 = async (file) => {
    const returnImg = await imageToBase64(file); // Path to the image
    return returnImg;
  };

  const getThumbnailImage = async (e, fileIndex) => {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event) => {
        const list = [...fileList];
        list[fileIndex]["image"] = event.target.result;
        setFileList(list);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleChange = async (e, i, type) => {
    e.persist();

    if (type === "image") {
      if (e.target.files[0]) {
        getThumbnailImage(e, i);
      }
    } else {
      if (typeof e.target.value === "string") {
        const list = [...fileList];
        list[i]["text"] = e.target.value;
        setFileList(list);
      }
    }
  };

  const [descerror, setDescError] = useState(false);

  const submitProduct = async (e) => {
    if (desc === "") {
      setDescError(true);
    } else {
      // const tempArr = [];
      // for (let i = 0; i < fileList?.files.length; i++) {
      //   const base6Image = await imgtoBase64(
      //     fileList?.files?.[i].originFileObj
      //   );
      //   tempArr.push(base6Image);
      // }
      // const ImgArr = [];
      // for (let i = 0; i < tempArr.length; i++) {
      //   const datas = {
      //     image: tempArr[i],
      //   };
      //   ImgArr.push(datas);
      // }

      // const data = {
      //   images: ImgArr,
      // };
      const description = {
        description: desc,
      };

      form
        .validateFields()
        .then(async (e) => {
          if (desc.length === 0) {
            setDescError(true);
          } else {
            const values = await form.getFieldsValue();
            console.log(values, "formdata");
            console.log(description, "description");
            console.log(fileList, "files");

            let newPricelist = tempArr.flat();
            newPricelist?.map((ele) => ({
              color: ele.color,
              size: ele.size,
              price: Number(ele.price),
              // ethPrice: Number(ele.ethPrice),
              quantity: Number(ele.quantity),
            }));

            let formData = {
              categoryId: allfieldValue?.categoryId,
              color: allfieldValue?.color,
              images: fileList,
              // price: allfieldValue?.price,
              // priceEth: allfieldValue?.priceEth,
              pageUrl: allfieldValue?.producturl,
              isActive: checked,
              isTop: isTop,
              isComingSoon: isComing,
              size: allfieldValue?.size,
              title: allfieldValue?.title,
              description: desc,
              weight: allfieldValue?.weight,
              relatedProducts: selectedRelatedProduct?.map(
                (item) => item.value
              ),

              // relatedProducts: allfieldValue?.relatedproduct,
              priceList: newPricelist || [],

              metaInfo: {
                pageTitle: values.pageTitle,
                pageDesc: values.pageDesc,
                keywords: values.keywords
                  .map((item) => {
                    return item.value;
                  })
                  .join(","),
              },
            };

            try {
              const config = {
                headers: {
                  Authorization: `Bearer ${Admin_Token}`,
                },
              };
              let res = await axios.post(addProduct, formData, config);
              if (res.status < 400) {
                message.success(`${res.data.message}`);
                // getHomeData();
                // setFiles([])
                navigate("/admin/product-management");
              }
            } catch (error) {
              message.error(`${error?.response?.data?.message}`);
            }
            // dispatch(
            //   NewProductAdd({ ...e, ...data, ...description }, Admin_Token)
            // );
            // form.resetFields();
            // setFileList([]);
            // setDesc("");
          }
          // setTimeout(() => {
          //   navigate("/admin/product-management");
          //   dispatch(fetchProducts());
          // }, 2000);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
          message.error(`${info?.response?.data?.msg}`);
        });
    }
  };
  const uploadButton = (
    <div className="dft">
      <AiOutlinePlus className="!text-black mx-auto !text-[1.4rem]" />
      <div className="mt-1">Upload</div>
    </div>
  );
  const handleDesc = (e) => {
    setDesc(e); // set desc to state
    if (e.length < 1) {
      setDescError(true);
    } else {
      setDescError(false);
    }
  };

  return (
    <>
      <div className="container-fluid edit_product">
        <div className="row">
          <h2 className="cms-title">Add product</h2>

          <div className="col-12 col-sm-12 col-md-12 bg-white shadow-sm p-5 cms-box">
            <div className="stapesSec">
              <ul>
                <li className={!saveNext ? "active" : ""}>
                  <span>01</span> Step 1
                </li>
                <li className={saveNext ? "active" : ""}>
                  <span>02</span> Step 2
                </li>
                {/* <li><span>03</span> Submit</li> */}
              </ul>
            </div>

            <Form layout="vertical" form={form} onFinish={submitProduct}>
              {saveNext == false ? (
                <>
                  <Form.Item
                    name="title"
                    type="text"
                    label=" Product Title"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input placeholder="Title" className={inputclass} />
                  </Form.Item>
                  <div className="my-3">
                    <label className="text-black font-bold mb-2">
                      Product Description
                    </label>
                    <Editor
                      className="absolute top-[-1rem]"
                      name="description"
                      value={desc}
                      onEditorChange={(e) => handleDesc(e)}
                      init={{
                        height: 300,
                        automatic_uploads: true,
                        file_picker_types: "file image media",
                        advlist_bullet_styles: "square",
                        advlist_number_styles:
                          "lower-alpha,lower-roman,upper-alpha,upper-r",
                        /* and here's our custom image picker*/
                        toolbar_mode: "sliding",
                      }}
                      plugins={[
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                        "textcolor",
                        "textpattern",
                        "image",
                        "imageupload",
                        "link",
                        "help",
                        "wordcount",
                        "code",
                        "preview",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "paste",
                        "charmap",
                        "searchreplace",
                        "visualblocks",
                        "emoticons",
                      ]}
                      toolbar={[
                        "undo redo | formatselect | bold italic backcolor forecolor   |alignleft aligncenter alignright alignjustify | fonts | bullist numlist outdent indent | removeformat | image| help",
                        "link imageupload | fontselect | fontsizeselect |fullscreen | insertdatetime | media | wordcount | charmap | searchreplace | visualblocks |  table | code | preview |emoticons |",
                      ]}
                    />
                  </div>
                  {descerror ? (
                    desc === "" ? (
                      <div className="text-[#F10606]">
                        This field is required
                      </div>
                    ) : null
                  ) : (
                    ""
                  )}
                  {/* </Form.Item> */}
                  <Form.Item
                    label="Category"
                    name="categoryId"
                    type="select"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Select placeholder="Select Product Category">
                      {allCategories?.data?.map((item) => (
                        <Select.Option key={item._id} value={item._id}>
                          {item.categoryName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <>
                    <Row>
                      {/* <Col md={4}>
                        <Form.Item
                          type="number"
                          name="price"
                          label="Price in $"
                          className="dft"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <InputNumber
                            min={1}
                            placeholder="Price"
                            className={inputnumberclass}
                          />
                        </Form.Item>
                      </Col> */}
                      {/* <Col md={4}>
                        <Form.Item
                          type="number"
                          name="priceEth"
                          label="Price in Eth"
                          className="dft"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <InputNumber
                            min="0"
                            step="0.01"
                            placeholder="Price"
                            className={inputnumberclass}
                            stringMode
                          />
                        </Form.Item>
                      </Col> */}

                      {/* <Col md={4}>
                        <Form.Item
                          type="number"
                          name="quantity"
                          label="Quantity"
                          className="dft"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder="Quantity"
                            className={inputnumberclass}
                            stringMode
                          />
                        </Form.Item>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Item
                          type="text"
                          label="Color"
                          name="color"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            className="
                      !leading-[2.563rem] !rounded-lg"
                            placeholder="Select Color"
                            onChange={(values) => {
                              setSelectedColor(values);
                            }}
                          >
                            <Select.Option value="NA">N/A</Select.Option>
                            <Select.Option
                              disabled={
                                selectedColor?.includes("NA") ? true : false
                              }
                              value="blue"
                            >
                              Blue
                            </Select.Option>

                            <Select.Option
                              disabled={
                                selectedColor?.includes("NA") ? true : false
                              }
                              value="brown"
                            >
                              Brown
                            </Select.Option>

                            <Select.Option
                              disabled={
                                selectedColor?.includes("NA") ? true : false
                              }
                              value="green"
                            >
                              Green
                            </Select.Option>

                            <Select.Option
                              disabled={
                                selectedColor?.includes("NA") ? true : false
                              }
                              value="grey"
                            >
                              Grey
                            </Select.Option>

                            <Select.Option
                              disabled={
                                selectedColor?.includes("NA") ? true : false
                              }
                              value="red"
                            >
                              Red
                            </Select.Option>

                            <Select.Option
                              disabled={
                                selectedColor?.includes("NA") ? true : false
                              }
                              value="yellow"
                            >
                              Yellow
                            </Select.Option>


                            <Select.Option
                              disabled={
                                selectedColor?.includes("NA") ? true : false
                              }
                              value="black"
                            >
                              Black
                            </Select.Option>
                            <Select.Option
                              disabled={
                                selectedColor?.includes("NA") ? true : false
                              }
                              value="white"
                            >
                              White
                            </Select.Option>
                            <Select.Option
                              disabled={
                                selectedColor?.includes("NA") ? true : false
                              }
                              value="orange"
                            >
                              Orange
                            </Select.Option>
                            <Select.Option
                              disabled={
                                selectedColor?.includes("NA") ? true : false
                              }
                              value="pink"
                            >
                              Pink
                            </Select.Option>
                            <Select.Option
                              disabled={
                                selectedColor?.includes("NA") ? true : false
                              }
                              value="purple"
                            >
                              Purple
                            </Select.Option>

                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          type="select"
                          name="size"
                          label="Size"
                          className="!rounded-[13px]"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Select
                            className="
                        !leading-[2.563rem] !rounded-lg"
                            mode="multiple"
                            placeholder="Select Size"
                            onChange={(values) => {
                              setSelectedSize(values);
                              console.log(
                                new Array(selectedColor?.length),
                                "newaarrr"
                              );
                              tempArr = new Array(selectedColor?.length);
                              console.log(tempArr, "price list field");
                              console.log(selectedColor?.length);
                            }}
                          >
                            {selectedColor?.includes("free") && (
                              <Select.Option value="free">
                                Free Size
                              </Select.Option>
                            )}
                            <Select.Option value="XS">XS</Select.Option>
                            <Select.Option value="S">S</Select.Option>
                            <Select.Option value="M">M</Select.Option>
                            <Select.Option value="L">L</Select.Option>
                            <Select.Option value="XL">XL</Select.Option>
                            <Select.Option value="XXL">XXL</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>

                  <Form.Item label="Product Image" className="row-border-box">
                    <Row className="align-items-center">
                      {fileList?.map((x, i) => (
                        <>
                          <Col md={1}>
                            <div className="productNoImg">
                              <img src={x.image ? x?.image : '/images/noimg.png'} />
                            </div>
                          </Col>
                          {/* <Upload
                  listType="picture-card"
                  fileList={x?.files}
                  onPreview={handlePreview}
                  onChange={(e) => handleChange(e, i, fileList)}
                  beforeUpload={beforeUpload}
                  name="images"
                  // name="files"
                  type="file"
                >
                  {fileList?.files?.length >= 8 ? null : uploadButton}
                </Upload> */}
                          <Col md={5}>
                            <div className="mb-3">
                              <label>
                                <strong>Add New Image or Video</strong>
                              </label>
                              {/* <label for="file-upload" class="custom-file-upload">
                              <CloudUploadOutlined /> Choose File to Upload
                              </label> */}
                              <input
                                name="image"
                                type="file"
                                // id="file-upload"
                                className="form-control"
                                onChange={(e) => {
                                  handleChange(e, i, "image");
                                  setImgErr("");
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="mb-3">
                              <label>
                                <strong>Add Text</strong>
                              </label>

                              <input
                                name="text"
                                type="text"
                                value={x?.text}
                                className="form-control"
                                onChange={(e) => handleChange(e, i, "text")}
                              />
                            </div>
                          </Col>
                          <Col md={1}>
                            <div className="add-delete-btn-clm">
                              {fileList.length - 1 === i && (
                                <button
                                  className="transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl add-product-btn"
                                  onClick={() => {
                                    if (!fileList?.[i]?.image) {
                                      addToast("Please Choose Image", {
                                        appearance: "error",
                                        autoDismiss: true,
                                      });
                                      return;
                                    }
                                    setFileList([
                                      ...fileList,
                                      { image: "", text: "" },
                                    ]);
                                  }}
                                >
                                  <img
                                    src={plusIcon}
                                    alt="Add"
                                    className="h-[23px]"
                                  />
                                </button>
                              )}
                              <span onClick={() => handleRemoveFileList(i)}>
                                <img
                                  src={deleteIcon}
                                  alt="Delete"
                                  className="h-[23px]"
                                />
                              </span>
                            </div>
                          </Col>
                          <p className="relative text-[#f10606] mt-[-2rem]">
                            {imgErr ? imgErr : ""}
                          </p>
                        </>
                      ))}

                      <div className="text-center">
                        <Col md={12}>
                          {
                            !fileList?.length ? (
                              <button
                                type="button"
                                className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] "
                                onClick={() =>
                                  setFileList([
                                    ...fileList,
                                    { image: "", text: "" },
                                  ])
                                }
                              >
                                Add Images
                              </button>
                            ) : ""
                          }
                        </Col></div>
                    </Row>

                    <Modal
                      visible={previewVisible}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="example"
                        className="w-full"
                        src={previewImage}
                      />
                    </Modal>
                  </Form.Item>
                  {/* {imgErr ? (
                    <p className="relative text-[#f10606] mt-[-2rem]">
                      This field is required
                    </p>
                  ) : (
                    ""
                  )} */}

                  <Form.Item
                    name="isActive"
                    type="switch"
                    initialValue={checked}
                    label="Status"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Switch
                      checkedChildren="Active"
                      checked={checked}
                      unCheckedChildren="Inactive"
                      onChange={handleChangeActive}
                    />
                  </Form.Item>



                  <Form.Item
                    name="isComing"
                    type="switch"
                    initialValue={isComing}
                    label="Is Coming Soon"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Switch
                      checkedChildren="Yes"
                      checked={isComing}
                      unCheckedChildren="No"
                      onChange={handleChangeIsComingSoon}
                    />
                  </Form.Item>

                  <Form.Item
                    name="isTop"
                    type="switch"
                    initialValue={isTop}
                    label={`Is Feature Item`}
                  // rules={[{ required: true, message: "This field is required" }]}
                  >
                    <Switch
                      checkedChildren="Enable Feature"
                      checked={isTop}
                      unCheckedChildren="Disable feature"
                      onChange={(checked) => setIsTop(checked)}
                    />
                  </Form.Item>




                  <Form.Item
                    name="producturl"
                    type="text"
                    label="Product URL"
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(
                          /^[a-zA-Z-]+$/i
                        ),
                        message: "This field is required & Symbol Not Allowed (Ex: @ % // )"
                      },
                    ]}
                  >
                    <Input placeholder="Product URL" className={inputclass} />
                  </Form.Item>
                  <small className="url-error-msg">
                    Please Enter URL properly (Ex - roaringtiger)
                  </small>

                  <Form.Item
                    name="weight"
                    type="text"
                    label="weight"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input
                      placeholder="Weight"
                      className={inputclass}
                      suffix="Gram"
                    />
                  </Form.Item>

                  <label>
                    <b>Related Products</b>
                  </label>
                  <ReactSelect
                    //  defaultValue={selectedRelatedProduct?.map((item) => ({
                    //   value: item._id,
                    //   label: item.title,
                    // }))}
                    value={selectedRelatedProduct?.map((item) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                    options={allProducts?.data?.map((item) => ({
                      value: item._id,
                      label: item.title,
                    }))}
                    isMulti
                    onChange={(e) => {
                      console.log(e);
                      setselectedRelatedProduct(e);
                    }}
                  />
                  <br />

                  {/* <Form.Item
                    type="text"
                    label="Related Product"
                    name="relatedProducts"
                  >
                    <Select
                      mode="multiple"
                      className="
                    !leading-[2.563rem] !rounded-lg"
                      placeholder="Select Product"
                    >
                      {allProducts?.data?.length ? (
                        allProducts?.data?.map((ele) => (
                          <Select.Option value={ele?._id}>
                            {ele.title}
                          </Select.Option>
                        ))
                      ) : (
                        <Select.Option value="">No Product Found</Select.Option>
                      )}
                    </Select>
                  </Form.Item> */}

                  <span
                    type="button"
                    className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] "
                    onClick={() => {
                      const values = form.getFieldsValue();

                      const regex = new RegExp(/^\S*$/);

                      setAllFieldValue(values);
                      if (!regex.test(values?.producturl)) {
                        message.error(
                          "Please Enter a valid URL (Ex - roaringtiger)"
                        );
                        return;
                      }

                      let f = form
                        .validateFields()
                        .then((res) => {
                          console.log(res, "ress");
                          Object.keys(res).map(function (key) {
                            if (res[key] == undefined) {
                              setsaveNext(false);
                            } else if (!fileList?.[0]?.image) {
                              setImgErr("This field is required");
                              return;
                            } else {
                              setsaveNext(true);
                            }
                          });
                        })
                        .catch((err) => {
                          console.log(err);
                        });

                      // console.log(f, 'ffff')
                    }}
                  >
                    Next
                  </span>
                </>
              ) : (
                <>
                  <div className="row-border-box add-prdct-price-color-row product-table">
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th className="color-clm">Color</th>
                          <th className="size-clm">size</th>
                          <th className="usd-clm">Price ($)</th>
                          {/* <th className="eth-clm">Price Eth</th> */}
                          <th className="qua-clm">quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedColor?.map((item, index) => {
                          tempArr[index] = new Array(selectedSize?.length);
                          return (
                            <>
                              {selectedSize?.map((subitem, i) => {
                                tempArr[index][i] = {
                                  color: item,
                                  size: subitem,
                                  price: 0,
                                  // ethPrice: 0,
                                  quantity: 0,
                                };
                                return (
                                  <tr key={index}>
                                    <td className="text-capitalize">{item}</td>
                                    <td>{subitem}</td>
                                    <td>
                                      <input
                                        name="price[]"
                                        min="0"
                                        type="number"
                                        placeholder={`Price`}
                                        className={inputnumberclass}
                                        onChange={(e) => {
                                          tempArr[index][i].price =
                                            e.target.value;
                                          finalPriceData = [].concat(
                                            ...tempArr
                                          );
                                        }}
                                      />
                                    </td>

                                    {/* <td>
                                      <input
                                        name="ethPrice"
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        placeholder="Price Eth"
                                        className={inputnumberclass}
                                        onChange={(e) => {
                                          tempArr[index][i].ethPrice =
                                            e.target.value;
                                          finalPriceData = [].concat(
                                            ...tempArr
                                          );
                                        }}
                                      />
                                    </td> */}

                                    <td>
                                      <input
                                        name="Quantity"
                                        min="0"
                                        type="number"
                                        placeholder="quantity"
                                        className={inputnumberclass}
                                        onChange={(e) => {
                                          tempArr[index][i].quantity =
                                            e.target.value;
                                          finalPriceData = [].concat(
                                            ...tempArr
                                          );
                                        }}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <Form.Item
                    name="pageTitle"
                    type="text"
                    label="Page Title"
                  // rules={[
                  //   { required: true, message: "This field is required" },
                  // ]}
                  >
                    <Input placeholder="Page Title" className={inputclass} />
                  </Form.Item>

                  <Form.Item
                    name="pageDesc"
                    type="text"
                    label="Page Description"
                  // rules={[
                  //   { required: true, message: "This field is required" },
                  // ]}
                  >
                    <TextArea
                      placeholder="Page Description"
                      className={inputclass}
                    />
                  </Form.Item>

                  <Form.Item
                    name="keywords"
                    label="Keywords"
                  // rules={[
                  //   { required: true, message: "This field is required" },
                  // ]}
                  >
                    <CreatableSelect
                      className={inputclass}
                      isMulti
                      // onChange={(newValue) => {
                      //   console.log(newValue);
                      // }}
                      noOptionsMessage={() => null}
                    />
                  </Form.Item>

                  <Form.Item>
                    <button
                      type="submit"
                      className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] "
                    // onClick={submitProduct}
                    >
                      Add Product
                    </button>
                  </Form.Item>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddProduct;
