import { ActionType } from "../types/productType";

const initialstate = {
  products: null,
  categories: [],
  addcategory: [],
  updatecategory: [],
  addproducts: [],
  selectproducts: [],
  selectsingleproduct: [],
  deleteproducts: [],
  updateproduct: [],
  deleteproductimage: [],
  selectsinglecategory: [],
  getactiveproducts: [],
  oneEth: 0,
};
export const ProductReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
    case ActionType.SET_PRODUCTS:
      return { ...state, products: payload };
    case ActionType.PRODUCT_CATEGORY:
      return { ...state, categories: payload };
    case ActionType.ADD_PRODUCT:
      return { ...state, addproducts: payload };
    case ActionType.SELECT_PRODUCT:
      return { ...state, selectproducts: payload };
    case ActionType.SELECT_SINGLE_PRODUCT:
      return { ...state, selectsingleproduct: payload };
    case ActionType.UPDATE_PRODUCT:
      return { ...state, updateproduct: payload };
    case ActionType.DELETE_PRODUCT:
      return { ...state, deleteproducts: payload };
    case ActionType.DELETE_PRODUCT_IMAGE:
      return { ...state, deleteproductimage: payload };
    case ActionType.ADD_CATEGORY:
      return { ...state, addcategory: payload };
    case ActionType.UPDATE_CATEGORY:
      return { ...state, updatecategory: payload };
    case ActionType.SELECT_SINGLE_CATEGORY:
      return { ...state, selectsinglecategory: payload };
    case ActionType.GET_ACTIVE_PRODUCTS:
      return {
        ...state,
        getactiveproducts: payload.allProduct,
        oneEth: payload.oneEth,
      };

    default:
      return state;
  }
};
