import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { holdersPageInitiate } from "../../../redux/actions/holderPageAction";
import BenefitPopup from "../benefit popup/BenefitPopup";
import "./benefitMeet.scss";
const BenefitMeet = () => {
  const dispatch = useDispatch();
  const holderPages =
    useSelector((state) => state?.holdersPage?.holdersContent) || [];
  console.log("holdersPageInitiate meet", holderPages);

  useEffect(() => {
    dispatch(holdersPageInitiate());
  }, [dispatch]);
  return (
    <div className="benefit_meet">
      <div className="container">
        <Container>
          <Row>
            <div className="col-lg-6 col-md-6 col-12 ">
              {
                holderPages?.data && holderPages?.data[0]?.image[0] !== undefined ? (
                  <img
                    src={holderPages?.data && holderPages?.data[0]?.image[0].item}
                    alt="tiger"
                    className=" mx-auto "
                  />
                ) : ""
              }

            </div>
            <div className="flex items-center justify-center col-lg-6 col-md-6 col-12">
              {holderPages?.data &&
                holderPages?.data[0]?.title?.map((item) => {
                  console.log(item);
                  return (
                    <div className="rtgc_content">
                      <h2 className="font-bold text-white text-left  mt-4 heading-design position-relative">
                        {item.heading}
                      </h2>
                      <div
                        className="text-gray-300 sm:text-base text-md my-4"
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      />
                      <div>
                        <BenefitPopup
                          buttonText={holderPages?.data[0]?.buttonText}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BenefitMeet;
