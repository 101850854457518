import {
  CMS_PAGE_GET_ALL_PAGE_CONTENT,
  CMS_PAGE_SECTION_CONTENT_GET,
  CMS_PAGE_SECTION_CONTENT_UPDATE,
  CMS_IMAGE_DELETE
} from "../types/cmsPageType";

const initState = {
  allPageContent: [],
  pageSection: [],
  deleteImage: [],
};
const cmsPageReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case CMS_PAGE_GET_ALL_PAGE_CONTENT:
      return {
        ...state,
        allPageContent: payload,
      };
    case CMS_PAGE_SECTION_CONTENT_UPDATE:
      return {
        ...state,
        pageSection: payload,
      };
    case CMS_PAGE_SECTION_CONTENT_GET:
      return {
        ...state,
        pageSection: payload,
      };
    case CMS_IMAGE_DELETE:
      return {
        ...state,
        deleteImage: payload,
      };

    default:
      return {
        ...state,
        payload: payload,
      };
  }
};
export default cmsPageReducer;
