import React from "react";

import "./BillingDetail.scss";

const BillingDetail = () => {
  return (
    <div>
      <div className="mx-0 mr-[0] grid grid-flow-col grid-rows-5  gap-4 xs:mx-0 sm:mx-4 sm:mr-[0] md:mx-[3] md:mr-[1rem] lg:mx-0 lg:mr-[2rem] ">
        <div className="col-span-1 row-span-4 justify-center rounded-[10px] border-[2px] border-stone-800 bg-[#fdfdfd10]  px-7 py-4 text-left ">
          <h2 className="heading-pattern position-relative text-[1.2rem] text-white ">
            Billing Detail
          </h2>

          <form className="mt-5">
            <div className="grid grid-cols-1 gap-1">
              <label className="text-left text-[14px] text-white">
                Your First & Last Name
              </label>
              <input
                type="text"
                className="pl-2 focus:outline-none h-[46px] rounded-[10px] border-[2px] border-[#6D6D6D] bg-transparent text-left text-white focus:border-[2px] focus:border-[#6D6D6D] focus:outline-1 focus:ring-transparent"
              />
            </div>
            <div className="my-3 grid grid-cols-1 gap-1">
              <label className="text-left text-[14px] text-white">
                Email Address
              </label>
              <input
                type="email"
                className="pl-2 focus:outline-none h-[46px] rounded-[10px] border-[2px] border-[#6D6D6D] bg-transparent text-left text-white focus:border-[2px] focus:border-[#6D6D6D] focus:outline-1 focus:ring-transparent"
              />
            </div>
            <div className="my-3 grid grid-cols-1 gap-1">
              <label className="text-left text-[14px] text-white">
                Mobile Number
              </label>
              <input
                type="text"
                className="pl-2 focus:outline-none border-[#6D6D6D]  h-[46px] rounded-[10px] border-[2px] bg-transparent text-left text-white focus:border-[2px] focus:border-[#6D6D6D] focus:outline-1 focus:ring-transparent"
              />
            </div>
            <div className="my-3 grid grid-cols-1 gap-1">
              <label className="text-left text-[14px] text-white">
                Address
              </label>
              <input
                type="text"
                className="pl-2 focus:outline-none h-[46px]  rounded-[10px] border-[2px] border-[#6D6D6D] bg-transparent text-left text-white focus:border-[2px] focus:border-[#6D6D6D] focus:outline-1 focus:ring-transparent"
              />
            </div>
            <div className="my-3 grid grid-cols-2 gap-4">
              <div className="grid grid-cols-1 gap-1">
                <label className="text-left text-[14px] text-white">City</label>
                <select className="h-[46px]  rounded-[10px] border-[2px] border-transparent bg-[#191919] text-left text-white focus:border-[2px] focus:border-transparent focus:outline-1 focus:ring-transparent">
                  <option>Select City</option>
                  <option>City1</option>
                  <option>City2</option>
                  <option>City 3</option>
                </select>
              </div>
              <div className="grid grid-cols-1 gap-1">
                <label className="text-left text-[14px] text-white">
                  State/Province/Region
                </label>
                <select className="h-[46px]  rounded-[10px] border-[2px] border-transparent bg-[#191919] text-left text-white focus:border-[2px] focus:border-transparent focus:outline-1 focus:ring-transparent">
                  <option>Select State</option>
                  <option>State1</option>
                  <option>State2</option>
                  <option>States3</option>
                </select>
              </div>
            </div>
            <div className="my-3 grid grid-cols-1 gap-1">
              <label className="text-left text-[14px] text-white">
                Post Code
              </label>
              <input
                type="text"
                className="pl-2 focus:outline-none h-[46px]  rounded-[10px] border-[2px] border-[#6D6D6D] bg-transparent text-left text-white focus:border-[2px] focus:border-[#6D6D6D] focus:outline-1 focus:ring-transparent"
              />
            </div>
          </form>
        </div>
        {/* Shipping Address Box */}
        <div className="row-span-1  rounded-[10px] border-[2px] border-stone-800 bg-[#fdfdfd10] px-7  pb-[10px] text-left  ">
          <h2 className="heading-pattern position-relative mt-[1.5rem] text-[1.2rem] text-white">
            Shipping Address
          </h2>

          <div className="mt-[1rem] flex items-center">
            <input
              type="checkbox"
              className="accent-orange-500 bg-tansparent mr-2 border-[2px] border-[#6D6D6D] bg-transparent text-orange-500  shadow-transparent outline-2 focus:border-transparent focus:shadow-transparent focus:ring-transparent"
              name="sameshiping"
            />
            <label className="text-left text-[14px] text-white">
              Same as Shipping
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingDetail;
