import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import CartItem from "../../../components/proshop-page/shopping cart/cart-item/CartItem";
import { useDispatch, useSelector } from "react-redux";
import "../shoppingCart.scss";
import styled from "styled-components";
import * as actionTypes from "../../../redux/types/cartSectionType";
import axios from "axios";
import {
  add_to_cart,
  get_all_cart_items,
  remove_a_cart_item,
  get_all_cart_items_with_cookie,
  remove_a_cart_item_with_cookie,
  add_to_cart_with_cookie,
} from "../../../api/Api-list";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getTotalCartItem } from "../../../redux/actions/cartSectionAction";
import { SpinnerCircular } from "spinners-react";
import { useCookies } from "react-cookie";
import * as Modals from "../../../components/modals";
import { useLocation, useNavigate } from "react-router-dom";

const token = "tiger-token" in localStorage;

function CartItemsList({ nextStep }) {
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCartAmount, setTotalCartAmount] = useState({ usd: 0, eth: 0 });
  const [oneEth, setOneEth] = useState(0);
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [user, setUser] = useState(token);
  const [NavbarContainer, setNavbarContainer] = useState(
    "navbar_container menu_bar text-white border-gradient md:ml-[7.7rem]"
  );

  const cartStep = useSelector((state) => state.cartSection.cartStep);

  const isAuthenticated = useSelector(
    (state) => state.userProfile.isAuthenticated
  );

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const location = useLocation();
  const navigate = useNavigate();

  const app_token = localStorage.getItem("tiger-token");
  const rtgcCartId = localStorage.getItem("rtgcCartId");

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  useEffect(async () => {
    if (location.pathname === "/my-profile" && app_token === null) {
      setLoginModalVisible(true);
    }

    if (location?.hash && app_token === null) {
      setLoginModalVisible(true);
    }
  }, [location.pathname, location?.hash, app_token]);

  useEffect(() => {
    localStorage.getItem("tiger-token")
      ? setNavbarContainer(
          "navbar_container menu_bar text-white border-gradient"
        )
      : setNavbarContainer(
          "navbar_container menu_bar text-white border-gradient md:ml-[7.7rem]"
        );
  }, []);

  const hideLoginModal = () => {
    setLoginModalVisible(false);
    if (location?.hash == "#?show=true" && app_token === null) {
      setLoginModalVisible(false);
    }
    location.hash = "";
  };

  const handleLogin = () => {
    setLoginModalVisible(true);
  };

  useEffect(() => {
    getAllCartItems();
  }, []);

  const getAllCartItems = async () => {
    setLoading(true);
    if (isAuthenticated) {
      try {
        const response = await axios.get(get_all_cart_items, config);

        if (response.status < 400) {
          setCart(response.data.cart.items);
          setTotalCartAmount({
            usd: response.data.details.totalPriceAmount,
            eth: response.data.details.totalEthAmount,
          });
          setOneEth(response.data.oneETH);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    } else if (!isAuthenticated && rtgcCartId !== null) {
      try {
        const response = await axios.get(
          get_all_cart_items_with_cookie(rtgcCartId)
        );

        if (response.status < 400) {
          setCart(response.data.cart.items);
          setTotalCartAmount({
            usd: response.data.details.totalPriceAmount,
            eth: response.data.details.totalEthAmount,
          });
          setOneEth(response.data.oneETH);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  const removeCartItem = async (id) => {
    if (isAuthenticated) {
      try {
        const response = await axios.delete(remove_a_cart_item(id), config);
        if (response.status < 400) {
          addToast(response.data.msg, {
            appearance: "success",
            autoDismiss: true,
          });
          getAllCartItems();
          dispatch(getTotalCartItem(app_token, isAuthenticated, rtgcCartId));
        }
      } catch (err) {
        console.log(err);
      }
    } else if (!isAuthenticated && rtgcCartId !== null) {
      try {
        const response = await axios.delete(
          remove_a_cart_item_with_cookie(rtgcCartId, id)
        );
        if (response.status < 400) {
          addToast(response.data.msg, {
            appearance: "success",
            autoDismiss: true,
          });
          getAllCartItems();
          dispatch(getTotalCartItem(app_token, isAuthenticated, rtgcCartId));
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const incQuantity = async (productId, priceTierId, quantity) => {
    if (isAuthenticated) {
      const payload = {
        items: {
          productId: productId,
          priceTierId: priceTierId,
          quantity: Number(quantity),
        },
      };

      try {
        const response = await axios.post(add_to_cart, payload, config);
        if (response.status < 400) {
          getAllCartItems();
        }
      } catch (err) {
        addToast(err.response.data.msg || err.response.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else if (!isAuthenticated && rtgcCartId !== null) {
      const payload = {
        cookieId: rtgcCartId,
        items: {
          productId: productId,
          priceTierId: priceTierId,
          quantity: Number(quantity),
        },
      };

      try {
        const response = await axios.post(add_to_cart_with_cookie, payload);
        if (response.status < 400) {
          getAllCartItems();
        }
      } catch (err) {
        addToast(err.response.data.msg || err.response.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };

  const decQuantity = async (productId, priceTierId, quantity) => {
    if (quantity < 1)
      return addToast("Quantity must be greater than 0", {
        appearance: "error",
        autoDismiss: true,
      });
    if (isAuthenticated) {
      const payload = {
        items: {
          productId: productId,
          priceTierId: priceTierId,
          quantity: Number(quantity),
        },
      };

      try {
        const response = await axios.post(add_to_cart, payload, config);
        if (response.status < 400) {
          getAllCartItems();
        }
      } catch (err) {
        addToast(err.response.data.msg || err.response.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else if (!isAuthenticated && rtgcCartId !== null) {
      const payload = {
        cookieId: rtgcCartId,
        items: {
          productId: productId,
          priceTierId: priceTierId,
          quantity: Number(quantity),
        },
      };

      try {
        const response = await axios.post(add_to_cart_with_cookie, payload);
        if (response.status < 400) {
          getAllCartItems();
        }
      } catch (err) {
        addToast(err.response.data.msg || err.response.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };

  if (loading)
    return (
      <div className="flex justify-center items-start">
        <SpinnerCircular
          size={50}
          thickness={100}
          speed={100}
          color="rgba(255, 133, 0, 1)"
          secondaryColor="rgba(0, 0, 0, 0.44)"
        />
      </div>
    );

  return (
    <Row className="mt-5">
      {cart.length > 0 ? (
        <>
          <Col xl="9" lg="12" md="12" sm="12">
            <div className=" items-center row hidden  pl-[23px] product_info_heading ">
              <h6 className=" col-lg-7 col-md-7 col-12 text-white text-[18px] ">
                Product
              </h6>
              <h6 className="col-lg-3 col-md-3 col-12 text-white text-[18px]">
                Quantity
              </h6>
              <h6 className="cart-price-clm col-lg-2 col-md-2 col-12 text-white text-[18px]">
                Price
              </h6>
            </div>
            {cart.map((item, key) => (
              <CartItem
                key={key}
                item={item}
                oneEth={oneEth}
                removeCartItem={() => {
                  removeCartItem(item._id);
                }}
                incQuantity={() => {
                  incQuantity(
                    item.product._id,
                    item.priceTier._id,
                    item.quantity + 1
                  );
                }}
                decQuantity={() => {
                  decQuantity(
                    item.product._id,
                    item.priceTier._id,
                    item.quantity - 1
                  );
                }}
              />
            ))}
          </Col>
          <Col xl="3" lg="12" md="12" sm="12">
            <Wrapper className="max-w-[29.375rem] mx-auto w-full  text-white order_summary">
              <div className="w-full flex flex-col bg-[#191919] bg-opacity-75 text-white rounded-xl p-4">
                <div className="text-white font-bold text-[20px] ">
                  Order Summary
                </div>
                <span className="text-[15px] text-gray-500">
                  {cart.length} {cart.length > 1 ? "Products" : "Product"}
                </span>

                <div className=" product_detail">
                  <hr className="border-b-black border-white" />
                  {cart.map((item) => {
                    return (
                      <>
                        <div className="flex justify-between">
                          <span>{item.product.title}</span>
                          <span className="flex items-center justify-center">
                            ${(item.quantity * item.priceTier.price).toFixed(2)}{" "}
                            (
                            <img
                              src="/images/eth-icon.png"
                              className="object-contain w-3"
                              alt="ethicon"
                            />
                            {(
                              (item.quantity * item.priceTier.price) /
                              oneEth
                            ).toFixed(5)}
                            )
                          </span>
                        </div>
                        <hr className="border-b-black border-white" />
                      </>
                    );
                  })}
                  <hr className="border-b-black border-white" />
                  <div className="flex justify-between">
                    <span>Sub total</span>
                    <span className="flex items-center justify-center">
                      ${totalCartAmount.usd?.toFixed(2)} (
                      <img
                        src="/images/eth-icon.png"
                        className="object-contain w-3"
                        alt="ethicon"
                      />
                      {(totalCartAmount.usd / oneEth).toFixed(5)})
                    </span>
                  </div>
                  <hr className="border-b-black border-white" />
                  <div className="flex justify-between checkout_button_order">
                    <button
                      className="text-lg  transition-all ease-in duration-150 hover:bg-orange-600 font-bold text-center w-full bg-[#FF8500] text-white py-2 rounded-full"
                      onClick={() => {
                        if (isAuthenticated) {
                          nextStep();
                          dispatch({
                            payload: 2,
                            type: actionTypes.CART_STEP,
                          });
                        } else {
                          handleLogin();
                        }
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </Wrapper>
          </Col>
        </>
      ) : (
        <div className="w-full text-center flex flex-col items-center justify-center">
          <span className="text-lg text-gray-600">Your cart is empty</span>

          <Link
            className="text-lg  transition-all ease-in duration-150 hover:bg-orange-600 font-bold text-center w-80 bg-[#FF8500] text-white py-2 rounded-full mt-3"
            to="/proshop"
          >
            Go to PRO SHOP
          </Link>
        </div>
      )}
      <Modals.LoginModal
        loginModalVisible={loginModalVisible}
        hideLoginModal={hideLoginModal}
        setUser={setUser}
        setNavbarContainer={setNavbarContainer}
        setLoginModalVisible={setLoginModalVisible}
      />
    </Row>
  );
}

export default CartItemsList;

const Wrapper = styled.div``;
