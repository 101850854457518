import { message } from "antd";
import axios from "axios";
import {
  get_all_content_Single_Page,
  get_single_section_content,
  update_page_content,
  cms_image_delete,
} from "../../api/Api-list";
import {
  CMS_PAGE_GET_ALL_PAGE_CONTENT,
  CMS_PAGE_SECTION_CONTENT_GET,
  CMS_PAGE_SECTION_CONTENT_UPDATE,
  CMS_IMAGE_DELETE,
} from "../types/cmsPageType";

export const getAllContentSingleInitiate = (id) => {
  return function (dispatch) {
    return axios
      .get(`${get_all_content_Single_Page}/${id}`)
      .then((response) => {
        dispatch({
          type: CMS_PAGE_GET_ALL_PAGE_CONTENT,
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        console.log("get_all_content error.message", error.response);
      });
  };
};

export const getSectionContentInitiate = (name, Admin_Token) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Admin_Token}`,
      },
    };
    axios
      .post(
        get_single_section_content,
        {
          section: name,
        },
        config
      )
      .then((response) => {
        console.log(response.data.message, "response.data");
        dispatch({
          type: CMS_PAGE_SECTION_CONTENT_GET,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const updateSectionContentInitiate = (
  allValues,
  _id,
  titleId,
  section,
  Admin_Token
) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Admin_Token}`,
      },
    };
    axios
      .patch(
        update_page_content,
        {
          ...allValues,
          _id,
          titleId,
          section,
        },
        config
      )
      .then((response) => {
        console.log(
          response.data.message,
          "CMS_PAGE_SECTION_CONTENT_UPDATE.data"
        );
        dispatch({
          type: CMS_PAGE_SECTION_CONTENT_UPDATE,
          payload: response.data,
        });
        message.success(`${response.data.message}`);
      })
      .catch((error) => {
        console.log(error);
        message.error(`${error.response.data.msg}`);
      });
  };
};

export const DeleteCmsImage = (id, itemId) => {
  const Admin_Token = localStorage.getItem("tiger");

  console.log(itemId, "id, itemId");
  return (dispatch) => {
    const data = {
      id: id,
      itemId: itemId,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${Admin_Token}`,
      },
      data,
    };
    axios
      .delete(cms_image_delete, config)
      .then((response) => {
        console.log(response.data.message, "response.data");

        dispatch({
          type: CMS_IMAGE_DELETE,
          payload: response.data,
        });

        message.success(`${response.data.message}`);
      })
      .catch((error) => {
        console.log(error);
        message.error(`${error.response.data.msg}`);
      });
  };
};
