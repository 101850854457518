import React, { useEffect, useState } from "react";
import "./footer.scss";

import { BsTwitter, BsInstagram } from "react-icons/bs";
import { RiLinkedinFill } from "react-icons/ri";
import { BsArrowUp } from "react-icons/bs";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { get_menu } from "../../api/Api-list";

function Footer() {
  // const location = useLocation();
  const [showButton, setShowButton] = useState(false);
  const [footerMenu, setFooterMenu] = useState([]);
  // const home = location.pathname === "/";
  const PageSettingpage = useSelector(
    (state) => state?.cmsPageSetting?.settingpage
  );
  const loginToken = localStorage.getItem("tiger-token");
  const config = {
    headers: {
      Authorization: `Bearer ${loginToken}`,
    },
  };
  console.log(loginToken, "token for login")
  // console.log(PageSettingpage.data[0]?.twitter, "jusbd");
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };


  const getMenu = async (type) => {
    try {
      const response = await axios.get(get_menu(type), config);
      if (response.status < 400) {
        setFooterMenu(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMenu("Footer");
  }, []);


  return (
    <div className="footer_container">
      {showButton && (
        <div className="text-white top-arrow">
          <div className="text-center " onClick={scrollToTop}>
            <BsArrowUp size={20} />
          </div>
        </div>
      )}
      <div className="footer_logo">
        <a href="/" target="_blank" className="cursor-pointer text-center">
          <img
            alt="logo"
            className="w-full !max-w-[5rem] mx-auto"
            src={PageSettingpage.data ? PageSettingpage?.data[0]?.image : ""}
            title={PageSettingpage.data ? PageSettingpage?.data[0]?.title : ""}
          />
          {/* <span className="text-white capitalize">
            {PageSettingpage.data ? PageSettingpage?.data[0]?.title : ""}
            Roaring tiger golf club | RTGC
          </span> */}
        </a>
      </div>

      <div className="footer_social_icon">
        <ul className="footer_social_navigation">
          <li>
            <a
              href={
                PageSettingpage.data
                  ? "https://" + PageSettingpage.data[0]?.twitter
                  : ""
              }
              target="_blank"
              rel="noreferrer"
            >
              <BsTwitter />
            </a>
          </li>
          <li>
            <a
              href={
                PageSettingpage.data
                  ? "https://" + PageSettingpage.data[0]?.instagram
                  : ""
              }
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram />
            </a>
          </li>
          <li>
            <a
              href={
                PageSettingpage.data
                  ? "https://" + PageSettingpage.data[0]?.linkedin
                  : ""
              }
              target="_blank"
              rel="noreferrer"
            >
              <RiLinkedinFill />
            </a>
          </li>
        </ul>
      </div>

      <div className="footer_heading">
        <ul className="footer_navigation">
          {footerMenu.filter((active) => active?.isActive)?.map((item) => (
            <li>
            <Link to={`/${item?.link}`}>{item?.pageName} </Link>
          </li>
          ))}
          {/* <li>
            <Link to="/cart">Cart </Link>
          </li>
          <li>
            <Link to="/orderConfirmation">Order </Link>
          </li>
          <li>
            <Link to="/billing-detail">Checkout </Link>
          </li>
          <li>
            <Link to="/my-profile">My Profile </Link>
          </li>


          <li>
            <Link to="/mint-page">Mint Page</Link>
          </li>
          <li>
            <Link to="/mint-sale">Mint Sale</Link>
          </li>
          <li>
            <Link to="/mint-sucess">Mint Sucess</Link>
          </li>
          <li>
            <Link to="/holders">Member Benefit</Link>
          </li>
          <li>
            <Link to="/admin-login">Admin Login</Link>
          </li>       
          <li>
            <Link to="/innerpage">Inner Page</Link>
          </li> */}
        </ul>
      </div>

      <div className="footer_heading">
        <ul className="footer_navigation">
          <li>
            <Link to="/privacy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/terms">Terms of Use</Link>
          </li>
          <li>
            <Link to="/sitemap">Sitemap</Link>
          </li>
        </ul>
      </div>

      <div className="footer_copyright flex items-center justify-center flex-row ">
        <p className="m-0">
          {PageSettingpage.data ? PageSettingpage?.data[0]?.copyright : ""}
        </p>
        <a href="https://thoughtmedia.com/" target="_blank" rel="noreferrer">
          <img
            src="images/footer_icon.png "
            alt="footer icon"
            className="pl-4 d-inline"
            title="Developed by Thought Media"
          />
        </a>
      </div>
    </div>
  );
}

export default Footer;
