import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./slider.scss";
function ClubhouseOuter() {
  return (
    <div className="w-full pt-[9rem] slider-custom">
      <div className="container-fluid">
        <div className="row p-0">
          <div className="col-9 p-0 ">
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block"
                  src="images/clubhouse-outer.png"
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3 className="font-bold text-white text-[1.8rem]">411</h3>
                  <p className="text-white font-semibold text-[1.3rem]">
                    cost of oregon
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="images/clubhouse-outer.png"
                  alt="First slide"
                />

                <Carousel.Caption>
                  <h3 className="font-bold text-white text-[1.8rem]">411</h3>
                  <p className="text-white font-semibold text-[1.3rem]">
                    cost of oregon
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="images/clubhouse-outer.png"
                  alt="First slide"
                />

                <Carousel.Caption>
                  <h3 className="font-bold text-white text-[1.8rem]">411</h3>
                  <p className="text-white font-semibold text-[1.3rem] ">
                    cost of oregon
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="col-3 p-0">
            <img
              src="images/clubhouse-outer2.png"
              alt="clubhouse-outer"
              className="w-full h-full"
            />
          </div>
        </div>
        <div className="flex flex-wrap ">
          <div className="w-full sm:w-[75%] p-4">
            <p className="text-left">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClubhouseOuter;
