import React, { useState, useEffect } from "react";
import { Row, Col, Table, Popconfirm, Switch } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import editIcon from "../../images/edit.png";
import Delete from "../../images/delete.png";

import { PropTypes } from "prop-types";

import { fetchProducts } from "../../../redux/actions/productAction";
import { deleteProducts } from "../../../redux/actions/productAction";
import { change_product_status } from "../../../api/Api-list";
import { useToasts } from "react-toast-notifications";
import axios from "axios";

const Products = () => {
  const [page, setPage] = useState(1);
  const { addToast } = useToasts();

  const fetchProductsPage = useSelector(
    (state) => state?.allProducts?.selectproducts
  );
  const dispatch = useDispatch();
  const Admin_Token = localStorage.getItem("tiger");

  const config = {
    headers: {
      Authorization: `Bearer ${Admin_Token}`,
    },
  };

  const changeProductStatus = async (id) => {
    try {
      const response = await axios.patch(change_product_status(id), {}, config);
      if (response.status < 400) {
        addToast(`${response.data.message}`, {
          appearance: "success",
          autoDismiss: true,
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    dispatch(fetchProducts(page, 100));
  }, [dispatch]);
  const navigate = useNavigate();
  const delProduct = (pid, Admin_Token) => {
    dispatch(deleteProducts(pid, Admin_Token));
    setTimeout(() => {
      navigate("/admin/product-management");
      dispatch(fetchProducts(1, 100));
    }, 2000);
  };
  // write  a function to delele the image using ant upload component

  let columns = [
    {
      title: "Sr no",
      dataIndex: "sr_no",
      render: (item, data, index) => {
        return <>{(page - 1) * 10 + index + 1}</>;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (item) => {
        return <div dangerouslySetInnerHTML={{ __html: item }} />;
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      render: (item, data) => {
        return <span>{data?.category?.[0]?.categoryName}</span>;
      },
    },
    {
      title: "Price ($)",
      dataIndex: "price",
      render: (item, data) => {
        return <span>${data?.minPrice}</span>;
      },
    },
    {
      title: "Color",
      dataIndex: "color",
      render: (item) => {
        return (
          <span className="text-capitalize">
            {item.length > 1 ? item.join(", ") : item}
          </span>
        );
      },
    },
    {
      title: "Size",
      dataIndex: "size",
      render: (item) => {
        return <span>{item.length > 1 ? item.join(", ") : item}</span>;
      },
    },
    {
      title: "Url",
      dataIndex: "pageUrl",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (item, data) => {
        return (<>
        <Switch defaultChecked={data?.isActive} onChange={() =>changeProductStatus(data._id)} />
       
        </>);
      },
    },
    {
      title: "Image",
      dataIndex: "images",
      render: (item) => {
        return (
          <>
            <img
              src={item[0]?.image}
              className="w-full max-w-[5rem]"
              alt="productImage"
            />
          </>
        );
      },
    },

    

    {
      title: "Actions",
      dataIndex: "actions",
      render: (item, data, index) => {
        return (
          <div className="d-flex align-items-center">
            &nbsp;&nbsp;&nbsp;
            <Link to={`${data._id}`}>
              <img src={editIcon} alt="Edit" className="h-[15px]" />
            </Link>
            <Popconfirm
              title="Delete this item? This cannot be undone."
              onConfirm={() => delProduct(data._id, Admin_Token)}
              okText="Yes"
              cancelText="No"
            >
              <img className="w-[16px] ml-[5px]" src={Delete} alt="delete" />
            </Popconfirm>
          </div>
        );
      },
    },
  ];


  console.log(columns)
  console.log(fetchProductsPage, )

  return (
    <>
      <div className="prd-manage">
        {true && (
          <Row gutter={16}>
            <div className="flex items-center justify-between w-full px-2">
              <h3 className="m-0">Manage Products</h3>
              <Link to="/admin/product-management/add">
                <button className="add-btn">Add Product</button>
              </Link>
            </div>
            <Col span={24} className="mt-4 user-table">
              <Table
                rowKey="_id"
                rowSelection={null}
                key={fetchProductsPage?.data?.length}
                columns={columns}
                defaultCurrent={1}
                dataSource={fetchProductsPage?.data || []}
                scroll={{ x: 1000 }}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  },
                }}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
Products.propTypes = {
  users: PropTypes.array,
};

export default Products;