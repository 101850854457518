import React, { useState } from "react";
import "antd/dist/antd.css";
import { Modal } from "antd";

import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import "./benefitPopUp.scss";

import { BiMessageRounded } from "react-icons/bi";
import { MdVerified } from "react-icons/md";
import { MdOutlineFileUpload } from "react-icons/md";
import { MdRepeat } from "react-icons/md";
const BenefitPopup = ({ buttonText }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [countLikes, setCountLikes] = useState(8);

  const handleLike = () => {
    setIsLiked(true);
    setCountLikes(countLikes + 1);
  };
  const handleUnlike = () => {
    setIsLiked(false);
    setCountLikes(countLikes - 1);
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="benefit_popup">
      <button
        className="text-white border-2 rounded-2xl text-xs"
        onClick={showModal}
      >
        {buttonText}
      </button>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="p-0 twitter-field-modal benefit_popup_model"
      >
        <div className="row  benefit-body">
          <div className="col-lg-7 col-md-5 col-sm-7 col-12">
            <img
              src="/images/twitter-modal-image.jpg"
              alt="tiger"
              className="twitter-modal-image"
            />
          </div>
          <div className="col-lg-5 col-md-7 col-sm-5 col-12 twitter-field-right">
            <div className=" twitter_text">
              <h2 className="text-lg font-bold text-white heading-pattern position-relative">
                Twitter Feed
              </h2>
            </div>

            <div className="d-flex profile_text_img">
              <div className="pr-4">
                <img
                  src="/images/team.jpg"
                  alt="tiger"
                  className=" mx-auto rounded-full w-[50px] h-[50px] object-cover"
                />
              </div>
              <div className="flex-grow">
                <h2 className="text-left text-[1rem] font-bold  leading-tight text-white">
                  Geoffrey Pement
                </h2>
                <div className="flex items-center justify">
                  <div className="absolute z-10 ml-2  mt-[-12px] rounded bg-white p-[0.4rem]"></div>
                  <MdVerified className=" w-8 z-30 mr-1 mt-[-12px] text-[1.4rem] text-[#589BE9]" />
                  <p className="text-[0.9rem] text-white"> @Geoffrey 1hr</p>
                </div>
              </div>
            </div>
            <p className="text-gray-300  text-[12px] mt-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse nec sapien enim. In et suscipit libero, non molestie
              risus.
            </p>
            <div className="my-2 flex items-center ">
              <div className="mr-7 flex items-center">
                <BiMessageRounded className=" mr-2 cursor-pointer text-[1.13rem] text-white" />
                <span className="text-[0.7rem] text-white">8</span>
              </div>
              <div className="mr-7 flex items-center">
                <MdRepeat className="mr-2 rotate-90 cursor-pointer text-[1.13rem] text-white" />
                <span className="text-[0.7rem] text-white">8</span>
              </div>
              <div className="mr-7 flex items-center">
                {/* <AiOutlineHeart className="mr-2 cursor-pointer text-[1.13rem] text-white" />
                <span className="text-[0.7rem] text-white">8</span> */}
                <div className="flex items-center justify-center space-x-1">
                  <div className="relative">
                    {isLiked ? (
                      <AiFillHeart
                        onClick={handleUnlike}
                        className="d-inline animate-pulse cursor-pointer text-[1rem]  text-red-500 delay-[1s] duration-75 ease-in-out hover:transition"
                        size={21}
                      />
                    ) : (
                      <AiOutlineHeart
                        onClick={handleLike}
                        className="d-inline animate-pulse cursor-pointer text-[1rem]  text-white delay-[1s] duration-75 ease-in-out hover:transition"
                        size={21}
                      />
                    )}
                  </div>{" "}
                  <span className="text-[0.7rem] text-white">{countLikes}</span>
                </div>
              </div>

              <div className="mr-7 flex items-center">
                <MdOutlineFileUpload className="mr-2 cursor-pointer text-[1.2rem] text-white" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BenefitPopup;
