// List of all The APIs we are using
const API = process.env.REACT_APP_API_URL;

// forgot password API
export const forgot_password = API + "/api/auth/forgotPassword";
//Admin-api
export const loginAdmin = API + "/api/auth/loginAdmin";

//Admin-api  adminProfile
export const adminProfile = API + "/api/user/me";

//Admin-api changePassword
export const change_Admin_Password = API + "/api/auth/changePassword";

//Admin-api User activeUser
export const activeUser = API + "/api/user/"; // admin token to be edit

//Admin-api User deleteUser
export const delete_user_by_admin = API + "/api/user/deleteUser";

// Admin-api User singleUser
export const get_single_user = API + "/api/user/single"; // admin token to be edit
export const get_single_user_details = (id) => API + `/api/user/details/${id}`;

//Admin-api User editAdmin
export const editAdmin = API + "/api/user/editAdminProfile";

//Admin-api User addUserByAdmin
export const add_user_by_admin = API + "/api/auth/user/add";

//Admin-api User addUserByAdmin
export const edit_user_by_admin = API + "/api/user/editUserByAdmin";
// delete cms Image

export const cms_image_delete = API + "/api/content/delete/image";
//User-api

//User List-api
export const UserList = API + "/api/user";

//User Register-api
export const register = API + "/api/auth/registerUser";

export const forgot = API + "/api/auth/forgotPassword";
export const verify = API + "/api/auth/verify";
export const resendverify = API + "/api/auth/resendVerificationToken/";

export const changePassword = API + "/api/auth/passwordReset";

//User login
export const login = API + "/api/auth/loginUser";

// fetch product Category
export const productCategory = API + "/api/category/";
export const addProductCategory = API + "/api/category/add";
export const editProductCategory = API + "/api/category/update";
export const getSingleCategory = API + "/api/category/single";
export const change_product_status = (id) =>
  API + `/api/product/change-status/${id}`;
export const delete_product_caterory = API + `/api/category/delete`;
//  add Products
export const addProduct = API + "/api/product/add";

// fetch all Products
// page seo api

export const getAllPages = API + "/api/content/pages";
export const pageUpdate = API + "/api/content/page/update";
export const getSinglePage = API + "/api/content/page/single";

export const fetchAllProducts = (page, size) =>
  API + `/api/product/?page=${page}&size=${size}`;
export const fetchAllActiveProducts = () => API + `/api/product/`;
export const get_ProductByCategory = ({
  page,
  size,
  orderType,
  keywords,
  filter,
  orderKey,
  categoryId,
  color,
  itemSize,
}) =>
  API +
  `/api/product/?page=${page}&size=${size}&orderType=${orderType}&keywords=${keywords}&filter=${filter}&orderKey=${orderKey}?category=${categoryId}&color=${color}&itemSize=${itemSize}`;
export const recentProducts = (page, size, orderType, orderKey) =>
  API +
  `/api/product/?page=${page}&size=${size}&orderType=${orderType}&orderKey=${orderKey}`;
export const delete_category = API + `/api/category/delete`;

// fetch single product

export const fetch_Single_Product = API + "/api/product/single";
export const fetch_Single_Product_by_Slug =
  API + "/api/product/get-a-product-by-slug";

export const fetch_Category_by_Slug = (id) =>
  API + `/api/category/get-a-category-by-slug/${id}`;

// update Product
export const update_product = API + "/api/product/update";
export const fav_product = () => API + `/api/product/fev`;
export const update_fav_product = (id) => API + `/api/product/add-to-fev/${id}`;
export const remove_fav_product = (id) =>
  API + `/api/product/remove-from-fev/${id}`;

export const delete_Products = API + "/api/product/delete";
export const get_all_coupons = (pageNum, size) =>
  API + `/api/coupon/?page=${pageNum}&size=${size}`;
export const delete_Coupons = (id) => API + `/api/coupon/delete/${id}`;
export const change_Coupons_Status = (id) =>
  API + `/api/coupon/change-status/${id}`;
export const get_Single_Coupons = (id) => API + `/api/coupon/${id}`;
export const update_Coupons = (id) => API + `/api/coupon/update/${id}`;
// DELETE PRODUCT IMAGE
export const delete_product_image = API + "/api/product/deleteImage";
// CMS PAGE API

// single page section
export const get_single_page_sections = API + "/api/content/pagecontent";

//get single section by section Name
export const get_single_section_by_section_name = API + "/api/content/section";

//get home page team section
export const get_home_page_team_section = API + "/api/team";
export const delete_team_member = (id) => API + `/api/team/delete/${id}`;

//update home page team section
export const update_home_page_team_section = API + "/api/team/update";
export const add_home_page_team_section = API + "/api/team/add";

//update home page team section
export const single_home_page_team_section = API + "/api/team";

//get All Pages List
export const get_all_pages = API + "/api/content/pages";

//get all content
export const get_all_content = API + "/api/content";
//get all content  singlepage
export const get_all_content_Single_Page = API + "/api/content/pagecontent";

// update page content
export const update_page_content = API + "/api/content/update";

//update section content
export const get_single_section_content = API + "/api/content/section";

//Admin setting nav

//get the setting page data
export const get_setting_page_data = API + "/api/settings";

//update the setting page data
export const update_setting_page_data = API + "/api/settings/update";

//get the single data setting page
export const get_single_data_setting_page = API + "/api/settings/single";

//get the single data setting page
export const delete_single_data_setting_page = API + "/api/settings/delete";

// save a address
export const save_address = API + "/api/user/createAddress";
export const get_address = API + "/api/user/getAllAddress";
export const delete_address = (id) => API + `/api/user/deleteAddress/${id}`;
export const get_single_address = (id) => API + `/api/user/getAddress/${id}`;
export const update_address = (id) => API + `/api/user/editAddress/${id}`;
export const make_default = (id) =>
  API + `/api/user/address/make-default/${id}`;
export const add_coupons = API + "/api/coupon/add";
// settings => menu
export const create_menu = API + "/api/headerFooter/add";
export const get_menu = (type) => API + `/api/headerFooter/?pageType=${type}`;
export const update_menu = (id) => API + `/api/headerFooter/update/${id}`;
export const delete_menu = (id) => API + `/api/headerFooter/delete/${id}`;
export const getsingle_menu = (id) => API + `/api/headerFooter/${id}`;
export const change_menu_status = (id) =>
  API + `/api/headerFooter/change-status/${id}`;
export const menu_rearrange = (type) =>
  API + `/api/headerFooter/change-order/?pageType=${type}`;

//clubhouse
export const create_clubhouse = API + "/api/clubhouse/add";
export const update_clubhouse = (id) => API + `/api/clubhouse/update/${id}`;

export const get_clubhouse = () => API + `/api/clubhouse`;
export const get_single_clubhouse = (id) => API + `/api/clubhouse/${id}`;
export const delete_clubhouse = (id) => API + `/api/clubhouse/delete/${id}`;
export const clubhouse_rearrange = () => API + `/api/clubhouse/change-order`;

// orders
export const get_all_orders =
  API + "/api/order/?orderKey=createdAt&orderType=desc";
export const get_order_details = (id) => API + `/api/order/details/${id}`;
export const return_order = API + "/api/order/return";
export const cancel_order = (orderId) =>
  API + `/api/order/cancel-order/${orderId}`;

// admin email template
export const add_email_template = API + "/api/email-template/add";
export const get_all_email_templates = API + "/api/email-template";
export const delete_email_template = (id) =>
  API + `/api/email-template/delete/${id}`;
export const get_email_template_details = (id) =>
  API + `/api/email-template/${id}`;
export const update_email_template = (id) =>
  API + `/api/email-template/update/${id}`;

// admin manage country
export const add_country = API + "/api/location/country/add";
export const get_all_countries = API + "/api/location/country";
export const get_single_country = (id) => API + `/api/location/country/${id}`;
export const update_a_country = (id) =>
  API + `/api/location/country/update/${id}`;
export const delete_a_country = (id) =>
  API + `/api/location/country/remove/${id}`;

// admin manage state
export const add_state = API + "/api/location/state/add";
export const get_all_states = (id) =>
  API + `/api/location/state/get-all-states/${id}`;
export const get_single_state = (id) => API + `/api/location/state/${id}`;
export const update_a_state = API + "/api/location/state/update";
export const delete_a_state = (id) => API + `/api/location/state/remove/${id}`;

// admin manage tax
export const add_tax = API + "/api/location/tax/add";
export const get_all_tax = (id) =>
  API + `/api/location/tax/getAllTaxList/${id}`;
export const get_single_tax = (id) => API + `/api/location/tax/single/${id}`;
export const update_a_tax = API + "/api/location/tax/update";
export const delete_a_tax = (id) => API + `/api/location/tax/delete/${id}`;

// notificatioin
export const notification_update = API + "/api/user/settings/notifications";

// admin order
export const get_admin_order_list = () =>
  API + `/api/order/?orderKey=createdAt&orderType=desc`;
export const get_admin_order_list_user = (userid) =>
  API + `/api/order/?userId=${userid}&orderKey=createdAt&orderType=desc`;
export const get_admin_order_details = (id) => API + `/api/order/details/${id}`;
export const change_order_status = (id, status) =>
  API + `/api/order/change-status/${id}?orderStatus=${status}`;
export const filter_by_order_status = (status) =>
  API + `/api/order/?orderStatus=${status}&orderKey=createdAt&orderType=desc`;

// cart
export const add_to_cart = API + "/api/cart/set";
export const add_to_cart_with_cookie = API + "/api/cart/cookie/set";
export const get_all_cart_items = API + "/api/cart";
export const get_all_cart_items_with_cookie = (cookie) =>
  API + `/api/cart/cookie/get/${cookie}`;
export const remove_a_cart_item = (id) => API + `/api/cart/remove/${id}`;
export const remove_a_cart_item_with_cookie = (cookie, id) =>
  API + `/api/cart/remove/cookie/${cookie}?itemId=${id}`;
export const remove_coupon_from_cart = API + "/api/cart/remove-coupon";
export const get_fedex_services = API + "/api/cart/fedex-services";
export const generate_braintree_token = API + "/api/payment/generate-token";
export const cart_payment = (nonceToken) =>
  API + `/api/payment/pay/${nonceToken}`;
export const cart_payment_success = (paymentId, token, payerId) =>
  API +
  `/api/payment/success?paymentId=${paymentId}&token=${token}&PayerID=${payerId}`;
export const cart_payment_cancel = (orderId, token) =>
  API + `/api/payment/cancelled?orderId=${orderId}&token=${token}`;
export const retry_cart_payment = (orderId) =>
  API + `/api/payment/retry-payment/${orderId}`;
export const get_signature = API + "/api/payment/get-signature";
