import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import editIcon from "../../images/edit.png";

import { AiFillSetting } from "react-icons/ai";
import { PropTypes } from "prop-types";
// import {
//   deletUserInitiate,
//   fetchUsers,
// } from "../../../redux/actions/adminUsersActions";
import { fetchSettingPageInitiate } from "../../../redux/actions/cmsSettingAction";
import "./cmsSettingPageList.scss";

const CmsSettingPageList = () => {
  const [page, setPage] = useState(1);
  const selector = useSelector((state) => state?.cmsPageSetting?.settingpage);
  console.log(selector);
  const activeSettingId = selector?.data?.length;
  const dispatch = useDispatch();
  // const Admin_Token = localStorage.getItem("tiger");
  useEffect(() => {
    dispatch(fetchSettingPageInitiate());
  }, [dispatch, activeSettingId]);

  let columns = [
    {
      title: "Sr no",
      dataIndex: "sr_no",
      render: (item, data, index) => {
        return <>{(page - 1) * 10 + index + 1}</>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "Phone no",
      dataIndex: "mobile_no",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "Logo",
      dataIndex: "image",
      render: (item) => {
        console.log(item, "itemitemitemitemitemitem");
        return (
          <>
            <img src={item} className="w-full max-w-[120px]" alt="ls" />
          </>
        );
      },
    },
    {
      title: "Twiiter",
      dataIndex: "twitter",
      render: (item) => {
        return <>{item}</>;
      },
    },
    {
      title: "Instagram",
      dataIndex: "instagram",
      render: (item) => {
        return <>{item}</>;
      },
    },
    {
      title: "LinkedIn",
      dataIndex: "linkedin",
      render: (item) => {
        return <>{item}</>;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (item) => {
        return <>{item}</>;
      },
    },
    {
      title: "Copyright",
      dataIndex: "copyright",
      render: (item) => {
        return <>{item}</>;
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (item, data, index) => {
        return (
          <div className="d-flex align-items-center cms-setting{">
            &nbsp;&nbsp;&nbsp;
            <Link to={`cms-form/${data._id}`}>
              <img src={editIcon} alt="Edit" style={{ height: "15px" }} />
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="cms-setting">
        {true && (
          <Row gutter={16}>
            <Col sm={24} xl={12}>
              <div className="flex items-center">
                <AiFillSetting size={"30px"} color={"Black"} />
                <h2 className="pl-5">Site Setting</h2>
              </div>
            </Col>
            <Col span={24} className="mt-4 user-table">
              <Table
                rowSelection={null}
                columns={columns}
                defaultCurrent={1}
                dataSource={selector?.data ? selector?.data : []}
                scroll={{ x: 1000 }}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  },
                }}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
CmsSettingPageList.propTypes = {
  users: PropTypes.array,
};

export default CmsSettingPageList;
