import React, { useState, useEffect } from "react";
import { Row, Col, Table, Popconfirm } from "antd";
import { Link } from "react-router-dom";
// import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Delete from "../../images/delete.png";
import editIcon from "../../images/edit.png";
import viewicon from "../../images/view-icon.png";
import "./customer-list.scss";
import { PropTypes } from "prop-types";
import {
  deletUserInitiate,
  fetchUsers,
} from "../../../redux/actions/adminUsersActions";
import AddNewUser from "./Customer-detail/addUserModel/AddNewUser";

const CustomerList = (props, users) => {
  const [page, setPage] = useState(1);
  const [addUserModalVisible, setaddUserModalVisible] = useState(false);
  const selector = useSelector((state) => state.usersDataByAdmin.users) || [];
  // const adminProfile = useSelector((state) => state.adminProfile);
  // const activeUser = selector?.data?.length;
  const dispatch = useDispatch();

  const Admin_Token = localStorage.getItem("tiger");
  console.log(Admin_Token, "Admin_TokenAdmin_TokenAdmin_Token");

  const handleAddUser = () => {
    setaddUserModalVisible(true);
  };

  const hideLoginModal = () => {
    setaddUserModalVisible(false);
  };

  useEffect(() => {
    dispatch(fetchUsers(Admin_Token));
  }, [dispatch]);
  const handledeleteuser = (id, Admin_Token) => {
    dispatch(deletUserInitiate(id, Admin_Token));
    setTimeout(() => {
      dispatch(fetchUsers(Admin_Token));
    }, 1000);
  };
  let columns = [
    {
      title: "Sr no",
      dataIndex: "sr_no",
      render: (item, data, index) => {
        return <>{(page - 1) * 10 + index + 1}</>;
      },
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      render: (item) => {
        return <span className="capitalize">{item}</span>;
      },
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      render: (item) => {
        return <span className="capitalize">{item}</span>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (item) => {
        return <>{item}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (item) => {
        return <>{item ? "Active" : "Inactive"}</>;
      },
    },
    // {
    //     title: 'Created At',
    //     dataIndex: 'date',
    //     render: (item) => {
    //         return <> {item.split("T")[0]}</>
    //     }
    // },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (item, data, index) => {
        return (
          <div className="d-flex align-items-center">
            {/* {console.log(data)} */}
            <Link to={`user-detail/${data._id}`}>
              <img src={viewicon} alt="View" style={{ height: "15px" }} />
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link to={`edit-user/${data._id}`}>
              <img src={editIcon} alt="View" style={{ height: "15px" }} />
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Popconfirm
              title="Are you sure to delete this Item"
              onConfirm={() => handledeleteuser(data._id, Admin_Token)}
              okText="Yes"
              cancelText="No"
            >
              <img className="w-[16px] ml-[5px]" src={Delete} alt="delete" />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="custom-user">
        {true && (
          <Row gutter={16}>
            <Col sm={24} xl={12}>
              <h2>Users</h2>
            </Col>
            <Col sm={24} xl={12} className="text-right">
              <button className="add-btn" onClick={handleAddUser}>
                Add new user
              </button>
            </Col>
            <Col span={24} className="mt-4 user-table">
              <Table
                rowSelection={null}
                columns={columns}
                defaultCurrent={1}
                dataSource={selector?.data}
                scroll={{ x: 1000 }}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  },
                }}
              />
            </Col>
          </Row>
        )}
        <AddNewUser
          addUserModalVisible={addUserModalVisible}
          hideLoginModal={hideLoginModal}
        />
      </div>
    </>
  );
};

CustomerList.propTypes = {
  users: PropTypes.array,
};

export default CustomerList;
