export default {
  abi: [
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "orderId",
          type: "bytes32"
        },
        {
          components: [
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256"
            },
            {
              internalType: "address",
              name: "tokenAddress",
              type: "address"
            },
            {
              internalType: "enum TokenType",
              name: "tokenType",
              type: "uint8"
            }
          ],
          internalType: "struct Payment",
          name: "payment",
          type: "tuple"
        },
        {
          internalType: "bytes",
          name: "signature",
          type: "bytes"
        }
      ],
      name: "payForProduct",
      outputs: [],
      stateMutability: "payable",
      type: "function"
    }
  ]
};
