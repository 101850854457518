import { ActionType } from "../types/seoTypes";
import { message } from "antd";
import axios from "axios";
import {} from "../../api/Api-list";
import { getAllPages, getSinglePage, pageUpdate } from "../../api/Api-list";

const DispatchAllPages = (data) => {
  return {
    type: ActionType.GET_ALL_PAGES,
    payload: data,
  };
};

export const getAllPagesAction = () => {
  return (dispatch) => {
    axios
      .get(getAllPages)
      .then((response) => {
        dispatch(DispatchAllPages(response.data));
        return response.data;
      })
      .catch((error) => {
        console.log(error.response.msg);
        message.error(error.response.msg);
      });
  };
};

const DispatchSinglePage = (data) => {
  return {
    type: ActionType.GET_SINGLE_PAGE,
    payload: data,
  };
};
export const getSinglePageAction = (id, Admin_Token) => {
  return (dispatch) => {
    axios
      .get(`${getSinglePage}/${id}`, {
        headers: {
          Authorization: `Bearer ${Admin_Token}`,
        },
      })
      .then((response) => {
        dispatch(DispatchSinglePage(response.data));

        return response.data;
      })
      .catch((error) => {
        console.log(error.response.msg);
        message.error(error.response.msg);
      });
  };
};

const DispatchUpdatePage = (data) => {
  return {
    type: ActionType.UPDATE_PAGE,
    payload: data,
  };
};

export const updatePageAction = (newdata, Admin_Token) => {
  return (dispatch) => {
    axios
      .post(pageUpdate, newdata, {
        headers: {
          Authorization: `Bearer ${Admin_Token}`,
        },
      })
      .then((response) => {
        dispatch(DispatchUpdatePage(response.data));
        message.success("Page Data Updated Successfully");
        return response.data;
      })
      .catch((error) => {
        console.log(error.response.msg);
        message.error(error.response.msg);
      });
  };
};
