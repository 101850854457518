import React, { useEffect, useState } from "react";
import { Form, Input, List, Modal, Switch, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
// import { editUserByAdminInitiate } from "../../../redux/actions/adminUsersActions";
import {
  create_menu,
  getsingle_menu,
  get_home_page_team_section,
  get_menu,
  update_menu,
} from "../../../api/Api-list";
import axios from "axios";
import { Button } from "../../admin-components/Buttons/Button";
import { updateHomeTeamSectionInitiate } from "../../../redux/actions/cmsSettingAction";
import { useToasts } from "react-toast-notifications";
import { get } from "http";
import "./menu.scss"
const EditMenu = () => {
  const Admin_Token = localStorage.getItem("tiger");
  const [form] = Form.useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(true);
  const [pageType, setPageType] = useState("");
  const [pageName, setPageName] = useState("");
  const [menuLink, setMenuLink] = useState("");

  const inputclass =
    "form-control bg-transparent !py-[0.7rem] !rounded-[0.3rem] text-black";

  const config = {
    headers: {
      Authorization: `Bearer ${Admin_Token}`,
    },
  };

  const submitUpdate = async () => {
    try {
      let payload = {
        pageType: pageType,
        pageName: pageName,
        isActive: check,
        link: menuLink,
      };
      const response = await axios.patch(update_menu(id), payload, config);
      if (response.status < 400) {
        addToast("Add menu successfully", {
          appearance: "success",
          autoDismiss: true,
          position: "top-center",
        });
        navigate("/admin/menu");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSingleMenu = async () => {
    try {
      setTimeout(() => setLoading(false));
      const response = await axios.get(getsingle_menu(id), config);
      if (response.status < 400) {
        setCheck(response?.data?.data?.isActive);
        setPageName(response?.data?.data?.pageName);
        setPageType(response?.data?.data?.pageType);
        setMenuLink(response?.data?.data?.link);
        setLoading(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleMenu(id);
  }, []);

  return (
    <>
      {loading ? (
        <div className=" ">
          <h2 className="text-center ">Update Menu</h2>
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={submitUpdate}
          >
            <Form.Item
              name="pageType"
              label="Page Type"
              rules={[
                { required: true, message: "Please Select Your Page Type" },
              ]}
              className="text-red-100"
              initialValue={pageType}
            >
              <select
                className={inputclass}
                onChange={(e) => setPageType(e.target.value)}
                name="pageType"
                value={pageType}
              >
                <option value="">Select Page Type</option>
                <option value="Header">Header</option>
                <option value="Footer">Footer</option>
              </select>
            </Form.Item>
            <Form.Item
              name="pageName"
              label="Page Name"
              initialValue={pageName}
              rules={[{ required: true, message: "Enter Your Page Name" }]}
            >
              <Input
                onChange={(e) => setPageName(e.target.value)}
                className={inputclass}
                value={pageName}
              />
            </Form.Item>

            <Form.Item
              name="link"
              label="Menu Link Name"
              initialValue={menuLink}
              // rules={[{ required: true, message: "Enter Your Menu Link" }]}
            >
              <Input
                onChange={(e) => setMenuLink(e.target.value)}
                className={inputclass}
                value={menuLink}
              />
              <small>For Example - menu</small>
            </Form.Item>

            <Switch defaultChecked={check} onChange={() => setCheck(!check)} />

            <br />
            <br />

            <Form.Item>
              <Button
                type="submit"
                className="w-full py-2 rounded-md font-semibold text-[1.1rem] !bg-orange-500  text-center text-white hover:!border-none focus:!border-none"
              >
                Update Menu
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default EditMenu;
