import * as actionTypes from "../types/cartType.js";

const initailState = {
  cart: [],
};

const cartReducer = (state = initailState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_CART: {
      const itemInCart = state.cart.find(
        (item) => item._id === action.payload._id
      );
      const itemIndexCart = state.cart.findIndex(
        (item) => item._id === action.payload._id
      );
      let carts = JSON.parse(JSON.stringify(state.cart));
      if (itemInCart) {
        let quantity = carts[itemIndexCart].quantity;
        quantity++;
        carts[itemIndexCart].quantity = quantity;
      } else {
        carts.push({
          ...action.payload,
          quantity: action?.payload?.quantity ? action.payload.quantity : 1,
        });
      }
      return {
        ...state,
        cart: carts,
      };
    }
    case actionTypes.REMOVE_FROM_CART: {
      return {
        ...state,
        cart: state.cart.filter(
          (product) => product._id !== action.payload._id
        ),
      };
    }
    case actionTypes.UPDATE_CART: {
      return {
        ...state,
        cart: state.cart.map((product) =>
          product._id === action.payload._id
            ? { ...product, quantity: action.payload.quantity }
            : product
        ),
      };
    }
    case actionTypes.CLEAR_CART: {
      return {
        ...state,
        cart: [],
      };
    }
    case actionTypes.LOAD_CART: {
      return {
        ...state,
        cart: action.payload.cart,
      };
    }
    case actionTypes.SET_PRODUCTS:
      return {
        ...state,
        Products: action.payload,
      };

    default:
      return state;
  }
};

export default cartReducer;
