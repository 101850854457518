import {
  DELETE_FETCH_SETTING_PAGE,
  FETCH_HOME_PAGE_TEAM_SECTION,
  FETCH_PAGE_BY_ID,
  FETCH_SETTING_PAGE,
  UPDATE_FETCH_SETTING_PAGE_SUCCESS,
} from "../types/cmsSettingType";

const initState = {
  allPage: [],
  teamSection: [],
  settingpage: [],
};
const cmsPageSettingReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case FETCH_PAGE_BY_ID:
      return {
        ...state,
        allPage: payload,
      };
    case FETCH_HOME_PAGE_TEAM_SECTION:
      return {
        ...state,
        teamSection: payload,
      };
    case FETCH_SETTING_PAGE:
      return {
        ...state,
        settingpage: payload,
      };

    case UPDATE_FETCH_SETTING_PAGE_SUCCESS:
      return {
        ...state,
        settingpage: payload,
      };
    case DELETE_FETCH_SETTING_PAGE:
      return {
        ...state,
        settingpage: payload,
      };
    default:
      return {
        ...state,
        payload: payload,
      };
  }
};
export default cmsPageSettingReducer;
