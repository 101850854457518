import React, { useEffect, useState } from "react";
import editIcon from "../../images/edit.png";
import Delete from "../../images/delete.png";
import { Row, Col, Table, Popconfirm, Switch, message, Pagination } from "antd";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import {
  delete_Coupons,
  change_Coupons_Status,
  get_all_coupons,
} from "../../../api/Api-list";
const API = process.env.REACT_APP_API_URL;
const GetAllCoupons = () => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [lastPage, setLastPage] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const app_token = localStorage.getItem("tiger");
  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };
  useEffect(() => {
    getAllCoupons();
  }, [pageNum]);

  const handleStatus = async (id) => {
    try {
      const response = await axios.patch(change_Coupons_Status(id), {}, config);
      if (response.status < 400) {
        getAllCoupons();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const typeOfDiscount = (type) => {
    if (type === "fixed") {
      return <span>Fixed</span>;
    } else {
      return <span>Percentage</span>;
    }
  };
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(delete_Coupons(id), config);
      if (response.status < 400) {
        getAllCoupons();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllCoupons = async () => {
    try {
      const response = await axios.get(get_all_coupons(pageNum, 15), config);
      if (response.status < 400) {
        console.log(response.data);
        message.success(response.data.msg);
        setData(response.data.allCoupons.data);
        setLastPage(response.data.allCoupons.metadata.totalPages);
      }
    } catch (error) {
      console.log(error);
      // message.error();
    }
  };
  const paginate = (e) => {
    setPageNum(e.selected + 1);
  };
  let columns = [
    {
      title: "Coupan Code",
      dataIndex: "code",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "Number of Uses",
      dataIndex: "numberOfUses",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    
    {
      title: "Multiuser",
      dataIndex: "isMulti",
      render: (item) => {
        return <span>{item ? "Yes" : "No"}</span>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (item) => {
        return typeOfDiscount(item);
      },
    },
    {
      title: "Discount Amount",
      dataIndex: "value",
      render: (item, data) => {
        return <span>{item}{data?.type == 'percent' ? "%" : "" }</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (item, data) => {
        return (
          <Switch
            defaultChecked={item}
            onChange={() => handleStatus(data?._id)}
          />
        );
      },
    },
    {
      title: "Action",
      dataIndex: "actions",
      render: (item, data, index) => {
        return (
          <div className="d-flex align-items-center">
            &nbsp;&nbsp;&nbsp;
            <Link to={`${data._id}`}>
              <img src={editIcon} alt="Edit" className="h-[15px]" />
            </Link>
            <Popconfirm
              title="Delete this item? This cannot be undone."
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(data._id)}
            >
              <img className="w-[16px] ml-[5px]" src={Delete} alt="delete" />
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="prd-manage">
        <Row gutter={16}>
          <div className="flex items-center justify-between w-full px-2">
            <h3 className="m-0">All Coupon</h3>
            <Link to="/admin/get_all_coupons/add_coupons">
              <button className="add-btn">Add Coupan</button>
            </Link>
          </div>
          <Col span={24} className="mt-4 user-table">
            <Table
              rowKey="_id"
              rowSelection={null}
              key={data?.length}
              columns={columns}
              defaultCurrent={1}
              dataSource={data || []}
              scroll={{ x: 1000 }}
              pagination={false}
            />
          </Col>
          <ReactPaginate
            previousLabel={"<<"}
            nextLabel={">>"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={lastPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={paginate}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </Row>
      </div>
    </>
  );
};
GetAllCoupons.propTypes = {
  users: PropTypes.array,
};
export default GetAllCoupons;
