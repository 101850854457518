// import React from "react";
import "./mintSucess.scss";
import { Container, Row } from "react-bootstrap";
import MintBuy from "../mint buy/MintBuy";
import MintSold from "../mint sold/MintSold";
import SecondaryFooter from "../../secondaryfooter/SecondaryFooter";
import { mintPageAction } from "../../../redux/actions/mintPageAction";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Footer from "../../footer/Footer";

function MintSuccess() {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      dispatch(mintPageAction());
    }, 1000);
  }, []);

  const mintData = useSelector((state) => state.mintsold.mintContent);
  console.log(mintData, "title");
  // console.log(newimage, "newimage");
  return (
    <>
    <div className="mint_success pt-[15rem] ">
      <Container>
        <Row className="pb-[9rem]">
          <div className="!max-w-[1350px] row  flex items-center flex-row justify-center mx-auto">
            <div className=" col-lg-6 col-md-12 col-12 items-end text-center w-full">
              {
                location.pathname === "/mint-sale" ? (
                  <MintBuy />
                ) : (
                  ""
                )
              }
              {mintData && location.pathname !== '/mint-sale' &&
                mintData?.data?.map((item, index) => {
                  if (item?.section === "MINTSOLD") {
                    return (
                      <div key={index}>
                        <MintSold
                          desc={
                            item?.title
                              ?.filter((t, index) => index === 0)
                              ?.reduce((prev, curr) => curr, {}).description
                          }
                        />
                      </div>
                    );
                  }
                })}

            </div>
            <div className="col-lg-6 col-md-12 col-12 mint_card_image">
              {mintData &&
                mintData?.data?.map((item, index) => {
                  if (item?.section === "MINTSOLD") {
                    return (
                      <div key={index}>
                        <img
                          src={
                            item?.image
                              ?.filter((t, index) => index === 0)
                              ?.reduce((prev, curr) => curr, {}).item
                          }
                          alt="mint"
                          className="w-full"
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </Row>
        {/* <SecondaryFooter /> */}
      </Container>
    </div>
    <Footer />
    </>
  );
}

export default MintSuccess;
