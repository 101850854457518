import React from "react";
import { Form, Input, message, Select } from "antd";
import { add_country } from "../../../../api/Api-list";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { locationData } from "../../../../utils/locaiton";

const AddCountry = () => {
  const Admin_Token = localStorage.getItem("tiger");
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: `Bearer ${Admin_Token}`,
    },
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      let splitedData = values?.countryName.split(" ");
      let data = {
        countryName: values.countryName,
        countryCode: splitedData?.[0]
      }
      const response = await axios.post(add_country, data, config);
      if (response.status < 400) {
        message.success(response.data.msg);
        navigate("/admin/country");
      }
    } catch (err) {
      console.log(err);
      message.error(err.response.data.message);
    }
  };

  let filterData = locationData?.map((ele) => ({
    label: ele?.label,
    value: `${ele?.value} ${ele?.label}`,
  }));
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  return (
    <>
      <div className="container-fluid edit_product">
        <div className="row">
          <h2 className="cms-title">Add Country</h2>
          <div className="col-12 col-sm-12 col-md-12 bg-white shadow-sm p-5 cms-box">
            <Form layout="vertical" form={form} onFinish={handleSubmit}>
              <Form.Item
                name="countryName"
                label="Country Name"
                type="text"
                rules={[
                  { required: true, message: "Country name is required" },
                ]}
              >
                {/* <Input placeholder="Country Name" /> */}

                <Select
                  showSearch
                  placeholder="Select Country"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={filterData}
                  //   options={[
                  //   {
                  //     value: "jack",
                  //     label: "Jack",
                  //   },
                  //   {
                  //     value: "lucy",
                  //     label: "Lucy",
                  //   },
                  //   {
                  //     value: "tom",
                  //     label: "Tom",
                  //   },
                  // ]}
                />
              </Form.Item>

              <Form.Item>
                <button
                  type="submit"
                  className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] mt-2"
                >
                  Add Country
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCountry;
