import { Modal } from "antd";
import "../login/login.scss";

export function PaymentSettingsConfirmationModal({
  isConfirmationModalOpen,
  setIsConfirmationModalOpen,
  wantToEditPaymentSettings,
  setWantToEditPaymentSettings,
}) {
  return (
    <Modal
      open={true}
      title="Are you sure?"
      footer={null}
      onCancel={() => {
        setIsConfirmationModalOpen(false);
      }}
      centered={true}
      className="login_modal"
    >
      <div style={{ textAlign: "center", color: "white" }}>
        <p className="text-red-500 text-lg">
        If you change the credentials for payment gateway (and do not change them back) it will cause irreversible problems with existing payments and/or refunds. Do you want to continue?
        </p>
        <div>
          <button
            className="login-form-button px-5 py-2 text-center login_button mx-1"
            onClick={() => {
              setWantToEditPaymentSettings(true);
              setIsConfirmationModalOpen(false);
            }}
          >
            Yes
          </button>
          <button
            className="login-form-button px-5 py-2 text-center login_button mx-1"
            onClick={() => {
              setIsConfirmationModalOpen(false);
            }}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
}
