import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "antd";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import editIcon from "../../images/edit.png";

import { PropTypes } from "prop-types";

import { getAllPagesAction } from "../../../redux/actions/seoAction";

const PageSeoManagement = () => {
  const [page, setPage] = useState(1);

  const fetchAllPages = useSelector((state) => state?.seoData?.allpages);
  const dispatch = useDispatch();
  // const Admin_Token = localStorage.getItem("tiger");
  useEffect(() => {
    dispatch(getAllPagesAction());
  }, [dispatch]);

  let columns = [
    {
      title: "Sr no",
      dataIndex: "sr_no",
      render: (item, data, index) => {
        return <>{(page - 1) * 10 + index + 1}</>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "Title",
      dataIndex: "pageTitle",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: " Description",
      dataIndex: "pageDesc",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: " Keyword",
      dataIndex: "pageKeyword",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "Page Url",
      dataIndex: "pageUrl",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      render: (item) => {
        return <span>{new Date(item).toLocaleDateString()}</span>;
      },
    },

    {
      title: "Actions",
      dataIndex: "actions",
      render: (item, data, index) => {
        return (
          <div className="d-flex align-items-center">
            &nbsp;&nbsp;&nbsp;
            <Link to={`${data._id}`}>
              <img src={editIcon} alt="Edit" className="!h-[15px]" />
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="prd-manage">
        {true && (
          <Row gutter={16}>
            <div className="flex items-center justify-between w-full px-2">
              <h3 className="m-0">Manage Seo</h3>
            </div>
            <Col span={24} className="mt-4 user-table">
              <Table
                rowKey="_id"
                rowSelection={null}
                key={fetchAllPages?.data?.length}
                columns={columns}
                defaultCurrent={1}
                dataSource={fetchAllPages?.data || []}
                scroll={{ x: 1000 }}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  },
                }}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
PageSeoManagement.propTypes = {
  seo: PropTypes.array,
};

export default PageSeoManagement;
