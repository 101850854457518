import React, { useState, useEffect } from "react";
import { Row, Col, Table, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import editIcon from "../../../images/edit.png";
import deleteIcon from "../../../images/delete.png";
import Delete from "../../../images/delete.png";
import { PropTypes } from "prop-types";

import { CategoryList } from "../../../../redux/actions/productAction";
import {
  delete_address,
  delete_product_caterory,
  productCategory,
} from "../../../../api/Api-list";
import Swal from "sweetalert2";
import axios from "axios";

const ManageCategory = () => {
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState([]);

  // const fetchCategories = useSelector(
  //   (state) => state?.allProducts?.categories
  // );
  const dispatch = useDispatch();
  const Admin_Token = localStorage.getItem("tiger");
  useEffect(() => {
    getAllCategory()
  }, []);
  const navigate = useNavigate();


  const getAllCategory = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Admin_Token}`,
      },
    };
    try {
      const response = await axios.get(productCategory, config);
      setCategory(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  // write  a function to delele the image using ant upload component

  let columns = [
    {
      title: "Sr no",
      dataIndex: "sr_no",
      render: (item, data, index) => {
        return <>{(page - 1) * 10 + index + 1}</>;
      },
    },
    {
      title: "Category Name",
      dataIndex: "categoryName",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (item) => {
        return <span>{item ? "Active" : "Inactive"}</span>;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (item) => {
        return <span>{new Date(item).toLocaleDateString()}</span>;
      },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      render: (item) => {
        return <span>{new Date(item).toLocaleDateString()}</span>;
      },
    },

    {
      title: " Image",
      dataIndex: "image",
      render: (item) => {
        return (
          <>
            <img
              src={item}
              className="w-full max-w-[5rem] "
              alt="productImage"
            />
          </>
        );
      },
    },
    

    {
      title: "Actions",
      dataIndex: "actions",
      render: (item, data, index) => {
        return (
          <>
            <div className="d-flex align-items-center">
              &nbsp;&nbsp;&nbsp;
              <Link to={`${data?.categoryUrl}`}>
                <img src={editIcon} alt="Edit" className="!h-[15px]" />
              </Link>
              &nbsp;&nbsp;&nbsp;
              <span onClick={() => deleteProductCategory(data._id)}>
                <img src={deleteIcon} alt="delete" className="!h-[15px]" />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  const deleteProductCategory = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Admin_Token}`,
       
      },
      data: {
        _id: id,
      },
    };
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Successfully!", "Category Deleted Successfully", "success");

        axios
          .delete(delete_product_caterory, config)
          .then((res) => {

            if (res.status === 200) {
              getAllCategory()
              navigate('/admin/category-management')
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return (
    <>
      <div className="prd-manage">
        {true && (
          <Row gutter={16}>
            <div className="flex items-center justify-between w-full px-2">
              <h3 className="m-0">Manage Categories</h3>
              <Link to="/admin/category-management/add">
                <button className="add-btn">Add Category</button>
              </Link>
            </div>
            <Col span={24} className="mt-4 user-table">
              <Table
                rowKey="_id"
                rowSelection={null}
                key={category?.length}
                columns={columns}
                defaultCurrent={1}
                dataSource={category || []}
                scroll={{ x: 1000 }}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  },
                }}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
ManageCategory.propTypes = {
  users: PropTypes.array,
};

export default ManageCategory;
