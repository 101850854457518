import React from "react";
import { Form, Input, message } from "antd";
import {
  add_state,
  add_tax,
  get_all_countries,
  get_all_states,
} from "../../../../api/Api-list";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { useEffect } from "react";
import { useState } from "react";

const AddTax = () => {
  const [countryOptions, setCountryOptions] = useState([]);

  const [stateOptions, setStateOptions] = useState([]);

  const Admin_Token = localStorage.getItem("tiger");
  const [form] = Form.useForm();

  const navigate = useNavigate();

  useEffect(() => {
    getAllCountry();
  }, []);

  const config = {
    headers: {
      Authorization: `Bearer ${Admin_Token}`,
    },
  };

  const getAllCountry = async () => {
    try {
      const response = await axios.get(get_all_countries, config);
      if (response.status < 400) {
        const countryOptions = response.data.countries.map((country) => {
          return {
            value: country._id,
            label: country.countryName,
          };
        });
        setCountryOptions(countryOptions);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllStatesAccordingToCountry = async (id) => {
    try {
      const response = await axios.get(get_all_states(id), config);
      if (response.status < 400) {
        const stateOptions = response.data.states.map((state) => {
          return {
            value: state.stateId,
            label: state.stateName,
          };
        });
        setStateOptions(stateOptions);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const response = await axios.post(
        add_tax,
        {
          totalTaxAmount: Number(values.totalTaxAmount),
          stateId: values.stateName.value,
        },
        config
      );
      if (response.status < 400) {
        message.success(response.data.msg);
        navigate("/admin/tax");
      }
    } catch (err) {
      console.log(err);
      message.error(err.response.data.msg);
    }
  };

  return (
    <>
      <div className="container-fluid edit_product">
        <div className="row">
          <h2 className="cms-title">Add Tax</h2>
          <div className="col-12 col-sm-12 col-md-12 bg-white shadow-sm p-5 cms-box">
            <Form layout="vertical" form={form} onFinish={handleSubmit}>
              <Form.Item
                name="countryName"
                label="Select Country"
                rules={[{ required: true, message: "Select a country" }]}
              >
                <ReactSelect
                  value={form.getFieldValue("countryName")}
                  onChange={(e) => {
                    getAllStatesAccordingToCountry(e.value);
                    form.setFieldsValue({
                      stateName: "",
                    });
                  }}
                  options={countryOptions}
                />
              </Form.Item>

              <Form.Item
                name="stateName"
                label="Select State"
                rules={[{ required: true, message: "Select a State" }]}
              >
                <ReactSelect
                  value={form.getFieldValue("stateName")}
                  onChange={(e) => {}}
                  options={stateOptions}
                />
              </Form.Item>

              <Form.Item
                name="totalTaxAmount"
                label="Tax Amount (%)"
                type="text"
                rules={[{ required: true, message: "Tax amount is required" }]}
              >
                <Input placeholder="Tax amount" />
              </Form.Item>

              <Form.Item>
                <button
                  type="submit"
                  className="bg-[#B70100] text-white transition-all ease-out duration-100 font-semibold py-[0.6rem] px-4 rounded-3xl hover:bg-[#313E49] mt-2"
                >
                  Add Tax
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTax;
