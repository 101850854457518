import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CartItem from "../../components/proshop-page/shopping cart/cart-item/CartItem";
import "./nfts.scss";

function MyNfts() {
  return (
    <div className="nfts !pt-[7rem]">
      {/* <button style={{ "margin-left": "50rem" }} onClick={connectToMetaMask}>
        Connect To Your Wallet To See Your NFTs
      </button> */}
      <Container>
        <Row>
          <Col>
            <div className="text-center nfts_content">
              <h2 className="text-[40px] font-bold text-white mb-1 text-center mt-4 ">
                My Nfts
              </h2>
              <p className="my-2 text-[20px] text-[#a6a6a6] ">
                2 items are available for purchase
              </p>
              <img
                src="images/dot_border.png"
                alt="borderimage"
                className="text-center d-inline"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xl="12" lg="12" md="12" sm="12">
            <div className=" items-center row hidden  pl-[23px] product_info_heading ">
              <h6 className=" col-lg-7 col-md-7 col-12 text-white text-[18px] ">
                Product
              </h6>
              <h6 className="col-lg-3 col-md-3 col-12 text-white text-[18px]">
                Quantity
              </h6>
              <h6 className=" col-lg-2 col-md-2 col-12 text-white text-[18px]">
                Price
              </h6>
            </div>
            <CartItem />
            <CartItem />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MyNfts;
