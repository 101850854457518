import React from "react";
import OrderProduct from "../../orderProduct/OrderProduct";
import { useEffect, useRef } from "react";
const OrderSummary = (padding) => {
  const divRef = useRef(null);

  if (padding === 10) {
    divRef.current.className = "mt-3 pt-20";
  } else if (padding === 20) {
    divRef.current.class = "mt-3  pb-[1rem] md:pb-[1rem] lg:pb-[19rem]";
  }
  useEffect(() => {
    console.log(divRef.current);
    if (padding.padding === 10) {
      divRef.current.className = "mt-3 pt-20";
    } else if (padding.padding === 20) {
      divRef.current.className = "mt-3  pb-[1rem] md:pb-[1rem] lg:pb-[7rem]";
    }
    console.log(padding.padding);
  }, [divRef, padding]);
  return (
    <div>
      <div className="grid grid-flow-col grid-rows-1 gap-3 bg-[#fdfdfd10]">
        <div className="col-span-1 rounded-[10px] border-[2px] border-stone-800  p-5 px-4">
          <div ref={divRef} className="mt-3 ">
            <h4 className="text-[20px] font-bold text-white">Order Summary</h4>
            <p className="mb-0 text-[15px] text-gray-400">2 Products</p>
            <OrderProduct />
            <hr className="opacity-25" />
            <OrderProduct />
            <hr className="opacity-25" />
            <div className="pt-[2rem] pb-[2rem]">
              <div className="product_total flex justify-between py-1">
                <h6 className="text-[15px] text-white ">Product Total</h6>
                <h6 className="text-[15px] text-white">$16</h6>
              </div>
              <div className="product_total flex  justify-between py-1">
                <h6 className="text-[15px] text-white">Tax (20%)</h6>
                <h6 className="text-[15px] text-white">$2</h6>
              </div>
              <div className="product_total flex  justify-between py-1">
                <h6 className="text-[15px] text-white">Coupon Code</h6>
                <h6 className="text-[15px] text-white">$0</h6>
              </div>
              <div className="product_total flex  justify-between py-1">
                <h6 className="text-[17px] font-semibold text-white">Total</h6>
                <h6 className="text-[17px] font-semibold  text-white">$18</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
