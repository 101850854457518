import React, { useEffect } from "react";
import { Tabs } from "antd";
import { useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.css";
import MyAccount from "../../components/profile-page/my-account/MyAccount";
import "./MyProfile.scss";
import MyOrder from "../../components/profile-page/my-order/MyOrder";
import { useNavigate } from "react-router-dom";
import MyNftsDetails from "../../components/profile-page/my-order/orderItem/MyNftsDetails";
import MyAddress from "../../components/profile-page/my-address/MyAddress";
import FavouriteProducts from "../../components/profile-page/products/FavouriteProducts";
const { TabPane } = Tabs;

const MyProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();

  function callback(key) {
    console.log(key);
    // if (key == 3) {
    //   navigate("/my-profile#myorder");
    // }

    switch (key) {
      case "1":
        navigate("/my-profile#myaccount");

        break;

      case "2":
        navigate("/my-profile#nftdetails");
        break;

      case "3":
        navigate("/my-profile#myorder");
        break;

      case "4":
        navigate("/my-profile#myaddress");
        break;
      case "5":
        navigate("/my-profile#favourite");
        break;

      default:
        navigate("/my-profile#myaccount");
    }
  }
  useEffect(() => {
    console.log(location);
  }, []);

  var token = localStorage.getItem("tiger-token");
  useEffect(() => {
    if (token === null) {
      navigate("/");
    }
  });

  const callme = () => {
    if (location?.hash == "#myorder") {
      return "3";
    }

    if (location?.hash == "#myaccount") {
      return "1";
    }

    if (location?.hash == "#nftdetails") {
      return "2";
    }

    if (location?.hash == "#myaddress") {
      return "4";
    }

    if (location?.hash == "#favourite") {
      return "5";
    }
  };
  return (
    <div className="py-8 profile_content ">
      <div className="py-4 flex flex-col items-center justify-center">
        <h2 className=" font-bold text-white text-center pt-[5rem] profile_title pb-3 ">
          My Profile
        </h2>
      </div>

      <div className="tabs-container">
        <Tabs
          activeKey={callme()}
          defaultActiveKey={location?.search ? "4" : "1"}
          onChange={callback}
        >
          <TabPane tab="My Account" key="1">
            <MyAccount />
          </TabPane>
          <TabPane tab="My NFTs" key="2">
            <MyNftsDetails />
          </TabPane>
          <TabPane tab="My Orders" key="3">
            <MyOrder />
          </TabPane>
          <TabPane tab="My Address" key="4">
            <MyAddress />
          </TabPane>
          <TabPane tab="Favourite" key="5">
            <FavouriteProducts />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default MyProfile;
