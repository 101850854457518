import React from 'react'
import { Link } from 'react-router-dom'
import "./Error.scss"

const Error = () => {
  return (
    <div>
      <div className='not-found-page'>
        <h1>404 - Not Found!</h1>
        <Link to="/">Go Home</Link>
      </div>
  </div>
  )
}

export default Error