import React from "react";
// import { Avatar, Image } from "antd";
import { Link, useNavigate } from "react-router-dom";
import * as actionTypes from "../../../redux/types/cartType";
import { setProduct } from "../../../redux/actions/productAction";
import { useDispatch, useSelector } from "react-redux";
import { remove_fav_product, update_fav_product } from "../../../api/Api-list";
import axios from "axios";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

function RecentProductCard({ product, oneEth }) {
  const cart = useSelector((state) => state.cartItem.cart);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const app_token = localStorage.getItem("tiger-token");

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  const handleLike = async (id) => {
    try {
      let res = await axios.patch(update_fav_product(id), {}, config);
      if (res.status < 400) {
        // addToast(res.data.message, {
        //   appearance: "success",
        //   autoDismiss: true,
        // });
      }
    } catch (error) {
      console.log(error);
      // addToast(error.response.data.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  };
  const handleUnlike = async (id) => {
    try {
      let res = await axios.delete(remove_fav_product(id), config);
      if (res.status < 400) {
        // addToast(res.data.message, {
        //   appearance: "success",
        //   autoDismiss: true,
        // });
      }
    } catch (error) {
      console.log(error);
      // addToast(error.response.data.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  };

  const addToCartHandler = (product) => {
    dispatch({
      type: actionTypes.ADD_TO_CART,
      payload: product,
    });
  };
  const goToDetails = (product) => {
    dispatch(setProduct(product));
    navigate(`/prop-detail/${product?.pageUrl}`);
  };
  return (
    <div
      className="w-full cursor-pointer bg-[#161616] rounded-xl transition-all duration-300 hover:bg-[#353434] Recent_product_card group"
      onClick={() => goToDetails(product)}
    >
      <div>
        <div className="no-underline text-white">
          <img
            src={product?.images[0]?.image || "../../images/noimg.png"}
            className="rcnt-add-prd-img rounded-xl w-full object-cover max-h-[250px]"
            title={product?.title}
            alt={product?.title}
          />
        </div>
      </div>
      <div className="p-4 w-full space-y-2">
        <p className="text-[#979797] font-bold text-[15px]">
          {product?.category?.[0]?.categoryName}
        </p>
        {product?.isComingSoon ? (
          <p className="product-card-usd mt-40" style={{ fontSize: "18px" }}>
            Coming Soon
          </p>
        ) : (
          ""
        )}

        <p className="text-white font-bold text-[20px] group-hover:!text-[#f97316]">
          {product?.title}
        </p>

        {!product?.isComingSoon && (
          <div className="flex justify-between items-center mt-2">
            {product?.minPrice && (
              <span className="product-card-usd">${product?.minPrice} </span>
            )}
            <span className="product-card-ethereum">
              {(product?.minPrice / oneEth).toFixed(5)}
              <img src="/images/eth-icon.png" alt="ethicons" />
            </span>
          </div>
        )}

        <div className="flex justify-between p-1 items-center">
          {product?.category.length > 0 && (
            <div>
              <img
                src={product?.category?.[0]?.image}
                alt="team"
                className="d-inline w-8 object-cover rounded-full "
              />
              <span className="font-semibold text-[16px] pl-2">
                {" "}
                {product?.category?.[0]?.categoryName}
              </span>
            </div>
          )}
        </div>

        <div className="text-center">
          <button
            className="border-2 text-white px-4 py-2 rounded-full m-1 my-3 font-semibold text-[15px] hover:bg-orange-500/30 hover:border-orange-500 "
            onClick={() => goToDetails(product)}
          >
            View Product
          </button>
        </div>
      </div>
    </div>
  );
}

export default RecentProductCard;
