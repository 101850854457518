import { message } from "antd";
import axios from "axios";
import {
  add_home_page_team_section,
  get_home_page_team_section,
  get_setting_page_data,
  get_single_page_sections,
  update_home_page_team_section,
  update_setting_page_data,
} from "../../api/Api-list";
import {
  FETCH_HOME_PAGE_TEAM_SECTION,
  FETCH_PAGE_BY_ID,
  FETCH_SETTING_PAGE,
  UPDATE_FETCH_SETTING_PAGE_SUCCESS,
} from "../types/cmsSettingType";

export const getSinglePageSectionInitiate = () => {
  return function (dispatch) {
    axios
      .get(`${get_single_page_sections}/home`)
      .then((response) => {
        dispatch({
          type: FETCH_PAGE_BY_ID,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};
export const getHomeTeamSectionInitiate = (Admin_Token) => {
  return function (dispatch) {
    const config = {
      headers: { Authorization: `Bearer ${Admin_Token}` },
    };
    axios
      .get(get_home_page_team_section, config)
      .then((response) => {
        dispatch({
          type: FETCH_HOME_PAGE_TEAM_SECTION,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};


export const addHomeTeamSectionInitiate = (
  values,
  Admin_Token,
  id,
  navigate
) => {
  return (dispatch) => {
    // console.log(values, "dataValue");
    const config = {
      headers: {
        Authorization: `Bearer ${Admin_Token}`,
      },
    };
    axios
      .post(
        `${add_home_page_team_section}`,
        {
          ...values,
        },
        config
      )

      .then(async (response) => {
        console.log("update_home_page_team_section", response.data);

        // dispatch({
        //   type: UPDATE_FETCH_SETTING_PAGE_SUCCESS,
        //   payload: response.data,
        // });

        const allUsers = await axios
          .get(get_home_page_team_section, config)
          .then((response) => {
            dispatch({
              type: FETCH_HOME_PAGE_TEAM_SECTION,
              payload: response.data,
            });
          })
          .catch((error) => {
            console.log(error.response);
          });

        navigate("/admin/team");
        message.success(`${response.data.message}`);
      })
      .catch((error) => {
        console.log(error);
        message.error(`${error.response.data.message}`);

      });
  };
};



export const updateHomeTeamSectionInitiate = (
  values,
  Admin_Token,
  id,
  navigate
) => {
  return (dispatch) => {
    // console.log(values, "dataValue");
    const config = {
      headers: {
        Authorization: `Bearer ${Admin_Token}`,
      },
    };
    axios
      .patch(
        `${update_home_page_team_section}/${id}`,
        {
          ...values,
        },
        config
      )

      .then(async (response) => {
        console.log("update_home_page_team_section", response.data);

        // dispatch({
        //   type: UPDATE_FETCH_SETTING_PAGE_SUCCESS,
        //   payload: response.data,
        // });

        const allUsers = await axios
          .get(get_home_page_team_section, config)
          .then((response) => {
            dispatch({
              type: FETCH_HOME_PAGE_TEAM_SECTION,
              payload: response.data,
            });
          })
          .catch((error) => {
            console.log(error.response);
          });

        navigate("/admin/team");
        message.success(`${response.data.message}`);
      })
      .catch((error) => {
        console.log(error);
        message.error(`${error.response.data.message}`);

      });
  };
};

// home page logo and setting tab by admin
export const fetchSettingPageInitiate = () => {
  return function (dispatch) {
    axios
      .get(get_setting_page_data)
      .then((response) => {
        dispatch({
          type: FETCH_SETTING_PAGE,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const adminSettingUpdate = (values, id, Admin_Token, navigate) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Admin_Token}`,
      },
    };
    axios
      .patch(
        update_setting_page_data,
        {
          _id: id,
          ...Object.keys(values)
            .filter((key) => key !== "__v")
            .reduce((prev, curr) => ({ ...prev, [curr]: values[curr] }), {}),
        },
        config
      )

      .then(async (response) => {
        console.log("response./admin/setting", response.data);

        // dispatch({
        //   type: UPDATE_FETCH_SETTING_PAGE_SUCCESS,
        //   payload: response.data,
        // });

        await axios
          .get(get_setting_page_data)
          .then((response) => {
            dispatch({
              type: FETCH_SETTING_PAGE,
              payload: response.data,
            });
          })
          .catch((error) => {
            console.log(error.response);
          });

        navigate("/admin/setting");
        message.success(`${response.data.message}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
