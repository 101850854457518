import axios from "axios";
import { resendverify } from "../../api/Api-list";
import * as types from "../types/usertypes";
import { message } from "antd";

const resendStart = () => {
  return {
    type: types.USER_RESEND_VERIFICATION,
  };
};

const loginStart = () => {
  return {
    type: types.USER_LOGIN,
  };
};

export const loginuser = (email, password) => {
  return (dispatch) => {
    dispatch(loginStart());
    axios
      .post("/api/user/login", {
        email: email,
        password: password,
      })
      .then((res) => {
        console.log(res, "========");
        dispatch({
          type: types.USER_LOGIN_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: types.USER_LOGIN_FAILURE,
        });
      })
      .finally(() => {
        dispatch(loginStart());
      });
  };
};

// const loginuser = (email, password) => {
//   return {
//     type: types.USER_LOGIN,
//     payload: {
//       email: email,
//       password: password,
//     },
//   };
// };

export const resendVerifyLink = (email) => {
  return async (dispatch) => {
    dispatch(resendStart());
    try {
      const response = await axios.post(resendverify, {
        email: email,
      });
      console.log(response);
      message.success(response.data.msg);
    } catch (err) {
      console.log(err);
      message.error(err.response.data.msg);
    }
  };
};
