// import {
//     LOGIN_ADMIN,
//     LOGIN_ADMIN_SUCCESS,
//     LOGIN_ADMIN_FAILURE,
//     REGISTER_USER,
//     REGISTER_USER_SUCCESS,
//     REGISTER_USER_FAILURE,
//     initialState,
// } from '../actions/LoginRegister';
import * as types from "../types/usertypes";

const initialState = {
  isAuthenticated: false,
  user: {},
  loading: true,
  error: false,
};

const AdminActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_ADMIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.LOGIN_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload,
      };
    case types.LOGIN_ADMIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        error: action.payload,
      };

    case types.LOGOUT_ADMIN:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        user: null,
      };

    case types.ADMIN_PROFILE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload,
      };
    case types.CHANGE_ADMIN_PASSWORD:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    case types.UPDATE_ADMIN_PROFILE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload,
      };

    default:
      return state;
  }
};

export default AdminActionReducer;
