// import Header from "./components/header/Header";
// import Footer from "./components/footer/Footer";

import "./App.scss";

import { Route, Routes, useLocation } from "react-router-dom";

import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";

import Homepage from "./pages/Homepage/Homepage";
import ProShop from "./pages/proshop/ProShop";
import Order from "./components/orderConfirmation/Order";

// import MyAccount from "./components/profile-page/my-account/MyAccount";
// import OrderSummary from "./components/proshop-page/shopping cart/cart-item/OrderSummary";
import ShoppingCart from "./pages/shopping-cart/ShoppingCart";

import Profile from "./admin/admin-dashboard/profile/profile";
import CustomerList from "./admin/admin-dashboard/customer-list/customer_list";
import AdminOrderList from "./admin/admin-dashboard/admin-order/admin-order-list/adminOrderList";
import AdminOrderDetails from "./admin/admin-dashboard/admin-order/admin-order-details/adminOrderDetails";

// import MintWhitelist from "./components/mintpage components/mint whitelist/MintWhitelist";
import MintSuccess from "./components/mintpage components/mint_sucess/MintSucess";

// import RecentProductCard from "./components/proshop-page/product/RecentProductCard";
import Adminlogin from "./admin/admin-login/admin-login";
import AdminDashboard from "./admin/admin-dashboard/admin-dashboard";
import Prodcuts from "./admin/admin-dashboard/product-management/Products";
import AddProduct from "./admin/admin-dashboard/product-management/AddProduct";
import MemberBenefit from "./pages/memberBenefit page/MemberBenefit";
// import BillingDetail from "./components/checkout/billingDetail/BillingDetail";
import Checkout from "./components/checkout/Checkout";
import MyProfile from "./pages/myAccount/MyProfile";
// import MintSold from "./components/mintpage components/mint sold/MintSold";
import MintPage from "./pages/mintPage/MintPage";
import Error from "./pages/Error/Error";
import PropCategory from "./components/Propshop categories/PropCategory";
import PropProductDetail from "./components/propshop-product-detail/PropProductDetail";
import ViewUser from "./admin/admin-dashboard/customer-list/Customer-detail/viewuser/ViewUser";
import EditUser from "./admin/admin-dashboard/customer-list/Customer-detail/editUser/EditUser";
import InnerPage from "./components/innerpage/InnerPage";
import AdminLayout from "./Layout/AdminLayout";
import UserLayout from "./Layout/UserLayout";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Cms from "./admin/admin-dashboard/cms/Cms";
import CmsForm from "./admin/admin-dashboard/cms/CmsForm";
import { useEffect, useRef, useState } from "react";
import {
  fetchSettingPageInitiate,
  getHomeTeamSectionInitiate,
  getSinglePageSectionInitiate,
} from "./redux/actions/cmsSettingAction";
import { useDispatch, useSelector } from "react-redux";
import CmsSettingPageList from "./admin/admin-dashboard/cmsSettingPageList/CmsSettingPageList";
import GTCGallery from "./components/homepage components/gtc_gallery/GTC_Gallery";
import TeamPageList from "./admin/admin-dashboard/TeamPageList/TeamPageList";
import TeamForm from "./admin/admin-dashboard/teamForm/TeamForm";
import TeamCardComponent from "./components/homepage components/teamcardcomponent/TeamCardComponent";
// import Card_Container from "./components/homepage components/card_container/Card_Container";
import EditProduct from "./admin/admin-dashboard/product-management/EditProduct";
import ManageCategory from "./admin/admin-dashboard/product-management/Catergory/ManageCategory";
import AddCategory from "./admin/admin-dashboard/product-management/Catergory/AddCategory";
import EditCategory from "./admin/admin-dashboard/product-management/Catergory/EditCategory";
import Clubhouse from "./pages/Clubhouse/Clubhouse";
import ClubhouseOuter from "./pages/Clubhouse/ClubhouseOuter";
import PageSeoManagement from "./admin/admin-dashboard/PageSeoManagement/PageSeoManage";
import EditPageSeo from "./admin/admin-dashboard/PageSeoManagement/EditPageSeo";
import MyNfts from "./pages/my-nfts/MyNfts";
import EditAddress from "./components/profile-page/my-address/EditAddress";
import Menu from "./admin/admin-dashboard/settings/Menu";
import EditMenu from "./admin/admin-dashboard/settings/EditMenu";
import ClubhouseList from "./admin/admin-dashboard/clubhouse/ClubhouseList";
import EditClubhouse from "./admin/admin-dashboard/clubhouse/EditClubhouse";
import ReCAPTCHA from "react-google-recaptcha";
import AddCoupons from "./admin/admin-dashboard/coupons/add_coupons";
import GetAllCoupons from "./admin/admin-dashboard/coupons/getAll_coupons";
import EditCoupons from "./admin/admin-dashboard/coupons/edit_coupons";
import EmailTemplateList from "./admin/admin-dashboard/email-template/email-template-list";
import UpdateEmailTemplate from "./admin/admin-dashboard/email-template/update-email-template";
import CountryList from "./admin/admin-dashboard/manage-location/manage-country/country-list";
import AddCountry from "./admin/admin-dashboard/manage-location/manage-country/add-country";
import EditCountry from "./admin/admin-dashboard/manage-location/manage-country/edit-country";
import AddState from "./admin/admin-dashboard/manage-location/manage-state/add-state";
import StateList from "./admin/admin-dashboard/manage-location/manage-state/state-list";
import EditState from "./admin/admin-dashboard/manage-location/manage-state/edit-state";
import AddTax from "./admin/admin-dashboard/manage-location/manage-tax/add-tax";
import TaxList from "./admin/admin-dashboard/manage-location/manage-tax/tax-list";
import EditTax from "./admin/admin-dashboard/manage-location/manage-tax/edit-tax";
import OrderDetails from "./pages/Order/OrderDetails";
import Payment from "./pages/shopping-cart/payment/Payment";
import PaymentSuccess from "./pages/shopping-cart/payment/PaymentSuccess";
import Seo from "./components/Seo";
import axios from "axios";
import {
  add_to_cart,
  get_all_cart_items_with_cookie,
  get_menu,
} from "./api/Api-list";
import { SpinnerDotted } from "spinners-react";
import AddTeamForm from "./admin/admin-dashboard/teamForm/AddTeamForm";
import { getTotalCartItem } from "./redux/actions/cartSectionAction";

const { ethereum } = window;

const Loading = () => {
  return (
    <div className="loaderbg">
      <div className="loader">
        <SpinnerDotted size={64} thickness={100} speed={100} color="#ff8500" />
      </div>
    </div>
  );
};

function App() {
  const Admin_Token = localStorage.getItem("tiger");
  const dispatch = useDispatch();
  const recaptchaRef = useRef();
  const location = useLocation();
  const [seoData, setSeoData] = useState(null);

  const [pathOfHelmet, setPathOfHelmet] = useState({
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const isAuthenticated = useSelector(
    (state) => state.userProfile.isAuthenticated
  );

  const app_token = localStorage.getItem("tiger-token");
  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  // update authenticated cart items handler
  const updateCartItems = async (productId, priceTierId, quantity) => {
    const payload = {
      items: {
        productId: productId,
        priceTierId: priceTierId,
        quantity: Number(quantity),
      },
    };

    try {
      const response = await axios.post(add_to_cart, payload, config);
      if (response.status < 400) {
      }
    } catch (err) {
      console.log(err);
    }
  };

  // cookie set for cart
  const rtgcCartId = localStorage.getItem("rtgcCartId");

  useEffect(() => {
    if (isAuthenticated || rtgcCartId) return;
    localStorage.setItem("rtgcCartId", uuidv4());
  }, []);

  // update cart items after login with cookie cart
  useEffect(() => {
    if (isAuthenticated && rtgcCartId !== null) {
      const getCokieCartItems = async () => {
        try {
          const response = await axios.get(
            get_all_cart_items_with_cookie(rtgcCartId)
          );

          if (response.data?.cart?.items.length > 0) {
            // const items = response.data?.cart?.items.map((item) => {
            //   return {
            //     productId: item.product._id,
            //     priceTierId: item.priceTier._id,
            //     quantity: Number(item.quantity),
            //   };
            // });
            response.data?.cart?.items.forEach(async (item) => {
              await updateCartItems(
                item.product._id,
                item.priceTier._id,
                item.quantity
              );
            });
            dispatch(getTotalCartItem(app_token, isAuthenticated, rtgcCartId));
            localStorage.removeItem("rtgcCartId");
          } else {
            localStorage.removeItem("rtgcCartId");
          }
        } catch (err) {
          console.log(err);
        }
      };

      getCokieCartItems();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1200);
    return () => clearTimeout(timer);
  }, [location]);

  useEffect(() => {
    dispatch(getSinglePageSectionInitiate());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSettingPageInitiate());
  });
  useEffect(() => {
    dispatch(getHomeTeamSectionInitiate(Admin_Token));
  });

  const getSeoData = async (type) => {
    try {
      const response = await axios.get(get_menu(type));
      if (response.status < 400) {
        setSeoData(response?.data?.data);
        let data = response?.data?.data?.find(
          (ele) => ele.link === `${location.pathname}`
        );
        setPathOfHelmet({
          metaTitle: data?.pageTitle,
          metaDescription: data?.pageDesc,
          metaKeywords: data?.pageKeyword,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (location?.pathname) {
      getSeoData("Seo");
    }
  }, [location?.pathname]);

  if (isLoading) return <Loading />;

  return (
    <div className="App">
      <Seo
        title={pathOfHelmet?.metaTitle ? pathOfHelmet?.metaTitle : ""}
        desc={pathOfHelmet?.metaDescription}
        keywords={pathOfHelmet?.metaKeywords}
      />

      {/* admin layout */}
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_SITE_KEY}
        size="invisible"
        ref={recaptchaRef}
      />
      <Routes>
        <Route path="admin-login" element={<Adminlogin />} />
        <Route exact path="/admin" element={<AdminLayout />}>
          <Route index element={<AdminDashboard />} />
          <Route path="admin-dashboard" element={<AdminDashboard />} />
          <Route path="profile" element={<Profile />} />
          <Route path="order-list" element={<AdminOrderList />} />
          <Route path="order/:id" element={<AdminOrderDetails />} />
          <Route path="get_all_coupons/add_coupons" element={<AddCoupons />} />
          <Route path="get_all_coupons" element={<GetAllCoupons />} />
          <Route path="get_all_coupons/:id" element={<EditCoupons />} />
          {/* Product Routes  */}
          <Route path="product-management" element={<Prodcuts />} />
          <Route path="product-management/add" element={<AddProduct />} />
          <Route path="product-management/:id" element={<EditProduct />} />
          {/* Category Routes */}
          <Route path="category-management" element={<ManageCategory />} />
          <Route path="category-management/add" element={<AddCategory />} />
          <Route path="category-management/:id" element={<EditCategory />} />
          {/* Page Seo Management Routes */}
          <Route path="pageseo-management" element={<PageSeoManagement />} />

          <Route path="pageseo-management/:id" element={<EditPageSeo />} />
          {/* Cms Routes  */}

          <Route path="customer-list" element={<CustomerList />} />
          <Route path="customer-list/user-detail/:id" element={<ViewUser />} />
          <Route path="customer-list/edit-user/:id" element={<EditUser />} />
          <Route path="cms" element={<Cms />} />
          <Route path="cms/:id" element={<Cms />} />
          <Route path="setting" element={<CmsSettingPageList />} />
          <Route path="team" element={<TeamPageList />} />
          <Route path="team/team-form/:id" element={<TeamForm />} />
          <Route path="team/add" element={<AddTeamForm />} />
          <Route path="setting/cms-form/:id" element={<CmsForm />} />
          <Route path="/admin/menu" element={<Menu />} />
          <Route path="/admin/menu/:id" element={<EditMenu />} />
          <Route path="/admin/clubhouse" element={<ClubhouseList />} />
          <Route path="/admin/clubhouse/:id" element={<EditClubhouse />} />
          {/* admin email template routes */}
          <Route path="/admin/email-template" element={<EmailTemplateList />} />
          <Route
            path="/admin/email-template/:id"
            element={<UpdateEmailTemplate />}
          />

          {/* admin manage country routes */}
          <Route path="/admin/country" element={<CountryList />} />
          <Route path="/admin/add-country" element={<AddCountry />} />
          <Route path="/admin/country/:id" element={<EditCountry />} />

          {/* admin manage state routes */}
          <Route path="/admin/state" element={<StateList />} />
          <Route path="/admin/add-state" element={<AddState />} />
          <Route path="/admin/state/:id" element={<EditState />} />

          {/* admin manage state routes */}
          <Route path="/admin/tax" element={<TaxList />} />
          <Route path="/admin/add-tax" element={<AddTax />} />
          <Route path="/admin/tax/:id" element={<EditTax />} />
        </Route>

        {/* userLayout */}

        <Route exact path="/" element={<UserLayout />}>
          <Route index element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          <Route path="proshop" element={<ProShop />} />
          {/*<Route path="clubhouse" element={<CartItem />} /> */}
          {/*<Route path="order" element={<OrderSummary />} /> */}
          {/*<Route path="account" element={<MyAccount />} /> */}
          {/*<Route path="mint-whitelist" element={<MintWhitelist />} />*/}
          {
            /* <Route path="gallery" element={<GTCGallery />} />
          <Route path="ourteam" element={<TeamCardComponent />} />
          <Route path="roadmap" element={<Card_Container />} /> */ ``
          }
          <Route path="ourteam" element={<TeamCardComponent />} />
          {/* <Route path="roadmap" element={<Card_Container />} /> */}
          <Route path="gallery" element={<GTCGallery />} />
          <Route path="mint-sold" element={<MintSuccess />} />
          <Route path="mint-sale" element={<MintSuccess />} />
          <Route path="clubhouse" element={<Clubhouse />} />
          <Route path="clubhouse-outer" element={<ClubhouseOuter />} />
          <Route path="about" element={<InnerPage />} />
          <Route path="privacy" element={<InnerPage />} />
          <Route path="terms" element={<InnerPage />} />
          <Route path="sitemap" element={<InnerPage />} />
          <Route path="cart" element={<ShoppingCart />} />
          <Route path="my-nfts" element={<MyNfts />} />
          <Route path="orderConfirmation" element={<Order />} />
          <Route path="billing-detail" element={<Checkout />} />
          <Route path="my-profile" element={<MyProfile />} />
          <Route path="edit-address/:id" element={<EditAddress />} />
          <Route path="change-password/:id" element={<ChangePassword />} />
          <Route path="mint-page" element={<MintPage />} />
          <Route path="mint-sucess" element={<MintSuccess />} />
          <Route path="holders" element={<MemberBenefit />} />
          <Route path="prop-category/:id" element={<PropCategory />} />
          <Route path="prop-detail/:id" element={<PropProductDetail />} />
          <Route path="innerpage" element={<InnerPage />} />
          <Route path="order-details/:id" element={<OrderDetails />} />
          <Route path="payment" element={<Payment />} />
          <Route path="payment/success/:id" element={<PaymentSuccess />} />
          <Route path="/*" element={<Error />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
