import React, { useState, useEffect } from "react";
import axios from "axios";
import OrderSummary from "../../../components/proshop-page/shopping cart/cart-item/OrderSummary";
import "../shoppingCart.scss";
import {
  add_to_cart,
  get_all_cart_items,
  remove_coupon_from_cart,
} from "../../../api/Api-list";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CART_STEP } from "../../../redux/types/cartSectionType";
import { SpinnerCircular } from "spinners-react";

function Checkout({ previousStep }) {
  const [cart, setCart] = useState([]);
  const [totalCartAmount, setTotalCartAmount] = useState({ usd: 0, eth: 0 });
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponValue] = useState(0);
  const [couponType, setCouponType] = useState("");
  const [shippingCharge, setShippingCharge] = useState(0);
  const [loading, setLoading] = useState(false);
  const [btnloading, setBtnLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [oneEth, setOneEth] = useState(0);

  const cartStep = useSelector((state) => state.cartSection.cartStep);

  const app_token = localStorage.getItem("tiger-token");

  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  useEffect(() => {
    getAllCartItems();
  }, [cartStep]);

  const getAllCartItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(get_all_cart_items, config);

      if (response.status < 400) {
        setLoading(false);

        setCart(response.data?.cart?.items);
        setOneEth(response.data?.oneETH);
        setTotalCartAmount(() => {
          return {
            usd: response.data?.details?.totalPriceAmount,
            eth: response.data?.details?.totalEthAmount,
          };
        });
        setSelectedAddress(response.data?.cart?.address);
        setCouponCode(() => {
          return !response.data?.cart?.coupon
            ? ""
            : response.data?.cart?.coupon?.code;
        });
        setCouponValue(() => {
          return !response.data?.cart?.coupon
            ? 0
            : response.data?.cart?.coupon?.value;
        });
        setCouponType(() => {
          return !response.data?.cart?.coupon
            ? ""
            : response.data?.cart?.coupon?.type;
        });
        setShippingCharge(response.data?.details?.shippingAmount);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const applyCoupon = async () => {
    setBtnLoading(true);

    if (couponCode === "") {
      setBtnLoading(false);

      addToast("Please add a coupon first", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    try {
      const payload = {
        coupon: couponCode,
      };
      const response = await axios.post(add_to_cart, payload, config);
      if (response.status < 400) {
        setBtnLoading(false);

        addToast("Coupon applied", {
          appearance: "success",
          autoDismiss: true,
        });
        getAllCartItems();
      }
    } catch (err) {
      addToast(err.response.data.msg || err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
      setBtnLoading(false);
    }
  };

  const removeCoupon = async () => {
    setRemoveLoading(true);

    try {
      const response = await axios.delete(remove_coupon_from_cart, config);
      if (response.status < 400) {
        addToast(response.data.msg, {
          appearance: "success",
          autoDismiss: true,
        });

        setRemoveLoading(false);

        getAllCartItems();
      }
    } catch (err) {
      addToast(err.response.data.msg, {
        appearance: "error",
        autoDismiss: true,
      });
      setRemoveLoading(false);
    }
  };

  const checkout = async () => {
    if (
      (couponType === "" &&
        Number(
          shippingCharge +
            totalCartAmount.usd *
              (selectedAddress?.state?.totalTaxAmount / 100) +
            totalCartAmount.usd
        ) > 1) ||
      (couponType === "fixed" &&
        Number(
          shippingCharge +
            totalCartAmount.usd *
              (selectedAddress?.state?.totalTaxAmount / 100) +
            totalCartAmount.usd -
            couponValue
        ) > 1) ||
      (couponType === "percent" &&
        Number(
          shippingCharge +
            totalCartAmount.usd *
              (selectedAddress?.state?.totalTaxAmount / 100) +
            totalCartAmount.usd -
            ((totalCartAmount.usd *
              (selectedAddress?.state?.totalTaxAmount / 100) +
              totalCartAmount.usd) *
              couponValue) /
              100
        ) > 1)
    ) {
      navigate("/payment");
      dispatch({
        payload: 1,
        type: CART_STEP,
      });
    } else {
      addToast("Checkout amount must be greater than $1", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  if (loading)
    return (
      <div className="flex justify-center items-start">
        <SpinnerCircular
          size={50}
          thickness={100}
          speed={100}
          color="rgba(255, 133, 0, 1)"
          secondaryColor="rgba(0, 0, 0, 0.44)"
        />
      </div>
    );

  return (
    <div className="cart-final-step-sec">
      <div className="flex justify-between">
        <div className="final-step-product-info">
          {cart.map((item) => {
            return (
              <div className="final-step-product-info-item">
                <img
                  src={item.product?.images?.[0]?.image || "images/noimg.png"}
                  alt="man_card"
                  className="final-step-product-img"
                />
                <div className="final-step-product-info-text">
                  <h2>Name : {item.product.title}</h2>
                  <p>
                    <span>Size : </span>
                    {item.priceTier.size}
                  </p>
                  <p>
                    <span>Color : </span>
                    <span
                      style={{
                        cursor: "pointer",
                        background: item.priceTier.color,
                      }}
                      className="product-color "
                    ></span>
                  </p>
                  <p>
                    <span>Quantity : </span>
                    {item.quantity}
                  </p>
                  <div className="flex final-step-price">
                    <p class="product-card-usd">${item.priceTier.price}</p>
                    <p class="product-card-ethereum">
                      <img src="./images/eth-icon.png" alt="man-card" />
                      {(item.priceTier.price / oneEth).toFixed(5)}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="cart-add-dtl">
          <h2>{selectedAddress?.addressType} Address</h2>
          <h3>{selectedAddress?.fullName}</h3>
          <p>
            <span>Contact Number :</span> {selectedAddress?.phone}
          </p>
          <p>
            <span>Address :</span> {selectedAddress?.address},{" "}
            {selectedAddress?.city} - {selectedAddress?.pincode},{" "}
            {selectedAddress?.state?.name},{" "}
            {selectedAddress?.country?.countryName}
          </p>
        </div>
        <OrderSummary
          data={cart}
          totalCartAmount={totalCartAmount}
          selectedAddress={selectedAddress}
          previousStep={previousStep}
          couponCode={couponCode}
          setCouponCode={setCouponCode}
          applyCoupon={applyCoupon}
          couponValue={couponValue}
          couponType={couponType}
          shippingCharge={shippingCharge}
          removeCoupon={removeCoupon}
          checkout={checkout}
          btnloading={btnloading}
          removeLoading={removeLoading}
          oneEth={oneEth}
        />
      </div>
    </div>
  );
}

export default Checkout;
