import React, { useEffect, useState } from "react";
import { Col, Row, message, Table } from "antd";
import { MdOutlineCancel } from 'react-icons/md';
import viewIcon from "../../../../images/view-icon.png";
import axios from "axios";
import "./viewUser.scss";
import {
	get_admin_order_list_user,
  get_single_user,
  get_single_user_details,
} from "../../../../../api/Api-list";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewUserByAdminInitiate } from "../../../../../redux/actions/adminUsersActions";
function ViewUser() {
  const [data, setData] = useState(null);
  const [orderList, setOrderList] = useState([]);
  // const [loader, setLoader] = useState(true);

  const [userDetails, setUserDetails] = useState();

  const SECTION_NAMES = {
    DETAILS: "details",
    SETTINGS: "settings",
	ORDER: "order"
  };
  const [selectedSection, setSelectedSection] = useState(SECTION_NAMES.DETAILS);

  const { id } = useParams();
  const Admin_Token = localStorage.getItem("tiger");
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.usersDataByAdmin.users);

  const config = {
    headers: {
      Authorization: `Bearer ${Admin_Token}`,
    },
  };

  const singleUserDetail = async () => {
    dispatch(viewUserByAdminInitiate(id, Admin_Token));
  };
  // useEffect(() => {
  //   singleUserDetail();
  //   setData(selector);
  // }, [selector.firstName]);

  useEffect(() => {
    getUserDetails();
    getorderlist();

  }, [id]);

  const getUserDetails = async () => {
    try {
      const response = await axios.get(get_single_user_details(id), config);
      if (response.status < 400) {
        setUserDetails(response.data.userDetails);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const getorderlist = async () => {
    try {
      const response = await axios.get(get_admin_order_list_user(id), config);
      if (response.status < 400) {
        setOrderList(response?.data?.orders);
      }
    } catch (error) {
      console.log(error);
    }
  };
  


  // Table Structure
  const columns = [
    {
      title: "Order Id",
      dataIndex: "orderNumber",
      key: "orderNumber",
      render: (item) => {
        return <>{item}</>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (item, data) => {
        return <>{data?.address?.fullName}</>;
      },
    },
    {
      title: "Items",
      dataIndex: "totalItems",
      key: "totalItems",
      render: (item, data) => {
        return <>Total items ({data?.items?.map(item => item.quantity)?.reduce((a,b) => a+b, 0)})</>;

      },
    },

    {
      title: "Discount",
      dataIndex: "couponDiscountAmount",
      key: "couponDiscountAmount",
      render: (item,data) => {
        return <>{item ? `${item?.toFixed(2)}${data?.coupon?.type == 'percent' ? "%" : ""}` : 'NA'}</>;

      },
    },
    {
      title: "Price($)",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (item) => {
        return <>${item}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (item, i) => {
        return (
          <p
            className={`status-${i?.orderStatus
              ?.replace(/\s+/g, "-")
              ?.toLowerCase()}`}
          >
            {item}
          </p>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (item, data) => {
        return (
          <div className="action-icon-clm">
            <ul className="d-flex align-items-center">
              <li>
			  <Link to={`/admin/order/${data?._id}`}>
                  <img
                    src={viewIcon}
                    alt="View"
                    style={{ height: "15px" }}
                    title="View Details"
                  />
                </Link>
              </li>
              <li>
                <Link to={`#`}>
                  <MdOutlineCancel className="cancel-icon" title="Cancel" />
                </Link>
              </li>
              {/* <li>
								<Link to={`#`}>
									<TbTruckDelivery
									className="ship-icon"
									title="Ship" />
								</Link>
							</li>
							<li>
								<Link to={`#`}>
									<AiOutlineDeliveredProcedure
									className="deliver-icon"
									title="Delivered" />
								</Link>
							</li> */}
            </ul>
          </div>
        );
      },
    },
  ];

	// const dataSource = [
	// 	{
	// 		key: '1',
	// 		orderid: 'RTGC-01234567890',
	// 		name: 'Total Items (4)',
	// 		email: 'ram@thoughtmedia.com',
	// 		priceusd: '$ 6',
	// 		priceeth: '0.04',
	// 		status: 'Pending',
			
	// 	},
	// 	{
	// 		key: '2',
	// 		orderid: 'RTGC-01234567890',
	// 		name: 'Total Items (8)',
	// 		email: 'neha@thoughtmedia.com',
	// 		priceusd: '$ 6',
	// 		priceeth: '0.04',
	// 		status: 'Confirmed',
	// 	},
	// 	{
	// 		key: '3',
	// 		orderid: 'RTGC-01234567890',
	// 		name: 'Total Items (3)',
	// 		email: 'honey@gmail.com',
	// 		priceusd: '$ 6',
	// 		priceeth: '0.04',
	// 		status: 'Shipped',
	// 	},
	// 	{
	// 		key: '4',
	// 		orderid: 'RTGC-01234567890',
	// 		name: 'Total Items (2)',
	// 		email: 'nancy@thoughtmedia.com',
	// 		priceusd: '$ 6',
	// 		priceeth: '0.04',
	// 		status: 'Delivered',
	// 	},
	// 	{
	// 		key: '5',
	// 		orderid: 'RTGC-01234567890',
	// 		name: 'Total Items (5)',
	// 		email: 'tws.prabhjot@gmail.com',
	// 		priceusd: '$ 6',
	// 		priceeth: '0.04',
	// 		status: 'Payment Failed',
	// 	},
	// 	{
	// 		key: '6',
	// 		orderid: 'RTGC-01234567890',
	// 		name: 'Total Items (7)',
	// 		email: 'tws.prabhjot@gmail.com',
	// 		priceusd: '$ 6',
	// 		priceeth: '0.04',
	// 		status: 'Canceled By User',
	// 	},
	// 	{
	// 		key: '7',
	// 		orderid: 'RTGC-01234567890',
	// 		name: 'Total Items (6)',
	// 		email: 'tws.prabhjot@gmail.com',
	// 		priceusd: '$ 6',
	// 		priceeth: '0.04',
	// 		status: 'Reject By Admin',
	// 	},
	// 	{
	// 		key: '8',
	// 		orderid: 'RTGC-01234567890',
	// 		name: 'Total Items (9)',
	// 		email: 'tws.prabhjot@gmail.com',
	// 		priceusd: '$ 6',
	// 		priceeth: '0.04',
	// 		status: 'Return Request',
	// 	},
	// 	{
	// 		key: '9',
	// 		orderid: 'RTGC-01234567890',
	// 		name: 'Total Items (1)',
	// 		email: 'tws.prabhjot@gmail.com',
	// 		priceusd: '$ 6',
	// 		priceeth: '0.04',
	// 		status: 'Return Request Accept',
	// 	},
	// 	{
	// 		key: '10',
	// 		orderid: 'RTGC-01234567890',
	// 		name: 'Total Items (6)',
	// 		email: 'tws.prabhjot@gmail.com',
	// 		priceusd: '$ 6',
	// 		priceeth: '0.04',
	// 		status: 'Return Request Pending',
	// 	},
	// 	{
	// 		key: '11',
	// 		orderid: 'RTGC-01234567890',
	// 		name: 'Total Items (10)',
	// 		email: 'tws.prabhjot@gmail.com',
	// 		priceusd: '$ 6',
	// 		priceeth: '0.04',
	// 		status: 'Return Request Processing',
	// 	},
	// 	{
	// 		key: '12',
	// 		orderid: 'RTGC-01234567890',
	// 		name: 'Total Items (17)',
	// 		email: 'tws.prabhjot@gmail.com',
	// 		priceusd: '$ 6',
	// 		priceeth: '0.04',
	// 		status: 'Return Request Complete',
	// 	},
	// ];

  return (
    <div className="text-black">
      <div className="flex items-center justify-between w-full">
        <h3 className="m-0">User Details</h3>
      </div>
      <div className="tab-menu">
        <button
          className={selectedSection === SECTION_NAMES.DETAILS && "active"}
          onClick={() => {
            setSelectedSection(SECTION_NAMES.DETAILS);
          }}
        >
          User Details & Mailing Address
        </button>
        <button
          className={selectedSection === SECTION_NAMES.SETTINGS && "active"}
          onClick={() => {
            setSelectedSection(SECTION_NAMES.SETTINGS);
          }}
        >
          User Settings
        </button>

		<button
          className={selectedSection === SECTION_NAMES.ORDER && "active"}
          onClick={() => {
            setSelectedSection(SECTION_NAMES.ORDER);
          }}
        >
          Order
        </button>
      </div>
      <div>
        {selectedSection === SECTION_NAMES.DETAILS ? (
          <div className="view-user-tab-body-cntnt">
            <h4>Details</h4>
            <div className="user-details-tab-box">
              <div className="mt-0 view-user-cntnt-row">
                <h5>Full Name :</h5>{" "}
                <p>
                  {userDetails?.firstName} {userDetails?.lastName}
                </p>
              </div>
              <div className="view-user-cntnt-row">
                <h5>Email Address :</h5> <p>{userDetails?.email}</p>
              </div>
              <div className="view-user-cntnt-row">
                <h5>Status :</h5>{" "}
                <p>{userDetails?.isActive ? "Active" : "Inactive"}</p>
              </div>
            </div>
            <h4>Mailing Address</h4>
            <div>
              {userDetails?.address?.map((address) => {
                return (
                  <div className="mail-adrs-tab-box">
                    <div>
                      <p className="dflt-adrs">
                        {address.isDefault && "Default Address"}
                      </p>
                      <div className="mt-0 view-user-cntnt-row">
                        <h5>Full Name :</h5> <p>{address.fullName}</p>
                      </div>
                      <div className="view-user-cntnt-row">
                        <h5>Address :</h5>{" "}
                        <p>
                          {address.address}, {address.city},{" "}
                          {address.state.name}, {address.country.countryName}
                        </p>
                      </div>
                      <div className="view-user-cntnt-row">
                        <h5>Landmark :</h5> <p>{address.landmark}</p>
                      </div>
                      <div className="view-user-cntnt-row">
                        <h5>Pincode :</h5> <p>{address.pincode}</p>
                      </div>
                      <div className="view-user-cntnt-row">
                        <h5>Address Type :</h5> <p>{address.addressType}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

          </div>
        ) : selectedSection === SECTION_NAMES.SETTINGS ? (
          <div className="view-user-tab-body-cntnt">
            <div className="mail-adrs-tab-box">
              <div className="mt-0 view-user-cntnt-row">
                <h5>Email notification :</h5>
                <p>
                  {userDetails.userSettings.notification.isEmailNotificationOn
                    ? "ON"
                    : "OFF"}
                </p>
              </div>
              <div className="view-user-cntnt-row">
                <h5>News & offers notification :</h5>
                <p>
                  {userDetails.userSettings.notification
                    .isNewsAndOffersNotificationOn
                    ? "ON"
                    : "OFF"}
                </p>
              </div>
            </div>
          </div>
        ) : null}


		{selectedSection === SECTION_NAMES.ORDER && (
						<div className="order-list-content">
						{/* <h4>Order Details</h4> */}
						<Table 
						  dataSource={orderList || []}
						  columns={columns}
						  pagination={{}}
						/>;
					  </div>
		)} 
      </div>
    </div>
  );
}

export default ViewUser;
