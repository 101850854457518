import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import StepWizard from "react-step-wizard";
import "./shoppingCart.scss";
import CartItemsList from "./steps/CartItemsList";
import AddressSelection from "./steps/AddressSelection";
import Checkout from "./steps/Checkout";
import ShippingService from "./steps/ShippingService";

function ShoppingCart() {
  const cartStep = useSelector((state) => state.cartSection.cartStep);
  const isAuthenticated = useSelector(
    (state) => state.userProfile.isAuthenticated
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [cartStep]);

  return (
    <div className="shopping_cart" style={{ paddingTop: 150 }}>
      <Container>
        <Row>
          <Col>
            <div className="text-center shopping_cart_content">
              <h2 className="text-[40px] font-bold text-white mb-1 text-center mt-4 ">
                Your Shopping Cart
              </h2>
              {/* <p className="my-2 text-[20px] text-[#a6a6a6] ">
                {cart.length} items are available for purchase
              </p> */}
              <img
                src="images/dot_border.png"
                alt="borderimage"
                className="text-center d-inline"
              />
            </div>
          </Col>
        </Row>
        {/* {isAuthenticated ? ( */}
        <>
          <div className="cart-step-menu">
            <ul className="cart-step-list">
              <li className={cartStep === 1 ? "active" : null}>
                <span className="step-number">1</span>
                <p>Cart Items</p>
              </li>
              <li className={cartStep === 2 ? "active" : null}>
                <span className="step-number">2</span>
                <p>Select Delivery Address</p>
              </li>
              <li className={cartStep === 3 ? "active" : null}>
                <span className="step-number">3</span>
                <p>Select Shipping Service</p>
              </li>
              <li className={cartStep === 4 ? "active" : null}>
                <span className="step-number">4</span>
                <p>Checkout</p>
              </li>
            </ul>
          </div>
          <StepWizard>
            <CartItemsList />
            <AddressSelection />
            <ShippingService />
            <Checkout />
          </StepWizard>
        </>
        {/* ) : (
          <p className="w-full text-center text-gray-500 text-xl font-semibold mt-20">
            No items in cart. Please login and visit the shop to add items to
            your cart.
          </p>
        )} */}
      </Container>
    </div>
  );
}

export default ShoppingCart;
