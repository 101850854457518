import React from "react";
import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import { TbTruckDelivery } from "react-icons/tb";
import { MdOutlineCancel } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, DatePicker, Form, Input, Select, Space, Tag } from "antd";
import "./adminOrderDetails.scss";
import { useEffect } from "react";
import {
  change_menu_status,
  change_order_status,
  get_admin_order_details,
} from "../../../../api/Api-list";
import { useState } from "react";
import uuid from "react-uuid";

import axios from "axios";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { Container } from "react-bootstrap";
const { Option } = Select;

export default function Adminorder() {
  const [order, setOrder] = useState(null);
  const Admin_Token = localStorage.getItem("tiger");
  const { id } = useParams();
  const [form] = Form.useForm();
  const [isShipped, setShipped] = useState(false);
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const [liststatus, setListStatus] = useState([
    "Pending",
    "Payment Failed",
    "Confirmed",
    "Cancelled By User",
    "Reject By Admin",
    "Shipped",
    "Delivered",
    "Return Request",
    "Return Request Accept",
    "Return Request Reject",
    "Return request Pending",
    "Return Request Processing",
    "Return Request Complete",
  ]);

  const config = {
    headers: {
      Authorization: `Bearer ${Admin_Token}`,
    },
  };

  const getorderlist = async () => {
    try {
      const response = await axios.get(get_admin_order_details(id), config);
      if (response.status < 400) {
        console.log(response?.data, "daaa2");
        setOrder(response?.data?.order);
        checkOrderStatus(response?.data?.order?.orderStatus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getorderlist();
  }, []);

  const checkOrderStatus = (sta) => {
    if (sta === "Confirmed") {
      let filtereddata = liststatus?.filter(
        (ele) => ele == "Shipped" || ele == "Reject By Admin"
      );
      setListStatus(filtereddata);

      return;
    }

    if (sta === "Shipped") {
      setShipped(true);
      let filtereddata = liststatus?.filter((ele) => ele == "Delivered");
      setListStatus(filtereddata);

      return;
    }

    if (sta === "Return Request") {
      let filtereddata = liststatus?.filter(
        (ele) =>
          ele == "Return Request Accept" || ele == "Return Request Reject"
      );
      setListStatus(filtereddata);

      return;
    }
  };

  const submitOrder = async (e) => {
    // e.preventDefault();

    form
      .validateFields()
      .then(async (e) => {
        const values = await form.getFieldsValue();

        let data = {
          shippingId: values?.shippingId,
          deliveryDate: moment(values?.deliveryDate)?.format("YYYY-MM-DD"),
        };
        console.log(values, "formdata");
        try {
          const response = await axios.patch(
            change_order_status(id, values?.status),
            data,
            config
          );
          if (response.status < 400) {
            addToast("Status Updated successfully", {
              appearance: "success",
              autoDismiss: true,
              position: "top-center",
            });
            navigate("/admin/order-list");
          }
        } catch (error) {
          console.log(error);
          addToast(error?.response?.data?.message, {
            appearance: "error",
            autoDismiss: true,
            position: "top-center",
          });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const reasonReturn = (reason) => {
    if (
      reason == "Return Request" ||
      reason == "Return Request Accept" ||
      reason == "Return Request Reject" ||
      reason == "Return request Pending" ||
      reason == "Return Request Processing" ||
      reason == "Return Request Complete"
    ) {
      return (
        <>
          <Card
            title="Return Reason"
            style={{
              width: "100%",
            }}
          >
            <p>{order?.returnReason}</p>
          </Card>
          <br />
        </>
      )
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <h2>Order Details</h2>
        <div className="admin-ordr-details-action">
          {order?.orderStatus == "Cancelled By User" ||
            order?.orderStatus == "Reject By Admin" ||
            order?.orderStatus == "Return Request Accept" ||
            order?.orderStatus == "Return Request Reject" ||
            order?.orderStatus == "Pending" ||
            order?.orderStatus == "Delivered" ? null : (
            <Form
              className="flex align-items-center"
              layout="vertical"
              form={form}
              onFinish={submitOrder}
            >
              <Form.Item
                name="status"
                type="select"
                initialValue={order?.orderStatus}
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  placeholder="Choose Status"
                  onChange={(values) => {
                    if (values == "Shipped") {
                      setShipped(true);
                      return;
                    } else {
                      setShipped(false);
                    }
                  }}
                >
                  {liststatus?.map((list) => (
                    <Option value={list}>{list}</Option>
                  ))}
                </Select>
              </Form.Item>

              {isShipped ? (
                <>
                  <Form.Item
                    name="shippingId"
                    type="text"
                    initialValue={order?.shippingId}
                    disabled
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input
                      name="shippingId"
                      placeholder="Enter Tracking ID"
                      disabled={order?.shippingId ? true : false}
                    />
                  </Form.Item>

                  <Form.Item
                    name="deliveryDate"
                    type="date"
                    initialValue={
                      order?.deliveryDate ? moment(order?.deliveryDate) : ""
                    }
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <DatePicker
                      disabled={order?.deliveryDate ? true : false}
                      placeholder="Enter Expected Date"
                      name="deliveryDate"
                      className="form-control"
                      format="YYYY-MM-DD"
                      disabledDate={(current) =>
                        current.isBefore(moment().add(0, "day"))
                      }
                    />
                  </Form.Item>
                </>
              ) : (
                ""
              )}

              <button className="add-btn status-apply-btn" type="submit">
                Apply
              </button>
            </Form>
          )}
          {/* <ul className="d-flex align-items-center">
					<li>
						<Link to={`#`}>
							<MdOutlineCancel
							className="cancel-icon"
							title="Cancel" />
						</Link>
					</li>
					<li>
						<Link to={`#`}>
							<TbTruckDelivery
							className="ship-icon"
							title="Ship" />
						</Link>
					</li>
					<li>
						<Link to={`#`}>
							<AiOutlineDeliveredProcedure
							className="deliver-icon"
							title="Delivered" />
						</Link>
					</li>
				</ul> */}
        </div>
      </div>

      <div className="admin-ordr-details-body-content">
        {/* Product Status Info Section */}
        <div className="admin-product-status-row">
          <ul className="flex justify-between">
            <li className="order-id-clm">
              <p>
                <strong>Order ID :</strong> <span>{order?.orderNumber}</span>
              </p>
            </li>
            <li className="product-name-clm">
              <p>
                <strong>Product Name :</strong>{" "}
                {order?.items?.map((item) => (
                  <Tag>{item.productName}</Tag>
                ))}
              </p>
            </li>
            <li className="order-status-clm">
              <p>
                <strong>Order Status :</strong>{" "}
                <span className="status-shipped">{order?.orderStatus}</span>
              </p>
            </li>
          </ul>
        </div>

        {reasonReturn(order?.orderStatus)}
        {/* Product Delivery Info Section */}
        <div className="admin-product-delivery-info">
          <ul className="flex justify-between admin-delivery-row">
            <li>
              <h2>Delivery Address</h2>
              <h3>{order?.address?.fullName}</h3>
              <p>
                {order?.address?.isDefault ? order?.address?.address : ""}{" "}
                {order?.address?.isDefault ? order?.address?.landmark : ""},{" "}
                {order?.address?.isDefault ? order?.address?.city : ""},{" "}
                {order?.address?.isDefault ? order?.address?.state?.name : ""},{" "}
                {order?.address?.isDefault
                  ? order?.address?.country?.countryName
                  : ""}
              </p>
              <p>
                Pincode -{" "}
                {order?.address?.isDefault ? order?.address?.pincode : ""}
              </p>
              <h3>Phone number</h3>
              <p>{order?.address?.isDefault ? order?.address?.phone : ""}</p>
              {/* <h3>Email</h3>
              <p>surajit.naskar@ogmaconceptions.com</p> */}
            </li>
            <li className="ordr-dtl-total-itm">
              <h2>
                Total Items (
                {order?.items
                  ?.map((item) => item.quantity)
                  ?.reduce((a, b) => a + b, 0)}
                )
              </h2>
            </li>
            <li>
              <h2>Payment Summary</h2>
              <table className="admin-shipping-payment-summary">
                <tbody>
                  <tr>
                    <td>Total</td>
                    <td>${order?.totalAmount?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Tax</td>
                    <td>${order?.taxAmount?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Shipping</td>
                    <td>${order?.shippingAmount?.toFixed(2)}</td>
                  </tr>

                  <tr>
                    <td>Coupon</td>
                    <td>
                      {order?.coupon
                        ? `$${order?.couponDiscountAmount?.toFixed(2)}`
                        : "NA"}
                    </td>
                  </tr>

                  <tr>
                    <td>Sub Total</td>
                    <td>${order?.subTotalAmount?.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </li>
          </ul>
        </div>
        {/* Product Shiping Info Section */}
        <div className="product-shiping-info mb-10">
          <Container>
            <div className="flex justify-between admin-product-shiping-row">
              {order?.orderStatus === "Confirmed" ||
                order?.orderStatus === "Shipped" ||
                order?.orderStatus === "Delivered" ? (
                <div className="product-shiping-delivery-clm">
                  <ul className="flex">
                    <li
                      className={
                        order?.orderStatus === "Confirmed" ||
                          order?.orderStatus === "Shipped" ||
                          order?.orderStatus === "Delivered"
                          ? "active"
                          : null
                      }
                    >
                      <h3>Order Confirmed</h3>
                      <span className="dot"></span>
                    </li>
                    <li
                      className={
                        order?.orderStatus === "Shipped" ||
                          order?.orderStatus === "Delivered"
                          ? "active"
                          : null
                      }
                    >
                      <h3>Shipped</h3>
                      <span className="dot"></span>
                    </li>
                    <li
                      className={
                        order?.orderStatus === "Delivered"
                          ? "active"
                          : null
                      }
                    >
                      <h3>Delivered</h3>
                      <span className="dot"></span>
                    </li>
                  </ul>
                </div>
              ) : order?.orderStatus === "Return Request" ||
                order?.orderStatus === "Return Request Accept" ||
                order?.orderStatus === "Return Request Reject" ||
                order?.orderStatus === "Return Request Complete" ? (
                <div
                  className={
                    order?.orderStatus === "Return Request Reject"
                      ? "product-shiping-return-reject-clm"
                      : "product-shiping-return-accept-clm"
                  }
                >
                  <ul className="flex">
                    <li
                      className={
                        order?.orderStatus === "Return Request" ||
                          order?.orderStatus === "Return Request Accept" ||
                          order?.orderStatus === "Return Request Reject" ||
                          order?.orderStatus === "Return Request Complete"
                          ? "active"
                          : null
                      }
                    >
                      <h3>Return Requested</h3>
                      <span className="dot"></span>
                    </li>
                    {/* <li
                    className={
                      order?.orderStatus === "Return Request Accept" ||
                      order?.orderStatus === "Return Request Reject" ||
                      order?.orderStatus === "Return Request Complete"
                        ? "active"
                        : null
                    }
                  >
                    <h3>
                      {order?.orderStatus === "Return Request Accept"
                        ? "Accepted"
                        : order?.orderStatus === "Return Request Reject"
                        ? "Rejected"
                        : "Pending"}
                    </h3>
                    <span className="dot"></span>
                  </li> */}
                    <li
                      className={
                        order?.orderStatus === "Return Request Accept" ||
                          order?.orderStatus === "Return Request Reject" ||
                          order?.orderStatus === "Return Request Complete"
                          ? "active"
                          : null
                      }
                    >
                      <h3>
                        {order?.orderStatus === "Return Request Complete"
                          ? "Return Completed"
                          : order?.orderStatus ===
                            "Return Request Reject"
                            ? "Return Request Rejected"
                            : order?.orderStatus ===
                              "Return Request Accept"
                              ? "Return Request Accepted"
                              : "Return Request Pending"}
                      </h3>
                      <span className="dot"></span>
                    </li>
                  </ul>
                </div>
              ) : null}
              <div className="admin-product-order-id-clm">
                <p>
                  <span>Order Id :</span> {order?.orderNumber}
                </p>

                {order?.shippingId &&
                  order?.deliveryDate &&
                  (order?.orderStatus === "Shipped" ||
                    order?.orderStatus === "Delivered") && (
                    <>
                      <p>
                        <span>Shipping ID :</span> {order?.shippingId}
                      </p>

                      <p>
                        <span>Delivery Date :</span>{" "}
                        {moment(order?.deliveryDate)?.format(
                          "YYYY-MM-DD"
                        )}
                      </p>
                    </>
                  )}
              </div>
              <div className="admin-product-shiping-product-clm">
                {(order?.orderStatus === "Return Request" ||
                  order?.orderStatus === "Return Request Accept" ||
                  order?.orderStatus === "Return Request Reject" ||
                  order?.orderStatus === "Return Request Complete") &&
                  order?.items.filter(
                    (item) => item.orderStatus === "Delivered"
                  ).length > 0
                  ? order?.items
                    ?.filter((item) => item.orderStatus !== "Delivered")
                    ?.map((item) => {
                      return (
                        <div className="flex admin-shiping-product-dtl">
                          <div className="admin-product-shiping-product-img">
                            <img
                              src={
                                item?.image?.[0]?.image || "/images/noimg.png"
                              }
                              alt="product-img"
                              className="rounded-xl"
                            />
                          </div>
                          <div className="admin-product-shiping-product-info-text">
                            <h2>{item.productName}</h2>
                            <ul>
                              <li>
                                Size : <span>{item.priceTier.size}</span>
                              </li>
                              <li>
                                Quantity : <span>{item.quantity}</span>
                              </li>
                              <li>
                                Color :{" "}
                                <span
                                  className="w-4 h-4 rounded-full color"
                                  style={{
                                    backgroundColor: item.priceTier.color,
                                  }}
                                >
                                  {item.priceTier.color}
                                </span>
                              </li>
                            </ul>
                            <div class="flex items-center mt-2">
                              <span class="product-card-usd">
                              {order?.currency === 'usd' ? `$${order?.subTotalAmount}` : <div className="flex"><img src='/images/eth-icon.png' style={{ height: '20px', marginRight: '3px' }} /> 
                                {`${order?.amountInETH?.toFixed(5)}`}
                                </div>}
                                {/* ${item.priceTier.price.toFixed(2)} */}
                              </span>
                              <span class="product-card-ethereum">
                                {/* {item.priceTier.ethPrice.toFixed(2)}
                                <img
                                  src="/images/eth-icon.png"
                                  alt="ethicons"
                                /> */}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : order?.items?.map((item) => {
                    return (
                      <div className="flex admin-shiping-product-dtl">
                        <div className="admin-product-shiping-product-img">
                          <img
                            src={
                              item?.image?.[0]?.image || "/images/noimg.png"
                            }
                            alt="product-img"
                            className="rounded-xl"
                          />
                        </div>
                        <div className="admin-product-shiping-product-info-text">
                          <h2>{item.productName}</h2>
                          <ul>
                            <li>
                              Size : <span>{item.priceTier.size}</span>
                            </li>
                            <li>
                              Quantity : <span>{item.quantity}</span>
                            </li>
                            <li>
                              Color :{" "}
                              <span
                                className="w-4 h-4 rounded-full color"
                                style={{
                                  backgroundColor: item.priceTier.color,
                                }}
                              >
                                {item.priceTier.color}
                              </span>
                            </li>
                          </ul>
                          <div class="flex items-center mt-2">
                            <span class="product-card-usd">
                              {/* ${item.priceTier.price.toFixed(2)} */}
                              {order?.currency === 'usd' ? `$${order?.subTotalAmount}` : <div className="flex"><img src='/images/eth-icon.png' style={{ height: '20px', marginRight: '3px' }} /> 
                                {`${order?.amountInETH?.toFixed(5)}`}
                                </div>}
                            </span>
                            <span class="product-card-ethereum">
                              {/* {item.priceTier.ethPrice.toFixed(2)}
                              <img
                                src="/images/eth-icon.png"
                                alt="ethicons"
                              /> */}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Container>
        </div>

        {/* bottom card for delivery section */}
        {(order?.orderStatus === "Return Request" ||
          order?.orderStatus === "Return Request Accept" ||
          order?.orderStatus === "Return Request Reject" ||
          order?.orderStatus === "Return Request Complete") &&
          order?.items.filter((item) => item.orderStatus === "Delivered")
            .length > 0 ? (
          <div className="product-shiping-info mb-10">
            <Container>
              <div className="flex justify-between admin-product-shiping-row">
                <div className="product-shiping-delivery-clm">
                  <ul className="flex">
                    <li className="active">
                      <h3>Order Confirmed</h3>
                      <span className="dot"></span>
                    </li>
                    <li className="active">
                      <h3>Shipped</h3>
                      <span className="dot"></span>
                    </li>
                    <li className="active">
                      <h3>Delivered</h3>
                      <span className="dot"></span>
                    </li>
                  </ul>
                </div>

                <div className="admin-product-order-id-clm">
                  <p>
                    <span>Order ID :</span> {order?.orderNumber}
                  </p>

                  {order?.shippingId &&
                    order?.deliveryDate &&
                    (order?.orderStatus === "Shipped" ||
                      order?.orderStatus === "Delivered") && (
                      <>
                        <p>
                          <span>Shipping ID :</span> {order?.shippingId}
                        </p>

                        <p>
                          <span>Delivery Date :</span>{" "}
                          {moment(order?.deliveryDate)?.format(
                            "YYYY-MM-DD"
                          )}
                        </p>
                      </>
                    )}
                </div>
                <div className="admin-product-shiping-product-clm">
                  {order?.items
                    ?.filter((item) => item.orderStatus === "Delivered")
                    ?.map((item) => {
                      return (
                        <div className="flex admin-shiping-product-dtl">
                          <div className="admin-product-shiping-product-img">
                            <img
                              src={
                                item?.image?.[0]?.image || "/images/noimg.png"
                              }
                              alt="product-img"
                              className="rounded-xl"
                            />
                          </div>
                          <div className="admin-product-shiping-product-info-text">
                            <h2>{item.productName}</h2>
                            <ul>
                              <li>
                                Size : <span>{item.priceTier.size}</span>
                              </li>
                              <li>
                                Quantity : <span>{item.quantity}</span>
                              </li>
                              <li>
                                Color :{" "}
                                <span
                                  className="w-4 h-4 rounded-full color"
                                  style={{
                                    backgroundColor: item.priceTier.color,
                                  }}
                                >
                                  {item.priceTier.color}
                                </span>
                              </li>
                            </ul>
                            <div class="flex items-center mt-2">
                              <span class="product-card-usd">
                                {/* ${item.priceTier.price.toFixed(2)} */}
                                {order?.currency === 'usd' ? `$${order?.subTotalAmount}` : <div className="flex"><img src='/images/eth-icon.png' style={{ height: '20px', marginRight: '3px' }} /> 
                                {`${order?.amountInETH?.toFixed(5)}`}
                                </div>}
                              </span>
                              <span class="product-card-ethereum">
                                {/* {item.priceTier.ethPrice.toFixed(2)}
                                <img
                                  src="/images/eth-icon.png"
                                  alt="ethicons"
                                /> */}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </Container>
          </div>
        ) : null}

      </div>
    </>
  );
}
