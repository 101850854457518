import React from "react";
import { Input, Spin } from "antd";
import styled from "styled-components";
import "./orderSummary.scss";
import * as actionTypes from "../../../../redux/types/cartSectionType";
import { useDispatch } from "react-redux";

function OrderSummary(props) {
  const {
    data,
    totalCartAmount,
    selectedAddress,
    couponCode,
    setCouponCode,
    applyCoupon,
    couponValue,
    couponType,
    shippingCharge,
    removeCoupon,
    checkout,
    btnloading,
    removeLoading,
    oneEth,
  } = props;

  const dispatch = useDispatch();

  return (
    <Wrapper className="max-w-[29.375rem] mx-auto w-full  text-white order_summary">
      <div className="w-full flex flex-col bg-[#191919] bg-opacity-75 text-white rounded-xl p-4">
        <div className="text-white font-bold text-[20px] ">Order Summary</div>
        <span className="text-[15px] text-gray-500">
          {data.length} {data.length > 1 ? "Products" : "Product"}
        </span>

        <div className=" product_detail">
          <hr className="border-b-black border-white" />
          <div className="flex justify-between">
            <span>Product total</span>
            <span className="flex items-center justify-center">
              ${totalCartAmount.usd?.toFixed(2)} ({" "}
              <img
                src="/images/eth-icon.png"
                className="object-contain w-3"
                alt="ethicon"
              />
              {(totalCartAmount.usd / oneEth).toFixed(5)})
            </span>
          </div>
          <hr className="border-b-black border-white " />
          <div className="flex justify-between">
            <span>Tax({selectedAddress?.state?.totalTaxAmount}%)</span>
            <span>
              $
              {(
                totalCartAmount.usd *
                (selectedAddress?.state?.totalTaxAmount / 100)
              ).toFixed(2)}
            </span>
          </div>
          <hr className="border-b-black border-white" />
          <div className="flex justify-between">
            <span>Shipping charge</span>
            <span>${shippingCharge.toFixed(2)}</span>
          </div>
          <hr className="border-b-black border-white" />
          <div className="flex justify-between">
            <span>Sub total</span>
            <span>
              $
              {(
                shippingCharge +
                totalCartAmount.usd *
                  (selectedAddress?.state?.totalTaxAmount / 100) +
                totalCartAmount.usd
              ).toFixed(2)}
            </span>
          </div>
          <hr className="border-b-black border-white " />
          <div className="flex flex-col cupon-code-field">
            <div className="w-full flex mb-2 mt-1 items-center justify-between">
              <span>
                <span>Coupon Code</span>
                {couponType !== "" && (
                  <button
                    className="ml-3 text-red-500 text-sm"
                    onClick={removeCoupon}
                  >
                    - {removeLoading ? `Removing...` : "Remove"}
                  </button>
                )}
              </span>
              {couponType !== "" && (
                <span>
                  {couponType === "fixed"
                    ? "$"
                    : couponType === "percent"
                    ? ""
                    : ""}
                  {couponValue}
                  {couponType === "fixed"
                    ? ""
                    : couponType === "percent"
                    ? "%"
                    : ""}
                </span>
              )}
            </div>
            <Input
              type="text"
              className="w-full !text-white !rounded-lg bg-transparent
              !border-[2px] !border-[#6C6C6C] h-10 px-3"
              value={couponCode}
              onChange={(e) => {
                setCouponCode(e.target.value);
              }}
            />
            <button className="apply-btn" onClick={applyCoupon}>
              {btnloading ? `Applying..` : "Apply"}
            </button>
          </div>
          <hr className="border-b-black border-white" />
          <div className="flex justify-between sub_total_order">
            <span>Sub total</span>
            <span className="flex items-center justify-center">
              $
              {couponType === ""
                ? (
                    shippingCharge +
                    totalCartAmount.usd *
                      (selectedAddress?.state?.totalTaxAmount / 100) +
                    totalCartAmount.usd
                  ).toFixed(2)
                : couponType === "fixed"
                ? (
                    shippingCharge +
                    totalCartAmount.usd *
                      (selectedAddress?.state?.totalTaxAmount / 100) +
                    totalCartAmount.usd -
                    couponValue
                  ).toFixed(2)
                : couponType === "percent"
                ? (
                    shippingCharge +
                    totalCartAmount.usd *
                      (selectedAddress?.state?.totalTaxAmount / 100) +
                    totalCartAmount.usd -
                    ((totalCartAmount.usd *
                      (selectedAddress?.state?.totalTaxAmount / 100) +
                      totalCartAmount.usd) *
                      couponValue) /
                      100
                  ).toFixed(2)
                : ""}{" "}
              (
              <img
                src="/images/eth-icon.png"
                className="object-contain w-3"
                alt="ethicon"
              />
              {couponType === ""
                ? (
                    (shippingCharge +
                      totalCartAmount.usd *
                        (selectedAddress?.state?.totalTaxAmount / 100) +
                      totalCartAmount.usd) /
                    oneEth
                  ).toFixed(5)
                : couponType === "fixed"
                ? (
                    (shippingCharge +
                      totalCartAmount.usd *
                        (selectedAddress?.state?.totalTaxAmount / 100) +
                      totalCartAmount.usd -
                      couponValue) /
                    oneEth
                  ).toFixed(5)
                : couponType === "percent"
                ? (
                    (shippingCharge +
                      totalCartAmount.usd *
                        (selectedAddress?.state?.totalTaxAmount / 100) +
                      totalCartAmount.usd -
                      ((totalCartAmount.usd *
                        (selectedAddress?.state?.totalTaxAmount / 100) +
                        totalCartAmount.usd) *
                        couponValue) /
                        100) /
                    oneEth
                  ).toFixed(5)
                : ""}
              )
            </span>
          </div>
          <hr className="border-b-black border-white" />
          <div className="flex justify-center checkout_button_order mt-5">
            <button
              className="text-lg  transition-all ease-in duration-150 hover:bg-orange-600 font-bold text-center w-full bg-[#FF8500] text-white py-2 rounded-full mx-1"
              onClick={() => {
                props.previousStep();
                dispatch({
                  payload: 3,
                  type: actionTypes.CART_STEP,
                });
              }}
            >
              Previous
            </button>
            <button
              className="text-lg  transition-all ease-in duration-150 hover:bg-orange-600 font-bold text-center w-full bg-[#FF8500] text-white py-2 rounded-full mx-1"
              onClick={checkout}
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default OrderSummary;

const Wrapper = styled.div``;
