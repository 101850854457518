import React from "react";

import "./BillingOrder.scss";
import { FaRegQuestionCircle } from "react-icons/fa";

const BillingOrder = () => {
  return (
    <div>
      <div className="mx-0 grid grid-flow-col grid-rows-4 gap-4 xs:mx-0 sm:mx-4 md:mx-5 lg:mx-5">
        <div className="col-span-1  row-span-1 rounded-[10px] border-[2px] border-stone-800  bg-[#fdfdfd10]  px-5 text-left ">
          <h2 className="heading-pattern position-relative mt-[1.5rem] text-[1.2rem] text-white">
            Shipping Method
          </h2>

          <div className="mt-[2rem] flex items-center">
            <input
              type="radio"
              className="accent-orange-500 bg-tansparent  mr-2 border-transparent text-orange-500 shadow-transparent focus:border-transparent focus:shadow-transparent focus:ring-transparent"
              name="sameshiping"
            />
            <label className="text-left text-[14px] text-white">
              Delivery Charges - $5.00
            </label>
          </div>
        </div>
        <div className="col-span-1  row-span-3 justify-center rounded-[10px]  border-[2px] border-stone-800  bg-[#fdfdfd10]  px-5 py-6  text-left ">
          <h2 className="heading-pattern position-relative text-[1.2rem] text-white ">
            Payment
          </h2>

          <form className="mt-5">
            <div className="inline-flex items-center">
              <input
                type="radio"
                className="accent-orange-500 bg-tansparent  mr-2 border-transparent text-orange-500 shadow-transparent focus:border-transparent focus:shadow-transparent focus:ring-transparent"
                name="sameshiping"
              />
              <label className="text-left text-[14px] text-white">
                Credit Card
              </label>
            </div>
            <div className="mx-4 inline-flex items-center">
              <input
                type="radio"
                className="accent-orange-500 bg-tansparent  mr-2 border-transparent text-orange-500 shadow-transparent focus:border-transparent focus:shadow-transparent focus:ring-transparent"
                name="sameshiping"
              />
              <label className="text-left text-[14px] text-white">Paypal</label>
            </div>
            <div className="my-3 grid grid-cols-1 gap-1">
              <label className="text-left text-[14px] text-white">
                Card Name
              </label>
              <input
                type="text"
                className="pl-2 focus:outline-none border-[#6D6D6D]  h-[46px] rounded-[10px] border-[2px] bg-transparent text-left text-white focus:border-[2px] focus:border-[#6D6D6D] focus:outline-1 focus:ring-transparent"
              />
            </div>
            <div className="my-3 grid grid-cols-1 gap-1">
              <label className="text-left text-[14px] text-white">
                Card Holder Name
              </label>
              <input
                type="text"
                className="pl-2 focus:outline-none h-[46px]  rounded-[10px] border-[2px] border-[#6D6D6D] bg-transparent text-left text-white focus:border-[2px] focus:border-[#6D6D6D] focus:outline-1 focus:ring-transparent"
              />
            </div>
            <div className="my-3 grid grid-cols-2 gap-4">
              <div className="grid grid-cols-1 gap-1">
                <label className="text-left text-[14px] text-white">
                  Expiry Month
                </label>
                <select className="h-[46px]  rounded-[10px] border-[2px] border-transparent bg-[#191919] text-left text-white focus:border-[2px] focus:border-transparent focus:outline-1 focus:ring-transparent">
                  <option> Month</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </select>
              </div>
              <div className="grid grid-cols-1 gap-1">
                <label className="text-left text-[14px] text-white">
                  Expiry Year
                </label>
                <select className="h-[46px]  rounded-[10px] border-[2px] border-transparent bg-[#191919] text-left text-white focus:border-[2px] focus:border-transparent focus:outline-1 focus:ring-transparent">
                  <option> Year</option>
                  <option>2021</option>
                  <option>2022</option>
                  <option>2023</option>
                </select>
              </div>
            </div>
            <div className="my-3 grid grid-cols-1 gap-1">
              <label className="flex items-center justify-between text-left text-[14px] text-white">
                <span>CVC</span> <FaRegQuestionCircle className="text-[1rem]" />
              </label>
              <input
                type="text"
                className="focus:outline-none pl-2 h-[46px]  rounded-[10px] border-[2px] border-[#6D6D6D] bg-transparent text-left text-white focus:border-[2px] focus:border-[#6D6D6D] focus:outline-1 focus:ring-transparent"
              />
            </div>
            <div className="mb-[1.1rem] inline-flex items-center">
              <input
                type="checkbox"
                className="accent-orange-500 bg-tansparent mr-2 border-[2px] border-[#6D6D6D] bg-transparent text-orange-500  outline-2 focus:border-transparent focus:shadow-none focus:ring-transparent "
                name="sameshiping"
              />
              <label className="text-left text-[14px] text-white">
                Accept Terms and Condition
              </label>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="mx-auto  rounded-[2rem] bg-orange-500  text-[1.1rem] px-[4.6rem] py-[0.8rem] font-semibold"
              >
                Secure Checkout
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BillingOrder;
