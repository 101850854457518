import { Switch, Tag } from "antd";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  delete_address,
  get_address,
  get_single_address,
} from "../../../api/Api-list";
import "./MyAddress.scss";

export default function MyaddressList({
  addressData,
  deleteAddress,
  makeDefaultAddress,
}) {
  const app_token = localStorage?.getItem("tiger-token");
  const { addToast } = useToasts();
  const [showMenu, setShowMenu] = useState({});

  const toggleMenu = (id) => {
    setShowMenu((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <>
      <br />
      {addressData?.length
        ? addressData?.map((address, i) => (
            <>
              <div className="addDetails">
                {/* <h2>{address.fullName}</h2> */}
                <h2>{address?.fullName}</h2>
                <p>
                  <span></span>Contact Number : {address?.phone}
                </p>
                <p>
                  Address : {address?.address}, {address?.city} -{" "}
                  {address?.pincode}, {address?.state?.name},{" "}
                  {address?.country?.countryName}
                </p>

                <div className="abSect">
                  <Switch
                    checked={address?.isDefault}
                    onChange={() => makeDefaultAddress(address?._id)}
                  />
                  <div className="address-action-icon">
                    <img
                      src="images\ellipsis.png"
                      className="ellipsis"
                      alt="ellipsis"
                      onClick={() => toggleMenu(address?._id)}
                    />
                  </div>

                  {showMenu[address?._id] ? (
                    <div className="address-action-area show">
                      <Link
                        className="editBtn"
                        to={`/edit-address/${address?._id}`}
                      >
                        {" "}
                        <span>Edit</span>
                      </Link>
                      <a
                        className="delBtn"
                        onClick={() => deleteAddress(address?._id)}
                      >
                        Delete
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ))
        : ""}
    </>
  );
}
