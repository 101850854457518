import React, { useEffect } from "react";
import MintWhitelist from "../../components/mintpage components/mint whitelist/MintWhitelist";

const MintPage = () => {
  useEffect(() => {
    // This is temporary hack to load mint-sale page will remove later
    window.location.href = "/mint-sale";
  }, []);
  return (
    <div>
      <MintWhitelist />
    </div>
  );
};

export default MintPage;
