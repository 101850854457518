import Web3 from "web3";
import {
  CONFORMATION_MODAL,
  CONNECTED_WALLET_PROVIDER,
  LOAD_CONTRACT,
  LOAD_LOADER,
  LOAD_NFT_DETAILS,
  LOAD_WALLET_ADDRESS,
  SET_WALLET_PROVIDER,
  TOTAL_MINTED,
  WRONG_NETWORK
} from "../types/blockchainType";
import { METAMASK } from "../../constants/walletenums.js";
import erc721abi from "../../abis/erc721abi";

import axios from "axios";
import WalletConnectProvider from "@walletconnect/web3-provider";
const erc721Address = process.env.REACT_APP_ERC_721ADDRESS;
const { ethereum } = window;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

let web3;

export const connectToWalletConnect = async (dispatch, getState) => {
  dispatch({
    type: CONNECTED_WALLET_PROVIDER,
    payload: { connectedWalletProvider: "metamask" }
  });

  const provider = new WalletConnectProvider({
    rpc: {
      1: process.env.REACT_APP_RPC_URL
    }
  });
  try {
    await provider.disconnect();
  } catch (erro) {}

  web3 = new Web3(provider);

  // Check if user is already authenticated with WalletConnect
  if (provider.connected) {
    console.log("Connected to WalletConnect!");
  }

  // If not already authenticated, prompt user to connect
  provider
    .enable()
    .then(() => {
      dispatch({
        type: CONNECTED_WALLET_PROVIDER,
        payload: { connectedWalletProvider: "walletconnect" }
      });
      web3.eth.getAccounts().then(([from]) => {
        dispatch({
          type: LOAD_WALLET_ADDRESS,
          payload: { walletAddress: from, isConnected: true }
        });
      });
      loadContracts(dispatch, getState);
    })
    .catch((err) => {
      console.error(err);
    });
};

export const switchNetwork = async (dispatch, getState) => {
  await ethereum.enable();
  if (ethereum) {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${process.env.REACT_APP_CHAIN_ID}` }]
      });
      dispatch({
        type: WRONG_NETWORK,
        payload: { isWrongNetwork: false }
      });
      loadContracts(dispatch, getState);
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${process.env.REACT_APP_CHAIN_ID}`,
                chainName: process.env.REACT_APP_CHAIN_NAME,
                rpcUrls: [process.env.REACT_APP_RPC_URL],
                blockExplorerUrls: [process.env.REACT_APP_BLOCKURL],
                nativeCurrency: {
                  name: process.env.REACT_APP_CURRENCY_NAME,
                  symbol: process.env.REACT_APP_CURRENCY_SYMBOL, // 2-6 characters long
                  decimals: 18
                }
              }
            ]
          });
          loadContracts(dispatch, getState);
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  }
};

export const handleMetaMaskConnection = () => async (dispatch, getState) => {
  if (ethereum) {
    await ethereum.request({ method: "eth_requestAccounts" }).then(
      async (response) => {
        dispatch({
          type: SET_WALLET_PROVIDER,
          payload: { walletProvider: METAMASK }
        });
        debugger;
        dispatch({
          type: LOAD_WALLET_ADDRESS,
          payload: { walletAddress: response[0], isConnected: true }
        });
        debugger;
        await ethereum.enable();
        web3 = new Web3(ethereum);
        const chainId = await web3.eth.getChainId();
        console.log(chainId);
        if (Number(chainId) !== Number(process.env.REACT_APP_CHAIN_ID)) {
          dispatch({
            type: WRONG_NETWORK,
            payload: { isWrongNetwork: true }
          });
        } else {
          dispatch({
            type: CONNECTED_WALLET_PROVIDER,
            payload: { connectedWalletProvider: "metamask" }
          });
          loadContracts(dispatch, getState);
        }
      },
      (error) => {
        //Error Message
      }
    );
  } else {
    //Error Message to install metamask
    return false;
  }
};

const loadContracts = async (dispatch, getState) => {
  const erc721Methods = new web3.eth.Contract(erc721abi.erc721, erc721Address)
    .methods;
  dispatch({ type: LOAD_CONTRACT, payload: { erc721Methods } });
  await totalMinted(dispatch, getState);
  const { loadImage } = getState().blockChain;
  if (loadImage) await checkBalanceAndShowNFT(dispatch, getState);
};

export const mintToken = async (dispatch, getState) => {
  const {
    erc721Methods,
    walletAddress,
    amount,
    connectedWalletProvider,
    erc721Address
  } = getState().blockChain;

  const dispatchLoadLoader = (message) => {
    dispatch({
      type: LOAD_LOADER,
      payload: {
        loadLoader: true,
        message
      }
    });
  };

  try {
    let receipt;

    if (connectedWalletProvider === "walletconnect") {
      const mintingTx = erc721Methods.publicMint(amount);
      const encodedTx = mintingTx.encodeABI();

      dispatchLoadLoader("Please confirm on WalletConnect to mint");

      const tx = {
        from: walletAddress,
        to: process.env.REACT_APP_ERC_721ADDRESS,
        data: encodedTx
      };

      console.log(tx);

      await web3.eth
        .sendTransaction(tx)
        .on("transactionHash", (transactionHash) => {
          dispatch({
            type: CONFORMATION_MODAL,
            payload: {
              openConformationModal: true,
              transactionHash: transactionHash
            }
          });
        });
    } else {
      dispatchLoadLoader("Please confirm on MetaMask to mint");

      const mintingTx = erc721Methods.publicMint(amount);
      receipt = await mintingTx.send({ from: walletAddress });
    }

    console.log("NFT minted with value:", receipt);

    dispatch({
      type: LOAD_LOADER,
      payload: {
        loadLoader: false,
        message: ""
      }
    });

    dispatch({
      type: CONFORMATION_MODAL,
      payload: {
        openConformationModal: true,
        transactionHash: receipt.transactionHash
      }
    });
  } catch (error) {
    console.error(error);

    dispatch({
      type: LOAD_LOADER,
      payload: {
        loadLoader: false,
        message: ""
      }
    });
  }

  totalMinted(dispatch, getState);
};

export const totalMinted = async (dispatch, getState) => {
  const { erc721Methods } = getState().blockChain;
  const minted = await erc721Methods.totalSupply().call();
  dispatch({ type: TOTAL_MINTED, payload: { minted } });
};

const checkBalanceAndShowNFT = async (dispatch, getState) => {
  const { erc721Methods, walletAddress } = getState().blockChain;

  const balance = await erc721Methods.balanceOf(walletAddress).call();
  if (Number(balance) === 0) {
    dispatch({ type: LOAD_NFT_DETAILS, payload: { nftDetails: [] } });
  }
  let nftDetails = [];
  for (let x = 0; x < balance; x++) {
    const tokenId = await erc721Methods
      .tokenOfOwnerByIndex(walletAddress, x)
      .call();
    try {
      const {
        data: { image }
      } = await axios.get(
        `https://ipfs.io/ipfs/Qme6HhM7sWev9joEpGaEAqeo1V9P9rrwFS9bp1SJe4NstZ/${tokenId}`
      );
      console.log(image);
      nftDetails.push({ tokenId, image });
      console.log("nftDetailssss", nftDetails);
      dispatch({ type: LOAD_NFT_DETAILS, payload: { nftDetails } });
    } catch (error) {
      await sleep(5000);
      x--;
    }
  }
};
