import React from "react";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import {
  delete_address,
  get_address,
  make_default,
  save_address,
  get_all_states,
  get_all_countries,
} from "../../../api/Api-list";
import { Container } from "react-bootstrap";
import { Col, Row } from "antd";
import MyaddressList from "./MyaddressList";
import "./MyAddress.scss";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useEffect } from "react";
import Swal from "sweetalert2";

const MyAddress = ({ editAddress }) => {
  const addressType = ["Home", "Work"];
  const [addressData, setAddressData] = useState([]);
  const { addToast } = useToasts();
  const [toggle, setToggle] = useState(false);

  const [allStates, setAllStates] = useState([]);
  const [allCountries, setAllCountries] = useState([]);

  const app_token = localStorage.getItem("tiger-token");

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  useEffect(() => {
    getAddress();
    getAllCountry();
  }, []);

  const getAllStates = async (id = "") => {
    try {
      const response = await axios.get(get_all_states(id), config);
      if (response.status < 400) {
        setAllStates(response.data.states);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllCountry = async () => {
    try {
      const response = await axios.get(get_all_countries, config);
      if (response.status < 400) {
        setAllCountries(response.data.countries);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const initialValues = {
    fullName: "",
    phone: "",
    pincode: "",
    address: "",
    city: "",
    state: "",
    country: "",
    landmark: "",
    addressType: addressType[0],
  };

  const makeDefaultAddress = async (id) => {
    try {
      const response = await axios.patch(make_default(id), {}, config);
      if (response.status < 400) {
        addToast("Add Default Address successfully", {
          appearance: "success",
          autoDismiss: true,
          position: "top-center",
        });
        getAddress();
        setToggle(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/; // for Mobile Numbers

  const validationSchema = yup.object().shape({
    fullName: yup.string().required("Name is required").trim(),
    phone: yup
      .string()
      .required("Please provide a contact number")
      .length(10, "Please enter a valid mobile number.")
      .typeError("Please Enter a Valid Pincode")
      .matches(phoneRegExp, "Please enter a valid mobile number."),
    pincode: yup
      .string()
      .required("Pincode is required")
      .typeError("Please Enter a Valid Pincode"),
    address: yup.string().required("Address is required").trim(),
    city: yup.string().required("City is required").trim(),
    state: yup.string().required("State is required").trim(),
    country: yup.string().required("Country is required").trim(),
    landmark: yup.string().required("Landmark is required").trim(),
    addressType: yup
      .mixed()
      .oneOf(addressType)
      .required("This field is required"),
  });

  const addAddress = async (values, resetForm) => {
    try {
      const {
        fullName,
        phone,
        pincode,
        address,
        city,
        country,
        state,
        landmark,
        addressType,
      } = values;

      const payload = {
        fullName,
        phone: Number(phone),
        pincode,
        address,
        city,
        state,
        country,
        landmark,
        addressType,
      };

      const response = await axios.post(save_address, payload, config);
      if (response.status < 400) {
        addToast("Add Address successfully", {
          appearance: "success",
          autoDismiss: true,
          position: "top-center",
        });
        getAddress();
        setToggle(false);
      }
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
        position: "top-center",
      });
      console.log(err);
    }
  };

  const getAddress = async () => {
    try {
      const response = await axios.get(get_address, config);
      console.log(response.data.address);
      setAddressData(response.data.address);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAddress = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Successfully!", "Address Deleted Successfully", "success");

        axios
          .delete(delete_address(id), config)
          .then((res) => {
            if (res.status === 200) {
              getAddress();
              setToggle(false);
            }
          })
          .catch((err) => {
            console.log(err);
            addToast(err.response.data.message, {
              appearance: "error",
              autoDismiss: true,
              position: "top-center",
            });
          });
      }
    });
  };

  return (
    <Container>
      <Row justify="center">
        <Col lg={10} md={20} xs={24} sm={24} className="mt-5">
          {toggle ? (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                addAddress(values, resetForm);
              }}
            >
              {({
                values,
                setFieldValue,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => {
                return (
                  <form className="my-account" onSubmit={handleSubmit}>
                    <div className="ant-form-item">
                      <input
                        type="text"
                        placeholder="Full Name"
                        name="fullName"
                        value={values.fullName}
                        className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <div className="ant-form-item-explain-error">
                        <ErrorMessage name="fullName" />
                      </div>
                    </div>
                    <div className="ant-form-item">
                      <input
                        type="text"
                        placeholder="Contact Number"
                        name="phone"
                        className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                        value={values.phone}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <div className="ant-form-item-explain-error">
                        <ErrorMessage name="phone" />
                      </div>
                    </div>
                    <div className="ant-form-item">
                      <input
                        type="text"
                        placeholder="Pin Code"
                        name="pincode"
                        className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                        value={values.pincode}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <div className="ant-form-item-explain-error">
                        <ErrorMessage name="pincode" />
                      </div>
                    </div>

                    <div className="ant-form-item">
                      <input
                        type="text"
                        placeholder="Address"
                        name="address"
                        value={values.address}
                        className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <div className="ant-form-item-explain-error">
                        <ErrorMessage name="address" />
                      </div>
                    </div>

                    <div className="ant-form-item">
                      <input
                        type="text"
                        placeholder="City"
                        name="city"
                        className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                        value={values.city}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <div className="ant-form-item-explain-error">
                        <ErrorMessage name="city" />
                      </div>
                    </div>

                    <div className="ant-form-item">
                      <select
                        className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                        onChange={(e) => {
                          setFieldValue("country", e.target.value);
                          getAllStates(e.target.value);
                          setFieldValue("state", "");
                        }}
                        value={values.country}
                      >
                        <option value="">Select a country</option>
                        {allCountries.map((country) => {
                          return (
                            <option value={country._id}>
                              {country.countryName}
                            </option>
                          );
                        })}
                      </select>
                      <div className="ant-form-item-explain-error">
                        <ErrorMessage name="country" />
                      </div>
                    </div>

                    <div className="ant-form-item">
                      <select
                        className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                        onChange={(e) => {
                          setFieldValue("state", e.target.value);
                        }}
                        disabled={values.country === ""}
                        value={values.state}
                      >
                        <option value="">Select a state</option>
                        {allStates.map((state) => {
                          return (
                            <option value={state.stateId}>
                              {state.stateName}
                            </option>
                          );
                        })}
                      </select>
                      <div className="ant-form-item-explain-error">
                        <ErrorMessage name="state" />
                      </div>
                    </div>

                    <div className="ant-form-item">
                      <input
                        type="text"
                        placeholder="Landmark"
                        name="landmark"
                        className="ant-input bg-transparent !py-[0.7rem] focus:!shadow-none !rounded-[0.1rem] text-black"
                        value={values.landmark}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <div className="ant-form-item-explain-error">
                        <ErrorMessage name="landmark" />
                      </div>
                    </div>

                    <div className="flxRedio">
                      <div className="mr20">
                        <input
                          type="radio"
                          name="addressType"
                          id={addressType[0]}
                          value={addressType[0]}
                          onBlur={handleBlur}
                          onChange={() => {
                            setFieldValue("addressType", addressType[0]);
                          }}
                          checked={values.addressType === addressType[0]}
                        />
                        <label
                          htmlFor={addressType[0]}
                          style={{ color: "white" }}
                        >
                          Home
                        </label>
                      </div>
                      <div className="mr20">
                        <input
                          type="radio"
                          name="addressType"
                          id={addressType[1]}
                          value={addressType[1]}
                          onBlur={handleBlur}
                          onChange={() => {
                            setFieldValue("addressType", addressType[1]);
                          }}
                          checked={values.addressType === addressType[1]}
                        />
                        <label
                          htmlFor={addressType[1]}
                          style={{ color: "white" }}
                        >
                          Work
                        </label>
                      </div>
                      <ErrorMessage name="addressType" />
                    </div>
                    <button
                      type="submit"
                      className="text-[20px] font-bold text-center w-full mt-2 bg-[#ff8500] transition-all duration-75 hover:bg-orange-600 text-white py-[10px] rounded-3xl"
                    >
                      Add Address
                    </button>
                  </form>
                );
              }}
            </Formik>
          ) : (
            ""
          )}

          {!toggle ? (
            <button
              onClick={() => setToggle(!toggle)}
              className="text-[20px] font-bold text-center w-full mt-2 bg-[#245ef3] transition-all duration-75 hover:bg-orange-600 text-white py-[10px] rounded-3xl"
            >
              Add Address
            </button>
          ) : (
            ""
          )}

          <br />
          <MyaddressList
            addressData={addressData}
            deleteAddress={deleteAddress}
            makeDefaultAddress={makeDefaultAddress}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default MyAddress;
