import React from "react";
import { FaInstagram } from "react-icons/fa";
import { RiLinkedinFill } from "react-icons/ri";
import { RiTwitterFill } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import "./TeamCard.scss";

function TeamCard({ team }) {
  const location = useLocation();
  const home = location.pathname === "/";

  return (
    <div className="text-white flex-col items-center justify-center w-100 md:w-40 space-y-2 my-3 team_Card">
      <img
        src={team.image}
        className="w-full rounded-2xl max-w-[16rem] h-[15rem] mx-auto object-fill"
        alt="team"
      />

      <div className="member_name font-bold text-white hover:!text-orange-500 flex justify-center text-sm">
        {team.firstName}
        &nbsp;
        {team.lastName}
      </div>

      <div>
        <div className="member_text text-gray-700 flex justify-center text-[12px]">
          {team.description}
        </div>
      
          <div className="member_socialmedia flex justify-around">
            <a href={team.twitter} target="_blank" rel="noreferrer">
              <RiTwitterFill />
            </a>
            <a href={team.instagram} target="_blank" rel="noreferrer">
              <FaInstagram />
            </a>
            <a href={team.linkedin} target="_blank" rel="noreferrer">
              <RiLinkedinFill />
            </a>
          </div>
       
      </div>

    </div>
  );
}

export default TeamCard;
