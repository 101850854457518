import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "../shoppingCart.scss";
import * as actionTypes from "../../../redux/types/cartSectionType";
import axios from "axios";
import { add_to_cart } from "../../../api/Api-list";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

function ShippingService({ previousStep, nextStep }) {
  const cartStep = useSelector((state) => state.cartSection.cartStep);
  const shippingRates = useSelector((state) => state.cartSection.shippingRates);
  const selectedShippingRate = useSelector(
    (state) => state.cartSection.selectedShippingRate
  );

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const app_token = localStorage.getItem("tiger-token");

  const config = {
    headers: {
      Authorization: `Bearer ${app_token}`,
    },
  };

  const setShippingService = async () => {
    if (selectedShippingRate === null)
      return addToast("Please select a service to continue", {
        appearance: "error",
        autoDismiss: true,
      });

    const payload = {
      selectedShippingRate: selectedShippingRate,
    };
    try {
      const response = await axios.post(add_to_cart, payload, config);
      if (response.status < 400) {
        nextStep();
        dispatch({
          payload: 4,
          type: actionTypes.CART_STEP,
        });
      }
    } catch (err) {
      addToast(err.response.data.msg || err.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      <div className="cart-step-adrs-sec">
        {shippingRates.map((rates) => {
          return (
            <>
              <div className="no-border cart-add-dtl">
                <h3>{rates.serviceName}</h3>
                <p>
                  <span>Shipping charge :</span> $
                  {rates.ratedShipmentDetails[0].totalNetFedExCharge.toFixed(2)}
                </p>
                <div className="cart-step-radio">
                  <input
                    type="radio"
                    checked={
                      selectedShippingRate?.serviceName === rates.serviceName
                    }
                    onChange={() => {
                      dispatch({
                        type: actionTypes.UPDATE_SELECTED_SHIPPING_RATE,
                        payload: rates,
                      });
                    }}
                  />
                </div>
              </div>
              <hr className="border-b-black border-white" />
            </>
          );
        })}
        <div className="flex justify-center checkout_button_order mt-5">
          <button
            className="text-lg  transition-all ease-in duration-150 hover:bg-orange-600 font-bold text-center w-full bg-[#FF8500] text-white py-2 rounded-full mx-2"
            onClick={() => {
              previousStep();
              dispatch({
                payload: 2,
                type: actionTypes.CART_STEP,
              });
            }}
          >
            Previous
          </button>
          <button
            className="text-lg  transition-all ease-in duration-150 hover:bg-orange-600 font-bold text-center w-full bg-[#FF8500] text-white py-2 rounded-full mx-2"
            onClick={() => {
              setShippingService();
            }}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default ShippingService;
