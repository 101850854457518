import axios from "axios";
import { fetch_Single_Product_by_Slug } from "../../api/Api-list";
import { STORE_SINGLE_PRODUCT_DATA } from "../types/singleProductType";

const getSingleProduct = (id) => {
  return async (dispatch) => {
    const app_token = localStorage.getItem("tiger-token");
    const config = {
      headers: {
        Authorization: `Bearer ${app_token}`,
      },
    };
    try {
      const response = await axios.get(
        `${fetch_Single_Product_by_Slug}/${id}`,
        app_token && config
      );
      if (response.status < 400) {
        dispatch({
          type: STORE_SINGLE_PRODUCT_DATA,
          payload: {
            data: response?.data?.data,
            oneEth: response?.data?.oneEth,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export default getSingleProduct;
