import {
  FETCH_USERS_BY_ADMIN,
  DELETE_USER_BY_ADMIN,
  ADD_USER_BY_ADMIN,
  EDIT_USER_BY_ADMIN,
  VIEW_USER_BY_ADMIN,
} from "../types/usertypes";

const initState = {
  users: [],
};

const fetchUsersDataByAdminReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case FETCH_USERS_BY_ADMIN:
      return {
        ...state,
        users: payload,
      };
    case DELETE_USER_BY_ADMIN:
      return {
        ...state,
        users: payload,
      };
    case ADD_USER_BY_ADMIN:
      return {
        ...state,
        users: payload,
      };
    case EDIT_USER_BY_ADMIN:
      return {
        ...state,
        users: payload,
      };
    case VIEW_USER_BY_ADMIN:
      return {
        ...state,
        users: payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default fetchUsersDataByAdminReducer;
