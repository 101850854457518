import { FORGOT_PASSWORD, FORGOT_PASSWORD_FAILURE } from "../types/usertypes";

const initialState = {
  forgotPassword: [],
  forgotPasswordFailure: [],
};
export const forgotReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FORGOT_PASSWORD:
      return { ...state, forgotPassword: payload };
    case FORGOT_PASSWORD_FAILURE:
      return { ...state, forgotPasswordFailure: payload };
    default:
      return state;
  }
};
