import { GET_HOLDER_PAGE } from "../types/cmsPageType";

const initState = {
  holdersContent: [],
};
const holderPageReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case GET_HOLDER_PAGE:
      return {
        ...state,
        holdersContent: payload,
      };

    default:
      return {
        ...state,
        payload: payload,
      };
  }
};
export default holderPageReducer;
